import React from "react";
import { Grid, Paper, } from "@material-ui/core";

import { useStyles } from "./styles";
import { formatDate } from "../../helpers/helperFunctions";
import { STATUS, STATUS_TEXT } from "../../constants";

const DispatchMaterialDetails = ({ dispatchMaterial: poDetails, consignee }) => {
    const classes = useStyles();

    const returnStatus = (status) => {
        switch (status) {
            case STATUS.PENDING:
                return (
                    <button style={{ textTransform: 'uppercase' }} className={classes.pending}>
                        {STATUS_TEXT.PENDING}
                    </button>
                );
            case STATUS.APPROVED:
                return (
                    <button style={{ textTransform: 'uppercase' }} className={classes.approved}>
                        {STATUS_TEXT.APPROVED}
                    </button>
                );
            case STATUS.REJECTED:
                return (
                    <button style={{ textTransform: 'uppercase' }} className={classes.rejected}>
                        {STATUS_TEXT.REJECTED}
                    </button>
                );
            case STATUS.COMPLETED:
                return (
                    <button style={{ textTransform: 'uppercase' }} className={classes.completed}>
                        {STATUS_TEXT.COMPLETED}
                    </button>
                );
            case STATUS.IN_PROGRESS:
                return (
                    <button style={{ textTransform: 'uppercase' }} className={classes.inProgress}>
                        {STATUS_TEXT.IN_PROGRESS}
                    </button>
                );
            default:
                return null;
        }
    };

    return (<div style={{ marginBottom: "25px" }}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Paper style={{ padding: "10px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <Grid container>
                            <Grid item xs={8}>
                                <div className={classes.textLight}>
                                    Date : <span className={classes.textDark}>
                                        {/* {moment(poDetails.created).format("YYYY-MM-DD")} */}
                                        {formatDate(poDetails.dp_date)}
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ color: "#F08910" }}>{poDetails.text_status}</div>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div
                            className={classes.textLight}
                            style={{ marginBottom: "5px" }}
                        >
                            Consignee Name
                        </div>
                        <div className={classes.textDark}>{poDetails.consignee_name}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div
                            className={classes.textLight}
                            style={{ marginBottom: "5px" }}
                        >
                            Consignee GST
                        </div>
                        <div className={classes.textDark}>{consignee?.gst}</div>
                    </div>
                    <div>
                        <div
                            className={classes.textLight}
                            style={{ marginBottom: "5px" }}
                        >
                            Consignee Address
                        </div>
                        <div className={classes.textDark}>
                            {poDetails.care_off}
                            {poDetails.house_no}&nbsp;
                            {poDetails.street_2}<br />
                            {poDetails.street_3}<br />
                            {poDetails.street_4}&nbsp;
                            {poDetails.street_5}<br />
                            {poDetails.city}<br />
                            {poDetails.district} - {poDetails.postal_code}<br />
                            {poDetails.consignee_state}
                        </div>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
                <Paper style={{ padding: "10px" }}>
                    <div
                        className={classes.textLight}
                        style={{ marginBottom: "10px" }}
                    >
                        DP Reference Number : <span className={classes.textDark}>{poDetails.ref_no}</span>
                        <span style={{
                            float: 'right'
                        }}>
                            DP Status :&nbsp;&nbsp;
                            {returnStatus(poDetails.status)}
                        </span>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <div>
                                    <div
                                        className={classes.textLight}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Material Code
                                    </div>
                                    <div className={classes.textDark}>{poDetails.material_code}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <div>
                                    <div
                                        className={classes.textLight}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Material :
                                    </div>
                                    <div className={classes.textDark} >{poDetails.material_name}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <div>
                                    <div
                                        className={classes.textLight}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Quantity
                                    </div>
                                    <div className={classes.textDark}>{poDetails.quantity} MT</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <hr />
                    <div style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <h4>
                            Transportation Details :
                        </h4>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <div>
                                    <div
                                        className={classes.textLight}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Vehicle Number
                                    </div>
                                    <div className={classes.textDark}>{poDetails.vehicleNumber || '-'}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <div>
                                    <div
                                        className={classes.textLight}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Transporter Name
                                    </div>
                                    <div className={classes.textDark}>{poDetails.transporterName || '-'}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <div>
                                    <div
                                        className={classes.textLight}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        Driver Contact Number
                                    </div>
                                    <div className={classes.textDark} style={{ textTransform: 'uppercase' }}>{poDetails.driverContactNumber || '-'}</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <div
                            className={classes.textLight}
                            style={{ marginBottom: "5px" }}
                        >
                            Additional Comments
                        </div>
                        <div className={classes.textDark}>
                            {poDetails.transportRemarks || '---'}
                        </div>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    </div >)
}

export default DispatchMaterialDetails;