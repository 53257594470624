import React from 'react';
import axios from 'axios';
import { Grid, } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { Form } from '../../components/useForm';
import { FormControl } from '@material-ui/core';
import { getCurrentUser } from '../../services/userService';
import { getPurchaseOrderDetailsForView, } from '../../services/PurchaseOrder';
import { withRouter } from "react-router";

const token = localStorage.getItem('token');
const SERVERBASEURL = process.env.REACT_APP_API;

class UpdateTruckDetails extends React.Component {
    state = {
        currentUser: null,
        selectedPO: "",
        vehicleNumber: null,
        transporterName: null,
        driverContactNumber: null,
        transportRemarks: null,
        phoneError: null,
    }

    getCurrentUserFunc = async () => {
        let user = await getCurrentUser();
        this.setState({ currentUser: user.data.body });
        return user;
    };
    componentDidMount() {
        this.setState({
            vehicleNumber: this.props.dispatchMaterial?.vehicleNumber,
            transporterName: this.props.dispatchMaterial?.transporterName,
            transportRemarks: this.props.dispatchMaterial?.transportRemarks,
            driverContactNumber: this.props.dispatchMaterial?.driverContactNumber,
        })
    }

    validate = (mobile = this.state.driverContactNumber) => {
        let phoneError = '';
        let err = false;
        const letters = /^[A-Za-z]+$/;
        // console.log(this.state.driverContactNumber.match(letters));
        if (mobile === '' || mobile === null) {
            phoneError = 'Mobile number is required';
            err = true;
        } else if (
            mobile.length !== 10 ||
            !/^\d+$/.test(mobile) ||
            mobile.match(letters)
        ) {
            phoneError = 'Please enter valid mobile number';
            err = true;
        }
        this.setState({ phoneError });
        return !err;
    };

    handleFormSubmit = (e) => {
        if (this.validate()) {
            e.preventDefault();
            var formData = {
                vehicleNumber: this.state.vehicleNumber,
                transporterName: this.state.transporterName,
                driverContactNumber: this.state.driverContactNumber,
                transportRemarks: this.state.transportRemarks
            }
            var api = {
                method: 'post',
                url: SERVERBASEURL + "/dispatch-order/vehicle-details/" + this.props.dispatchPlanId + "/" + this.props.dispatchMaterialId,
                data: formData,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            axios(api)
                .then((res) => {
                    if (res?.status === 200 || res?.status === 201) {
                        this.props.setNotify({
                            isOpen: true,
                            message: res.data?.message || 'Vehicle Details Updated Successfully !',
                            type: 'success',
                        }
                        );
                    }
                    this.props.updateDPDetails();
                    this.props.handleClose();
                })
                .catch((err) => {
                    this.props.setNotify({
                        isOpen: true,
                        message: `${err?.response?.data?.message || 'Truck Details Update Unsuccessful !'}`,
                        type: 'error',
                    }
                    );
                    console.log('Error in performing operation', JSON.stringify(err?.response?.data?.message));
                    this.props.handleClose();
                });
        } else {
            this.props.setNotify({
                isOpen: true,
                message: 'Empty Values ! Please check !',
                type: 'error',
            });
        }
    };

    fetchPODetails = async () => {
        let response = await getPurchaseOrderDetailsForView(this.props.poId);
        this.setState({ poDetails: response.data.data[0] })
    }

    render() {
        return (
            <div className='root'>
                <Form onSubmit={this.handleFormSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"vehicleNumber"}
                                    type='text'
                                    label='Vehicle Number'
                                    value={this.state.vehicleNumber}
                                    required={true}
                                    onChange={e => this.setState({ vehicleNumber: e.target.value })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"transporterName"}
                                    type='text'
                                    label='Transporter Name'
                                    value={this.state.transporterName}
                                    onChange={e => this.setState({ transporterName: e.target.value })}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"driverContactNumber"}
                                    type='text'
                                    label='Driver Contact Number'
                                    value={this.state.driverContactNumber}
                                    required={true}
                                    error={this.state.phoneError}
                                    onChange={e => {
                                        this.setState({ driverContactNumber: e.target.value })
                                        this.validate(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"transportRemarks"}
                                    type='text'
                                    label='Any extra remarks'
                                    value={this.state.transportRemarks}
                                    onChange={e => this.setState({ transportRemarks: e.target.value })}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button color="primary" variant="contained" type='submit'
                                text="Save Vehicle Details"
                                onClick={this.handleFormSubmit}
                            />
                        </Grid>
                    </Grid>
                </Form>

            </div>
        );
    }
}

export default withRouter(UpdateTruckDetails);