import { useState, useEffect } from 'react';
import {
  approveReleaseRate,
  getHistoryReleaseRate,
  getReleaseRate,
  insertReleaseRate,
  updateReleaseRate,
} from '../services/dashboardService';
import moment from 'moment';
import {
  getAllProposedDiscounts,
  getTodaysProposedDiscount,
  getMaximumQuantity,
} from '../services/proposedDiscountService';
import { getCreditLimitByOrgId } from '../services/creditLimit';
import { getTillDateDispatch, getPendingOrderQuantity } from '../services/dispatchPlan';
import { getTodaysBookingQuantity } from '../services/orderBookingService';

import Card from '../components/ui/Card';
import { Dialog } from '../components/Dialog';
import { Form, useForm } from '../components/useForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../components/controls/Button';
import {
  lighten,
  makeStyles,
  Grid,
  useTheme,
  Typography,
  DialogActions,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableSortLabel,
  Tooltip,
  Link,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';

import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Input from '../components/controls/Input';
import ActionButton from '../components/controls/ActionButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DialogContent from '@material-ui/core/DialogContent';
import Notifications from '../components/Notifications';
import { getCurrentUser } from '../services/userService';
import { getFe500GradeRate, getTodaysReleaseRateForOrganization } from '../services/releaseRates';
import { DASHBOARD_RATE_DISPLAY, USER_TYPES } from '../constants';
import { DashboardStats } from './DashboardStats';
import { getGradeList } from '../services/gradeService';
import { getAllDiscountsForOrganization } from '../services/discountService';
import DiscountTable from './Discount/DiscountTable';
import SpecificReleaseRates from './SpecificReleaseRates';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    disableSorting: true,
    label: 'Date',
  },
  {
    id: 'suggestedrate',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Suggested Rate (Rs)',
  },
  {
    id: 'releaserate',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Released Rate (₹)',
  },
  {
    id: 'releaserategujarat',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Released Rate (₹) - Gujarat',
  },
  {
    id: 'releaseraterajasthan',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Released Rate (₹) - Rajasthan',
  },
  {
    id: 'releaseratemaharashtra',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Released Rate (₹) - Maharashtra',
  },
  {
    id: 'releaseratemp',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Released Rate (₹) - M.P.',
  },
  {
    id: 'nod_rate',
    numeric: true,
    disablePadding: false,
    disableSorting: true,
    label: 'Nod Rate (Rs)',
  },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
            // direction={orderBy === headCell.id ? order : "asc"}
            // onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null} */}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyle = makeStyles({
  root: {
    margin: '1em',
    height: '6em',
  },
  btn: {
    color: '#4DE019',
    borderColor: '#4DE019',
    '&:hover': {
      background: '#4DE019',
      color: '#fff',
    },
  },
  editBtn: {
    padding: '0.3rem',
    '&:hover': {
      color: '#4DE019',
    },
  },
  container: {
    display: 'block',
    maxWidth: '85vw',
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '10px',
    margin: '10px',
  },
  fixedHeightPaper: {
    // padding: theme.spacing(2),
    padding: '10px',
    marginTop: '10px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 150,
  },
});

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openProposedDiscounts, setOpenProposedDiscounts] = useState(false);
  const [todaysProposedDiscount, setTodaysProposedDiscount] = useState({});
  const [allProposedDiscount, setAllProposedDiscount] = useState([]);

  const [historyData, setHistoryData] = useState([]);
  const [reload, setReload] = useState(false);
  const [Fe500GradeRate, setFe500GradeRate] = useState(null);
  const [grades, setGrades] = useState([]);
  const [releaseRate, setReleaseRate] = useState({});
  const [todaysReleaseRateForDistributor, setTodaysReleaseRateForDistributor] = useState({});
  const [creditLimit, setCreditLimit] = useState();
  const [tillDateDispatch, setTillDateDispatch] = useState();
  const [pendingOrderQuantity, setPendingOrderQuantity] = useState();
  const [todaysBookingQuantity, setTodaysBookingQuantity] = useState();
  const [newReleaseRateExist, setNewReleaseRateExist] = useState(true);
  const [userType, setUserType] = useState(localStorage.getItem('user_type'));
  const [currentUser, setCurrentUser] = useState();
  const [discounts, setDiscounts] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  //
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);

  const [pageProposed, setPageProposted] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [rowsPerPageProposted, setRowsPerPageProposted] = useState(5);
  //
  const [maximumDis, setMaximumDis] = useState(null);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyle();
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const initialFormValues = {
    suggested_base_rate: '',
    today_date: today.toDateString(),
    new_release_rate: '0',
    new_release_rate_gujarat: '0',
    new_release_rate_rajasthan: '0',
    new_release_rate_maharashtra: '0',
    new_release_rate_mp: '0',
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };
  const handleClickOpenProposedDiscounts = () => {
    setOpenProposedDiscounts(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseHistory = () => {
    setOpenHistory(false);
    setOpenProposedDiscounts(false);
  };
  const handleApprove = async (id) => {
    const { data } = await approveReleaseRate(id, {
      new_release_rate: releaseRate.suggested_base_rate,
      new_release_rate_gujarat: releaseRate.suggested_base_rate,
      new_release_rate_maharashtra: releaseRate.suggested_base_rate,
      new_release_rate_rajasthan: releaseRate.suggested_base_rate,
      new_release_rate_mp: releaseRate.suggested_base_rate,
    });
    if (data.status === 0) {
      setNotify({
        isOpen: true,
        message: data.message || 'Release Rate could not be approved.',
        type: 'error',
      });
    } else if (data.status === 1) {
      setNotify({
        isOpen: true,
        message: data.message || 'Release Rate approved successfully.',
        type: 'success',
      });
      setReload(!reload);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePageProposed = (event, newPage) => {
    setPageProposted(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeRowsPerPageProposed = (event) => {
    setRowsPerPageProposted(parseInt(event.target.value, 10));
    setPage(0);
  };
  let emptyRows;
  // console.log(' jwt malformed', historyData);
  if (historyData) {
    emptyRows = rowsPerPage - Math.min(rowsPerPage, historyData.length - page * rowsPerPage);
  }

  const token = localStorage.getItem('token');
  const validate = () => {
    let temp = {};
    temp.new_release_rate = values.new_release_Rate ? '' : 'This filed is required';
  };
  const { values, setValues, handleInputChange } = useForm(initialFormValues, true, validate);

  const handleSubmit = async (e, id) => {
    // e.preventDefault();
    if (validate) {
      const { data } = await approveReleaseRate(id, {
        new_release_rate: values.new_release_rate,
        new_release_rate_gujarat: values.new_release_rate_gujarat,
        new_release_rate_rajasthan: values.new_release_rate_rajasthan,
        new_release_rate_maharashtra: values.new_release_rate_maharashtra,
        new_release_rate_mp: values.new_release_rate_mp,
      });
      if (data.status === 0) {
        setNotify({
          isOpen: true,
          message: data.message || 'Release Rate could not be approved.',
          type: 'error',
        });
      } else if (data.status === 1) {
        setNotify({
          isOpen: true,
          message: data.message || 'Release Rate approved successfully.',
          type: 'success',
        });
      }
    }
  };

  const getCurrentUserFunc = async () => {
    // setLoading(true)
    const user = await getCurrentUser();
    if (user && user.data) {
      setCurrentUser(user.data.body);
      return user.data.body;
    }
    // setLoading(false)
  };

  useEffect(() => {
    const getTodaysReleaseRateForDistributorsState = async () => {
      let fetchedUser = await getCurrentUserFunc();
      if (fetchedUser.user_type === 'distributor') {
        let response = await getTodaysReleaseRateForOrganization(fetchedUser.org_id);
        if (response) {
          setTodaysReleaseRateForDistributor(response.data.data);
        }
      }
    };
    getTodaysReleaseRateForDistributorsState();
  }, [reload]);

  useEffect(() => {
    const fetchFe500Rate = async () => {
      let response = await getFe500GradeRate();
      if (response) {
        setFe500GradeRate(response.data.data?.rate);
      }
      // }
    };
    fetchFe500Rate();
  }, [reload]);

  useEffect(() => {
    const fetchGrades = async () => {
      let response = await getGradeList();
      if (response) {
        setGrades(response.data.data);
      }
      // }
    };
    fetchGrades();
  }, [reload]);

  useEffect(() => {
    const releaseRate = async () => {
      const rRate = await getReleaseRate();
      if (rRate.data.data !== null && rRate.data.body !== null && rRate.data.data.length > 0) {
        if (rRate.data.data[0].new_release_rate !== 0) {
          const maximumDis = await getMaximumQuantity(rRate.data.data[0].new_release_rate, rRate.data.data[0].id);
          if (maximumDis.data.body) {
            setMaximumDis(maximumDis.data.body.MaximumQty);
          } else {
            setMaximumDis(rRate.data.data[0].new_release_Rate);
          }
          historyReleaseRates();
        }

        setReleaseRate(rRate.data.data[0] || 0);
        // setNewReleaseRateExist(false)
        setValues({
          suggested_base_rate: rRate.data.data[0].suggested_base_rate,
          new_release_rate: rRate.data.data[0].new_release_rate || rRate.data.data[0].suggested_base_rate,
          new_release_rate_gujarat: rRate.data.data[0].new_release_rate || rRate.data.data[0].suggested_base_rate,
          new_release_rate_rajasthan: rRate.data.data[0].new_release_rate || rRate.data.data[0].suggested_base_rate,
          new_release_rate_maharashtra: rRate.data.data[0].new_release_rate || rRate.data.data[0].suggested_base_rate,
          new_release_rate_mp: rRate.data.data[0].new_release_rate || rRate.data.data[0].suggested_base_rate,
        });
        if (rRate.data.data.length <= 0) {
          setNewReleaseRateExist(false);
        }
      } else {
        setNewReleaseRateExist(false);
      }
    };
    const historyReleaseRates = async () => {
      const historyRates = await getHistoryReleaseRate();
      setHistoryData(historyRates.data.data);
    };
    historyReleaseRates();
    releaseRate();
  }, [reload]);

  useEffect(() => {
    let creditLimitByOrgId = async () => {
      let data = await getCreditLimitByOrgId();
      let creditlimit = data.data.data;
      // console.log(creditlimit)
      if (creditlimit.length > 0) setCreditLimit(creditlimit[0].credit_limit_value);
    };
    let getTillDateDispatchQuantity = async () => {
      let data = await getTillDateDispatch();
      // console.log("\n\n\n ggggg "+JSON.stringify(data.tillDateDispatchQuantity))
      let tillDateDispatch = data.tillDateDispatchQuantity;
      // console.log(tillDateDispatch, 'tillDateDispatch')
      setTillDateDispatch(tillDateDispatch);
    };
    let getPendingDispatchOrderQuantity = async () => {
      let data = await getPendingOrderQuantity();
      // console.log("\n\n\n ggggg "+JSON.stringify(data.tillDateDispatchQuantity))
      let pendingOrderQuantity = data.pendingOrderQuantity;
      // console.log(tillDateDispatch, 'tillDateDispatch')
      setPendingOrderQuantity(pendingOrderQuantity);
    };
    let fetchTodaysBookingQuantity = async () => {
      let data = await getTodaysBookingQuantity();
      // console.log("\n\n\n ggggg "+JSON.stringify(data.tillDateDispatchQuantity))
      let todaysBookingQuantity = data.todaysBookingQuantity;
      // console.log(tillDateDispatch, 'tillDateDispatch')
      setTodaysBookingQuantity(todaysBookingQuantity);
    };
    getPendingDispatchOrderQuantity();
    getTillDateDispatchQuantity();
    fetchTodaysBookingQuantity();
    creditLimitByOrgId();
  }, [reload]);

  const functdiscounts = async () => {
    if (currentUser?.user_type === USER_TYPES.DISTRIBUTOR) {
      const discountLists = await getAllDiscountsForOrganization(currentUser?.org_id);
      const dLists = discountLists.data.data;
      setDiscounts(dLists);
    }
  };

  useEffect(() => {
    functdiscounts();
  }, [currentUser, reload]);


  function createData(name, calories, fat, carbs, protein, invoice) {
    return { name, calories, fat, carbs, protein, invoice };
  }

  const displayDistributorStats = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={classes.fixedHeightPaper}>
              <Typography
                style={{ minHeight: '75px', textAlign: 'center' }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Pending Order Quantity
              </Typography>
              <Typography component="p" variant="h4" style={{ textAlign: 'center' }}>
                {pendingOrderQuantity ? pendingOrderQuantity + ' MT' : '0'}
              </Typography>
              {/* <Typography color="textSecondary" className={classes.depositContext}>
              on 15 March, 2019
            </Typography> */}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={classes.fixedHeightPaper}>
              <Typography
                style={{ minHeight: '75px', textAlign: 'center' }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Till date dispatch
              </Typography>
              <Typography component="p" variant="h4" style={{ textAlign: 'center' }}>
                {tillDateDispatch ? tillDateDispatch + ' MT' : '0'}
              </Typography>
              <Typography color="textSecondary" className={classes.depositContext}>
                {/* on {Date()} */}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={classes.fixedHeightPaper}>
              <Typography
                style={{ minHeight: '75px', textAlign: 'center' }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Today's booking quantity
              </Typography>
              <Typography component="p" variant="h4" style={{ textAlign: 'center' }}>
                {todaysBookingQuantity ? todaysBookingQuantity + ' MT' : '0'}
              </Typography>
              {/* <Typography color="textSecondary" className={classes.depositContext}>
            on 15 March, 2019
          </Typography> */}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={classes.fixedHeightPaper}>
              <Typography
                style={{ minHeight: '75px', textAlign: 'center' }}
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Credit Limit
              </Typography>
              <Typography component="p" variant="h4" style={{ textAlign: 'center' }}>
                {creditLimit ? <span>&#8377;{creditLimit}</span> : 'N/A'}
              </Typography>
              {/* <Typography color="textSecondary" className={classes.depositContext}>
            on 15 March, 2019
          </Typography> */}
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  };

  const displayRateForGrades = () => {
    return grades.length > 0 && grades.map(grade => {
      if (grade.dashboard_display === DASHBOARD_RATE_DISPLAY.ENABLED) {
        return (
          <Card classes={classes.root} style={{ marginTop: '5px' }}>
            <Grid container direction={'row'} spacing={4}>
              <Grid style={{ minWidth: '20%' }} item>
                <Typography variant="subtitle2">Today's Rate For {grade.grade_name}: </Typography>
                {/* <Typography variant="subtitle1">(inclusive of GST)</Typography> */}
              </Grid>
              <Grid style={{ minWidth: '15%' }} item>
                {currentUser?.user_type === USER_TYPES.DISTRIBUTOR ? (
                  <Typography variant="subtitle2">
                    ₹{' '}
                    {todaysReleaseRateForDistributor.new_release_rate !== 0
                      ? todaysReleaseRateForDistributor.new_release_rate + grade.rate
                      : 'N/A'}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    ₹ {releaseRate.new_release_rate !== 0 ? releaseRate.new_release_rate + grade.rate : 'N/A'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        );
      }
    });
  };
  const displayReleaseRatesForAdmin = () => {
    return (
      <Card classes={classes.root}>
        <Grid container direction={'row'} spacing={4}>
          <Grid style={{ width: '20%' }} item>
            <Typography variant="subtitle1">Release Rates: </Typography>
            <Typography variant="subtitle1">(inclusive of GST)</Typography>
          </Grid>
          <Grid style={{ width: '15%' }} item>
            <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
              Gujarat
            </Typography>
            <Typography variant="h6">
              ₹ {releaseRate.new_release_rate_gujarat != 0 ? releaseRate.new_release_rate_gujarat : ''}
            </Typography>
          </Grid>
          <Grid style={{ width: '15%' }} item>
            <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
              Maharashtra
            </Typography>
            <Typography variant="h6">
              ₹ {releaseRate.new_release_rate_maharashtra != 0 ? releaseRate.new_release_rate_maharashtra : ''}
            </Typography>
          </Grid>
          <Grid style={{ width: '15%' }} item>
            <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
              Rajasthan
            </Typography>
            <Typography variant="h6">
              ₹ {releaseRate.new_release_rate_rajasthan != 0 ? releaseRate.new_release_rate_rajasthan : ''}
            </Typography>
          </Grid>
          <Grid style={{ width: '15%' }} item>
            <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
              M. P.
            </Typography>
            <Typography variant="h6">
              ₹ {releaseRate.new_release_rate_mp != 0 ? releaseRate.new_release_rate_mp : ''}
            </Typography>
          </Grid>
          <Grid style={{ width: '15%' }} item>
            <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
              Other States
            </Typography>
            <Typography variant="h6">
              ₹ {releaseRate.new_release_rate != 0 ? releaseRate.new_release_rate : ''}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  };

  useEffect(() => {
    const todaysDiscount = async () => {
      const dis = await getTodaysProposedDiscount();
      // console.log("todays discount", JSON.stringify(dis.data.data));
      if (dis.data.data !== null && dis.data.data.length > 0) {
        // console.log("innn");

        setTodaysProposedDiscount({
          quantity: dis.data.data[0] ? dis.data.data[0].quantity : 0,
          discount: dis.data.data[0] ? dis.data.data[0].discount : 0,
          multiple_proposed_discounts: dis.data.data[0] ? dis.data.data[0].multiple_proposed_discounts : 0,
        });
      }
    };
    todaysDiscount();
  }, []);

  useEffect(() => {
    const allDiscounts = async () => {
      const dis = await getAllProposedDiscounts();
      if (dis.data.data !== null) {
        setAllProposedDiscount(dis.data.data);
      }
    };
    allDiscounts();
  }, []);

  const displayBaseRateRow = () => {
    return (<Card classes={classes.root}><Grid container direction={'row'} spacing={4}>
      <Grid style={{ width: '20%' }} item>
        <Typography variant="subtitle1">Today Date: </Typography>
        <Typography variant="subtitle1">{today.toDateString()}</Typography>
      </Grid>
      <Grid style={{ width: '15%' }} item>
        <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
          Fe 500D
        </Typography>
        <Typography style={{ color: '#1A1E6E' }} variant="subtitle1">
          20mm
        </Typography>
      </Grid>
      {userType === 'admin' ? (
        <Grid style={{ width: '15%' }} item>
          {newReleaseRateExist ? (
            <>
              {' '}
              <Typography variant="subtitle1">Suggested Rate</Typography>
              <Typography variant="subtitle1">
                Rs {releaseRate.suggested_base_rate ? releaseRate.suggested_base_rate : ''}
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle1" style={{ marginTop: '1em' }}>
              Base rate has not been suggested yet{' '}
            </Typography>
          )}
        </Grid>
      ) : userType === USER_TYPES.DISTRIBUTOR ? (
        <>
          {newReleaseRateExist && releaseRate.new_release_rate != 0 ? (
            <>
              <Grid style={{ width: '30%' }} item>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Released Rate (with GST) :{' '}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 'bold' }} color="secondary">
                  Rs {todaysReleaseRateForDistributor.new_release_rate}
                </Typography>
              </Grid>
            </>
          ) : (
            <Typography variant="subtitle1" style={{ marginTop: '1em' }}>
              Base rate has not been released yet
            </Typography>
          )}{' '}
        </>
      ) : (
        <></>
      )}
      { }
      {newReleaseRateExist && userType === 'admin' && (
        <>
          {releaseRate.new_release_rate === 0 && (
            <Grid style={{ width: '15%' }} item>
              <Button
                className={classes.btn}
                onClick={() => handleApprove(releaseRate.id)}
                variant="outlined"
                text="Approve"
              >
                Approve
              </Button>
              {/* <div
        aria-label="add"
        className={classes.editBtn}
        onClick={handleClickOpen}
      > */}
              {/* <EditIcon /> */}
              {/* </div> */}
            </Grid>
          )}
        </>
      )}
      {(userType === 'admin' || userType === USER_TYPES.SALES_PERSON) && releaseRate.new_release_rate != 0 && (
        <Grid style={{ width: '15%' }} item>
          {newReleaseRateExist && (
            <>
              {' '}
              <Typography variant="subtitle1"> Released Rate (with GST)</Typography>
              {/* <Typography variant="subtitle1"> */}
              <Typography variant="h6">
                Rs {releaseRate.new_release_rate != 0 ? releaseRate.new_release_rate : ''}
              </Typography>
            </>
          )}
        </Grid>
      )}
      {newReleaseRateExist && !releaseRate.new_release_rate != 0 && userType === 'admin' && (
        <Grid style={{ width: '15%' }} item>
          <Tooltip title="Edit Base Rate" aria-label="edit_base_rate">
            <div aria-label="add" className={classes.editBtn} onClick={handleClickOpen}>
              <EditIcon />
            </div>
          </Tooltip>
        </Grid>
      )}
      {userType === 'admin' && releaseRate.new_release_rate != 0 && newReleaseRateExist && (
        <>
          <Grid style={{ width: '15%' }} item>
            <Typography variant="subtitle1">NOD Rate</Typography>
            <Typography variant="subtitle1">INR {maximumDis}</Typography>
          </Grid>
        </>
      )}
      {userType === 'admin' && (
        <Grid item>
          <Button onClick={handleClickOpenHistory} color="primary" text="View History">
            View History
          </Button>
        </Grid>
      )}
    </Grid>
    </Card>)
  }

  return (
    <>
      {!token && <Redirect to="/" />}
      <PageHeader title="Dashboard" icon={<DashboardIcon />} />


      {currentUser?.user_type !== USER_TYPES.DISTRIBUTOR ? displayBaseRateRow() :
        !(newReleaseRateExist && releaseRate.new_release_rate !== 0) ?
          <Card classes={classes.root}>
            <Typography variant="subtitle1" style={{ marginTop: '1em' }}>
              Base rate has not been released yet
            </Typography>
          </Card> : ""
      }

      {
        newReleaseRateExist &&
        releaseRate.new_release_rate !== 0 &&
        currentUser?.user_type === USER_TYPES.DISTRIBUTOR &&
        displayRateForGrades()
      }

      {/* PROPOSED_DISCOUNTS */}
      {
        userType === 'admin' && Object.keys(todaysProposedDiscount).length > 0 && (
          <Card classes={classes.root}>
            <Grid container direction={'row'} spacing={4}>
              <Grid style={{ width: '20%' }} item>
                <Typography variant="subtitle1">Today Suggested : </Typography>
                <Typography variant="subtitle1">Discounts</Typography>
              </Grid>
              <Grid style={{ width: '15%' }} item>
                <Typography variant="subtitle1">Quantity (MT): </Typography>
                <Typography variant="subtitle1">{todaysProposedDiscount.quantity}</Typography>
              </Grid>{' '}
              <Grid style={{ width: '15%' }} item>
                <Typography variant="subtitle1">Discount (Rs/MT): </Typography>
                <Typography variant="subtitle1">{todaysProposedDiscount.discount}</Typography>
              </Grid>{' '}
              <Grid style={{ width: '30%' }} item>
                <Typography variant="subtitle1">Multiple Suggested Discounts: </Typography>
                <Typography variant="subtitle1">{todaysProposedDiscount.multiple_proposed_discounts}</Typography>
              </Grid>
              {userType === 'admin' && (
                <Grid style={{ width: '20%' }} item>
                  <Button onClick={handleClickOpenProposedDiscounts} color="primary" text="View History">
                    View History
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        )
      }

      {currentUser?.user_type === USER_TYPES.DISTRIBUTOR && displayDistributorStats()}

      {currentUser?.user_type === USER_TYPES.DISTRIBUTOR && discounts.length > 0 &&
        <Paper>
          <DiscountTable
            discounts={discounts}
            tableTitle="Discounts Available Today :"
          />
        </Paper>
      }

      {
        ((currentUser?.user_type === 'admin') || (currentUser?.user_type === USER_TYPES.SALES_PERSON)) &&
        newReleaseRateExist &&
        releaseRate.new_release_rate != 0 &&
        displayReleaseRatesForAdmin()
      }

      {
        ((currentUser?.user_type === USER_TYPES.ADMIN) || (currentUser?.user_type === USER_TYPES.SALES_PERSON)) &&
        newReleaseRateExist &&
        releaseRate.new_release_rate !== 0 &&
        displayRateForGrades()
      }

      {
        ((currentUser?.user_type === USER_TYPES.ADMIN) || (currentUser?.user_type === USER_TYPES.SALES_PERSON)) &&
        <DashboardStats />
      }

      {/* {matchesSM && (
        <Card classes={classes.root}>
          <Grid container direction={"row"} spacing={5}>
            <Grid item>
              <Typography variant="subtitle1">Release Rate: </Typography>
              <Typography variant="subtitle1" color="secondary">
                INR {values.newReleaseRate}
              </Typography>
            </Grid>
            <Grid item>
              <Button className={classes.btn} variant="outlined" text="Approve">
                Approve
              </Button>
            </Grid>
            <Grid item>
              <ActionButton color="primary">
                <EditOutlinedIcon onClick={handleClickOpen} fontSize="large" />
              </ActionButton>
            </Grid>
          </Grid>
        </Card>
      )} */}
      {
        newReleaseRateExist && (
          <Dialog heading="Set Release Rate" handleClose={handleClose} open={open} width="lg">
            {/* <Form onSubmit={(e) => handleSubmit(e, releaseRate.id)}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Input
                    variant="outlined"
                    autoFocus
                    name="today_date"
                    margin="dense"
                    id="today"
                    label="Today's Date"
                    type="text"
                    value={moment(values.today_date).format('yy-MM-DD')}
                    onChange={handleInputChange}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Input
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    name="suggested_base_rate"
                    id="SuggestedRate"
                    label="Suggested Rate"
                    type="text"
                    fullWidth
                    value={values.suggested_base_rate}
                    onChange={handleInputChange}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Input
                    variant="outlined"
                    autoFocus
                    // type='number'
                    name="new_release_rate_gujarat"
                    margin="dense"
                    id="newReleaseRate_gujarat"
                    label="New Release Rate - Gujarat"
                    type="number"
                    fullWidth
                    value={values.new_release_rate_gujarat}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item>
                  <Input
                    variant="outlined"
                    // autoFocus
                    // type='number'
                    name="new_release_rate_rajasthan"
                    margin="dense"
                    id="newReleaseRateRajasthan"
                    label="New Release Rate - Rajasthan"
                    type="number"
                    fullWidth
                    value={values.new_release_rate_rajasthan}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item>
                  <Input
                    variant="outlined"
                    // autoFocus
                    // type='number'
                    name="new_release_rate_maharashtra"
                    margin="dense"
                    id="newReleaseRateMaharashtra"
                    label="New Release Rate - Maharashtra"
                    type="number"
                    fullWidth
                    value={values.new_release_rate_maharashtra}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item>
                  <Input
                    variant="outlined"
                    // autoFocus
                    // type='number'
                    name="new_release_rate_mp"
                    margin="dense"
                    id="newReleaseRateMP"
                    label="New Release Rate - M.P."
                    type="number"
                    fullWidth
                    value={values.new_release_rate_mp}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item>
                  <Input
                    variant="outlined"
                    // autoFocus
                    // type='number'
                    name="new_release_rate"
                    margin="dense"
                    id="newReleaseRateOthers"
                    label="New Release Rate - Others"
                    type="number"
                    fullWidth
                    value={values.new_release_rate}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button
                  text="Release"
                  type="submit"
                  color="primary"
                  // onClick={handleClose}
                  style={{ margin: '0 auto' }}
                />
              </DialogActions>
            </Form> */}
            <SpecificReleaseRates releaseRate={releaseRate}
              reload={() => setReload(!reload)}
              close={handleClose}
              setNotify={setNotify}
            />
          </Dialog>
        )
      }

      {
        userType === 'admin' && (
          <Dialog heading="Released Rate History" handleClose={handleCloseHistory} open={openHistory} width="md">
            <>
              {historyData.length > 0 ? (
                <div className={classes.container}>
                  <TableContainer style={{ textAlign: 'center' }}>
                    <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={historyData.length}
                      />
                      <TableBody>
                        {stableSort(historyData, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              row.approve_date !== null && (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                  <TableCell component="th" id={labelId} scope="row">
                                    {row.approve_date.split('T')[0]}
                                  </TableCell>
                                  <TableCell align="right">{row.suggested_base_rate}</TableCell>
                                  <TableCell align="right">{row.new_release_rate}</TableCell>
                                  <TableCell align="right">{row.new_release_rate_gujarat}</TableCell>
                                  <TableCell align="right">{row.new_release_rate_rajasthan}</TableCell>
                                  <TableCell align="right">{row.new_release_rate_maharashtra}</TableCell>
                                  <TableCell align="right">{row.new_release_rate_mp}</TableCell>
                                  <TableCell align="right">{row.nod_rate === null ? 'N/A' : row.nod_rate}</TableCell>
                                </TableRow>
                              )
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={historyData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <p>No history data</p>
              )}
            </>
          </Dialog>
        )
      }

      {/* PROPOSED DISCOUNTS DIALOG */}
      {
        userType === 'admin' && (
          <Dialog heading="Proposed Discounts" handleClose={handleCloseHistory} open={openProposedDiscounts} width="md">
            <DialogContent className={classes.textCenter}>
              <div
                style={{
                  minHeight: '300px',
                  minWidth: '500px',
                  marginTop: '20px',
                }}
              >
                <div style={{ marginBottom: '25px' }}>
                  <Paper className={classes.container}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell align="center">Id</TableCell> */}
                          <TableCell align="center">Quantity (MT) </TableCell>
                          <TableCell align="center">Discount (Rs/MT)</TableCell>
                          <TableCell align="center">Multiple Suggested Discounts</TableCell>
                          <TableCell align="center">Suggested Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stableSort(allProposedDiscount, getComparator(order, orderBy))
                          .slice(
                            pageProposed * rowsPerPageProposted,
                            pageProposed * rowsPerPageProposted + rowsPerPageProposted,
                          )
                          .map((row, index) => {
                            return (
                              <>
                                <TableRow key={row.name}>
                                  {/* <TableCell component="th" scope="row" align="center">
                          {row.id}
                        </TableCell> */}
                                  <TableCell align="center">{row.quantity}</TableCell>
                                  <TableCell align="center">{row.discount}</TableCell>
                                  <TableCell align="center">{row.multiple_proposed_discounts}</TableCell>
                                  <TableCell align="center">{moment(row.created).format('yy-MM-DD')}</TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={allProposedDiscount.length}
                      rowsPerPage={rowsPerPageProposted}
                      page={pageProposed}
                      onChangePage={handleChangePageProposed}
                      onChangeRowsPerPage={handleChangeRowsPerPageProposed}
                    />
                  </Paper>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )
      }
      <Notifications notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Dashboard;
