import { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
  Drawer,
  Hidden,
  IconButton,
  Typography,
  useTheme,
  Badge,
  Collapse
} from "@material-ui/core/";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import MenuIcon from "@material-ui/icons/Menu";
import StarBorder from '@material-ui/icons/StarBorder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link, useHistory } from "react-router-dom";
import { getCurrentUser } from '../services/userService'

import AppBar from "./ui/AppBar";

// Icons
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import TuneIcon from "@material-ui/icons/Tune";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import LogoBlack from '../assets/electrotherm-logo-black.png'
import LogoWhite from '../assets/electrotherm-logo-white.png'
import { USER_TYPES } from "../constants";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingLeft: 0,
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor:'#c7c9c9'
    opacity: '0.9',
    // backgroundImage: `url('/RefinedSteel.jpeg')`,
    backgroundImage: `url('/staintless-steel.jpeg')`,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    fontWeight: 'bolder',
  }
}));

const Header = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [openPurchaseSubMenu, setOpenPurchaseSubMenu] = useState(false);
  const [openDispatchPlanSubMenu, setOpenDispatchPlanSubMenu] = useState(false);
  const [userType, setUserType] = useState(localStorage.getItem('user_type'))
  const [currentUser, serCurrentUser] = useState()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handlePowerButtonClick = () => {
    localStorage.clear();
    history.push("/");
  };
  const getCurrentUserFunc = async () => {
    try {
      const user = await getCurrentUser()
      console.log('user', user)
      if (user.data.body) {
        setUserType(user.data.body.user_type)

      }
      serCurrentUser(user.data.body)
    } catch (err) {
      history.push('/')
    }
  }
  useEffect(() => {
    getCurrentUserFunc()
  }, [])
  const handleGradesClick = () => {
    setOpen(!open);
  };
  const drawer = (
    <div style={{ textTransform: 'uppercase' }}>
      {currentUser && <img style={{ width: '150px', marginLeft: '2em', marginTop: '5px', marginBottom: '5px' }} src="/logo.png" />}
      {/* <div className={classes.toolbar} /> */}

      <Divider />
      {currentUser && <List>
        <ListItem button key={"dashboard"} component={Link} to={"/"}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
        </ListItem>

        <ListItem button key={"order_booking"}
          component={Link}
          to={"/order_booking"}>
          <ListItemIcon>
            <StarBorder />
          </ListItemIcon>

          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Order Booking" />

        </ListItem>

        <ListItem button onClick={() => setOpenPurchaseSubMenu(!openPurchaseSubMenu)} key={"purchaseorder"} component={Link} to={"/order"}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }} primary="Purchase Order" />
          {currentUser && currentUser.user_type !== USER_TYPES.SALES_PERSON && <> {openPurchaseSubMenu && currentUser.status === 1 ? <ExpandLess /> : <ExpandMore />}</>}
        </ListItem>



        {currentUser && currentUser.user_type !== USER_TYPES.SALES_PERSON && <> {currentUser.status === 1 && <Collapse in={openPurchaseSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} key={"createPO"} component={Link} to={"/order/action"}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Create Purchase Order" />
            </ListItem>
          </List>
        </Collapse>}</>}

        <ListItem button key={"Ledger Records"}
          component={Link}
          to={"/ledger"}>
          <ListItemIcon>
            <ListOutlinedIcon />
          </ListItemIcon>

          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Ledger Records" />
        </ListItem>

        <ListItem button key={"all-dispatch-plans"}
          component={Link}
          to={"/all-dispatch-plans"}>
          <ListItemIcon>
            <StarBorder />
          </ListItemIcon>

          <ListItemText classes={{ primary: classes.listItemText }} primary="Dispatch Plans" />

        </ListItem>

        <ListItem
          button
          key={"consignees"}
          component={Link}
          to={"/consignees"}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }} primary="Consignees" />
        </ListItem>
        {userType === "admin" ? <>
          <ListItem
            button
            key={"sales_person"}
            component={Link}
            to={"/sales-persons"}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Sales Persons" />
          </ListItem>

          <ListItem
            button
            key={"distributors"}
            component={Link}
            to={"/distributors"}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Distributors" />
          </ListItem>

          <ListItem button key={"discount"} component={Link} to={"/discount"}>
            <ListItemIcon>
              <MoneyOffIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Discount" />
          </ListItem>

          <ListItem button key={"districts"} component={Link} to={"/districts"}>
            <ListItemIcon>
              <MoneyOffIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Districts" />
          </ListItem>

          <ListItem button key={"masterOrgTypes"} component={Link} to={"/master-org-types"}>
            <ListItemIcon>
              <MoneyOffIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Master Customer Types" />
          </ListItem>

          <ListItem button onClick={handleGradesClick} key={"materials"} component={Link} to={"/materials"}>
            <ListItemIcon>
              <FlipToFrontIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Materials" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            in={open}
            timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} key={"grades"} component={Link} to={"/grades"}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Grades" />
              </ListItem>
              <ListItem button className={classes.nested} key={"size"} component={Link} to={"/size"}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Size" />
              </ListItem>
              <ListItem button className={classes.nested} key={"types"} component={Link} to={"/types"}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Types" />
              </ListItem>

            </List>
          </Collapse>

          <ListItem button key={"Forget Password"} component={Link} to={"/forget-password-user"}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Forget Password" />
          </ListItem>
        </> :
          <>
            {userType === USER_TYPES.DISTRIBUTOR &&
              <ListItem button key={"discount"} component={Link} to={"/discount"}>
                <ListItemIcon>
                  <MoneyOffIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Discount" />
              </ListItem>
            }
            <ListItem button key={"Forget Password"} component={Link} to={"/forget-password-user"}>
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Forget Password" />
            </ListItem>
          </>
        }

      </List>}

    </div>
  );
  return (
    <div className={classes.root}>
      <AppBar classNames={classes.appBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {/* <img style={{ width: '150px', marginLeft: '2em', marginTop: '5px', marginBottom: '5px' }} src={LogoWhite} /> */}
        <Grid container alignItems="center">
          <Grid item sm></Grid>
          <Grid item>
            <IconButton>
              {/* <Badge badgeContent={4} color="secondary">
                <NotificationsIcon fontSize="small" style={{ fill: "#fff" }} />
              </Badge> */}
              {userType === 'admin' ? <Typography variant="h6" style={{ color: 'white' }}>Admin</Typography>
                : userType === USER_TYPES.SALES_PERSON ? <Typography style={{ color: 'white' }} variant="h6">
                  {currentUser?.name}</Typography>
                  :
                  <Typography style={{ color: 'white' }} variant="h6">
                    {localStorage.getItem('org_name')}</Typography>
              }
            </IconButton>
            <IconButton></IconButton>
            <IconButton onClick={handlePowerButtonClick}>
              <PowerSettingsNewIcon fontSize="small" style={{ fill: "#fff" }} />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar}>{children}</div>
      </main>
    </div>
  );
};

export default Header;
