import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";

export default function Select({
  name,
  label,
  value,
  error = null,
  onChange,
  options,
  required,
  defaultValue,
  disabled,
  style
}) {
  if(disabled) {
    return <></>;
  }
  return (
    <FormControl variant="outlined" {...(error && { error: true })}>
      <InputLabel style={style}>{label}</InputLabel>
      <MuiSelect defaultValue={defaultValue} label={label} name={name} required={required} value={value} onChange={onChange} style={style}>
        {/* <MenuItem value="">None</MenuItem> */}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
