import {makeStyles} from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,

  textCenter: {
    textAlign: "center",
  },
  btn: {
    color: "#4DE019",
    borderColor: "#4DE019",
    "&:hover": {
      background: "#4DE019",
      color: "#fff",
    },
  },
  btnReject: {
    color: "#F43333",
    borderColor: "#F43333",
    "&:hover": {
      background: "#F43333",
      color: "#fff",
    },
  },
  table: {
    // minWidth: 650,
    // display: "block",
    // overflowX: "auto",
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
  },
  textLight: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#323232",
    opacity: "0.75",
  },
  textDark: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#323232",
    opacity: "0.99",
  },
  comment: {
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#5A1515",
    opacity: "0.75",
  },
  inProgress:{
     color: '#155724',
     backgroundColor: '#d4edda',
     borderColor: '#c3e6cb',
     border: 0,
     padding: '6px 9px',
     fontWeight: 'bold',
  },
  rejected:{
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
 },
  pending:{
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
 },
  completed:{
    color: '#fff',
    backgroundColor: '#007bff',
    borderColor: '#ffeeba',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
 },
  approved:{
    color: '#fff',
    backgroundColor: '#17a2b8',
    borderColor: '#ffeeba',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
 }
}));