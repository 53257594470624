import { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import { useForm, Form } from "../../components/useForm";
import Select from "../../components/controls/Select";
import TextField from '@material-ui/core/TextField';
import moment from "moment";

let today = new Date();
const endDate = `${today.getFullYear()}-${today.getMonth() < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${new Date().getDate()}`

const initialFormValues = {
  org_id: 0,
  grade_id: 0,
  type_id: 0,
  size_id: 0,
  discount_name: "",
  discount_type: "quantity",
  discount_parameter: "",
  discount_rate: "",
  status: 1,
  id: 0,
  end_date: endDate,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function DiscountForm({
  grades,
  sizes,
  types,
  customers,
  materials,
  addOrEdit,
  recordForEdit,
}) {
  const classes = useStyles();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // eslint-disable-next-line no-unused-vars
  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('values', values)

      addOrEdit(values, resetForm);
    }
  };

  const discountStatus = [
    { id: 1, title: "Active" },
    { id: 0, title: "Inactive" },
  ];

  const discountTypes = [
    { id: "quantity", title: "Quantity" },
    { id: "rate", title: "Rate" },
  ];

  useEffect(() => {
    console.log('---Record', recordForEdit !== null)
    if (recordForEdit !== null) {
      console.log('record', recordForEdit)
      setValues({
        ...recordForEdit,
      });
    }
    else {
      setValues({ ...initialFormValues })
    }
  }, [recordForEdit, setValues]);

  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Select
              name="org_id"
              label="Distributor Name"
              value={values.org_id}
              onChange={handleInputChange}
              options={customers}
              required
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Select
              required
              name="type_id"
              label="Type"
              value={values.type_id}
              onChange={handleInputChange}
              options={types}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              required
              name="size_id"
              label="Size"
              value={values.size_id}
              onChange={handleInputChange}
              options={sizes}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              required
              name="grade_id"
              label="Grade"
              value={values.grade_id}
              onChange={handleInputChange}
              options={grades}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="discount_name"
              value={values.discount_name}
              label="Discount Name"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              name="discount_type"
              label="Discount Type"
              value={values.discount_type}
              onChange={handleInputChange}
              options={discountTypes}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              type="number"
              name="discount_parameter"
              value={values.discount_parameter}
              label="Parameter"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              type="number"
              name="discount_rate"
              value={values.discount_rate}
              label="Rate"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={discountStatus}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="end_date"
              label="End Date"
              type="date"
              required
              inputProps={{ min: endDate }}
              value={moment(values.end_date).format('yyyy-MM-DD')}
              onChange={handleInputChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <Button text="Submit" type="submit" />
            <Button
              text="Reset"
              type="button"
              color="default"
              onClick={resetForm}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
