import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getDistributorList() {
  const distributorList = await axios.get(
    SERVERBASEURL+"/distributors",
    config
  );
  return distributorList;
}

export async function getSpecificDistributor(orgId) {
  const distributor = await axios.get(
    SERVERBASEURL+`/distributor/${orgId}`,
    config
  );
  return distributor;
}

