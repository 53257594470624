import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getMaterialListGeneric() {
  const materialList = await axios.get(
    SERVERBASEURL + "/materials_generic",
    config
  );
  return materialList;
}

export async function getPOMaterialListGeneric(poId) {
  const materialList = await axios.get(
    SERVERBASEURL + "/materials_generic_for_po/" + poId,
    config
  );
  return materialList;
}

export async function getMaterialListSpecific(genericId) {
  const materialList = await axios.get(
    SERVERBASEURL + "/materials_specific/" + genericId,
    config
  );
  return materialList;
}

export async function getMaterialList() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const materialList = await axios.get(
    SERVERBASEURL + "/materials",
    config
  );
  return materialList;
}

export async function insertMaterial(data) {
  const savedStatus = await axios.post(
    SERVERBASEURL + "/material",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateMaterial(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/material/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteMaterial(id) {
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/material/${id}`,
    config
  );
  return deleteStatus;
}

export async function getMaterialSizes() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const materailSizes = await axios.get(
    SERVERBASEURL + "/material-size-rates",
    config
  );
  return materailSizes;
}

export async function getSpecialSizes() {
  const materailSizes = await axios.get(
    SERVERBASEURL + "/special-size-rates",
    config
  );
  return materailSizes;
}

export async function insertMaterialSize(data) {
  console.log(data);
  const savedStatus = await axios.post(
    SERVERBASEURL + "/material-size-rate",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateMaterialSize(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/material-size-rate/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteMaterialSize(id) {
  console.log(id);
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/material-size-rate/${id}`,
    config
  );
  return deleteStatus;
}

export async function getMaterialGrade() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const materailSizes = await axios.get(
    SERVERBASEURL + "/material-grade-rates",
    config
  );
  return materailSizes;
}

export async function insertMaterialGrade(data) {
  console.log(data);
  const savedStatus = await axios.post(
    SERVERBASEURL + "/material-grade-rates",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateMaterialGrade(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/material-grade-rates/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteMaterialGrade(id) {
  console.log(id);
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/material-grade-rates/${id}`,
    config
  );
  return deleteStatus;
}

export async function getMaterialType() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const materailSizes = await axios.get(
    SERVERBASEURL + "/material-type-rates",
    config
  );
  return materailSizes;
}

export async function insertMaterialType(data) {
  console.log(data);
  const savedStatus = await axios.post(
    SERVERBASEURL + "/material-type-rate",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateMaterialType(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/material-type-rate/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteMaterialType(id) {
  console.log(id);
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/material-type-rate/${id}`,
    config
  );
  return deleteStatus;
}
