import React from 'react';
import {
    Grid,
    TableBody,
    Paper,
    Toolbar,
    TableRow,
    TableCell,
    InputAdornment,
    TextField,
    TableContainer,
    Table,
    TableHead,
    MenuItem,
    Select,
    InputLabel,
    Chip
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import Input from "../../components/controls/Input";
import { withStyles } from '@material-ui/core/styles';
import Button from "../../components/controls/Button";
import { Form } from "../../components/useForm";
import Notifications from "./../../components/Notifications";
import axios from "axios";
import { withRouter } from "react-router";
import { getCurrentUser } from '../../services/userService'
import {getTodaysReleaseRateForOrganization} from "../../services/releaseRates";
import {USER_TYPES } from "../../constants"

const cssStyles = ((theme) => ({
    pageContent: {
        // margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,

    searchInput: {
        width: "58%",
        marginLeft: "18px",
        marginRight: "12px"
    },
    exportButton: {
        position: "relative",
        fontSize: "12px"
    },
    bookOrderButton: {
        position: "relative",
        fontSize: "12px",
        whiteSpace: "nowrap"
    },
    container: {
        display: "block",
        maxWidth: "85vw",
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "2px",
        margin: "5px",
        fontSize: "12px",
    },
}));


class NewOrderBookingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userType: localStorage.getItem('user_type'),
            orgList: [],
            orgId: '',
            selectedOrgIdx: -1,
            distributorName: null,
            contactPerson: "",
            contactNumber: "",
            gst: "",
            loading: false,
            quantity: null,
            rate: 0,
            discount: 0,
            errorMessage: null,
            currentUser: null,
            notify : {
                isOpen: false,
                message: "",
                type: ""
              }
        }

        this.token = localStorage.getItem("token");
        this.fetchListOfDistributors = this.fetchListOfDistributors.bind(this);
        this.getCurrentUserFunc = this.getCurrentUserFunc.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.fetchRate = this.fetchRate.bind(this);
        this.SERVERBASEURL = process.env.REACT_APP_API;
    }
    getCurrentUserFunc = async () => {
        const user = await getCurrentUser()
        console.log("in finc", this.state.currentUser)
        this.setState({ currentUser: user.data.body })
    }
    componentDidMount(props) {
        console.log('orderDetails123', this.props.orderDetails)
        this.getCurrentUserFunc()
        if (this.state.userType === "admin") {
            this.fetchListOfDistributors();
            // console.log('orderDetails', this.state)
        }
        if (this.props.orderDetails !== null) {
            var self = this;
            var orgs = [];
            axios({
                method: 'get',
                url: self.SERVERBASEURL + "/organizations_with_contact_info",
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${self.token}`,
                },
            })
                .then(res => {
                    console.log(res)
                    const resData = res.data.data
                    const personDetails = {
                        gst: 0,
                        // contact_name: '',
                        contact_number: ''
                    }
                    resData.forEach((element) => {
                        if (element.org_id === orderDetails.orgId) {
                            personDetails.gst = element.gst
                            personDetails.contact_number = element.phone
                            personDetails.contact_person = element.contact_person
                        }
                    })
                    console.log(resData, personDetails, 'resData')
                    this.setState({
                        // orgList: [{ id: localStorage.getItem('user_org_id'), contactPerson: localStorage.getItem('name'), contactNumber: localStorage.getItem('phone'), gst: localStorage.getItem('gst'), orgName: localStorage.getItem('org_name') }],
                        orgId: orderDetails.orgId,
                        selectedOrgIdx: orderDetails.orgId,
                        distributorName: orderDetails.distributorName,
                        contactPerson: personDetails.contact_person,
                        contactNumber: personDetails.contact_number,
                        gst: personDetails.gst,
                        quantity: orderDetails.quantity,
                        rate: orderDetails.releaseRate,
                        discount: orderDetails.discountRate,

                    });
                    // res.data.data.forEach(element => {
                    //     orgs.push({ id: element.id, contactPerson: element.contact_person, contactNumber: element.phone, gst: element.gst, orgName: element.org_name });
                    // });
                }).catch(err =>
                    console.log('Error in performing operation', err));
            const orderDetails = this.props.orderDetails
            console.log('orderDetails12', this.props.orderDetails, orgs)

        }
        else {
            this.setState({
                orgList: [{ id: localStorage.getItem('user_org_id'), contactPerson: localStorage.getItem('name'), contactNumber: localStorage.getItem('phone'), gst: localStorage.getItem('gst'), orgName: localStorage.getItem('org_name') }],
                orgId: localStorage.getItem('user_org_id'),
                selectedOrgIdx: 0,
                distributorName: localStorage.getItem('org_name'),
                contactPerson: localStorage.getItem('name'),
                contactNumber: localStorage.getItem('phone'),
                gst: localStorage.getItem('gst'),
                quantity: null,
                rate: 0,
                discount: 0
            });
            this.fetchRate();
        }
    }

    async fetchListOfDistributors() {
        var self = this;
        await axios({
            method: 'get',
            url: self.SERVERBASEURL + "/organizations_with_contact_info",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${self.token}`,
            },
        })
            .then(res => {
                var orgs = [];
                console.log('res of orgs', res.data.data)
                res.data.data.forEach(element => {
                    orgs.push({ id: element.id, contactPerson: element.contact_person, contactNumber: element.phone, gst: element.gst, orgName: element.org_name, email: element.email });
                });
                console.log(orgs, 'orgs')
                this.setState({ orgList: orgs });
            }).catch(err =>
                console.log('Error in performing operation', err));

        this.fetchRate();

    }

    async fetchRate() {
        var self = this;
        if (this.state.currentUser){
            if (this.state.currentUser.user_type === USER_TYPES.DISTRIBUTOR){
                this.getTodaysReleaseRateForDistributor(this.state.currentUser.org_id)
            }
        }else {
            // let fetchedUser = await getCurrentUserFunc();
            const user = await getCurrentUser();
            if (user && user.data) {
            //   setCurrentUser(user.data.body);
            //   return user.data.body;
            this.getTodaysReleaseRateForDistributor(user.data.body.org_id)
            }

        }
        return;
        axios({
            method: 'get',
            url: self.SERVERBASEURL + "/release-rates/today",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${self.token}`,
            },
        })
            .then(res => {
                // console.log('release_rate : -'+JSON.stringify(res))
                if (res.data.data.length > 0) {
                    this.setState({ rate: res.data.data[0].new_release_rate });
                }else{
                    this.setState({
                        notify: {
                            isOpen: true,
                            message: "The base rate is not released yet. Please contact admin !",
                            type: "error"
                        } 
                    })
                    setTimeout(()=>this.props.history.push("/dashboard"),1800);
                }
            }).catch(err =>
                console.log('Error in performing operation', err));
    }

    resetForm() {
        this.setState({
            orgId: '',
            selectedOrgIdx: -1,
            distributorName: null,
            contactPerson: "",
            contactNumber: "",
            gst: "",
            quantity: null,
        });
    }


    validateState() {

        if (this.state.selectedOrgIdx < 0) {
            this.setState({ errorMessage: "Please select a distributor" });
            return false;
        }

        if (this.state.quantity != null && this.state.quantity <= 0) {
            this.setState({ errorMessage: "Please select a quantity" });
            return false;
        }

        return true;
    }

    handleFormSubmit(e) {
        var self = this;
        self.setState({ loading: true })
        e.preventDefault();
        if (!this.validateState()) {
            console.log('in validation')
            return;
        }

        var orgData
        this.state.orgList.forEach((element) => {
            console.log("element", element, this.state.userType === 'distributor')
            if (this.state.userType === 'distributor') {
                if (element.id === this.state.orgId) {
                    orgData = element
                }
            }
            else {
                if (element.id === this.state.selectedOrgIdx) {
                    orgData = element
                }
            }
        })

        var data
        console.log('data232', orgData, this.state, this.state.currentUser)
        if (this.props.orderDetails !== null) {
            if (this.state.currentUser.user_type === "admin") {
                data = {
                    id: this.props.orderDetails.order_booking_id,
                    emailSendTo: orgData.email,
                    userType: this.state.currentUser.user_type,
                    // org_id: orgData.id,
                    quantity: this.state.quantity,
                    release_rate: this.state.rate,
                    discount_rate: this.state.discount,
                    order_booking_status: this.props.orderDetails.orderBookingStatus
                    // final_rate: 
                }
            } else {
                data = {
                    id: this.props.orderDetails.order_booking_id,
                    // emailSendTo: orgData.email,
                    userType: this.state.currentUser.user_type,
                    // org_id: orgData.id,
                    quantity: this.state.quantity,
                    release_rate: this.state.rate,
                    discount_rate: this.state.discount,
                    order_booking_status: this.props.orderDetails.orderBookingStatus
                    // final_rate: 
                }
            }
        }
        else {
            data = {
                // org_id: orgData.id,
                emailSendTo: orgData.email,
                userType: this.state.currentUser.user_type,
                quantity: this.state.quantity,
                release_rate: this.state.rate,
                discount_rate: this.state.discount,
                // final_rate: 
            }
        }
        console.log('in hundleSubmit12', this.props.orderDetails, orgData, data)
        if (this.state.userType === 'distributor') {
            data.org_id = this.state.orgId
        } else {
            data.org_id = orgData.id
        }
        axios({
            method: 'post',
            url: self.SERVERBASEURL + "/order_booking",
            data: data,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${self.token}`,
            },
        })
            .then(res => {
                console.log(res);
                if (res.data.statusCode === 400) {
                    self.setState({ errorMessage: res.data.message });
                }
                else if (res.data.blocked) {
                    self.setState({ errorMessage: res.data.message });
                }

                else {
                    console.log(res.data);
                    self.props.closeDialog();
                }

            }).catch(err => {
                console.log('Error in performing operation', err)
            });
        self.setState({ loading: false })


    }
    handleInputChange(e) {
        const { name, value } = e.target;
        var kv = {};
        kv[name] = value;
        this.setState({ ...kv });
    }

    handleQuantityChange(e) {
        this.handleInputChange(e);
        if (this.state.orgId == null || this.state.orgId.length === 0 || e.target.value === 0 || e.target.value === '') {

        }
        else {

            this.updateDiscountField(e.target.value);
        }


    }

    updateDiscountField(quantity) {
        var self = this;
        axios({
            method: 'get',
            url: self.SERVERBASEURL + "/applicablediscounts/" + this.state.orgId + "/" + quantity,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${self.token}`,
            },
        })
            .then(res => {
                self.setState({ discount: res.data.discount });

            }).catch(err =>
                console.log('Error in performing operation', err));

    }

    getCurrentDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return yyyy + '-' + mm + '-' + dd;
    }

    
    getTodaysReleaseRateForDistributor = async (orgId) => {
        //   let fetchedUser = await getCurrentUserFunc();
        //   if (fetchedUser.user_type === USER_TYPES.DISTRIBUTOR ) {
            let response = await getTodaysReleaseRateForOrganization(orgId);
            if (response) {
              this.setState({rate : response.data.data?.new_release_rate});
            }
        //   }
        };
      
      

    onSelectDistributor(e) {
        console.log(e.target.value, this.state.orgList[e.target.value], this.state.currentUser, '--orgl;ist')
        let data
        this.getTodaysReleaseRateForDistributor(e.target.value)
        this.state.orgList.forEach((element) => {
            if (element.id === e.target.value) {
                data = element
            }
        })
        // if (this.state.currentUser.user_type === "admin") {
        //     this.state.orgList.forEach((element) => {
        //         if (element.id === e.target.value) {
        //             data = element
        //         }
        //     })
        // }
        console.log('data ---->%%', data)
        if (e.target.value < 0 || this.state.orgList.length < 1) {
            return;
        }
        if (data) {
            var rowObj = data;
            this.setState({ selectedOrgIdx: e.target.value, orgId: rowObj.id, contactPerson: rowObj.contactPerson, contactNumber: rowObj.contactNumber, gst: rowObj.gst, quantity: 0, discount: 0 });
        }

    }

    render() {

        const { classes } = this.props;

        var orgListHtml = [];
        console.log(this.state.orgList, 'orgList')
        this.state.orgList.forEach((element, idx) => {
            orgListHtml.push(<MenuItem key={element.id} value={element.id}>{element.orgName}</MenuItem>)
        });
        console.log('loading', this.state.loading)

        return (<div className={classes.root}>
            {this.state.loading === false ? <Form onSubmit={(e) => { this.handleFormSubmit(e); }}>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12} style={{ display: "inline-grid" }}>
                        <>
                            <h4>&nbsp;Date: {this.getCurrentDate()}</h4>
                            {this.state.errorMessage != null ?
                                (<Chip onDelete={() => this.setState({ errorMessage: null })} color="secondary" label={this.state.errorMessage} />) : ""}
                        </>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    {this.state.userType === "admin" && <>
                        <Grid item md={5} xs={12}>
                            <InputLabel id="dist-select-label">Distributor</InputLabel>
                            <Select
                                label="dist-select-label"
                                value={this.state.selectedOrgIdx < 0 ? "" : this.state.selectedOrgIdx}
                                onChange={(e) => { this.onSelectDistributor(e) }}
                                autoWidth={true}
                                style={{ width: "100%" }}
                            >
                                {
                                    orgListHtml
                                }
                            </Select>
                        </Grid>
                        <Grid item md={2} xs={4}>
                            <Input
                                required
                                disabled
                                name="contact_person"
                                value={this.state.contactPerson}
                                label="Contact Person"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item md={2} xs={4}>
                            <Input
                                required
                                disabled
                                name="contact_number"
                                value={this.state.contactNumber}
                                label="Contact Number"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item md={3} xs={4}>
                            <Input
                                required
                                disabled
                                name="gstNumber"
                                value={this.state.gst}
                                label="GST"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>



                    </>}

                    <Grid item md={4} xs={12}>
                        <Input
                            required
                            name="quantity"
                            value={this.state.quantity}
                            label="Quantity (MT)"
                            type='number'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.handleQuantityChange(e) }}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <Input
                            required
                            disabled
                            name="rate"
                            value={this.state.rate}
                            label="Rate (Rs)"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.handleInputChange(e) }}
                        />
                    </Grid>

                    <Grid item md={2} xs={4}>
                        <Input
                            required
                            disabled
                            name="discount"
                            value={this.state.discount}
                            label="Discount (Rs)"
                            type='number'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.handleInputChange(e) }}
                        />
                    </Grid>

                    <Grid item md={4} xs={4}>
                        <Input
                            required
                            disabled
                            name="total"
                            value={(this.state.rate - this.state.discount) * this.state.quantity}
                            label="Total (Rs)"
                            type='number'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.handleInputChange(e) }}
                        />
                    </Grid>

                    <Grid item>
                        <Button text="Submit" type="submit" />
                    </Grid>
                </Grid>
            </Form> : <CircularProgress className='align-center' />}
            <Notifications notify={this.state.notify} setNotify={(value)=>{
                this.setState({notify:value})
            }} />
        </div>)
    }

}



export default withRouter(withStyles(cssStyles)(NewOrderBookingForm));