import { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
    Grid,
    makeStyles,
    Paper,
    Typography,
    Button,
    Avatar,
    Link,

} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Input from "../components/controls/Input";
import LogoBlack from '../assets/electrotherm-logo-black.png'
import Notifications from "../components/Notifications";

import { Headers } from "../constants/index";

const SERVERBASEURL = process.env.REACT_APP_API;


const initialValues = {
    email: "",
};
const useStyles = makeStyles((theme) => ({
    paperStyle: {
        padding: 20,
        margin: "50px auto",
        height: "70vh",
        width: 350,
    },
    avatarStyle: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: "30px",
        marginTop: "30px",
    },
    btnStyle: {
        margin: "30px 0",
    },
    fields: {
        margin: theme.spacing(1),
    },
}));

const Login = () => {
    const [values, setValues] = useState(initialValues);
    const [login, setLogin] = useState(false);
    const classes = useStyles();
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [errors, setErrors] = useState({
        password: '',
        confirm_password: ''
    })
    const [showMessage, setShowMessage] = useState(false)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        setShowMessage(true)
        axios.post(`${process.env.REACT_APP_API}/forget-password-send-mail`, { "email": values.email }).then(res => {
            if (res.data.statusCode === 200) {
                setNotify({
                    isOpen: true,
                    message: res.data.message,
                    type: "success"
                })
                setShowMessage(false)
                setValues(initialValues)
                // history.pushState('/')
            }
            else if (res.data.statusCode === 400) {
                setShowMessage(false)
                setNotify({
                    isOpen: true,
                    message: res.data.message,
                    type: "error"
                })
            }
        }).catch(err => {
            // what now?
            setShowMessage(false)
            console.log(err, 'error');
            setNotify({
                isOpen: true,
                message: "Something went wrong",
                type: "error"
            })

        })
        // setShowMessage(false)
    }
    const token = localStorage.getItem("token");
    console.log('showMessage', showMessage)
    return (
        <Grid>
            <Paper elevation={10} className={classes.paperStyle}>
                {!showMessage ? <> <Grid align="center">
                    <img style={{ width: '150px', marginLeft: '1.5em', marginTop: '5px', marginRight: '1em', marginBottom: '15px' }} className='align-center' src='/logo.png' />

                    <Typography variant="h5">Forget Password</Typography>
                </Grid>

                    <Input
                        className={classes.fields}
                        fullWidth
                        label="Email"
                        name="email"
                        required
                        value={values.email}
                        onChange={handleInputChange}
                        placeholder="Enter email"
                    ></Input>

                    <Button
                        className={classes.btnStyle}
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Send Reset Email
          </Button>
                    <p>Remember password? <Link href='/'>Login</Link></p></> : <> <CircularProgress className='align-center' /> </>}
            </Paper>
            {token && <Redirect to="/dashboard" />}
            <Notifications notify={notify} setNotify={setNotify} />

        </Grid>

    );
};

export default Login;
