import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
  Avatar,
  Link
} from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Input from "../components/controls/Input";
import LogoBlack from '../assets/electrotherm-logo-black.png'
import Texture from '../assets/texture1.jpeg'
import LoginDesign from '../assets/login-design.png'
import Notifications from "../components/Notifications";

import { Headers } from "../constants/index";
import "./login.css"
import {getCurrentUser } from "../services/userService";

const SERVERBASEURL = process.env.REACT_APP_API;


const initialValues = {
  email: "",
  distributorId: "",
  password: "",
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paperStyle: {
    padding: 20,
    margin: "0px auto",
    height: "70vh",
    // width: 350,
    // marginLeft: 'auto',
    width: '70%',
  },
  avatarStyle: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "30px",
    marginTop: "30px",
  },
  btnStyle: {
    margin: "30px 0",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  fields: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  image: {
    // backgroundImage: `url(${Texture})`,
    padding:'50px',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  addressLine: {
    fontSize:'11px',
    fontWeight:'bolder',
  },
  socialIcon: {
    position:'relative',
    top:'-6px',
    fontSize:'11px',
    fontWeight:'bolder'
  },
}));

const Login = () => {
  const [values, setValues] = useState(initialValues);
  const [login, setLogin] = useState(false);
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const getCurrentUserFunc = async () => {
    // setLoading(true)
    const user = await getCurrentUser()
    console.log('user', user)
    if (user && user.data){
      // serCurrentUser(user.data.body)
      setLogin(true);
    }
    // setLoading(false)
  }

  useEffect(() => {
    getCurrentUserFunc();
  }, [])

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const url = `${SERVERBASEURL}/login`;
    const formData = {
      email: values.email,
      password: values.password,
    };
    await axios
      .post(url, formData, {
        headers: Headers,
      })
      .then((response) => {
        console.log('res', response);
        if (response.status === 200 && response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("id", response.data.data.id);
          localStorage.setItem("user_org_id", response.data.data.org_id);
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("org_name", response.data.data.org_name)
          localStorage.setItem("gst", response.data.data.gst)
          localStorage.setItem("phone", response.data.data.phone)
          setLogin(true);
        }
        else {

          setNotify({
            isOpen: true,
            message: "Email/Password mismatch.",
            type: "error",
          });
        }
      })
      .catch((err) => new Error("Can't Log you now"));
  };
  const token = localStorage.getItem("token");
  return (
    // <Grid container>
      <div 
        style={{ 
          minHeight:'100vh',
          backgroundImage: `url(${Texture})`, 
          backgroundRepeat : 'repeat-y',
          backgroundSize: '100% 100%',
          // backgroundPosition: 'center',
        }}
      >
        <div>
          <Grid container style={{marginRight: '30px'}}>
            <Grid container justify="center">
              <div className="logo-div">
                <img src='/logo.png' alt="" className="authImage" />
              </div>
              </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div style={{textAlign:'center'}}>
                {/* <img src={LogoBlack} alt="" className="authImage" /> */}
                <div className='login-image-container'>
                  <img src={LoginDesign} className="login-design" alt="Electrotherm" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} 
              style={{
                maxWidth:'600px',
                // marginTop:'9%', 
                  // marginLeft:'15%',
                  textAlign:'center',
                  paddingLeft:'35px', 
                  paddingRight:'35px'
                }}
              >
              <div bodyStyle={{ 
                background: '#fff',
                borderRadius: '5px',
               }}>
                <div style={{ 
                  margin: '75px 35px', 
                  // paddingTop:'5%',
                }}>
                <Typography variant="h4" style={{marginBottom:'2px'}}>Distributor Login</Typography>
                <hr style={{marginBottom:'10px'}} />
                <form
                  // noValidate
                  autoComplete="off"
                  className={classes.form}
                  onSubmit={handleFormSubmit}
                >
                <Input
                  className={classes.fields}
                  fullWidth
                  label="Email/Distributor ID"
                  name="email"
                  required
                  value={values.email}
                  onChange={handleInputChange}
                  placeholder="Enter email/distributor ID"
                ></Input>
                <Input
                  className={classes.fields}
                  fullWidth
                  required
                  label="Password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleInputChange}
                  placeholder="Enter Password"
                ></Input>
                <span style={{float:'right'}}>
                <Link href='/forget-password'>Forgot Password ?</Link>
                </span>
                <Button
                  className={classes.btnStyle}
                  variant="contained"
                  fullWidth
                  align="center"
                  color="primary"
                  type="submit"
                >
                  Login
                </Button>
              </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      {token && login && <Redirect to="/dashboard" />}
      <Notifications notify={notify} setNotify={setNotify} />
    </div>
    // {/* </Grid> */}

  );
};

export default Login;
