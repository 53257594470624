import React from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  InputAdornment
} from "@material-ui/core";
import axios from "axios";
import _ from "lodash"
import { useHistory, useParams } from "react-router-dom";
import { withRouter } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import { useForm, Form } from "../../components/useForm";
import { Autocomplete } from "@material-ui/lab";
// import Select from '@material-ui/core/Select';
import Select from "../../components/controls/Select";
import Input from "../../components/controls/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Notifications from "../../components/Notifications";
import { getPOMaterialListGeneric,getMaterialListSpecific } from "../../services/materialService";
import { getAllDiscountsForProcessing } from "../../services/discountService";
import { getDistributorList } from "../../services/distributorService";
import { getReleaseRateForPO } from "../../services/releaseRates";
import { getSettings } from "../../services/settings";
import { getCreditLimitByOrgId } from "../../services/creditLimit";
import { getCurrentUser } from "../../services/userService";
import { getAvailableQuantity, getDispatchOrderDetailsForEditing } from "../../services/dispatchPlan";
import {
  InsertPurchaseOrder,
  getPurchaseOrderDetails
} from "../../services/PurchaseOrder";
import { getActiveConsigneeList } from "../../services/consigneeService";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Search } from "@material-ui/icons";
import env from "react-dotenv";
import "./create-dispatch-plans.css";

const token = localStorage.getItem("token");
const user_type = localStorage.getItem("user_type");
const user_id = localStorage.getItem("id");
const org_id = localStorage.getItem("org_id");

const SERVERBASEURL = process.env.REACT_APP_API;

class CreateDispatchPlans extends React.Component {
  state = {
    materialfields: [
      {
        key_id: 0,
        id: 0,
        materialid: 0,
        dispatchMaterials: [
          {
            key_id: 0,
            id: 0,
            materialid: 0,
            quantity: 0,
            rate: 0,
            discount: 0,
            total_amount: 0,
            amount_after_gst: 0,
            consignee_id: null,
            consignee_name: "",
            dispatch_to: "",
            quantityError:false
          }
        ]
      }
    ],
    distributors: [],
    materialList: [],
    specificMaterialsList:[],
    TotalQuantity: 0,
    TotalRate: 0,
    baseRate: 0,
    distributorId: "",
    deliveryLocation: "",
    AllDiscounts: [],
    TotalDiscount: 0,
    TotalGst: 0,
    GstPercent: 0,
    GrandTotal: 0,
    credit_limit_quantity: 0,
    credit_limit_value: 0,
    AllowSubmit: true,
    purchaseOrderId: null,
    dispatchOrderId: null,
    // MAX_QUANTITY:0,
    // REMAINING_QUANTITY:0,
    MAXIMUM_QUANTITY:{},
    quantityError : false,
    org_id:(user_type == 'admin') ? 0 : org_id,
    notify : {
      isOpen: false,
      message: "",
      type: "",
    },
    initialDispatchOrderDetails:{},
    validReleaseRate:true,
    quantityValidation:true,
    currentUser:{},
    consigneeList: [],
    remarks: "",
  };

  async componentDidMount() {
    let id = this.props.match.params.id;
    let dispatchOrderId = this.props.match.params.dispatchOrderId;
    this.setState({purchaseOrderId:id,dispatchOrderId});

    let initialDispatchOrderDetails  = await getDispatchOrderDetailsForEditing(dispatchOrderId);
    console.log(JSON.stringify(initialDispatchOrderDetails));
    // this.setState({initialDispatchOrderDetails});
    if (initialDispatchOrderDetails.length>0){
      this.setState({
        TotalQuantity: initialDispatchOrderDetails[0].total_quantity,
        TotalRate: initialDispatchOrderDetails[0].total_rate,
        TotalDiscount: initialDispatchOrderDetails[0].discount,
        TotalGst: initialDispatchOrderDetails[0].gst,
        GrandTotal:initialDispatchOrderDetails[0].total_amount,
        remarks: initialDispatchOrderDetails[0].remarks,
      })
    }
    var materialFieldsFetched=[];
    for (let i=0; i < initialDispatchOrderDetails.length;i++){
      let filteredDispatchList = initialDispatchOrderDetails.filter((d)=>(d.main_material_id == initialDispatchOrderDetails[i].main_material_id));
      let filteredMaterialList = await materialFieldsFetched.filter((m)=>(m.materialid === initialDispatchOrderDetails[i].main_material_id));
      // console.log("######"+JSON.stringify(filteredMaterialList));
      // console.log("%%%%%%%%%"+JSON.stringify(filteredDispatchList));
      var material={};
      if (filteredMaterialList.length === 0){
        material = {
          key_id: materialFieldsFetched.length,
          id: 0,
          materialid: initialDispatchOrderDetails[i].main_material_id,
          dispatchMaterials:[]
        };
        // console.log("******"+JSON.stringify(filteredDispatchList))
        for (let j=0;j<filteredDispatchList.length;j++){
          material.dispatchMaterials.push({
            key_id: j,
            id: j,
            materialid: filteredDispatchList[j].material_id,
            quantity: filteredDispatchList[j].quantity,
            rate: filteredDispatchList[j].rate,
            discount: 0,
            amount_after_gst: filteredDispatchList[j].amount_after_gst,
            total_amount: filteredDispatchList[j].amount,
            consignee_id: filteredDispatchList[j].consignee_id,
            consignee_name: filteredDispatchList[j].consignee_name,
            care_off: filteredDispatchList[j].care_off,
            house_no: filteredDispatchList[j].house_no,
            street_2: filteredDispatchList[j].street_2,
            street_3: filteredDispatchList[j].street_3,
            street_4: filteredDispatchList[j].street_4,
            street_5: filteredDispatchList[j].street_5,
            city: filteredDispatchList[j].city,
            district: filteredDispatchList[j].district,
            postal_code: filteredDispatchList[j].postal_code,
            quantityError:false
          })
        }
      materialFieldsFetched.push(material);
    }
    }
    materialFieldsFetched.length>0 && this.setState({materialfields:materialFieldsFetched})
    // console.log("!!!!!!!!"+JSON.stringify(materialFieldsFetched));
    var specificMaterialsList = [];

    const materialLists = await getPOMaterialListGeneric(id);
      const mLists = materialLists.data.data;
      let Materials = [];
      for (let [key, value] of mLists.entries()) {
        Materials[value.id] = value;
        // specificMaterialsList[value.id] = [];
      }
      this.setState({ materialList: Materials });
      // console.log("$$$$$"+JSON.stringify(this.state.materialList))

      let specific = await getMaterialListSpecific(1);
      // console.log(JSON.stringify(specific.data.data))
      let specificMaterials = specific.data.data;
      for (let [key,val] of specificMaterials.entries()){
        specificMaterialsList[val.id] = val;
      }
      this.setState({specificMaterialsList});
      // console.log(JSON.stringify(this.state.specificMaterialsList))
  
    let mainMaterials = {};
    let availableQuantity = await getAvailableQuantity(this.props.match.params.id);
    for (let index = 0; index < availableQuantity.length; index++) {
      mainMaterials[parseInt(availableQuantity[index].material_id)] = {
        availableQuantity:parseInt(availableQuantity[index].available_quantity || availableQuantity[index].quantity),
        remainingQuantity:parseInt(availableQuantity[index].available_quantity || availableQuantity[index].quantity),
        quantity: parseInt(availableQuantity[index].quantity),
        quantityError:false
      }      
    }
    await this.setState({MAXIMUM_QUANTITY:mainMaterials});
    // console.log('\n\n*******'+JSON.stringify(this.state.MAXIMUM_QUANTITY));

    let user = await getCurrentUser();
    // console.log("$$$"+JSON.stringify(user.data.body));
    this.setState({currentUser:user.data.body});

    // this.setState({ materialList: Materials });
    this.getRodaysReleaseRate();
    this.fetchPurchaseOrderDetails();
    this.getSettings();
    this.getAllDiscount();
  
    let consignees = await getActiveConsigneeList();
    console.log(consignees.data.data);
    this.setState({ consigneeList: consignees.data.data });
  
  }

  fetchPurchaseOrderDetails = async () => {
    let data = await getPurchaseOrderDetails(this.state.purchaseOrderId);
    let po = data.data.data[0];
    // console.log('----'+JSON.stringify(po))
    // console.log('***'+JSON.stringify(po.org_id))
    if (this.state.currentUser.user_type==="distributor" && po.org_id !== this.state.currentUser.org_id){
      this.setState({notify:{
        isOpen: true,
        message: 'You are not authorized to view this page! Please contact admin for help !',
        type: "error",
        timeOut:3500
      }});
      setInterval(()=>{
         this.props.history.push("/order")
      },3500);
    }
    // this.setState({ MAX_QUANTITY:po[0].total_quantity, REMAINING_QUANTITY:po[0].total_quantity });
  };
  getRodaysReleaseRate = async () => {
    let data = await getReleaseRateForPO(this.state.purchaseOrderId);
    let ReleaseRates = data.data.data;
    let baseRate = 0;
    if (ReleaseRates.length > 0) {
      baseRate = ReleaseRates[0].new_release_rate;
    }else{
      this.setState({validReleaseRate:false});
      console.log("No Release Rate Data Fetched !");
    }
    this.setState({ baseRate });
  };

  getSettings = async () => {
    let data = await getSettings();
    let Settings = data.data.data;
    let GstPercent = 0;
    if (Settings.length > 0) {
      GstPercent = Settings[0].gst;
    }
    this.setState({ GstPercent });
  };

  getAllDiscount = async () => {
    let discounts = await getAllDiscountsForProcessing();
    let AllDiscounts = discounts.data.data;
    this.setState(state => {
      return {
        ...state,
        AllDiscounts: AllDiscounts
      };
    });
    // console.log(JSON.stringify(AllDiscounts))
  };

  // removeFormRow =async index => {
  //   let materialfields = this.state.materialfields.filter((val, i) => {
  //     return val.key_id != index;
  //   });

  //  await this.setState(state => {
  //     return {
  //       ...state,
  //       materialfields: materialfields
  //     };
  //   });
  //   this.updateTotalRate();
  // };

  removeDispatchFormRow = async (index,parentMaterial) => {
    let materialfields = this.state.materialfields;
    let dispatchMaterials = this.state.materialfields[parentMaterial].dispatchMaterials.filter((val, i) => {
      return val.key_id != index;
    });
    materialfields[parentMaterial].dispatchMaterials = dispatchMaterials;

   await this.setState(state => {
      return {
        ...state,
        materialfields: materialfields
      };
    });
    this.updateTotalQuantity(parentMaterial,index);
    this.updateTotalRate();
  };

  // addFormRow = () => {
  //   let materialfields = this.state.materialfields;
  //   let thisIndex = materialfields.length;
  //   materialfields.push({
  //     key_id: thisIndex,
  //     id: 0,
  //     materialid: 0,
  //     dispatchMaterials: [
  //       {
  //         key_id: 0,
  //         id: 0,
  //         materialid: 0,
  //         quantity: 0,
  //         rate: 0,
  //         discount: 0,
  //         total_amount: 0,
  //         consignee_name: "",
  //         dispatch_to: "",
  //         quantityError:false
  //       }
  //     ]
  //     //   quantity: 0,
  //     //   rate: 0,
  //     //   discount: 0,
  //     //   total_amount: 0,
  //     //   consignee_name: "",
  //     //   dispatch_to: ""
  //   });
  //   this.setState(state => {
  //     return {
  //       ...state,
  //       materialfields: materialfields
  //     };
  //   });
  // };
  addDispatchRow = index => {
    // console.log("-----" + index);
    let materialfields = this.state.materialfields;
    let thisIndex = materialfields[index].dispatchMaterials.length;
    materialfields[index].dispatchMaterials.push({
      key_id: thisIndex,
      id: 0,
      materialid: 0,
      quantity: 0,
      rate: 0,
      discount: 0,
      total_amount: 0,
      consignee_name: "",
      dispatch_to: "",
      quantityError:false
    });
    this.setState(state => {
      return {
        ...state,
        materialfields: materialfields
      };
    });
  };
  materialFormRow = (field, idx) => {
    const index = field.key_id;
    return (
      <Paper
        key={index}
        elivation={0}
        style={{ margin: "20px", padding: "10px" }}
      >
        <div style={{ padding: 12, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input
                name={"materialid_" + index}
                style={{ display: "none" }}
                type="hidden"
                value={field.materialid}
              />

              <Select
                name={"material_id_" + index}
                label="Material"
                // value={this.state.materialList[this.state.materialfields[index]?.materialid]}
                // value={this.state.materialfields[index]?.materialid}
                value={field.materialid}
                onChange={e => {
                  // handleMainMaterialChange(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[field.key_id].materialid = e.target.value;
                  this.setState({ materialfields });                  
                }}
                options={this.state.materialList}
                required
              />
            </Grid>
            {this.state.materialfields[index]?.dispatchMaterials &&
              this.state.materialfields[index].dispatchMaterials.map(f =>
                this.dispatchFormRow(f, index)
              )}
            <Grid item xs={4}>
              <Button
                color="primary"
                onClick={() => this.addDispatchRow(index)}
                style={{
                  width: "300px",
                  marginLeft: "10px",
                  color: "#856404",
                  borderColor: "#ffeeba"
                }}
              >
                <AddIcon fontSize="small" />
                Add Dispatch Material
              </Button>
            </Grid>
            {/* <Grid item xs={4}>
              {this.state.materialfields.length < 1 ? (
                ""
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => this.removeFormRow(index)}
                  style={{
                    width: "200px",
                    marginLeft: "10px"
                  }}
                >
                  Remove Material
                </Button>
              )}
            </Grid> */}
          </Grid>
          {/* <Divider light /> */}
        </div>
      </Paper>
    );
  };
  dispatchFormRow = (field, parentMaterial) => {
    const index = field.key_id;
    // console.log("Parent Material :- " + parentMaterial);
    return (
      <Paper
        key={index}
        elivation={0}
        style={{ margin: "20px", padding: "10px", paddingLeft: "0px" }}
      >
        <div style={{ padding: 12, paddingLeft: "0px", marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input
                name={"materialid_" + index}
                style={{ display: "none" }}
                type="hidden"
                value={field.materialid}
              />

              <Select
                name={"material_id_" + index}
                label="Dispatch Material"
                value={this.state.materialfields[parentMaterial].dispatchMaterials[index]?.materialid}
                // value={field.id}
                onChange={e => {
                  // this.handleMaterialChange(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    field.key_id
                  ].materialid = e.target.value;
                  this.setState({ materialfields });
                  this.handleMaterialChange(e,index,parentMaterial);
                  this.updateTotalRate();
                }}
                // options={this.state.specificMaterialsList[this.state.materialfields[parentMaterial].materialid] || []}
                options={this.state.specificMaterialsList.filter((sm)=>sm.parent_generic === this.state.materialfields[parentMaterial].materialid)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"quantity_" + index}
                //value={eval("values.quantity_" + index)}
                label="Quantity(TON)"
                type="number"
                // value={this.state.materialfields[parentMaterial].dispatchMaterials[index]?.materialid}
                value={field.quantity}
                required
                onBlur={e=>{
                  let materialfields = this.state.materialfields;
                  let genericMaterial = this.state.materialfields[parentMaterial].materialid;
                  // console.log(this.state.MAXIMUM_QUANTITY[genericMaterial].availableQuantity+'@@@@@')
                  let availableQuantity = this.state.MAXIMUM_QUANTITY[genericMaterial].remainingQuantity;
                  let MAXIMUM_QUANTITY = this.state.MAXIMUM_QUANTITY;
                  if (e.target.value > availableQuantity ){
                    // console.log('true'+availableQuantity)
                    MAXIMUM_QUANTITY[genericMaterial].quantityError = true;
                    this.setState({MAXIMUM_QUANTITY});
                  }else{
                    if (materialfields[parentMaterial].dispatchMaterials[index].quantityError ===false){
                      MAXIMUM_QUANTITY[genericMaterial].remainingQuantity-=parseInt(e.target.value|| 0);
                    }
                    // else{
                      // MAXIMUM_QUANTITY[genericMaterial].remainingQuantity-=parseInt(e.target.value);
                    // }
                    MAXIMUM_QUANTITY[genericMaterial].quantityError = false;
                    this.setState({MAXIMUM_QUANTITY});
                  }
                }}
                onChange={e => {
                  // console.log(e.target.value+'+++++')
                  let materialfields = this.state.materialfields;
                  let quantity = e.target.value;
                  let materialSelected =
                    materialfields[parentMaterial].dispatchMaterials[
                      field.key_id
                    ].materialid;
                    
                    materialfields[parentMaterial].dispatchMaterials[
                    field.key_id
                  ].rate =
                    this.state.baseRate +
                    ((this.state.specificMaterialsList[materialSelected]?.grade_rate )+
                      (this.state.specificMaterialsList[materialSelected]?.type_rate  )+
                      (this.state.specificMaterialsList[materialSelected]?.size_rate ))-
                      this.state.materialList[materialfields[parentMaterial].materialid].discount;

                  materialfields[parentMaterial].dispatchMaterials[field.key_id].amount_after_gst =  materialfields[parentMaterial].dispatchMaterials[field.key_id].rate+
                    (materialfields[parentMaterial].dispatchMaterials[field.key_id].rate * (this.state.GstPercent/100));    
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].total_amount = 0;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].discount = this.state.materialList[materialfields[parentMaterial].materialid].discount*parseInt(e.target.value);
                  try {
                    if (
                      parseInt(e.target.value) !== NaN &&
                      e.target.value !== "" &&
                      this.state.materialfields[parentMaterial]
                        .dispatchMaterials[index].rate !== "" &&
                      e.target.value > 0 &&
                      this.state.materialfields[parentMaterial]
                        .dispatchMaterials[index].rate > 0 &&
                      parseInt(quantity) !== NaN &&
                      quantity > 0
                    ) {
                      materialfields[parentMaterial].dispatchMaterials[
                        index
                      ].total_amount =
                        materialfields[parentMaterial].dispatchMaterials[index]
                          .rate * quantity;
                    }
                  } catch (error) {
                    console.log(error);
                  }
                  //   let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    field.key_id
                  ].quantity = e.target.value;
                  this.setState({ materialfields });
                  // this.updateTotalRate(index, parentMaterial);
                  this.updateTotalRate();
                  this.updateTotalQuantity(parentMaterial,index);
                }}
                // error={this.state.materialfields[parentMaterial].dispatchMaterials[index].quantityError}
                error={this.state.MAXIMUM_QUANTITY[this.state.materialfields[parentMaterial].materialid]?.quantityError}
                helperText={this.state.MAXIMUM_QUANTITY[this.state.materialfields[parentMaterial].materialid]? `Max. Quantity for this material can be ${this.state.MAXIMUM_QUANTITY[this.state.materialfields[parentMaterial].materialid]?.availableQuantity}`:""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"rate_" + index}
                readOnly={true}
                label="Rate(per ton)"
                // value={field.rate}
                value={
                  this.state.materialfields[parentMaterial].dispatchMaterials[
                    index
                  ]?.rate
                }
                required
                type="number"
              />
              <Input
                name={"discount_" + index}
                style={{ display: "none" }}
                type="hidden"
                value={field.discount}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"total_amoun_" + index}
                readOnly={true}
                label="Total Amount"
                // value={field.total_amount}
                value={
                  this.state.materialfields[parentMaterial].dispatchMaterials[
                    index
                  ]?.total_amount
                }
                type="number"
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Input
                name={"consignee_name_" + index}
                value={field.consignee_name}
                label="Consignee Name"
                required
                onChange={e => {
                  // handleConsigneeName(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    field.key_id
                  ].consignee_name = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"dispatch_to_" + index}
                value={field.dispatch_to}
                label="Dispatch To"
                required
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    field.key_id
                  ].dispatch_to = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid> */}
            {this.renderConsigneeFields(field,index,parentMaterial)}
          </Grid>
          {/* <Divider light /> */}
          <Grid item xs={4}>
              {this.state.materialfields.length < 1 ? (
                ""
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => this.removeDispatchFormRow(index,parentMaterial)}
                  style={{
                    width: "270px",
                    marginLeft: "10px",
                    marginTop:"15px"
                  }}
                >
                  Remove Dispatch Material
                </Button>
              )}
            </Grid>
        </div>
      </Paper>
    );
  };

  renderConsigneeFields = (field, index, parentMaterial) => {
    return (
      <>
        <Select
          // name={"consignee" + index}
          name="consignee_id"
          label="Consignee"
          // value={field.consignee_id}
          value={this.state.materialfields[parentMaterial].dispatchMaterials[index]?.consignee_id}
          onChange={e => {
            let materialfields = this.state.materialfields;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].consignee_id = e.target.value;
            materialfields[parentMaterial].dispatchMaterials[
              index
              // ].consignee_name = this.state.consigneeList[e.target.value].name;
            ].consignee_name = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).name;

            materialfields[parentMaterial].dispatchMaterials[
              index
            ].care_off = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).care_off;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].house_no = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).house_no;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].street_2 = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).street_2;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].street_3 = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).street_3;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].street_4 = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).street_4;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].street_5 = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).street_5;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].city = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).city;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].district = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).district;
            materialfields[parentMaterial].dispatchMaterials[
              index
            ].postal_code = this.state.consigneeList.find(
              val => val.id === e.target.value
            ).postal_code;
            this.setState({ materialfields });
          }}
          // options={this.state.specificMaterialsList.filter((sm)=>sm.parent_generic === this.state.materialfields[parentMaterial].materialid)}
          options={this.state.consigneeList.map(({ id, name: title }) => ({
            title,
            id
          }))}
          required
        />
        {this.state.materialfields[parentMaterial].dispatchMaterials[index]
          .consignee_id && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                name={"consignee_name_" + index}
                value={field.consignee_name}
                label="Consignee Name"
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"gst_number" + index}
                // value={field.dispatch_to}
                value={this.state.consigneeList.find(
                  val => val.id === this.state.materialfields[parentMaterial].dispatchMaterials[index]?.consignee_id
                )?.gst}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="GST Number"
                disabled
                onChange={e => {
                  // handleDispatchTo(e, index)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"care_off_" + index}
                value={field.care_off}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="Address: C/O"
                required
                inputProps={{ maxLength: 14 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].care_off = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"house_no_" + index}
                value={field.house_no}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="House No"
                required
                inputProps={{ maxLength: 14 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].house_no = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"street_2_" + index}
                value={field.street_2}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="Street 2"
                required
                inputProps={{ maxLength: 14 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].street_2 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"street_3_" + index}
                value={field.street_3}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="Street 3"
                // required
                inputProps={{ maxLength: 14 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].street_3 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"street_4_" + index}
                value={field.street_4}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="Street 4"
                // required
                inputProps={{ maxLength: 14 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].street_4 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"street_5_" + index}
                value={field.street_5}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="Street 5"
                // required
                inputProps={{ maxLength: 14 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].street_5 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"city_" + index}
                value={field.city}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="City"
                required
                inputProps={{ maxLength: 30 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].city = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"district_" + index}
                value={field.district}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="District"
                required
                inputProps={{ maxLength: 30 }}
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].district = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"postal_code" + index}
                value={field.postal_code}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[index].consignee_id].address}
                label="Pin Code"
                type="number"
                required
                onChange={e => {
                  // handleDispatchTo(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].dispatchMaterials[
                    index
                  ].postal_code = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
          </>
        )}
      </>
    );
  };

  updateTotalQuantity = (parentMaterial,index) => {
    let TotalQuantity = 0;
    for (let [k, val] of this.state.materialfields.entries()) {
      for (let [key, value] of this.state.materialfields[
        k
      ].dispatchMaterials.entries()) {
        TotalQuantity += parseInt(
          this.state.materialfields[k].dispatchMaterials[key].quantity?this.state.materialfields[k].dispatchMaterials[key].quantity:0
        );
      }
    }
    this.setState({ TotalQuantity });

    // let materialfields = this.state.materialfields;
    // if (this.state.materialfields[parentMaterial].dispatchMaterials[index].quantity > this.state.REMAINING_QUANTITY){
    //   materialfields[parentMaterial].dispatchMaterials[index].quantityError = true;
    //   this.setState({materialfields});
    // }else{
    //   materialfields[parentMaterial].dispatchMaterials[index].quantityError = false;
    //   this.setState({materialfields});
    // }
    // this.setState({REMAINING_QUANTITY: this.state.MAX_QUANTITY - TotalQuantity});
  };

  updateTotalRate = () => {
    let AllowSubmit = this.state.AllowSubmit;
    AllowSubmit = true;
    // let dispatchMaterialFields = this.state.materialfields[parentMaterial]
    //   .dispatchMaterials;
    // let TotalRate = this.state.TotalRate;
    let TotalRate = 0;
    for (let [k, val] of this.state.materialfields.entries()) {
      let dispatchMaterialFields = this.state.materialfields[k]
        .dispatchMaterials;
      for (let [key, value] of dispatchMaterialFields.entries()) {
        TotalRate +=
          parseInt(value.total_amount) !== NaN
            ? parseInt(value.total_amount)
            : 0;
      }
    }
    let AllDiscounts = this.state.AllDiscounts;
    // let Total =
    //   parseInt(dispatchMaterialFields[index].total_amount) !== NaN
    //     ? parseInt(dispatchMaterialFields[index].total_amount)
    //     : 0;
    // let Quantity =
    //   parseInt(dispatchMaterialFields[index].quantity) !== NaN
    //     ? parseInt(dispatchMaterialFields[index].quantity)
    //     : 0;
    // let this_org_id = this.state.org_id;
    // let MaxDiscountRate = 0;
    // for (let [key, value] of AllDiscounts.entries()) {
    //  if(AllDiscounts[key].org_id == 0 || AllDiscounts[key].org_id == this_org_id){
    //   if (
    //     value.discount_type === "quantity" &&
    //     parseInt(value.discount_parameter) <= Quantity
    //   ) {
    //     let DiscountedPrice =
    //       parseInt(Quantity * parseInt(value.discount_rate)) !== NaN
    //         ? parseInt(Quantity * parseInt(value.discount_rate))
    //         : 0;
    //     if (
    //       parseInt(value.material_id) !== NaN &&
    //       parseInt(value.material_id) > 0
    //     ) {
    //       if (
    //         parseInt(dispatchMaterialFields[index].materialid) ==
    //         parseInt(value.material_id)
    //       ) {
    //         MaxDiscountRate = DiscountedPrice;
    //       }
    //     } else if (MaxDiscountRate <= DiscountedPrice) {
    //       MaxDiscountRate = DiscountedPrice;
    //     }
    //   } else if (
    //     value.discount_type === "rate" &&
    //     parseInt(value.discount_parameter) <= Total
    //   ) {
    //     let DiscountedPrice =
    //       parseInt(Quantity * parseInt(value.discount_rate)) !== NaN
    //         ? parseInt(Quantity * parseInt(value.discount_rate))
    //         : 0;
    //     if (
    //       parseInt(value.material_id) !== NaN &&
    //       parseInt(value.material_id) > 0
    //     ) {
    //       if (
    //         parseInt(dispatchMaterialFields[index].materialid) ==
    //         parseInt(value.material_id)
    //       ) {
    //         MaxDiscountRate = DiscountedPrice;
    //       }
    //     } else if (MaxDiscountRate <= DiscountedPrice) {
    //       MaxDiscountRate = DiscountedPrice;
    //     }
    //   }
    // }
    //   dispatchMaterialFields[index].discount = MaxDiscountRate;
    // }

    let TotalDiscount = 0;
    for (let [k, val] of this.state.materialfields.entries()) {
      let dispatchMaterialFields = this.state.materialfields[k]
        .dispatchMaterials;
      for (let [key, value] of dispatchMaterialFields.entries()) {
        TotalDiscount +=
          parseInt(value.discount) !== NaN ? parseInt(value.discount) : 0;
      }
    }
    let GrandTotal = TotalRate - TotalDiscount;
    let TotalGst = (this.state.GstPercent * GrandTotal) / 100;
    GrandTotal = GrandTotal + TotalGst;

    this.setState(state => {
      return {
        ...state,
        AllDiscounts: AllDiscounts,
        TotalDiscount: TotalDiscount,
        GrandTotal: GrandTotal,
        TotalRate: TotalRate,
        TotalGst: TotalGst,
        AllowSubmit: AllowSubmit
      };
    });
  };

   handleMaterialChange = (e,index,parentMaterial) => {
    let materialfields = this.state.materialfields;

    let materialSelected = materialfields[parentMaterial].dispatchMaterials[index].materialid;
  materialfields[parentMaterial].dispatchMaterials[index].rate = this.state.baseRate +
    ((this.state.specificMaterialsList[materialSelected]?.grade_rate) +
      (this.state.specificMaterialsList[materialSelected]?.type_rate )+
      (this.state.specificMaterialsList[materialSelected]?.size_rate) ) - this.state.materialList[
        materialfields[parentMaterial].materialid
      ].discount;
  materialfields[parentMaterial].dispatchMaterials[index].total_amount = 0;
  materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
    materialfields[parentMaterial].dispatchMaterials[index].rate +
    materialfields[parentMaterial].dispatchMaterials[index].rate * this.state.GstPercent/100;
  let quantity = materialfields[parentMaterial].dispatchMaterials[index].quantity;
  try {
    if (
      parseInt(e.target.value) !== NaN &&
      e.target.value !== "" &&
      this.state.materialfields[parentMaterial]
        .dispatchMaterials[index].rate !== "" &&
      e.target.value > 0 &&
      this.state.materialfields[parentMaterial]
        .dispatchMaterials[index].rate > 0 &&
      parseInt(quantity) !== NaN &&
      quantity > 0
    ) {
      materialfields[parentMaterial].dispatchMaterials[
        index
      ].total_amount =
        materialfields[parentMaterial].dispatchMaterials[index]
          .rate * quantity;
      materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
        materialfields[parentMaterial].dispatchMaterials[index].rate +
        materialfields[parentMaterial].dispatchMaterials[index].rate * this.state.GstPercent/100;
       
    }
  } catch (error) {
    console.log(error);
  }
  this.setState({ materialfields });
    this.updateTotalRate(index,parentMaterial);
  };


  prettifyDispatchMaterials = async () => {
    let dispatchMaterialFields = [];
    for (let [key, value] of this.state.materialfields.entries()) {
      // let dispatch = {};
      let main_material_id = this.state.materialfields[key].materialid;
      // dispatch['main_material_id'] = main_material_id;
      for (let [k, val] of this.state.materialfields[
        key
      ].dispatchMaterials.entries()) {
        if (this.state.MAXIMUM_QUANTITY[main_material_id].quantityError){
          console.log("Quantity Error !")
          this.setState({quantityValidation:false});
        }
        await dispatchMaterialFields.push({ main_material_id, ...val });
      }
    }
    // console.log("*****" + JSON.stringify(dispatchMaterialFields));
    return dispatchMaterialFields;
  };
  handleSearch = e => {};
  handleSubmit = async e => {
    e.preventDefault();
    let data = {};
    data.purchase_order_id =
      this.state.purchaseOrderId !== undefined ? parseInt(this.state.purchaseOrderId) : 2;
    data.customer_id = parseInt(this.state.distributorId);
    data.delivery_location = this.state.deliveryLocation;
    // data.material = dispatchMaterialFields;
    data.material = await this.prettifyDispatchMaterials();
    data.total_materials = this.state.materialfields.length;
    data.total_quantity = this.state.TotalQuantity;
    data.total_rate = this.state.TotalRate;
    data.discount = this.state.TotalDiscount;
    data.gst = this.state.TotalGst;
    data.created_by = user_id;
    data.creator_type = user_type;
    data.total_amount = this.state.GrandTotal;
    data.remarks = this.state.remarks;

    // console.log(JSON.stringify(data));
    if (this.state.validReleaseRate && this.state.quantityValidation){
      axios({
        method: "put",
        url: SERVERBASEURL + "/dispatch-order/"+this.state.dispatchOrderId,
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          // console.log("RESPOnse received :- " + JSON.stringify(res));
          if (res.data.status !== undefined && res.data.status === 1) {
            // console.log("\n\n FIrrst Reset Form \n");
            this.setState({notify:{
              isOpen: true,
              message: res.data.message,
              type: "success",
            }});
            // if (this.state.purchaseOrderId === undefined) {
            // console.log("\n\nReset Form \n");

            this.resetFormState();

              // }
          }
        })
        .catch(err => {
          this.setState({notify:{
            isOpen: true,
            message: `${err.response.data.message || 'Dispatch Order Creation Unsuccessful !'}`,
            type: "error",
          }});
          console.log("Error in performing operation", err);
        });
      }else{
        this.setState({notify:{
          isOpen: true,
          // message: `Your Purchase Order Does Not have a valid Release Rate ! Please Contact Admin !`,
          message: (!this.state.quantityValidation && 'The quantity selected is not valid !Please contact admin for help !') || `Your Purchase Order Does Not have a valid Release Rate ! Please Contact Admin !`,
          type: "error",
      }});
    }
  };

  resetFormState = () => {
    this.setState({
      materialfields: [
        {
          key_id: 0,
          id: 0,
          materialid: 0,
          dispatchMaterials: [
            {
              key_id: 0,
              id: 0,
              materialid: 0,
              quantity: 0,
              rate: 0,
              discount: 0,
              total_amount: 0,
              consignee_name: "",
              dispatch_to: "",
              quantityError:false
            }
          ]
        }
      ],
      distributors: [],
      materialList: [],
      TotalQuantity: 0,
      TotalRate: 0,
      baseRate: 0,
      distributorId: "",
      deliveryLocation: "",
      AllDiscounts: [],
      TotalDiscount: 0,
      TotalGst: 0,
      GstPercent: 0,
      GrandTotal: 0,
      credit_limit_quantity: 0,
      credit_limit_value: 0,
      AllowSubmit: true,
      id: 2
    });
  };

  render() {
    return (
      <main className="pageContent">
        <div className="toolbar" />

        <div style={{ marginBottom: "20px", marginTop: "60px" }}>
          <Grid container justify="space-between">
            <Grid>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  href="/getting-started/installation/"
                  //  onClick={handleClick}
                >
                  Purchase Order Details
                </Link>
                <Link
                  color="inherit"
                  href="/getting-started/installation/"
                  // onClick={handleClick}
                >
                  Dispatch Plan
                </Link>
                <Link
                  color="textPrimary"
                  href="/components/breadcrumbs/"
                  // onClick={this.handleClick}
                  aria-current="page"
                >
                  Create Dispatch Plan
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid>
              {/* <Button
                variant="outlined"
                color="primary"
                size="large"
                type="submit"
                className="button"
                style={{ marginRight: "10px", backgroundColor: "#fff" }}
              >
                Submit Plan
              </Button> */}
            </Grid>
          </Grid>
        </div>

        <div className="root">
          <Form onSubmit={this.handleSubmit}>
            <Paper
              elevation={0}
              className="paperbg"
              style={{ marginBottom: "20px" }}
            >
              <div style={{ padding: 12 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Material</p>
                      <p className="viewItem">
                        {this.state.materialfields.length}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Quantity</p>
                      <p className="viewItem">{this.state.TotalQuantity}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Rate</p>
                      <p className="viewItem">{this.state.TotalRate}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Discount</p>
                      <p className="viewItem">{this.state.TotalDiscount}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>GST</p>
                      <p className="viewItem">{this.state.TotalGst}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Amount</p>
                      <p className="viewItem">{this.state.GrandTotal}</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Paper
              elevation={0}
              className="paperbg"
              style={{ marginBottom: "40px", paddingTop: "10px" }}
            >
              {this.state.materialfields &&
                this.state.materialfields.map((field, index) =>
                  this.materialFormRow(field, index)
                )}

              {/* <div>
                <Button
                  color="primary"
                  onClick={() => this.addFormRow()}
                  style={{
                    width: "200px",
                    margin: "20px 10px",
                    textAlign: "left"
                  }}
                >
                  <AddIcon fontSize="small" />
                  Add Material
                </Button>
              </div> */}
              <div style={{ margin: "15px", textAlign: 'center'}}>
                  <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "200px" , marginBottom: "25px"}}
                  type="submit"
                  >
                    Save
                  </Button>
              </div>
            </Paper>
          </Form>

          <div style={{ marginBottom: "25px"}}>
            <Grid container spacing={2} xs={12} md={12}>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    backgroundColor: '#fff'
                  }}
                >
                  <Input
                    label="Add Remarks"
                    className="remarkInput"
                    onChange={(e)=>{
                      this.setState({remarks : e.target.value});
                    }}
                    value={this.state.remarks}
                    style={{'width': '100%'}}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Notifications notify={this.state.notify} setNotify={n=>this.setState({notify:n})} />
      </main>
    );
  }
}

export default withRouter(CreateDispatchPlans);
