import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getDispatchPlans() {
  const dispatchPlansList = await axios.get(
    SERVERBASEURL + "/dispatch-orders",
    config
  );
  return dispatchPlansList.data.data;
}

export async function getDispatchPlansForPO(id) {
  const dispatchPlansList = await axios.get(
    SERVERBASEURL + `/dispatch-orders/${id}`,
    config
  );
  return dispatchPlansList.data.data;
}

export async function getDispatchPlansDetails(id) {
  const dispatchPlansList = await axios.get(
    SERVERBASEURL + `/dispatch-order-details/${id}`,
    config
  );
  return dispatchPlansList.data.data;
}

export async function getDispatchOrderDetailsForEditing(dispatchOrderId) {
  const dispatchPlan = await axios.get(
    SERVERBASEURL + `/dispatch-order-details-for-editing/${dispatchOrderId}`,
    config
  );
  return dispatchPlan.data.data;
}

export async function setDispatchOrderStatus(data) {
  const d = await axios.post(
    SERVERBASEURL + "/set-dispatch-order",
    data,
    config
  );
  return d;
}

export async function insertGrade(data) {
  const savedStatus = await axios.post(
    SERVERBASEURL + "/material-grade-rate",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateGrade(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/material-grade-rate/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteGrade(id) {
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/material-grade-rate/${id}`,
    config
  );
  return deleteStatus;
}

export async function getAvailableQuantity(id) {
  const availableQuantity = await axios.get(
    SERVERBASEURL + `/get-available-quantity/${id}`,
    config
  );
  console.log("getAvailableQuantity" + JSON.stringify(availableQuantity));
  return availableQuantity.data.data;
}

export async function getInvoicesForDispatchMaterial(dispatchMaterialId) {
  const invoices = await axios.get(
    SERVERBASEURL + `/invoices/${dispatchMaterialId}`,
    config
  );
  console.log("dispatchMaterialId" + JSON.stringify(invoices.data.data));
  return invoices.data.data;
}

export async function getInvoice(invoiceName) {
  const invoices = await axios.get(
    SERVERBASEURL + `/download-invoice/${invoiceName}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/pdf",
      },
    }
  );
  console.log("invoice" + JSON.stringify(invoices?.data));
  return invoices?.data;
}

export async function getSalesOrderFile(salesOrderFileName) {
  const salesOrder = await axios.get(
    SERVERBASEURL + `/download/sales-order/${salesOrderFileName}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/pdf",
      },
    }
  );
  console.log("salesOrder" + JSON.stringify(salesOrder.data.data));
  return salesOrder.data;
}

export async function getTillDateDispatch() {
  const dispatchQuantity = await axios.get(
    SERVERBASEURL + `/till-date-dispatch`,
    config
  );
  // console.log("getTillDateDispatch"+JSON.stringify(dispatchQuantity));
  return dispatchQuantity.data.data;
}

export async function getPendingOrderQuantity() {
  const dispatchQuantity = await axios.get(
    SERVERBASEURL + `/pending-order-quantity`,
    config
  );
  // console.log("getPendingOrderQuantity"+JSON.stringify(dispatchQuantity));
  return dispatchQuantity.data.data;
}

export async function updateDispatchedQuantity(data, dispatchMaterialId) {
  // console.log(data,"   ,",dispatchMaterialId);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/quantity-dispatched/${dispatchMaterialId}`,
    data,
    config
  );
  return savedStatus;
}

export async function getDispatchOrderList() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const d = await axios.get(
    SERVERBASEURL + "/all-dispatch-orders",
    config
  );
  return d;
}

export async function getOtherStateParity() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const d = await axios.get(
    SERVERBASEURL + "/master/other-state-parity",
    config
  );
  return d;
}

export async function getExPlantRates() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const d = await axios.get(
    SERVERBASEURL + "/master/ex-plant-rates",
    config
  );
  return d;
}

export async function getDispatchPlanReport(status) {
  const report = await axios.get(
    SERVERBASEURL + `/download/dp-report/${status}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/pdf",
      },
    }
  );
  // console.log("dispatchPlanReport" + JSON.stringify(report.data));
  return report;
}