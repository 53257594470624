import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
let config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
    },
};

export async function getCurrentUser() {
    config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    };
    const d = await axios.get(
        SERVERBASEURL + "/getCurrentUser",
        config
    ).catch(err => {
        return err
    });
    return d;
}

export async function resetPassword(data) {
    config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    };
    console.log('in resetPassword', data)
    const d = await axios.post(
        SERVERBASEURL + "/forgetPasswordUser",
        data,
        config,

    ).catch(err => {
        return err
    });
    return d;
}
