import axios from 'axios';
import env from 'react-dotenv';
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
    },
};

export async function getAllOrgTypes() {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const stateList = await axios.get(SERVERBASEURL + '/master/org-types', config);
    console.log("🚀 ~ file: masterOrgTypeService.js ~ line 14 ~ getAllOrgTypes ~ stateList", stateList);
    return stateList.data.data;
}

export async function getActiveOrgTypes() {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const materialList = await axios.get(SERVERBASEURL + '/master/org-types/active', config);
    return materialList;
}

export async function insertOrgType(data) {
    const savedStatus = await axios.post(SERVERBASEURL + '/master/org-type', JSON.stringify(data), config);
    return savedStatus;
}

export async function updateOrgType(data) {
    console.log(data);
    const savedStatus = await axios.put(SERVERBASEURL + `/master/org-type/${data.id}`, data, config);
    return savedStatus;
}

export async function disableOrgType(id) {
    const deleteStatus = await axios.delete(SERVERBASEURL + `/master/org-type/${id}`, config);
    return deleteStatus;
}
