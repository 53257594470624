import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function releaseTodaysRate(id, data) {
  const d = await axios.post(
    SERVERBASEURL + "/specific-release-rates/" + id, data,
    config
  );
  return d;
}

// export async function insertSpecificReleaseRate() {
//   const d = await axios.post(
//     SERVERBASEURL + "/specific-release-rate",
//     config
//   );
//   return d;
// }

// export async function getTodaysReleaseRate() {
//   const d = await axios.get(
//     SERVERBASEURL + "/release-rates/today",
//     config
//   );
//   return d;
// }

// export async function getReleaseRateForPO(poId) {
//   const d = await axios.get(
//     SERVERBASEURL + "/release-rates/po/" + poId,
//     config
//   );
//   return d;
// }

// export async function getTodaysReleaseRateForOrganization(orgId) {
//   const d = await axios.get(
//     SERVERBASEURL + "/todays-release-rate-for-organization/" + orgId,
//     config
//   );
//   return d;
// }

// export async function getFe500GradeRate() {
//   config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
//   const d = await axios.get(
//     SERVERBASEURL + "/Fe500-grade-rate/",
//     config
//   );
//   return d;
// }
