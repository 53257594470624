//local environment
let accessToken = localStorage.getItem('token');

export const Headers = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + accessToken || null,
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
};

export const STATUS = {
  PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
};

export const STATUS_TEXT = {
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const TYPE_DEPENDENCY = {
  DEPENDENT: 1,
  INDEPENDENT: 0,
};

export const USER_TYPES = {
  ADMIN: 'admin',
  DISTRIBUTOR: 'distributor',
  SALES_PERSON: 'sales_person',
};

export const SIZE_RATES = {
  APPLICABLE: 1,
  NOT_APPLICABLE: 0,
};
export const GRADE_RATES = {
  APPLICABLE: 1,
  NOT_APPLICABLE: 0,
};
export const DASHBOARD_RATE_DISPLAY = {
  ENABLED: 1,
  DISABLED: 0,
};

export const ORG_TYPES = {
  DISTRIBUTOR: 1,
  PROJECT_CUSTOMER: 2,
  BUSINESS_ASSOCIATE: 3,
};

export const ORG_TYPE_TEXT = {
  DISTRIBUTOR: 'Distributor',
  PROJECT_CUSTOMER: 'Project Customer',
  BUSINESS_ASSOCIATE: 'Business Associate',
};

export const ORG_TYPE_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};