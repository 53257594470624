import React from 'react';
import axios from 'axios';
import { Grid, InputAdornment, makeStyles } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import Select from '../../components/controls/Select';
import { Select as MaterialUISelect, MenuItem, InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import { number } from 'prop-types';
import { getDistributorList } from '../../services/distributorService';
import { getCurrentUser } from '../../services/userService';
import { getPurchaseOrderDetailsForView, getPurchaseOrderListForDistributor, setContractDetailsForPO } from '../../services/PurchaseOrder';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';

const token = localStorage.getItem('token');
const SERVERBASEURL = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));
class EditContractDetails extends React.Component {
    state = {
        currentUser: null,
        selectedDistributor: '',
        selectedPO: "",
        allDistributors: [],
        allPOs: [],
        distributor_hide_class: '',
        contractFile: null,
        contractId: null,
    }

    getCurrentUserFunc = async () => {
        // setLoading(true)
        let user = await getCurrentUser();
        this.setState({ currentUser: user.data.body });
        // setLoading(false)
        return user;
    };

    async componentDidMount() {
        let fetchedUser = await this.getCurrentUserFunc();
        // this.setState({ distributor_hide_class: this.state.currentUser && this.state.currentUser.user_type == 'admin' ? 'show' : 'none' });
        console.log(fetchedUser)
        if (fetchedUser && fetchedUser.user_type != "admin") {
            // await fetchPOList(fetchedUser.org_id);
            this.setState({
                distributor_hide_class: 'show',
                selectedDistributor: fetchedUser.org_id
            });
            this.fetchPODetails();
        }
        const allDistributors = await getDistributorList();
        this.setState({ allDistributors: allDistributors.data.data });
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('sap_contract_id', this.state.contractId);
        formData.append('contractFile', this.state.contractFile);
        var api = {
            method: 'post',
            url: SERVERBASEURL + "/purchase-order/edit-contract/" + this.props.poId,
            data: formData,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        axios(api)
            .then((res) => {
                if (res.data.status !== undefined && res.data.status === 1) {
                    this.props.setNotify({
                        isOpen: true,
                        message: res.data?.message,
                        type: 'success',
                    }
                    );
                }
                this.props.updatePOList();
                this.props.handleClose();
            })
            .catch((err) => {
                this.props.setNotify({
                    isOpen: true,
                    message: `${err?.response?.data?.message || 'Contract Details Update Unsuccessful !'}`,
                    type: 'error',
                }
                );
                console.log('Error in performing operation', JSON.stringify(err?.response?.data?.message));
                this.props.handleClose();
            });
    };

    fetchPODetails = async () => {
        let response = await getPurchaseOrderDetailsForView(this.props.poId);
        this.setState({ poDetails: response.data.data[0] })
    }

    render() {
        return (
            <div className='root'>
                {/* <div className={classes.root}> */}
                <Form onSubmit={this.handleFormSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            // {...(error && { error: true })}
                            >
                                <Input
                                    name={"contractId"}
                                    type='text'
                                    label='Contract Number'
                                    value={this.state.contractId}
                                    required
                                    onChange={e => this.setState({ contractId: e.target.value })}
                                // value={field.typeid}
                                />
                            </FormControl>
                        </Grid>
                        {/* {currentUser && currentUser.user_type != 'admin' ? <Grid item xs={12} md={6}></Grid> : ''} */}
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            // {...(error && { error: true })}
                            >
                                <Input
                                    name={"contract"}
                                    type='file'
                                    label='Contract File'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    onChange={e => {
                                        this.setState({ contractFile: e.target.files[0] })
                                    }}
                                    required
                                // value={field.typeid}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button color="primary" variant="contained"
                                text="Save Contract Details"
                                // disabled={!this.state.selectedPO}
                                onClick={this.handleFormSubmit}
                            />
                            {/* </Link> */}
                        </Grid>
                    </Grid>
                </Form>

            </div>
        );
    }
}

export default withRouter(EditContractDetails);