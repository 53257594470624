import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Card,
  CardContent,
  CircularProgress
} from "@material-ui/core";
import axios from "axios";
import { getCurrentUser } from '../../services/userService'

import { useHistory, useParams } from 'react-router-dom';
import AddIcon from "@material-ui/icons/Add";
import { useForm, Form } from "../../components/useForm";
import { Autocomplete } from "@material-ui/lab";
// import Select from '@material-ui/core/Select';
import Select from "../../components/controls/Select";
import Input from "../../components/controls/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { getMaterialListGeneric, getMaterialType } from "../../services/materialService";
import { getAllDiscountsForProcessing, getDiscountForEachMaterialInPurchaseOrder } from "../../services/discountService"
import { getDistributorList, getSpecificDistributor } from "../../services/distributorService";
import { getOrderBookings } from "../../services/orderBookingService";
import { getSettings } from "../../services/settings";
import { getCreditLimitByOrgId } from "../../services/creditLimit";
import { InsertPurchaseOrder, getPurchaseOrderDetails, getPurchaseOrderListForOrder } from "../../services/PurchaseOrder";
import Notifications from "../../components/Notifications";
import { Redirect } from "react-router-dom";

import env from "react-dotenv";
import { TYPE_DEPENDENCY } from "../../constants";

const token = localStorage.getItem("token");
const user_id = localStorage.getItem("token");
const org_id = localStorage.getItem("user_org_id");
// const user_type = localStorage.getItem("user_type");

const SERVERBASEURL = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,
  paperbg: {
    backgroundColor: "#fafafa",
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
  },
  viewItem: {
    marginTop: "5px",
    paddingTop: "12px",
    textAlign: "center",
    width: "120px",
    height: "40px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  textCenter: {
    textAlign: "center",
  },
}));


//form input initaila values
const initialFormValues = {
  dealer_id: "",
  delivery_location: "",
};
const OrderAction = () => {
  const { id } = useParams();
  const [currentUser, serCurrentUser] = useState()
  const [userLoading, setUserLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();
  const [materialsDiscount, setMaterialsDiscount] = useState([])
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    distributorEmail: '',
    materialfields: [{
      key_id: 0,
      id: 0,
      materialid: 0,
      type_id: null,
      quantity: 0,
      rate: 0,
      discount: 0,
      total_amount: 0,
      // amount_after_gst: 0,
      consignee_name: "",
      dispatch_to: "",
    }],
    distributors: [],
    bookingOrders: [],
    materialList: [],
    TotalQuantity: 0,
    materialMap: {},
    materialTypes: [],
    totalPOCreatedQty: 0,
    TotalRate: 0,
    baseRate: 0,
    distributorId: currentUser && (currentUser.user_type == 'admin') ? "" : user_id,
    orderBookingId: "",
    deliveryLocation: "",
    AllDiscounts: [],
    TotalDiscount: 0,
    TotalGst: 0,
    GstPercent: 0,
    GrandTotal: 0,
    Basic: 0,
    AllowSubmit: true,
    org_id: org_id
  });
  const distributor_hide_class = currentUser && (currentUser.user_type == 'admin') ? "show" : "none";

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const resetFormState = () => {
    let materialfields = [];
    let AllowSubmit = true;
    let TotalQuantity = 0;
    let materialMap = {};
    let TotalRate = 0;
    let distributorId = (currentUser?.user_type == 'admin') ? "" : user_id;
    let deliveryLocation = "";
    let TotalDiscount = 0;
    let TotalGst = 0;
    let GstPercent = 0;
    let GrandTotal = 0;
    let orderBookingId = "";
    setState((state) => {
      return {
        ...state,
        materialfields: materialfields,
        AllowSubmit: AllowSubmit,
        TotalQuantity: TotalQuantity,
        TotalRate: TotalRate,
        distributorId: distributorId,
        deliveryLocation: deliveryLocation,
        TotalDiscount: TotalDiscount,
        TotalGst: TotalGst,
        GstPercent: GstPercent,
        GrandTotal: GrandTotal,
        orderBookingId: orderBookingId,
        materialMap: materialMap
      };
    });
  }
  const getCurrentUserFunc = async () => {
    const user = await getCurrentUser();
    if (user && user.data) {
      if (user.data.body.user_type !== "admin") {
        setState(state => {
          return {
            ...state,
            distributorId: user.data.body.org_id
          }
        })
      }
      serCurrentUser(user.data.body);
      setUserLoading(!userLoading);
    }
  }

  useEffect(() => {
    getCurrentUserFunc()
  }, [])
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // console.log("validate", temp, fieldValues);
  };

  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleSubmit = (e) => {
    setState((state) => {
      return { ...state, AllowSubmit: false };
    });
    setLoading(true);
    e.preventDefault();
    //console.log("dealer_id", e.target.dealer_id.value);
    //console.log("delivery_location", e.target.delivery_location.value);
    //const data = new FormData(e.target);
    //for (let [key, value] of data.entries()) {
    //console.log(key, value);
    //}

    let materialfields = [];
    for (let [key, value] of state.materialfields.entries()) {
      materialfields.push(value);
    }
    let data = {};
    data.po_id = (id !== undefined) ? parseInt(id) : 0;
    data.customer_id = parseInt(state.distributorId);
    data.delivery_location = state.deliveryLocation;
    data.material = materialfields;
    data.total_materials = materialfields.length;
    data.total_quantity = state.TotalQuantity;
    data.total_rate = state.TotalRate;
    data.discount = state.TotalDiscount;
    data.gst = state.TotalGst;
    data.total_amount = state.GrandTotal;
    data.org_id = state.org_id;
    data.order_booking_id = state.orderBookingId;
    data.emailSendTo = state.distributorEmail
    data.userType = currentUser.user_type
    //let result = InsertPurchaseOrder(data);
    //console.log(result);
    let dist
    if (currentUser) {
      if (currentUser.user_type === "admin") {
        dist = state.distributors.filter((val) => {
          return state.distributorId === val.org_id
        })
      }
    }
    if (data.userType === "admin") {
      data.emailSendTo = dist[0].email
    }

    axios({
      method: 'post',
      url: SERVERBASEURL + "/purchase-order/createOrUpdate",
      data: data,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        if (res.status !== 200) {
          setNotify({
            isOpen: true,
            message: res.data.message,
            type: "error",
          });
          // history.push('/order')
          // window.location.reload()
        }
        else if (res.data.status !== undefined && res.data.status === 1) {
          setNotify({
            isOpen: true,
            message: res.data.message || "PO Created Successfully !",
            type: "success",
          });
          if (id === undefined) {
            resetFormState();
          }
          setState((state) => {
            return { ...state, AllowSubmit: true };
          });
          setInterval(() => {
            history.push('/order')

          }, 1000)
        }
        setLoading(false)

      })
      .catch(err => {
        console.log('Error in performing operation', err);
        setNotify({
          isOpen: true,
          message: "Some Error Occured !",
          type: "error",
        });
        setState((state) => {
          return { ...state, AllowSubmit: true };
        });
        setLoading(false)
      });
  };

  // const removeFormRow = (index) => {
  //   let totalQty = state.TotalQuantity;
  //   let materialfields = state.materialfields.filter((val, i) => {
  //     if (val.key_id === index) {
  //       totalQty = totalQty - val.quantity;
  //     }
  //     return val.key_id !== index;
  //   });

  //   setState((state) => {
  //     return {
  //       ...state,
  //       materialfields: materialfields,
  //       TotalQuantity: totalQty,
  //     };
  //   });
  // };
  // const addFormRow = () => {
  //   let materialfields = state.materialfields;
  //   let thisIndex = materialfields.length;
  //   materialfields.push({
  //     key_id: thisIndex,
  //     id: 0,
  //     materialid: 0,
  //     quantity: 0,
  //     rate: 0,
  //     discount: 0,
  //     total_amount: 0,
  //     // amount_after_gst: 0,
  //     consignee_name: "",
  //     dispatch_to: "",
  //   });
  //   setState((state) => {
  //     return {
  //       ...state,
  //       materialfields: materialfields,
  //     };
  //   });
  // };

  const handleOrderBookingChange = (e) => {
    let orderBookingId = (parseInt(e.target.value) !== NaN) ? parseInt(e.target.value) : 0;
    const totalPOCreatedList = async () => {
      let totalPOCreatedQty = 0;
      const prevPOList = await getPurchaseOrderListForOrder(orderBookingId);
      prevPOList.forEach(element => {
        totalPOCreatedQty = totalPOCreatedQty + element.total_quantity;
      });
      setState((state) => {
        return {
          ...state,
          orderBookingId: orderBookingId,
          totalPOCreatedQty: totalPOCreatedQty,
          baseRate: state.bookingOrders[orderBookingId].release_rate
        }
      });
    };
    totalPOCreatedList();

  }

  const orderBookings = async (org_id, distributors, idx) => {

    let orderBookingList = await getOrderBookings(org_id);
    // console.log(orderBookingList, 'ddspdf', org_id)
    let obList = [];
    for (let [key, value] of orderBookingList.entries()) {
      obList[value.id] = value;
      var ts = value.created.split("T");
      // obList[value.id].title = ["BO", distributors[idx].sap_distributor_code, ts.join("|")].join("|");
      obList[value.id].title = value.order_booking_reference_number;
    }
    // console.log('-dd', obList);
    setState((state) => {
      return {
        ...state,
        bookingOrders: obList
      }
    });
  }
  // console.log('state', state)
  const handleDistributorChange = (org_id) => {
    let distributorId
    if (org_id) {
      distributorId = org_id
    }
    // console.log(org_id, 'org_id')
    // else {
    //   distributorId = (parseInt(e.target.value) !== NaN) ? parseInt(e.target.value) : 0;
    // }

    // console.log("Selected distributorId: " + state.distributorId);
    let distributors = state.distributors;
    let distributor
    let this_org_id = 0;
    if (distributorId > 0) {
      // console.log('in diwsctrubvutror', distributorId, distributors)
      distributor = distributors.filter((val) => {
        return val.org_id === distributorId
      })
      orderBookings(distributorId, distributors, distributorId);
    }

    let AllowSubmit = state.AllowSubmit;
    // console.log('distributor12', distributor)
    setState((state) => {
      return {
        ...state,
        distributorId: distributorId,
        distributorEmail: distributor[0].email,
        AllowSubmit: AllowSubmit,
        org_id: distributorId,
      };
    });
  }

  const handleDeliveryChange = (e) => {
    let deliveryLocation = state.deliveryLocation;
    deliveryLocation = e.target.value;
    setState((state) => {
      return {
        ...state,
        deliveryLocation: deliveryLocation
      };
    });
  }


  const handleMaterialChange = async (e, index) => {
    e.preventDefault();
    let State = state;
    State.materialfields[index].id = (parseInt(e.target.value) !== NaN) ? parseInt(e.target.value) : "";

    State.materialfields[index].rate = state.baseRate + (State.materialList[e.target.value].grade_rate + State.materialList[e.target.value].type_rate + State.materialList[e.target.value].size_rate);
    State.materialfields[index].total_amount = 0;
    // State.materialfields[index].amount_after_gst = (State.materialfields[index].rate+State.materialfields[index].rate*State.GstPercent/100);
    try {
      if (parseInt(e.target.value) !== NaN && e.target.value !== "" && State.materialfields[index].rate !== "" && e.target.value > 0 && State.materialfields[index].rate > 0 && parseInt(State.materialfields[index].quantity) !== NaN && State.materialfields[index].quantity > 0) {
        State.materialfields[index].total_amount = (State.materialfields[index].rate * State.materialfields[index].quantity);
      }
    } catch (error) {

    }

    await calculateDiscounts(index, State);
    // setState({
    //   ...state,
    //   materialfields: {
    //     discount: materialsDiscount[State.materialfields[index].id]
    //   }

    // })
    // console.log('matrial discoount', {
    //   materialfields: {
    //     discount: materialsDiscount
    //   }
    // })
    // setState({
    //   State
    // });

  };

  const handleTypeChange = async (e, index) => {
    e.preventDefault();
    let State = state;
    let materialSelected = State.materialfields[index].id;
    State.materialfields[index].type_id = (parseInt(e.target.value) !== NaN) ? parseInt(e.target.value) : "";

    State.materialfields[index].rate = state.baseRate + (State.materialList[materialSelected].grade_rate + State.materialTypes[e.target.value].rate + State.materialList[materialSelected].size_rate);
    State.materialfields[index].total_amount = 0;
    State.materialfields[index].amount_after_gst = (State.materialfields[index].rate + State.materialfields[index].rate * State.GstPercent / 100);
    try {
      if (parseInt(e.target.value) !== NaN && e.target.value !== "" && State.materialfields[index].rate !== "" && e.target.value > 0 && State.materialfields[index].rate > 0 && parseInt(State.materialfields[index].quantity) !== NaN && State.materialfields[index].quantity > 0) {
        State.materialfields[index].total_amount = (State.materialfields[index].rate * State.materialfields[index].quantity);
      }
    } catch (error) {

    }
    await calculateDiscounts(index, State);

  };

  const handleDispatchTo = (e, index) => {
    let materialfields = state.materialfields;
    materialfields[index].dispatch_to = e.target.value;
    setState((state) => {
      return {
        ...state,
        materialfields: materialfields
      };
    });
  }

  const handleConsigneeName = (e, index) => {
    let materialfields = state.materialfields;
    materialfields[index].consignee_name = e.target.value;
    setState((state) => {
      return {
        ...state,
        materialfields: materialfields
      };
    });
  }

  const getRemainingQtyInOrderBooking = () => {
    // console.log('bookingOrders', state.bookingOrders)
    if (state.bookingOrders[state.orderBookingId]) { return state.bookingOrders.length > 0 ? (state.bookingOrders[state.orderBookingId].quantity - state.totalPOCreatedQty - state.TotalQuantity) : 0 }
  }

  const handleOnChangeQuantity = async (e, index) => {
    let State = state;

    let newQty = (parseInt(e.target.value) !== NaN && parseInt(e.target.value) >= 0) ? parseInt(e.target.value) : 0;
    let prevQty = (parseInt(State.materialfields[index].quantity) !== NaN) ? parseInt(State.materialfields[index].quantity) : 0;
    let remaining = getRemainingQtyInOrderBooking() + prevQty;

    if (remaining - newQty < 0) {
      return;
    }

    State.materialfields[index].quantity = newQty;
    State.TotalQuantity = 0;
    for (let [key, value] of State.materialfields.entries()) {
      State.TotalQuantity += parseInt(value.quantity);
    }
    State.materialfields[index].total_amount = 0;
    // State.materialfields[index].amount_after_gst = (State.materialfields[index].rate+(State.materialfields[index].rate*State.GstPercent/100));


    if (parseInt(e.target.value) !== NaN && e.target.value !== "" && State.materialfields[index].rate !== "" && e.target.value > 0 && State.materialfields[index].rate > 0) {
      if (State.materialfields[index].quantity !== "" && State.materialfields[index].quantity > 0) {
        State.materialfields[index].total_amount = (State.materialfields[index].rate * e.target.value);
      }
    }

    // setState({
    //   State
    // });
    await calculateDiscounts(index, State);

  };



  useEffect(() => {
    let creditLimitByOrgId = async () => {
      let data = await getCreditLimitByOrgId();
      let creditlimit = data.data.data;
      // console.log(creditlimit, 'creditLimit')
      setState((state) => {
        return {
          ...state,
        };
      });
    };
    creditLimitByOrgId();
  }, []);
  useEffect(() => {
    // console.log(state)


  }, [])
  useEffect(() => {
    let getsettings = async () => {

      // console.log(state, '-dsd')

      let data = await getSettings();
      let Settings = data.data.data;
      let GstPercent = 0;
      if (Settings.length > 0) {
        GstPercent = Settings[0].gst
      }
      setState((state) => {
        return {
          ...state,
          GstPercent: GstPercent,
        };
      });
    };
    getsettings();
  }, []);

  useEffect(() => {
    const getAllDiscount = async () => {
      let discounts = await getAllDiscountsForProcessing();
      let AllDiscounts = discounts.data.data;
      setState((state) => {
        return {
          ...state,
          AllDiscounts: AllDiscounts,
        };
      });
    };
    getAllDiscount();
  }, []);

  useEffect(() => {
    const distributorsList = async () => {
      var distLists;
      if (currentUser?.user_type === "admin") {
        distLists = await getDistributorList();
      }
      else {
        distLists = await getSpecificDistributor();
      }
      const dLists = distLists.data.data;
      // console.log(dLists, 'dLists');
      const distributorId = state.distributorId;
      // orderBookings(distributorId, dLists, distributorId);
      let distributors = [];
      let distributorVal = {};
      for (let [key, value] of dLists.entries()) {
        distributors[value.id] = value;
        distributorVal = value;
      }
      // console.log('dis', distributors, distributorId);

      setState((state) => {
        return {
          ...state,
          distributors: distributors,
          distributorId: currentUser?.user_type === "admin" ? distributorId : distributorVal.id
        };
      });
      // console.log('condi', user_type !== "admin" && distributors.length > 0, user_type !== "admin", distributors.length)
      if (currentUser?.user_type !== "admin" && distributors.length > 0) {
        // console.log('insdd')
        orderBookings(distributorVal.id, distributors, distributorVal.id);

      } else if (distributors.length > 0) {
        // console.log('insdd2')
        let orderBookingList = await getOrderBookings(state.distributorId);
        // console.log(orderBookingList, 'dsd', distributors)
        let obList = [];
        for (let [key, value] of orderBookingList.entries()) {
          obList[value.id] = value;
          var ts = value.created.split("T");
          // obList[value.id].title = ["BO", distributors[state.distributorId].sap_distributor_code, ts.join("|")].join("|");
          obList[value.id].title = value.order_booking_reference_number;
        }
        setState((state) => {
          return {
            ...state,
            bookingOrders: obList
          }
        });
      }
    };
    distributorsList();

  }, [userLoading]);

  useEffect(() => {
    const materials = async () => {
      const materialLists = await getMaterialListGeneric();
      const mLists = materialLists.data.data;
      let Materials = [];
      for (let [key, value] of mLists.entries()) {
        Materials[value.id] = value;
      }

      setState((state) => {
        return {
          ...state,
          materialList: Materials,
        };
      });
    };
    materials();
  }, []);


  useEffect(() => {
    const fetchTypes = async () => {
      const materialLists = await getMaterialType();
      var types = [];
      let mLists = materialLists.data.data.filter(type => type.dependency === TYPE_DEPENDENCY.INDEPENDENT);
      for (let [key, value] of mLists.entries()) {
        types[value.id] = { ...value, title: value.type_name };
      }

      setState((state) => {
        return {
          ...state,
          materialTypes: types,
        };
      });
      // setMaterialTypes(mLists);
    };
    fetchTypes();
  }, []);

  const calculateDiscounts = async (index, State) => {
    const materialQtyRateMap = {};
    State.materialfields.forEach((element) => {

      if (materialQtyRateMap[element.id] == null) {
        materialQtyRateMap[element.id] = {};
        materialQtyRateMap[element.id].type_id = element.type_id;
        materialQtyRateMap[element.id].quantityVal = element.quantity;
        materialQtyRateMap[element.id].rateBeforeDiscount = element.quantity * element.rate;
      }
      else {
        materialQtyRateMap[element.id].type_id = element.type_id;
        materialQtyRateMap[element.id].quantityVal = materialQtyRateMap[element.id].quantityVal + element.quantity;
        materialQtyRateMap[element.id].rateBeforeDiscount = materialQtyRateMap[element.id].rateBeforeDiscount + element.quantity * element.rate;
      }
    });

    let getDiscountsPerMaterial = async () => {
      let discountsPerMaterial = await getDiscountForEachMaterialInPurchaseOrder(materialQtyRateMap, State.distributorId, state.orderBookingId);
      if (discountsPerMaterial.data.data != null) {
        setMaterialsDiscount(discountsPerMaterial.data.data)
        const materialfields = [...state.materialfields]
        if (discountsPerMaterial.data.data[State.materialfields[index].id]) {
          if (discountsPerMaterial.data.data[State.materialfields[index].id].qty !== -1) {
            materialfields[index].discount = discountsPerMaterial.data.data[State.materialfields[index].id].qty
          } else if (discountsPerMaterial.data.data[State.materialfields[index].id].rate !== -1) {
            materialfields[index].discount = discountsPerMaterial.data.data[State.materialfields[index].id].rate
          } else {
            materialfields[index].discount = 0
          }
        }
        // let discountedRate = materialfields[index].rate - materialfields[index].discount;
        // materialfields[index].amount_after_gst = (discountedRate +(discountedRate*State.GstPercent/100));
        await setState({
          ...state, materialfields: materialfields
        })
        undateTotalRate(index, discountsPerMaterial.data.data, State);
      }

    };
    await getDiscountsPerMaterial();
  }


  const undateTotalRate = (index, materialMap, State) => {

    let AllowSubmit = State.AllowSubmit;
    AllowSubmit = true;
    let materialfields = State.materialfields;
    let TotalRate = 0;
    for (let [key, value] of materialfields.entries()) {
      TotalRate += (parseInt(value.total_amount) !== NaN) ? parseInt(value.total_amount) : 0;
    }

    let AllDiscounts = State.AllDiscounts;

    let TotalDiscount = 0;
    for (let [key, value] of materialfields.entries()) {
      if (materialMap && materialMap[value.id]) {
        if (materialMap[value.id].qty > 0) {
          TotalDiscount = TotalDiscount + materialMap[value.id].qty * value.quantity;
        } else if (materialMap[value.id].rate > 0) {
          TotalDiscount = TotalDiscount + materialMap[value.id].qty * value.rate;
        }
      }
    }

    let GrandTotal = (TotalRate - TotalDiscount);
    let Basic = (TotalRate * 100 / (100 + State.GstPercent)).toFixed(2);
    let TotalGst = ((State.GstPercent * Basic) / 100).toFixed(2);
    // GrandTotal = GrandTotal + TotalGst;

    setState((state) => {
      return {
        ...State,
        AllDiscounts: AllDiscounts,
        TotalDiscount: TotalDiscount,
        GrandTotal: GrandTotal,
        TotalRate: TotalRate,
        TotalGst: TotalGst,
        Basic: Basic,
        AllowSubmit: AllowSubmit,
      };
    });
  };


  useEffect(() => {
    if (id !== undefined) {
      let funcPurchaseOrderDetails = async () => {
        let distributorId = 0;
        let deliveryLocation = 0;
        let TotalDiscount = 0;
        let GrandTotal = 0;
        let TotalGst = 0;
        let TotalQuantity = 0;
        let TotalRate = 0;
        let materialfields = [];
        let distributors
        let orderBookingId = 0
        let distributorEmail = ''
        let userType = ''
        let data = await getPurchaseOrderDetails(id);
        distributors = await getDistributorList();
        let PurchaseOrderDetails = data.data.data;
        for (let [key, value] of PurchaseOrderDetails.entries()) {
          const distributor = state.distributors.filter((val) => {
            return val.org_id === value.customer_id
          })
          distributorId = value.customer_id;
          deliveryLocation = value.delivery_location;
          orderBookingId = value.order_booking_id
          TotalDiscount = value.total_discount;
          GrandTotal = value.total_rate;
          TotalGst = value.total_gst;
          TotalQuantity = value.total_quantity;
          if (currentUser) {
            if (distributor && currentUser.user_type === 'admin') { distributorEmail = distributor[0].email }
            distributorEmail = distributor[0].email
            userType = currentUser.user_type
          }
          TotalRate = value.total_rate;
          materialfields.push({
            key_id: key,
            id: value.material_id,
            materialid: value.pm_id,
            type_id: value.type_id,
            quantity: value.quantity,
            rate: value.rate,
            discount: value.discount,
            total_amount: value.amount,
            consignee_name: value.consignee_name,
            dispatch_to: value.dispatch_to,
          });
        }
        if (currentUser?.user_type === "admin") {
          let orderBookingList = await getOrderBookings(distributorId);
          let obList = [];
          const dist = distributors.data.data.filter((val) => {
            return distributorId === val.org_id
          })
          for (let [key, value] of orderBookingList.entries()) {
            obList[value.id] = value;
            var ts = value.created.split("T");
            obList[value.id].title = ["BO", dist[0].sap_distributor_code, ts.join("|")].join("|");

          }
          setState((state) => {
            return {
              ...state,
              bookingOrders: obList
            }
          });
        }
        else {
          let orderBookingList = await getOrderBookings(distributorId);
          let obList = [];
          const dist = distributors.data.data.filter((val) => {
            return distributorId === val.org_id
          })
          for (let [key, value] of orderBookingList.entries()) {
            obList[value.id] = value;
            var ts = value.created.split("T");
            obList[value.id].title = ["BO", dist[0].sap_distributor_code, ts.join("|")].join("|");

          }
          setState((state) => {
            return {
              ...state,
              bookingOrders: obList
            }
          });
        }
        setState((state) => {
          return {
            ...state,
            distributorId: distributorId,
            distributorEmail: distributorEmail,
            userType: userType,
            deliveryLocation: deliveryLocation,
            TotalDiscount: TotalDiscount,
            GrandTotal: GrandTotal,
            TotalGst: TotalGst,
            TotalQuantity: TotalQuantity,
            TotalRate: TotalRate,
            materialfields: materialfields,
            orderBookingId: orderBookingId
          };
        });
      };

      setTimeout(funcPurchaseOrderDetails, 1000);
    }
  }, []);

  const materialFormRow = (field, idx) => {
    const index = field.key_id;
    return (
      <Paper
        key={index}
        elivation={0}
        style={{ margin: "20px", padding: "10px" }}
      >
        <div style={{ padding: 12, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>

              <Input
                name={"materialid_" + index}
                style={{ display: "none" }}
                type="hidden"
                value={field.materialid}
              />

              <Select
                name={"material_id_" + index}
                label="Material"
                value={field.id}
                onChange={(e) => handleMaterialChange(e, index)}
                options={state.materialList}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                name={"typeid_" + index}
                style={{ display: "none" }}
                type="hidden"
                value={field.typeid}
              />
              <Select
                name={"type_id_" + index}
                label="Type"
                value={field.type_id}
                onChange={(e) => handleTypeChange(e, index)}
                options={state.materialTypes}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"quantity_" + index}
                //value={eval("values.quantity_" + index)}
                label="Quantity(TON)"
                type="number"
                value={field.quantity}
                required
                onChange={(e) => handleOnChangeQuantity(e, index)}
              // error={errors.}
              // helperText="Quantity should be integer"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"rate_" + index}
                readOnly={true}
                label="Rate(per ton)"
                value={field.rate}
                required
                type="number"
              />
              <Input
                name={"discount_" + index}
                style={{ display: "none" }}
                type="hidden"
                value={field.discount}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"total_amoun_" + index}
                readOnly={true}
                label="Total Amount"
                value={field.total_amount}
                type="number"
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Input
                name={"consignee_name_" + index}
                value={field.consignee_name}
                label="Consignee Name"
                required
                onChange={(e) => handleConsigneeName(e, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={"dispatch_to_" + index}
                value={field.dispatch_to}
                label="Dispatch To"
                required
                onChange={(e) => handleDispatchTo(e, index)}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              {state.materialfields.length < 1 ? (
                ""
              ) : (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => removeFormRow(index)}
                    style={{
                      width: "200px",
                      marginLeft: "10px",
                    }}
                  >
                    Remove Material
                  </Button>
                )}
            </Grid> */}
          </Grid>
          {/* <Divider light /> */}
        </div>
      </Paper>
    );
  };

  return (
    <main className={classes.pageContent}>
      <div className={classes.toolbar} />
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        Create Purchase Order
      </Typography>
      <div className={classes.root}>
        <Form onSubmit={handleSubmit}>
          <Paper
            elevation={0}
            className={classes.paperbg}
            style={{ marginBottom: "20px" }}
          >
            <div style={{ padding: 12 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} style={{ display: { distributor_hide_class } }}>
                  <Select
                    name="distributor_id"
                    label="Distributor"
                    disabled={currentUser?.user_type === "admin" ? false : true}
                    options={state.distributors}
                    value={state.distributorId}
                    required
                    onChange={(e) => { handleDistributorChange(e.target.value) }}
                  />
                </Grid>
                {(currentUser?.user_type != 'admin') ? <Grid item xs={12} md={6}></Grid> : ''}
                <Grid item xs={12} md={6}>
                  <Select
                    name="order_booking"
                    label="OrderBooking"
                    options={state.bookingOrders}
                    value={state.orderBookingId}
                    required
                    onChange={handleOrderBookingChange}
                  />
                </Grid>
                {state.orderBookingId && <>
                  <Grid container item xs={12} md={6}>
                    <Input
                      name="contact_name"
                      required
                      disabled
                      label="Contact Name"
                      value={state.distributors[state.distributorId].name}
                    />
                    <Input
                      name="gst"
                      required
                      disabled
                      label="GST"
                      value={state.distributors[state.distributorId].gst}
                    />
                    <Input
                      name="contact_phone"
                      required
                      disabled
                      label="Contact Phone"
                      value={state.distributors[state.distributorId].phone}
                    />

                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <Card className={classes.root} variant="outlined" style={{ width: "100%" }}>
                      <CardContent>
                        <Typography className={classes.pos} color="textPrimary">
                          Address:
                        </Typography>
                        <Typography variant="body2" component="p" color="textSecondary">
                          {state.distributors[state.distributorId].address}
                        </Typography>
                      </CardContent>
                    </Card>

                  </Grid>
                </>
                }

              </Grid>
            </div>
          </Paper>

          {state.orderBookingId && <>
            <Paper
              elevation={0}
              className={classes.paperbg}
              style={{ marginBottom: "20px" }}>
              <Grid container item xs={12} md={6}>
                <Input
                  name="delivery_location"
                  required
                  label="Delivery Location"
                  onChange={handleDeliveryChange}
                  value={state.deliveryLocation}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Paper>
          </>}

          {state.orderBookingId && <>
            <Paper
              elevation={0}
              className={classes.paperbg}
              style={{ marginBottom: "20px" }}
            >
              <div style={{ padding: 12, fontSize: "14px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Material</p>
                      <p className={classes.viewItem}>
                        {state.materialfields.length}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Remaining Qty</p>
                      <p className={classes.viewItem}>
                        {getRemainingQtyInOrderBooking()}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Quantity</p>
                      <p className={classes.viewItem}>{state.TotalQuantity}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Basic Amount(without GST)</p>
                      <p className={classes.viewItem}>{state.Basic}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>GST</p>
                      <p className={classes.viewItem}>{state.TotalGst}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Rate(with GST)</p>
                      <p className={classes.viewItem}>{state.TotalRate}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Discount</p>
                      <p className={classes.viewItem}>{state.TotalDiscount}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Final Amount(with GST)</p>
                      <p className={classes.viewItem}>{state.GrandTotal}</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Paper
              elevation={0}
              className={classes.paperbg}
              style={{ marginBottom: "40px", paddingTop: "10px" }}
            >
              {state.materialfields &&
                state.materialfields.map((field, index) =>
                  materialFormRow(field, index)
                )}

              {/* <div>

                <Button
                  color="primary"
                  onClick={() => addFormRow()}
                  style={{
                    width: "200px",
                    margin: "20px 10px",
                    textAlign: "left",
                  }}
                >
                  <AddIcon fontSize="small" />
                Add Material
              </Button>

              </div> */}
            </Paper> </>
          }

          {
            (state.AllowSubmit && state.materialfields.length > 0) ? <div className={classes.textCenter}>
              <Button
                color="primary"
                variant="contained"
                style={{ width: "200px" }}
                type="submit"
              >
                Save
              </Button>
            </div> : (loading &&
              <div className={classes.textCenter}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled
                  style={{ width: "250px" }}
                  type="button"
                >
                  Creating&nbsp;&nbsp;&nbsp;<CircularProgress />
                </Button>
              </div>
            )
          }

        </Form>
      </div>
      {currentUser && <> {currentUser.status === 0 && <Redirect to="/dashboard" />}</>}
      {currentUser === null && <Redirect to="/" />}

      <Notifications notify={notify} setNotify={setNotify} />
    </main>
  );
};

export default OrderAction;
