import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsIcon from '@material-ui/icons/Settings';
import { useParams, useHistory } from 'react-router-dom';
import Notifications from "../../components/Notifications";
import { Form } from "../../components/useForm";
import Input from "../../components/controls/Input";
import { Dialog as DialogComponent } from "../../components/Dialog";

import { getDispatchPlansDetails, setDispatchOrderStatus, getInvoicesForDispatchMaterial, getInvoice, updateDispatchedQuantity } from "../../services/dispatchPlan";
import { getCurrentUser } from "../../services/userService";
import { STATUS, USER_TYPES } from "../../constants/index";
import ActionButton from "../../components/controls/ActionButton";
import UpdateTruckDetails from "./UpdateTruckDetails";
import DispatchMaterialDetails from "./DispatchMaterialDetails";
import { getSpecificConsignee } from "../../services/consigneeService";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,

  textCenter: {
    textAlign: "center",
  },
  btn: {
    color: "#4DE019",
    borderColor: "#4DE019",
    "&:hover": {
      background: "#4DE019",
      color: "#fff",
    },
  },
  btnReject: {
    color: "#F43333",
    borderColor: "#F43333",
    "&:hover": {
      background: "#F43333",
      color: "#fff",
    },
  },
  table: {
    // minWidth: 650,
    // display: "block",
    // overflowX: "auto",
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
  },
  textLight: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#323232",
    opacity: "0.75",
  },
  textDark: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#323232",
    opacity: "0.99",
  },
  comment: {
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#5A1515",
    opacity: "0.75",
  },
}));

const OrderView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [dispatchDetails, setDispatchDetails] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [dispatchOrder, setDispatchOrder] = useState({
    total_amount: 0,
    total_materials: 0,
    total_quantity: null,
    total_rate: null,
    discount: null,
    gst: null,
    creator_type: null,
    status: null,
    reasonForRejection: '',
    purchaseOrderId: null,
    consignee_id: null,
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [invoices, setInvoices] = useState([]);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [truckDetailForm, setTruckDetailForm] = useState(false);
  const [materialDisplay, setMaterialDisplay] = useState(false);
  const [reasonForRejectionText, setReasonForRejectionText] = useState("");
  const [reasonForRejection, setReasonForRejection] = useState("");
  const [dispatchedQuantityInputed, setDispatchedQuantityInputed] = useState({})
  const [dispatchMaterialId, setDispatchMaterialId] = useState()
  const [dispatchMaterial, setDispatchMaterial] = useState()
  const [consignee, setConsignee] = useState()
  // const [status  , setStatus] = useState(1);
  let { id } = useParams();

  const [comment, setComment] = useState(null);

  const theme = useTheme();
  //   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTruckDetailFormPopUpOpen = (dm) => {
    setDispatchMaterialId(dm.dispatch_material_id);
    setDispatchMaterial(dm);
    setTruckDetailForm(true);
  };

  const handleTruckDetailFormPopUpClose = () => {
    setTruckDetailForm(false);
  };

  const handleMaterialDisplayPopUpOpen = dm => {
    setDispatchMaterial(dm);
    setMaterialDisplay(true);
  };

  const handleMaterialDisplayPopUpClose = () => {
    setMaterialDisplay(false);
  };

  const handleInvoiceClickOpen = async (dispatchMateriaId) => {
    let invoices = await getInvoicesForDispatchMaterial(dispatchMateriaId);
    setInvoices(invoices);
    setInvoiceOpen(true);
  };

  const handleInvoiceClose = () => {
    setInvoiceOpen(false);
  };

  const handleSubmitApproveDO = () => {
    let funcSetDispatchOrderStatus = async () => {
      let reasonForRejectionText = "";
      let status = 3;
      let data = await setDispatchOrderStatus({ reasonForRejectionText, id, status });
      let d = data.data;
      if (d.status == 1) {
        setReasonForRejectionText("");
        setReasonForRejection("");
        // setStatus(2);
        setDispatchOrder({ ...dispatchOrder, status: 3 });
        setNotify({
          isOpen: true,
          message: "Order Approved",
          type: "success",
        });
      }
    };
    funcSetDispatchOrderStatus();
  }

  const handleRejectDOMessage = (e) => {
    // let reasonForRejectionText = e.target.value;
    setReasonForRejectionText(e.target.value);
  }


  const handleSubmitRejectDOMessage = () => {
    // let reason_for_rejection_text = state.reason_for_rejection_text;
    if (reasonForRejectionText == "") {
      alert("Please enter reason for rejection.");
    } else {

      let funcSetDispatchOrderStatus = async () => {
        // let reasonForRejectionText = state.reasonForRejectionText;
        let status = 2;
        let data = await setDispatchOrderStatus({ reasonForRejectionText, id, status });
        let d = data.data;
        if (d.status == 1) {
          setOpen(false);
          setReasonForRejection(reasonForRejectionText);
          setReasonForRejectionText(reasonForRejectionText);
          // setStatus(2);
          setDispatchOrder({ ...dispatchOrder, status: 2, reasonForRejection: reasonForRejectionText });
          setNotify({
            isOpen: true,
            message: "Order Rejected",
            type: "error",
          });
        }
      };
      funcSetDispatchOrderStatus();

    }
  }

  const fetchConsignee = async () => {
    let response = await getSpecificConsignee(dispatchOrder?.consignee_id);
    setConsignee(response?.data?.data[0]);
  }

  const fetchDispatchDetails = async () => {
    let dispatchedPlanDetails = await getDispatchPlansDetails(id);
    setDispatchOrder({
      total_amount: dispatchedPlanDetails[0]?.total_amount,
      total_materials: dispatchedPlanDetails[0]?.total_materials,
      total_quantity: dispatchedPlanDetails[0]?.total_quantity,
      total_rate: dispatchedPlanDetails[0]?.total_rate,
      discount: dispatchedPlanDetails[0]?.discount,
      gst: dispatchedPlanDetails[0]?.gst,
      creator_type: dispatchedPlanDetails[0]?.creator_type,
      status: dispatchedPlanDetails[0]?.status,
      reasonForRejection: dispatchedPlanDetails[0]?.reason_for_rejection,
      remarks: dispatchedPlanDetails[0]?.remarks,
      purchaseOrderId: dispatchedPlanDetails[0]?.purchase_order_id,
      consignee_id: dispatchedPlanDetails[0]?.consignee_id,
    })
    setDispatchDetails(dispatchedPlanDetails);
  }
  useEffect(async () => {
    await fetchDispatchDetails();
    let user = await getCurrentUser();
    setCurrentUser(user.data.body)
  }, [])

  useEffect(() => {
    fetchConsignee();
  }, [dispatchOrder?.consignee_id])

  return (
    <main className={classes.pageContent}>
      <div className={classes.toolbar} />

      <div style={{ marginBottom: "20px" }}>
        <Grid container justify="space-between">
          <Grid>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="textPrimary" href={`/dispatch-plans/${dispatchOrder.purchaseOrderId}`} >
                All Dispatch Plans
              </Link>
              <Link color="inherit">
                Dispatch Plan Details
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid>
            {
              (currentUser && currentUser.user_type !== USER_TYPES.SALES_PERSON && currentUser.user_type != dispatchOrder.creator_type && (dispatchOrder.status == 1)) ?
                <div>
                  <Button
                    className={classes.btnReject}
                    variant="outlined"
                    style={{ marginRight: "5px" }}
                    onClick={handleClickOpen}
                  >
                    Reject
                  </Button>
                  <Button className={classes.btn} variant="outlined" onClick={(e) => handleSubmitApproveDO()}>
                    Approve Plan
                  </Button>
                </div>
                : (
                  currentUser && currentUser.user_type == dispatchOrder.creator_type && (dispatchOrder.status == STATUS.REJECTED || dispatchOrder.status === STATUS.PENDING)
                ) ? <div>
                  <Button className={classes.btnReject}
                    variant="outlined"
                    onClick={() => history.push('/edit-dispatch-plan/' + dispatchOrder.purchaseOrderId + "/" + id)}
                  >
                    Edit
                  </Button>
                </div> : ""
            }
          </Grid>
        </Grid>
      </div>

      {/* comment popup */}
      <Dialog
        // fullScreen={fullScreen}
        // maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.textCenter}>
          {"Add Comments"}
        </DialogTitle>
        <Divider light></Divider>
        <DialogContent className={classes.textCenter}>
          <div
            style={{ minHeight: "300px", minWidth: "500px", marginTop: "20px" }}
          >
            <Grid constainer justify="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Type here..."
                  multiline
                  label="Add Comments for Rejection"
                  rows={8}
                  cols={700}
                  fullWidth
                  onChange={(e) => handleRejectDOMessage(e)}
                  variant="outlined"
                ></TextField>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "90px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "200px" }}
                  // onClick={submitComment}
                  onClick={(e) => handleSubmitRejectDOMessage(e)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>


      {/* invoice popup */}
      <Dialog
        open={invoiceOpen}
        onClose={handleInvoiceClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          {`Invoice (${invoices.length})`}
        </DialogTitle>
        <Divider light></Divider>
        <DialogContent className={classes.textCenter}>
          <div
            style={{ minHeight: "300px", minWidth: "500px", marginTop: "20px" }}
          >
            <div style={{ marginBottom: "25px" }}>
              <Paper className={classes.container}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Invoice No.</TableCell>
                      <TableCell align="center">Invoice</TableCell>
                      <TableCell align="center">SO_No.</TableCell>
                      <TableCell align="center">INV_DT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.length === 0 ?
                      <TableRow><TableCell colSpan={4}> No invoices available ! </TableCell></TableRow>
                      : invoices.map((row) => (
                        <TableRow key={row.FILE_NM}>
                          <TableCell align="center">{row.INV_NO}</TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row.FILE_NM}&nbsp;&nbsp;
                            <Link
                              onClick={async () => {
                                try {

                                  let invoiceData = await getInvoice(row.FILE_NM);
                                  // console.log(invoiceData)
                                  const blob = new Blob([invoiceData], { type: 'application/pdf' })
                                  let a = document.createElement('a');
                                  a.href = window.URL.createObjectURL(blob);
                                  a.download = `${row.FILE_NM || 'Invoice'}`
                                  a.click();
                                } catch (err) {
                                  console.log("🚀 ~ file: DispatchPlanDetails.js ~ line 385 ~ onClick={ ~ err", err?.response.data);
                                  setNotify({
                                    isOpen: true,
                                    message: `${err.response.data.message ||
                                      "Invoice not Found ! Please contact Admin !"
                                      }`,
                                    type: "error"
                                  });
                                }
                                // console.info("I'm a button.");
                              }}
                              style={{ 'cursor': 'pointer' }}
                            >
                              <AttachFileIcon
                                fontSize="small"
                              />
                            </Link>
                          </TableCell>
                          <TableCell align="center">{row.SO_NO}</TableCell>
                          <TableCell align="center">{row.INV_DT}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {dispatchOrder.remarks &&
        <div style={{ marginBottom: "25px" }}>
          <div className={classes.textDark} style={{ marginBottom: "10px" }}>
            Remarks
          </div>
          <Paper style={{ padding: "10px" }}>
            <p className={classes.comment}>
              {dispatchOrder.remarks}
            </p>
          </Paper>
        </div>
      }
      {dispatchOrder.status == STATUS.REJECTED && dispatchOrder.reasonForRejection &&
        <div style={{ marginBottom: "25px" }}>
          <div className={classes.textDark} style={{ marginBottom: "10px" }}>
            Comment For Rejection
          </div>
          <Paper style={{ padding: "10px" }}>
            <p className={classes.comment}>
              {/* {Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum in
              distinctio repellat totam temporibus repudiandae sapiente, natus
              dignissimos blanditiis assumenda quas, harum nihil magni non.
              Impedit in natus quis nihil.} */}
              {dispatchOrder.reasonForRejection}
            </p>
          </Paper>
        </div>
      }
      <div style={{ marginBottom: "25px" }}>
        <Paper className={classes.container}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Total Material</TableCell>
                <TableCell align="center">Total Quantity (MT)</TableCell>
                <TableCell align="center">Total Rate (with GST) (&#8377;)</TableCell>
                <TableCell align="center">Discount (&#8377;)</TableCell>
                <TableCell align="center">GST (&#8377;)</TableCell>
                <TableCell align="center">Final Amount (with GST) (&#8377;)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    {dispatchOrder.total_materials}
                  </TableCell>
                  <TableCell align="center">{dispatchOrder.total_quantity}</TableCell>
                  <TableCell align="center">{dispatchOrder.total_rate}</TableCell>
                  <TableCell align="center">{dispatchOrder.discount}</TableCell>
                  <TableCell align="center">{dispatchOrder.gst}</TableCell>
                  <TableCell align="center">{dispatchOrder.total_amount}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </Paper>
      </div>

      <div className={classes.table}>
        <Paper className={classes.container}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Line Item No.</TableCell>
                <TableCell align="center">Material Code</TableCell>
                <TableCell align="center">Material Name</TableCell>
                <TableCell align="center">Quantity (MT)</TableCell>
                {(dispatchDetails[0]?.dependent_type_id) &&
                  <TableCell align="center">Special Length (m)</TableCell>
                }
                <TableCell align="center">Rate	(&#8377;)</TableCell>
                <TableCell align="center">Total (&#8377;)</TableCell>
                <TableCell align="center">Consignee</TableCell>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Invoice</TableCell>
                <TableCell align="center">Dispatched Quantity (MT)</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dispatchDetails.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="center">{row.line_item_no}</TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.material_code}
                  </TableCell>
                  <TableCell align="center">{row.material_name}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  {dispatchDetails[0]?.dependent_type_id &&
                    <TableCell align="center">{row.special_length}</TableCell>}
                  <TableCell align="center">{row.rate}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell align="center">{row.consignee_name}</TableCell>
                  <TableCell align="center">{row.dispatch_to}</TableCell>
                  <TableCell align="center">
                    <Link
                      onClick={() => handleInvoiceClickOpen(row.dispatch_material_id)}
                      style={{ 'cursor': 'pointer' }}
                    >
                      {row.invoices}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {
                      currentUser && currentUser.user_type === "admin" ?
                        <Form onSubmit={async e => {
                          e.preventDefault();
                          console.log('form submit' + JSON.stringify(row))
                          try {
                            const response = await updateDispatchedQuantity({
                              dispatched_quantity: dispatchedQuantityInputed[row.dispatch_material_id]
                            }, row.dispatch_material_id)
                            // console.log('REsponse Dispatched Quantity : \n'+JSON.stringify(response));
                            setNotify({
                              isOpen: true,
                              message: `${response.data.message ||
                                "Dispatched Quantity Updated Successfully !"
                                }`,
                              type: "success"
                            });
                          } catch (err) {
                            // console.log('REsponse Dispatched Quantity : \n'+JSON.stringify(err.response.data.message));
                            setNotify({
                              isOpen: true,
                              message: `${err.response.data.message ||
                                "Dispatched Quantity could not be updated !"
                                }`,
                              type: "error"
                            });
                          }
                          fetchDispatchDetails();
                          setDispatchedQuantityInputed({ [row.dispatch_material_id]: null })
                        }}>
                          <Input
                            label="Dispatched"
                            type="Number"
                            id="outlined-size-small"
                            // defaultValue={row.dispatched_quantity}
                            value={dispatchedQuantityInputed[row.dispatch_material_id] || row.dispatched_quantity}
                            variant="outlined"
                            disabled={row.lineItemStatus === STATUS.COMPLETED || row.status !== STATUS.IN_PROGRESS}
                            size="small"
                            onChange={e => {
                              console.log(e.target.value);
                              setDispatchedQuantityInputed({ [row.dispatch_material_id]: e.target.value })
                            }}
                          />
                          {(row.status === STATUS.IN_PROGRESS) &&
                            <Button
                              type='submit'
                              disabled={row.lineItemStatus === STATUS.COMPLETED || row.status !== STATUS.IN_PROGRESS}
                            >
                              Save
                            </Button>
                          }
                        </Form>
                        : row.dispatched_quantity
                    }
                  </TableCell>
                  <TableCell>
                    <ActionButton color="primary">
                      <VisibilityIcon
                        onClick={(e) => handleMaterialDisplayPopUpOpen(row)}
                        fontSize="small"
                      />
                    </ActionButton>
                    {(dispatchOrder.status === STATUS.IN_PROGRESS) && (currentUser?.user_type === USER_TYPES.ADMIN || currentUser?.user_type === USER_TYPES.SALES_PERSON) && <ActionButton color="primary">
                      <SettingsIcon
                        onClick={e => handleTruckDetailFormPopUpOpen(row)}
                        fontSize="small"
                      />
                    </ActionButton>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <DialogComponent
        heading="Update Vehicle Details"
        handleClose={handleTruckDetailFormPopUpClose}
        open={truckDetailForm}
        width="md"
      >
        <UpdateTruckDetails
          dispatchMaterialId={dispatchMaterialId}
          dispatchMaterial={dispatchMaterial}
          dispatchPlanId={id}
          handleClose={handleTruckDetailFormPopUpClose}
          setNotify={setNotify}
          updateDPDetails={fetchDispatchDetails}
        />
      </DialogComponent>
      <DialogComponent
        heading="Material Details"
        handleClose={handleMaterialDisplayPopUpClose}
        open={materialDisplay}
        width="md"
      >
        <DispatchMaterialDetails
          dispatchMaterial={dispatchMaterial}
          dispatchPlanId={id}
          consignee={consignee}
        // handleClose={handleMaterialDisplayPopUpClose}
        // setNotify={setNotify}
        // updateDPList={funcGetDispatchOrderList}
        />
      </DialogComponent>
      <Notifications notify={notify} setNotify={setNotify} />
    </main>
  );
};

export default OrderView;
