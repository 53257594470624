import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function InsertPurchaseOrder(data) {
  const d = await axios.post(
    SERVERBASEURL + "/purchase-order/createOrUpdate",
    data,
    config
  );
  return d;
}

export async function setPurchaseOrderStatus(data) {
  const d = await axios.post(
    SERVERBASEURL + "/set-purchase-order",
    data,
    config
  );
  return d;
}

export async function getPurchaseOrderDetails(id) {
  const d = await axios.get(
    SERVERBASEURL + "/purchase-order-details/" + id,
    config
  );
  return d;
}

export async function getPurchaseOrderDetailsForView(id) {
  const d = await axios.get(
    SERVERBASEURL + "/purchase-order-details-for-view/" + id,
    config
  );
  return d;
}


export async function getPurchaseOrderList(id) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const d = await axios.get(
    SERVERBASEURL + "/get-purchase-order-list",
    config
  );
  return d;
}

export async function getPurchaseOrderListForOrder(orderBookingId) {
  const d = await axios.get(
    SERVERBASEURL + "/get-purchase-order-list/" + orderBookingId,
    config
  );
  console.log(d);
  return d.data != null ? d.data.data : [];
}

export async function dispatchOrderCountForPO(poId) {
  const d = await axios.get(
    SERVERBASEURL + "/get-dispatch-count-for-po/" + poId,
    config
  );
  console.log(d);
  return d.data != null ? d.data.data : [];
}

export async function getContractFile(contractFileName) {
  const contract = await axios.get(
    SERVERBASEURL+`/download/contract/${contractFileName}`,
    {headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/pdf",
    },
  }   
  );
  console.log("contract"+JSON.stringify(contract.data.data));
  return contract.data;
}

export async function getPurchaseOrderListForDistributor(orgId) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const d = await axios.get(
    SERVERBASEURL + "/all-po-for-distributor/"+orgId,
    config
  );
  return d;
}