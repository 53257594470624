import { useState, useEffect } from "react";
import {
  makeStyles,
  TableBody,
  Paper,
  Toolbar,
  TableRow,
  TableCell,
  InputAdornment,
} from "@material-ui/core";
import Notifications from "../../components/Notifications";
import { Dialog } from "../../components/Dialog";
import {
  insertMaterial,
  updateMaterial,
  deleteMaterial,
  getMaterialList,
  getMaterialGrade,
  getMaterialSizes,
  getMaterialType,
} from "../../services/materialService";
import { Search } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ActionButton from "../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import useTable from "../../components/useTable";
import PageHeader from "../../components/PageHeader";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import MaterialForm from "./MaterialForm";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const Materials = () => {
  const [materials, setMaterials] = useState([]);
  const [grades, setGrades] = useState([{ id: "", title: "" }]);
  const [sizes, setSizes] = useState([{ id: "", title: "" }]);
  const [types, setTypes] = useState([{ id: "", title: "" }]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [parentGenericData, setParentGenericData] = useState([])
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    { id: "name", label: "Name" },
    { id: "hierarchy", label: "Hierarchy", disableSorting: true },
    { id: "material_code", label: "Material Code" },
    { id: "grade", label: "Grade", disableSorting: true },
    { id: "size_name", label: "Size", disableSorting: true },
    { id: "type", label: "Type", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true },
  ];
  const materialsGet = async () => {
    const materialLists = await getMaterialList();
    const mLists = materialLists.data.data.reverse();
    if (mLists.length > 0) {
      let dataList = [];
      mLists.forEach((data) => {
        if (data.status === 1 && data.hierarchy === 'generic') {
          dataList.push({
            id: data.id,
            title: data.name,
          });
        }
      });
      setParentGenericData(dataList)
    }
    setMaterials(mLists);
  };
  useEffect(() => {
    const materials = async () => {
      const materialLists = await getMaterialList();
      const mLists = materialLists.data.data.reverse();
      if (mLists.length > 0) {
        let dataList = [];
        mLists.forEach((data) => {
          if (data.status === 1 && data.hierarchy === 'generic') {
            dataList.push({
              id: data.id,
              title: data.name,
            });
          }
        });
        setParentGenericData(dataList)
      }

      setMaterials(mLists);
    };
    materials();
  }, []);

  useEffect(() => {
    const materials = async () => {
      const materialGradeLists = await getMaterialGrade();
      if (materialGradeLists.data.data.length > 0) {
        let dataList = [];
        materialGradeLists.data.data.forEach((data) => {
          if (data.status === 1) {
            dataList.push({
              id: data.id,
              title: data.grade_name,
            });
          }
        });
        setGrades(dataList);
      }
    };
    materials();
  }, []);

  useEffect(() => {
    const materials = async () => {
      const materialSizeLists = await getMaterialSizes();
      if (materialSizeLists.data.data.length > 0) {
        let dataList = [];
        materialSizeLists.data.data.forEach((data) => {
          if (data.status === 1) {
            dataList.push({
              id: data.id,
              title: data.size_name,
            });
          }
        });
        setSizes(dataList);
      }
    };
    materials();
  }, []);

  useEffect(() => {
    const materials = async () => {
      const materialTypeLists = await getMaterialType();
      if (materialTypeLists.data.data.length > 0) {
        let dataList = [];

        materialTypeLists.data.data.forEach((data) => {
          if (data.status === 1) {
            dataList.push({
              id: data.id,
              title: data.type_name,
            });
          }
        });
        setTypes(dataList);
      }
    };
    materials();
  }, []);

  const handlePopUpOpen = () => {
    setRecordForEdit(null);
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure to delete this record")) {
      deleteMaterial(id);
      setNotify({
        isOpen: true,
        message: "Material Deactivated Successfully",
        type: "error",
      });
      materialsGet()
      // window.location.reload();
    }
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value.toLowerCase())
          );
        }
      },
    });
  };

  const addOrEdit = async (material, resetForm) => {
    if (material.formId === 0) {
      const response = await insertMaterial(material);
    } else await updateMaterial(material);
    resetForm();
    setRecordForEdit(null);
    setOpenPopUp(false);
    setNotify({
      isOpen: true,
      message: "Submitted successfully",
      type: "success",
    });
    materialsGet()
    // window.location.reload();
  };

  const openInPopUp = (row) => {
    setRecordForEdit(row);
    setOpenPopUp(true);
  };

  const {
    TableContainer,
    TableHeader,
    TablePaginations,
    recordsAfterPagingAndSorting,
  } = useTable(materials, headCells, filterFn, [5, 10, 25, { label: 'All', value: materials.length }]);

  return (
    <>
      <PageHeader
        title="Materials"
        subTitle="Materials details and Maintenance"
        icon={<FlipToFrontIcon />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Input
            label="Search by Material Name"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={handlePopUpOpen}
          />
        </Toolbar>
        <TableContainer>
          <TableHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.hierarchy}</TableCell>
                <TableCell>{row.material_code === null ? "NULL" : row.material_code}</TableCell>
                <TableCell>{row.grade_name}</TableCell>
                <TableCell>{row.size_name}</TableCell>
                <TableCell>{row.type_name}</TableCell>
                <TableCell>{row.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>
                  <ActionButton onClick={() => {
                    openInPopUp(row);
                  }} color="primary">
                    <EditOutlinedIcon

                      fontSize="small"
                    />
                  </ActionButton>
                  <ActionButton
                    color="secondary"
                    onClick={() => onDelete(row.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
        <TablePaginations />
      </Paper>
      <Dialog
        heading="Add Material"
        handleClose={handleClose}
        open={openPopUp}
        width="xs"
      >
        <MaterialForm
          materials={materials}
          types={types}
          parentGenericData={parentGenericData}
          sizes={sizes}
          grades={grades}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Dialog>
      <Notifications notify={notify} setNotify={setNotify} />
    </>
  );
};
export default Materials;
