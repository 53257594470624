import axios from 'axios';
import env from 'react-dotenv';
const SERVERBASEURL = process.env.REACT_APP_API;

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
};

export async function getAllOrganizations() {
  const customersList = await axios.get(SERVERBASEURL + '/organizations', config);
  return customersList;
}

export async function getAllCustomers() {
  const customersList = await axios.get(SERVERBASEURL + '/users', config);
  return customersList;
}

export async function insertSalesPerson(data) {
  const savedStatus = await axios.post(SERVERBASEURL + '/sales-person', JSON.stringify(data), config);
  return savedStatus;
}

export async function updateSalesPerson(data) {
  console.log(data);
  const savedStatus = await axios.put(SERVERBASEURL + `/sales-person/${data.id}`, data, config);
  return savedStatus;
}

export async function deleteCustomer(id) {
  const deleteStatus = await axios.delete(SERVERBASEURL + `/user/${id}`, config);
  return deleteStatus;
}

//NEW
export async function getDistributorById(id) {
  const distributor = await axios.get(SERVERBASEURL + `/distributor/${id}`, config);
  return distributor;
}
export async function getAllSalesPersons() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const distributors = await axios.get(SERVERBASEURL + `/sales-persons`, config);
  return distributors;
}

export async function getDistributorsForSalesPersons(salesPersonId) {
  const consignee = await axios.get(SERVERBASEURL + `/sales-person/connected-distributors/${salesPersonId}`, config);
  return consignee.data.data;
}
