import React from "react";
import { Grid, Paper, Link } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFileOutlined";

import { useStyles } from "./styles";
import { formatDate } from "../../helpers/helperFunctions";
import { getContractFile } from "../../services/PurchaseOrder";

const PODetails = ({ poDetails }) => {
  const classes = useStyles();

  return (<div style={{ marginBottom: "25px" }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Paper style={{ padding: "10px" }}>
          <div style={{ marginBottom: "10px" }}>
            <Grid container>
              <Grid item xs={8}>
                <div className={classes.textLight}>
                  Date : <span className={classes.textDark}>
                    {/* {moment(poDetails.created).format("YYYY-MM-DD")} */}
                    {formatDate(poDetails.created)}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ color: "#F08910" }}>{poDetails.text_status}</div>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div
              className={classes.textLight}
              style={{ marginBottom: "5px" }}
            >
              Distributor
            </div>
            <div className={classes.textDark}>{poDetails.org_name}</div>
          </div>
          <div>
            <div
              className={classes.textLight}
              style={{ marginBottom: "5px" }}
            >
              Contract ID
            </div>
            <div className={classes.textDark}>
              {/* {poDetails.sap_contract_id}&nbsp; */}
              {poDetails.sap_contract_id || '--'}&nbsp;&nbsp;
              {poDetails.contract_filename &&
                <Link
                  onClick={async () => {
                    let contractData = await getContractFile(poDetails.contract_filename);
                    console.log(contractData)
                    const blob = new Blob([contractData], { type: 'application/pdf' })
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = `${poDetails.contract_filename || 'Contract'}`
                    a.click();
                    // console.info("I'm a button.");
                  }}
                  style={{ 'cursor': 'pointer' }}
                >
                  <AttachFileIcon fontSize="small" />
                </Link>
              }
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper style={{ padding: "10px" }}>
          <div
            className={classes.textLight}
            style={{ marginBottom: "10px" }}
          >
            Ref NO : <span className={classes.textDark}>{poDetails.ref_no}</span>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <div>
                  <div
                    className={classes.textLight}
                    style={{ marginBottom: "5px" }}
                  >
                    Contact Person
                  </div>
                  <div className={classes.textDark}>{poDetails.name}</div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <div>
                  <div
                    className={classes.textLight}
                    style={{ marginBottom: "5px" }}
                  >
                    Contact Number
                  </div>
                  <div className={classes.textDark}>{poDetails.phone}</div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <div>
                  <div
                    className={classes.textLight}
                    style={{ marginBottom: "5px" }}
                  >
                    GST Number
                  </div>
                  <div className={classes.textDark} style={{ textTransform: 'uppercase' }}>{poDetails.gstNumber}</div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <div>
                  <div
                    className={classes.textLight}
                    style={{ marginBottom: "5px" }}
                  >
                    Location
                  </div>
                  <div className={classes.textDark}>{poDetails.city}</div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <div
                  className={classes.textLight}
                  style={{ marginBottom: "5px" }}
                >
                  Bill To
                </div>
                <div className={classes.textDark}>
                  {poDetails.delivery_location}
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <div
                  className={classes.textLight}
                  style={{ marginBottom: "5px" }}
                >
                  Total PO Quantity
                </div>
                <div className={classes.textDark}>
                  {poDetails.total_quantity} MT
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <div
                  className={classes.textLight}
                  style={{ marginBottom: "5px" }}
                >
                  Total DP Quantity
                </div>
                <div className={classes.textDark}>
                  {poDetails.dispatchedPlansQuantity} MT
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Grid>
  </div>)
}

export default PODetails;