import React from "react";
import {
    Button,
    Grid,
    Paper,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableHead,
} from "@material-ui/core";
import _ from "lodash";
import { withRouter } from "react-router";
import { useForm, Form } from "../components/useForm";
import Select from "../components/controls/Select";
import Input from "../components/controls/Input";
import { getActiveOrgTypes } from "../services/masterOrgTypeService";
import { releaseTodaysRate } from "../services/specificReleaseRates";

class SpecificReleaseRates extends React.Component {
    state = {
        releaseRates: [
            {
                customer_type: 1,
                id: 0,
                key_id: 0,
                materialid: 0,
                suggested_base_rate: 0,
                new_release_rate: 0,
                new_release_rate_gujarat: 0,
                new_release_rate_rajasthan: 0,
                new_release_rate_maharashtra: 0,
                new_release_rate_mp: 0,
            }
        ],
        CUSTOMER_TYPES: [],
    };

    async componentDidMount() {
        await this.fetchAllCustomerTypes();
        let rates = [];

        this.state.CUSTOMER_TYPES.map((type, index) => {
            rates.push({
                customer_type: type.id,
                id: index,
                key_id: index,
                suggested_base_rate: this.props.releaseRate.suggested_base_rate,
                new_release_rate: this.props.releaseRate.suggested_base_rate,
                new_release_rate_gujarat: this.props.releaseRate.suggested_base_rate,
                new_release_rate_rajasthan: this.props.releaseRate.suggested_base_rate,
                new_release_rate_maharashtra: this.props.releaseRate.suggested_base_rate,
                new_release_rate_mp: this.props.releaseRate.suggested_base_rate,
            })
        })
        this.setState({ releaseRates: rates });
    }

    fetchAllCustomerTypes = async () => {
        const stateLists = await getActiveOrgTypes();
        this.setState({ CUSTOMER_TYPES: stateLists?.data?.data });
    };

    removeDispatchFormRow = async () => {
        let index = this.state.releaseRates.length - 1;
        let releaseRates = this.state.releaseRates.filter((val, i) => {
            return val.key_id != index;
        });

        this.setState(state => {
            return {
                ...state,
                releaseRates: releaseRates
            };
        });
    };

    addDispatchRow = () => {
        let releaseRates = this.state.releaseRates;
        let thisIndex = this.state.releaseRates.length;
        releaseRates.push({
            customer_type: 1,
            id: 0,
            key_id: thisIndex,
            materialid: 0,
            new_release_rate: 0,
            new_release_rate_gujarat: 0,
            new_release_rate_rajasthan: 0,
            new_release_rate_maharashtra: 0,
            new_release_rate_mp: 0,

        });
        this.setState(state => {
            return {
                ...state,
                releaseRates
            };
        });
    };
    releaseRateRows = () => {
        // const index = field.key_id;
        const index = this.state.releaseRates[0].key_id;
        return (
            <Paper
                key={1}
                elivation={0}
                style={{ margin: "2px", padding: "1px" }}
            >
                <div style={{ padding: 12, marginTop: "10px" }}>
                    <Grid container spacing={2}>
                        <Table className="border-1">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="border-1">Customer Type</TableCell>
                                    <TableCell className="border-1" numeric>
                                        Suggested Rate
                                    </TableCell>
                                    <TableCell className="border-1" numeric>
                                        General Release Rate
                                    </TableCell>
                                    <TableCell className="border-1" numeric>
                                        Gujarat
                                    </TableCell>
                                    <TableCell className="border-1" numeric>
                                        Maharashtra
                                    </TableCell>
                                    <TableCell className="border-1" numeric>
                                        Rajasthan
                                    </TableCell>
                                    <TableCell className="border-1" numeric>
                                        M.P.
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.releaseRates.length > 0 && (
                                <TableBody>
                                    {this.state.releaseRates &&
                                        this.state.releaseRates.map((f) => this.newDispatchFormRow(f))}
                                </TableBody>
                            )}
                        </Table>
                        {/* <Grid item xs={4}>
                            <Button
                                color="primary"
                                onClick={() => this.addDispatchRow()}
                                style={{
                                    width: "300px",
                                    marginLeft: "10px",
                                    color: "#856404",
                                    borderColor: "#ffeeba"
                                }}
                            >
                                <AddIcon fontSize="small" />
                                Add Another Customer Type
                            </Button>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                            {this.state.releaseRates.length <= 1 ? (
                                ""
                            ) : (
                                <Button
                                    color="secondary"
                                    size="small"
                                    onClick={() => this.removeDispatchFormRow()}
                                    style={{
                                        width: "200px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    Remove Material
                                </Button>
                            )}
                        </Grid> */}
                    </Grid>
                    {/* <Divider light /> */}
                </div>
            </Paper>
        );
    };

    handleInputChange = (key, value, index) => {
        let releaseRates = this.state.releaseRates;
        releaseRates[index][key] = value;
        this.setState({ releaseRates })
    }


    newDispatchFormRow = (field) => {
        const index = field.key_id;
        return (
            <TableRow key={field.key_id}>
                <TableCell className="border-1" component="th" scope="size">
                    <Select
                        name="org_type_id"
                        label="Distributor Type"
                        value={this.state.releaseRates[index].customer_type}
                        // onChange={handleInputChange}
                        options={this.state.CUSTOMER_TYPES}
                    />
                </TableCell>
                <TableCell className="border-1">
                    <Input
                        name="suggested_base_rate"
                        id="SuggestedRate"
                        label="Suggested Rate"
                        type="text"
                        // fullWidth
                        value={this.state.releaseRates[index].suggested_base_rate}
                        // onChange={this.handleInputChange}
                        disabled
                    />
                </TableCell>
                <TableCell className="border-1" numeric>
                    <Input
                        name={'new_release_rate' + index}
                        label="General Release Rate"
                        value={this.state.releaseRates[index].new_release_rate}
                        required
                        onChange={e => this.handleInputChange('new_release_rate', e.target.value, index)}
                        type="number"
                    />
                </TableCell>
                <TableCell className="border-1" numeric>
                    <Input
                        name={'new_release_rate_gujarat' + index}
                        label="Gujarat"
                        value={this.state.releaseRates[index]?.new_release_rate_gujarat}
                        required
                        onChange={e => this.handleInputChange('new_release_rate_gujarat', e.target.value, index)}
                        type="number"
                    />
                </TableCell>
                <TableCell className="border-1" numeric>
                    <Input
                        name={'new_release_rate_maharashtra' + index}
                        label="Maharashtra"
                        value={this.state.releaseRates[index]?.new_release_rate_maharashtra}
                        type="number"
                        onChange={e => this.handleInputChange('new_release_rate_maharashtra', e.target.value, index)}
                    />
                </TableCell>
                <TableCell className="border-1" numeric>
                    <Input
                        name={'new_release_rate_rajasthan' + index}
                        label="Rajasthan"
                        value={this.state.releaseRates[index]?.new_release_rate_rajasthan}
                        type="number"
                        onChange={e => this.handleInputChange('new_release_rate_rajasthan', e.target.value, index)}
                    />
                </TableCell>
                <TableCell className="border-1">
                    <Input
                        name={'new_release_rate_mp' + index}
                        label="M.P."
                        value={this.state.releaseRates[index]?.new_release_rate_mp}
                        type="number"
                        onChange={e => this.handleInputChange('new_release_rate_mp', e.target.value, index)}
                    />
                </TableCell>
            </TableRow>
        );
    };
    validate = () => {
        let temp = {};
        // temp.new_release_rate = values.new_release_Rate ? '' : 'This filed is required';
    };
    handleSubmit = async (e, id) => {
        e.preventDefault();
        if (this.validate) {
            try {
                const data = await releaseTodaysRate(id, { releaseRates: this.state.releaseRates });
                if (data.data?.status === 0) {
                    this.props.setNotify({
                        isOpen: true,
                        message: data.message || 'Release Rate could not be released.',
                        type: 'error',
                    });
                } else if (data.data?.status === 1) {
                    this.props.setNotify({
                        isOpen: true,
                        message: data.message || 'Release Rate released successfully.',
                        type: 'success',
                    });
                    this.props.reload();
                    this.props.close();
                }
            } catch (err) {
                this.props.setNotify({
                    isOpen: true,
                    message: err?.response?.data?.message || 'Release Rate could not be approved.',
                    type: 'error',
                });

            }

        }
    };
    resetFormState = () => {
        this.setState({
            materialfields: [
                {
                    key_id: 0,
                    id: 0,
                    materialid: 0,
                    releaseRates: [
                        {
                            customer_type: 1,
                            id: 0,
                            key_id: 0,
                            materialid: 0,
                            new_release_rate: 0,
                            new_release_rate_gujarat: 0,
                            new_release_rate_rajasthan: 0,
                            new_release_rate_maharashtra: 0,
                            new_release_rate_mp: 0,

                        }
                    ]
                }
            ],
        });
    };

    render() {
        return (
            <main className="pageContent">
                <div className="toolbar" />

                <div className="root">
                    <Form onSubmit={(e) => this.handleSubmit(e, this.props.releaseRate.id)}>
                        <Paper
                            elevation={0}
                            className="paperbg"
                            style={{ marginBottom: "40px", paddingTop: "10px" }}
                        >
                            {this.releaseRateRows()}
                            <div style={{ margin: "15px", textAlign: "center" }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ width: "200px", marginBottom: "25px" }}
                                    type="submit"
                                >
                                    Release
                                </Button>
                            </div>
                        </Paper>
                    </Form>

                </div>
            </main>
        );
    }
}

export default withRouter(SpecificReleaseRates);
