import React from "react";
import { TextField } from "@material-ui/core";

export default function Input({
  name,
  label,
  value,
  onChange,
  disabled,
  error = null,
  ...other
}) {
  return (
    <TextField
      variant="outlined"
      disabled={disabled}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
      {...other}
    />
  );
}
