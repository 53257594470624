import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import { useForm, Form } from "../../components/useForm";
import Select from "../../components/controls/Select";
import { Select as MaterialUISelect, MenuItem, InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import { number } from "prop-types";
import { STATES } from "../../constants/states";
import { ORG_TYPES, ORG_TYPE_TEXT } from "../../constants";
import { getActiveOrgTypes } from "../../services/masterOrgTypeService";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialFormValues = {
  org_name: "",
  sap_distributor_code: null,
  gst: "",
  tin: "",
  phone: "",
  name: "",
  email: "",
  address: "",
  city: "",
  postal_code: "",
  state: null,
  status: 1,
  formId: 0,
  user_type: "distributor",
  // org_type: ORG_TYPES.DISTRIBUTOR,
  org_type_id: null,
  district: '',
  // password: "",
  confirm_password: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export default function CustomerForm({ addOrEdit, recordForEdit, type }) {
  const classes = useStyles();
  console.log('--csadc', recordForEdit)

  const [ORG_TYPES, setORG_TYPES] = useState([])

  const validate = (fieldValues = values) => {
    console.log('---', fieldValues)
    let temp = { ...errors };
    if ("org_name" in fieldValues) {
      temp.org_name = "";
      if (fieldValues.org_name === "" || fieldValues.org_name === undefined) {
        temp.org_name = "Distributor name is required";
      } else if (fieldValues.org_name && fieldValues.org_name.length > 150) {
        temp.org_name = "Distributor name should be maximum 150 characters";
      }
    }
    if ("name" in fieldValues) {
      temp.name = "";
      if (fieldValues.name === "" || fieldValues.name === undefined) {
        temp.name = "Contact person name is required";
      } else if (fieldValues.org_name && fieldValues.org_name.length > 150) {
        temp.name = "Contact person name should be maximum 150 characters";
      } else if (/[0-9]/.test(fieldValues.name)) {
        temp.name = "Contact person name only contain should be only alphabet"
      }
    }
    if ("sap_distributor_code" in fieldValues) {
      console.log('!Number.isInteger(fieldValues.sap_distributor_code)', !Number.isInteger(fieldValues.sap_distributor_code))
      temp.sap_distributor_code = "";
      console.log(fieldValues.sap_distributor_code);
      if (
        fieldValues.sap_distributor_code === "" ||
        fieldValues.sap_distributor_code === undefined
      ) {
        temp.sap_distributor_code = "Distributor code is required";
      }
      else if (fieldValues.sap_distributor_code.length < 7) {
        temp.sap_distributor_code = "Distributor Code number should be of 7 length"
      }
      // else if (!Number.isInteger(parseInt(fieldValues.sap_distributor_code))) {
      //   temp.sap_distributor_code = "Distributor Code Should be number"
      // }
    }
    if ("gst" in fieldValues) {
      temp.gst = "";
      if (fieldValues.gst === "" || fieldValues.gst === undefined) {
        temp.gst = "GST number is required";
      }
      else if (fieldValues.gst.length !== 15) {
        temp.gst = "GST number should be of 15 length"
      }
    }
    // if ("tin" in fieldValues) {
    //   temp.tin = "";
    //   if (fieldValues.tin === "" || fieldValues.tin === undefined) {
    //     temp.tin = "TIN number is required";
    //   }
    //   else if (fieldValues.tin.length !== 11) {
    //     temp.tin = "TIN number should be of 11 length"
    //   }
    // }
    if ("address" in fieldValues) {
      temp.address = "";
      if (fieldValues.address === "" || fieldValues.address === undefined) {
        temp.address = "Address is required";
      } else if (fieldValues.address && fieldValues.address.length > 250) {
        temp.address = "Address should be maximum 150 characters";
      }
    }
    if ("district" in fieldValues) {
      temp.district = "";
      if (fieldValues.district === "" || fieldValues.district === undefined) {
        temp.district = "District is required";
      }
      //  else if (fieldValues.district && fieldValues.city.length > 80) {
      //   temp.city = "City should be maximum 150 characters";
      // } 
      else if (/[0-9]/.test(fieldValues.district)) {
        temp.district = "District Name only contain should be only alphabet"
      }
    }
    if ("city" in fieldValues) {
      temp.city = "";
      if (fieldValues.city === "" || fieldValues.city === undefined) {
        temp.city = "City is required";
      } else if (fieldValues.city && fieldValues.city.length > 80) {
        temp.city = "City should be maximum 150 characters";
      } else if (/[0-9]/.test(fieldValues.city)) {
        temp.city = "City Name only contain should be only alphabet"
      }
    }
    if ("postal_code" in fieldValues) {
      temp.postal_code = "";
      console.log(fieldValues.postal_code.toString().length)
      console.log('filk12', fieldValues.postal_code)

      if (
        fieldValues.postal_code === "" ||
        fieldValues.postal_code === undefined
      ) {
        temp.postal_code = "Postal code is required";
      }
      // else if(){

      // }
      else if (fieldValues.postal_code.toString().length !== 6) {
        temp.postal_code = "Postal Code number should be 6 length"
      }
      // else if (
      //   fieldValues.postal_code &&
      //   fieldValues.postal_code.length === 8
      // ) {
      //   temp.postal_code = "Postal code should be maximum 150 characters";
      // } else if (
      //   fieldValues.postal_code &&
      //   fieldValues.postal_code.length > 8
      // ) {
      //   temp.postal_code = "Postal code should be maximum 150 characters";
      // }
    }
    if ("state" in fieldValues) {
      temp.state = "";
      if (fieldValues.state === "" || fieldValues.state === undefined) {
        temp.state = "Distributor's state is required";
      }
    }
    if ("email" in fieldValues) {
      temp.email = "";
      if (fieldValues.email === "" || fieldValues.email === undefined) {
        temp.email = "Please enter email id";
      } else if (fieldValues.email && !/$^|.+@.+..+/.test(fieldValues.email)) {
        temp.email = "Email is not valid";
      }
    }
    if ("phone" in fieldValues) {
      console.log('---dsds', !/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(fieldValues.phone))

      console.log(fieldValues.phone.length !== 10, '---')
      temp.phone = "";
      const letters = /^[A-Za-z]+$/;
      console.log(fieldValues.phone.match(letters))
      if (fieldValues.phone == "" || fieldValues.phone === undefined) {
        temp.phone = "Mobile number is required";

      } else if (fieldValues.phone.length !== 10 || !/^\d+$/.test(fieldValues.phone) || fieldValues.phone.match(letters)) {
        temp.phone = "Please enter valid mobile number";
      }
    }
    console.log(fieldValues.password === ''
    )
    if (type === "edit") {
      if (fieldValues.password !== '') {
        console.log('--')
        if ("password" in fieldValues) {
          temp.password = "";
          if (fieldValues.password === "" || fieldValues.password === undefined) {
            temp.password = "Password is required";
          } else if (
            fieldValues.password &&
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(
              fieldValues.password
            )
          ) {
            temp.password =
              "Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet";
          } else if (fieldValues.password && fieldValues.password.length > 100) {
            temp.password = "Password should be max 100 characters long";
          }
        }
      }
      if (fieldValues.password === '') {
        temp.password = ''

      }
    }
    if (type === "add") {
      if ("password" in fieldValues) {
        temp.password = "";
        if (fieldValues.password === "" || fieldValues.password === undefined) {
          temp.password = "Password is required";
        } else if (
          fieldValues.password &&
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(
            fieldValues.password
          )
        ) {
          temp.password =
            "Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet";
        } else if (fieldValues.password && fieldValues.password.length > 100) {
          temp.password = "Password should be max 100 characters long";

        }
      }
    }
    if ("confirm_password" in fieldValues) {
      temp.confirm_password = "";
      console.log(fieldValues.confirm_password, "=", values.password);
      if (
        fieldValues.confirm_password === "" ||
        fieldValues.confirm_password === undefined
      ) {
        temp.confirm_password = "Confirm Password is required";
      } else if (
        fieldValues.confirm_password &&
        fieldValues.confirm_password != values.password
      ) {
        temp.confirm_password =
          "The two passwords that you entered do not match!";
      }
    }
    if (type === "edit" && fieldValues.password === '') {
      temp.confirm_password = ""
    }
    // if ("organizationId" in fieldValues)
    //   temp.organizationId = Number(fieldValues.organizationId)
    //     ? ""
    //     : "Minimum 1 numbers required";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // eslint-disable-next-line no-unused-vars
  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const organizationStatus = [
    { id: 1, title: "Active" },
    { id: 0, title: "Inactive" },
  ];

  const userTypes = [
    { id: "distributor", title: "Distributor" },
    { id: "admin", title: "Admin" },
  ];

  const fetchAllCustomerTypes = async () => {
    const stateLists = await getActiveOrgTypes();
    console.log("🚀 ~ file: CustomerForm.js ~ line 307 ~ fetchAllCustomerTypes ~ stateLists", stateLists?.data?.data);
    setORG_TYPES(stateLists?.data?.data);
  };

  useEffect(() => {
    fetchAllCustomerTypes();
  }, [])

  useEffect(() => {
    if (recordForEdit !== null)
      if (type === 'edit') {
        delete recordForEdit["password"]
      }
    setValues({
      ...recordForEdit,
    });
  }, [recordForEdit, setValues]);
  console.log('---> va', values)
  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Input
              name="org_name"
              value={values.org_name}
              label="Distributor Name"
              onChange={handleInputChange}
              error={errors.org_name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="sap_distributor_code"
              value={values.sap_distributor_code}
              label="Distributor Code"
              type="number"
              onChange={handleInputChange}
              required
              error={errors.sap_distributor_code}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="gst"
              value={values.gst}
              label="GST No"
              onChange={handleInputChange}
              error={errors.gst}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="district"
              value={values.district}
              label="District"
              onChange={handleInputChange}
              error={errors.district}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="address"
              value={values.address}
              label="Address"
              onChange={handleInputChange}
              error={errors.address}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="city"
              value={values.city}
              label="City"
              onChange={handleInputChange}
              error={errors.city}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="postal_code"
              value={values.postal_code}
              label="Postal Code"
              onChange={handleInputChange}
              error={errors.postal_code}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="outlined">
              <InputLabel id="stateDropdown">State</InputLabel>
              <MaterialUISelect
                // defaultValue='Active'
                labelId="stateDropdown"
                name="state"
                value={values.state}
                label="State"
                onChange={handleInputChange}
                error={errors.state}
                // options={STATES.states}
                MenuProps={MenuProps}
              >
                {STATES.length > 0 && STATES.map((state) => (
                  <MenuItem key={state.id} value={state.code}>
                    {state.name}
                  </MenuItem>
                ))}
              </MaterialUISelect>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="name"
              label="Contact Person"
              type="text"
              value={values.name}
              error={errors.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="email"
              autoComplete={false}
              value={values.email}
              label="Email"
              onChange={handleInputChange}
              error={errors.email}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              name="phone"
              value={values.phone}
              label="Mobile"
              onChange={handleInputChange}
              error={errors.phone}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              name="password"
              // value={values.password}
              label="Password"
              type="password"
              onChange={handleInputChange}
              error={errors.password}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="confirm_password"
              value={values.confirm_password}
              label="Confirm Password"
              type="password"
              onChange={handleInputChange}
              error={errors.confirm_password}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              name="org_type_id"
              label="Distributor Type"
              value={values.org_type_id}
              onChange={handleInputChange}
              options={ORG_TYPES}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              defaultValue='Active'
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={organizationStatus}
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <Input
              name="quantity"
              value={values.quantity}
              label="Quantity (ton)"
              type="quantity"
              onChange={handleInputChange}
            />
          </Grid> */}
          {/* <Grid item md={6} xs={12}>
            <Input
              name="value"
              value={values.value}
              label="Value (cr)"
              type="value"
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button text="Submit" type="submit" />
            <Button
              text="Reset"
              type="button"
              color="default"
              onClick={resetForm}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
