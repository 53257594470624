import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import TuneIcon from "@material-ui/icons/Tune";
import { makeStyles, Grid, Typography, Divider } from "@material-ui/core";
import { Dialog } from "../../components/Dialog";
import Notifications from "../../components/Notifications";
import Card from "../../components/ui/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 9,
  },
  card: {
    height: 500,
    width: 700,
  },
  smallCard: {
    height: 400,
    width: 200,
  },
  smallCard__2: {
    height: 400,
    width: 300,
  },
  bottomCard: {
    marginTop: 50,
    height: 60,
    width: "100%",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handlePopUpOpen = () => {
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  return (
    <>
      <PageHeader
        title="Settings"
        subTitle="Manage your materials"
        icon={<TuneIcon />}
      />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={6}>
            <Grid item>
              <Card classNames={classes.card}>
                <Grid justify="space-between" container spacing={24}>
                  <Grid item>
                    <Typography variant="h6">Revised TMT Prices</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      style={{ fontWeight: 600 }}
                    >
                      EDIT
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.root} spacing={2}>
                  <Grid item>
                    <Grid container justify="center" spacing={4}>
                      <Grid item>
                        <Card classNames={classes.smallCard}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 600 }}
                          >
                            SIZE <sub>(MM)</sub>
                          </Typography>
                          <br />
                          <br />
                          <Divider />
                          <br />
                          <Typography variant="subtitle2">8</Typography>
                          <br />
                          <Typography variant="subtitle2">10</Typography>
                          <br />
                          <Typography variant="subtitle2">12</Typography>
                          <br />
                          <Typography variant="subtitle2">14</Typography>
                          <br />
                          <Typography variant="subtitle2">8</Typography>
                          <br />
                          <Typography variant="subtitle2">10</Typography>
                          <br />
                          <Typography variant="subtitle2">12</Typography>
                          <br />
                          <Typography variant="subtitle2">14</Typography>
                          <br />
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card classNames={classes.smallCard}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 600 }}
                          >
                            REVISED PREMIUM <sub>(EXCLUDING GST)</sub>
                          </Typography>
                          <Divider />
                          <br />
                          <Typography variant="subtitle2">+2400</Typography>
                          <br />
                          <Typography variant="subtitle2">+1200</Typography>
                          <br />
                          <Typography variant="subtitle2">+350</Typography>
                          <br />
                          <Typography variant="subtitle2">Basic</Typography>
                          <br />
                          <Typography variant="subtitle2">+2400</Typography>
                          <br />
                          <Typography variant="subtitle2">+1200</Typography>
                          <br />
                          <Typography variant="subtitle2">+350</Typography>
                          <br />
                          <Typography variant="subtitle2">+350</Typography>
                          <br />
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card classNames={classes.smallCard}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 600 }}
                          >
                            EXPORT RATE PARTY <sub>(EXCLUDING GST)</sub>
                          </Typography>
                          <Divider />
                          <br />
                          <Typography variant="subtitle2">INR 13000</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 12000</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 11500</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 11500</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 13000</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 12000</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 11500</Typography>
                          <br />
                          <Typography variant="subtitle2">INR 11500</Typography>
                          <br />
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card classNames={classes.card}>
                <Grid justify="space-between" container spacing={6}>
                  <Grid item>
                    <Typography variant="h6">Revised TMT Prices</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      style={{ fontWeight: 600 }}
                    >
                      EDIT
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.root} spacing={2}>
                  <Grid item>
                    <Grid container justify="center" spacing={6}>
                      <Grid item>
                        <Card classNames={classes.smallCard__2}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 600 }}
                          >
                            GRADE
                          </Typography>
                          <br />
                          <br />
                          <Divider />
                          <br />
                          <Typography variant="subtitle2">CRS 500</Typography>
                          <br />
                          <Typography variant="subtitle2">CRS 500 D</Typography>
                          <br />
                          <Typography variant="subtitle2">FE 550 D</Typography>
                          <br />
                          <Typography variant="subtitle2">
                            CUT & BEND
                          </Typography>
                          <br />
                          <Typography variant="subtitle2">
                            SPECIAL LENGTH
                          </Typography>
                          <br />
                          <Typography variant="subtitle2">
                            RANDOM(7-11m)
                          </Typography>
                          <br />
                          <Typography variant="subtitle2">NON-PRIME</Typography>
                          <br />
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card classNames={classes.smallCard__2}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 600 }}
                          >
                            REVISED PREMIUM <sub>(EXCLUDING GST)</sub>
                          </Typography>
                          <Divider />
                          <br />
                          <Typography variant="subtitle2">+2400</Typography>
                          <br />
                          <Typography variant="subtitle2">+2600</Typography>
                          <br />
                          <Typography variant="subtitle2">+350</Typography>
                          <br />
                          <Typography variant="subtitle2">+2400</Typography>
                          <br />
                          <Typography variant="subtitle2">+1300</Typography>
                          <br />
                          <Typography variant="subtitle2">
                            -1300 to 2100
                          </Typography>
                          <br />
                          <Typography variant="subtitle2">-1000</Typography>
                          <br />
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card classNames={classes.bottomCard}>
                <Grid justify="space-between" container spacing={24}>
                  <Grid item>
                    <Typography color="primary" variant="subtitle1">
                      DOWEL BAR 16mm to 36mm will be Rs. (+) 1500 from Fe 500 D
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      style={{ fontWeight: 600 }}
                    >
                      EDIT
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        heading="Add Discount"
        handleClose={handleClose}
        open={openPopUp}
        width="xs"
      ></Dialog>

      <Notifications notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Settings;
