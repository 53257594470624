import { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import {
  makeStyles,
  TableBody,
  Paper,
  Toolbar,
  TableRow,
  TableCell,
  InputAdornment,
} from "@material-ui/core";

import { getAllCustomers, getAllOrganizations } from "../../services/customerService";
// import { getMaterialList } from "../../services/materialService";
import {
  getAllDiscounts,
  insertDiscount,
  updateDiscount,
  deleteDiscount,
  disableDiscount,
  getAllDiscountsForOrganization
} from "../../services/discountService";
import {
  getMaterialList,
  getMaterialGrade,
  getMaterialSizes,
  getMaterialType,
} from "../../services/materialService";
import { Dialog } from "../../components/Dialog";
import useTable from "../../components/useTable";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import AddIcon from "@material-ui/icons/Add";
import { Search } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ActionButton from "../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Notifications from "../../components/Notifications";
import DiscountForm from "./DiscountForm";
import { getCurrentUser } from "../../services/userService";
import { USER_TYPES } from "../../constants";
import DiscountTable from "./DiscountTable";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));


const Discount = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([{ id: "", title: "" }]);
  const [materials, setMaterials] = useState([{ id: "", title: "" }]);
  const [grades, setGrades] = useState([{ id: 0, title: "All" }]);
  const [sizes, setSizes] = useState([{ id: 0, title: "All" }]);
  const [types, setTypes] = useState([{ id: 0, title: "All" }]);
  const [discounts, setDiscounts] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [userOrgId, setUserOrgId] = useState(localStorage.getItem('user_org_id'));
  const [userType, setUserType] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getCurrentUserFunc = async () => {
    const user = await getCurrentUser();
    if (user && user.data) {
      setCurrentUser(user.data.body);
      setUserType(user.data.body.user_type);
      setUserOrgId(user.data.body.org_id);
    }
  }

  useEffect(() => {
    getCurrentUserFunc();
  }, [])

  const handlePopUpOpen = () => {
    setRecordForEdit(null);
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure to disable this record")) {
      // const updatedData = customers.filter((row) => row.organizationId !== id);
      // deleteDiscount(id);
      disableDiscount(id);
      setNotify({
        isOpen: true,
        message: "Discount Disabled successfully",
        type: "error",
      });
      functdiscounts();
    }
  };
  useEffect(() => {
    const materials = async () => {
      const materialGradeLists = await getMaterialGrade();
      if (materialGradeLists.data.data.length > 0) {
        let dataList = [];
        dataList.push({
          id: 0,
          title: "All",
        });
        materialGradeLists.data.data.forEach((data) => {
          if (data.status === 1) {
            dataList.push({
              id: data.id,
              title: data.grade_name,
            });
          }
        });
        setGrades(dataList);
      }
    };
    materials();
  }, []);

  useEffect(() => {
    const materials = async () => {
      const materialSizeLists = await getMaterialSizes();
      if (materialSizeLists.data.data.length > 0) {
        let dataList = [];
        dataList.push({
          id: 0,
          title: "All",
        });
        materialSizeLists.data.data.forEach((data) => {
          if (data.status === 1) {
            dataList.push({
              id: data.id,
              title: data.size_name,
            });
          }
        });
        setSizes(dataList);
      }
    };
    materials();
  }, []);

  useEffect(() => {
    const materials = async () => {
      const materialTypeLists = await getMaterialType();
      if (materialTypeLists.data.data.length > 0) {
        let dataList = [];
        dataList.push({
          id: 0,
          title: "All",
        });
        materialTypeLists.data.data.forEach((data) => {
          if (data.status === 1) {
            dataList.push({
              id: data.id,
              title: data.type_name,
            });
          }
        });
        setTypes(dataList);
      }
    };
    materials();
  }, []);
  useEffect(() => {
    const customers = async () => {
      const customersList = await getAllOrganizations();
      if (customersList.data.data.length > 0) {
        let dataList = [{ id: 0, title: "All" }];
        customersList.data.data.forEach((data) => {
          dataList.push({
            id: data.id,
            title: data.org_name,
          });
        });
        setCustomers(dataList);
      }
    };
    customers();
  }, []);

  useEffect(() => {
    const materials = async () => {
      const materialsList = await getMaterialList();
      if (materialsList.data.data.length > 0) {
        let dataList = [{ id: 0, title: "All" }];
        materialsList.data.data.forEach((data) => {
          dataList.push({ id: data.id, title: data.name });
        });
        setMaterials(dataList);
      }
    };
    materials();
  }, []);

  const functdiscounts = async () => {
    if (currentUser?.user_type === USER_TYPES.ADMIN) {// || userOrgId == null) {
      const discountLists = await getAllDiscounts();
      const dLists = discountLists.data.data;
      setDiscounts(dLists);
    } else {
      const discountLists = await getAllDiscountsForOrganization(userOrgId);
      const dLists = discountLists.data.data;
      setDiscounts(dLists);
    }
  };

  useEffect(() => {
    functdiscounts();
  }, [currentUser]);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) => {
            if (userType === 'admin') {
              return x.org_name.toLowerCase().includes(target.value.toLowerCase())
            } else {
              return x.discount_name.toLowerCase().includes(target.value.toLowerCase())
            }
          }
          );
        }
      },
    });
  };

  const addOrEdit = async (discount, resetForm) => {
    let response
    if (discount.id === 0) {
      response = await insertDiscount(discount);
    } else {
      response = await updateDiscount(discount);
    }

    if (response.data.data === null) {
      setNotify({
        isOpen: true,
        message: response.data.message,
        type: "error",
      });
    } else {
      resetForm();
      setRecordForEdit(null);
      setOpenPopUp(false);
      setNotify({
        isOpen: true,
        message: "Submitted successfully",
        type: "success",
      });
      functdiscounts();
    }
    //window.location.reload();
  };

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const openInPopUp = (row) => {
    setRecordForEdit(row);
    setOpenPopUp(true);
  };

  return (
    <>
      <PageHeader
        title="Discount"
        subTitle="Discount details and Maintenance"
        icon={<MoneyOffIcon />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Input
            label={`Search by ${userType === 'admin' ? "Organization" : "discount"} name`}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {userType === 'admin' &&
            <Button
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={handlePopUpOpen}
            />
          }
        </Toolbar>
        <DiscountTable
          discounts={discounts}
          openInPopUp={openInPopUp}
          filterFn={filterFn}
        />
      </Paper>
      <Dialog
        heading="Add Discount"
        handleClose={handleClose}
        open={openPopUp}
        width="xs"
      >
        <DiscountForm
          types={types}
          sizes={sizes}
          grades={grades}
          customers={customers}
          materials={materials}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Dialog>
      <Notifications notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Discount;
