import { Paper, Card, Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
    marginTop: "3rem",
  },
  PageHeader: {
    padding: theme.spacing(4),
    display: "flex",
    margin: theme.spacing(1),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));

export default function PageHeader({ title, subTitle, icon }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid xs={12} md={12} item>
        <Paper elevation={3} square className={classes.root}>
          <div className={classes.PageHeader}>
            <Card className={classes.pageIcon}>{icon}</Card>
            <div className={classes.pageTitle}>
              <Typography variant="h6" component="div">
                {title}
              </Typography>
              <Typography variant="subtitle2" component="div">
                {subTitle}
              </Typography>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
