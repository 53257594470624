import React from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Select as MaterialUISelect, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Form } from '../../components/useForm';
// import Select from '@material-ui/core/Select';
import Select from '../../components/controls/Select';
import Input from '../../components/controls/Input';
import Notifications from '../../components/Notifications';
import {
  getPOMaterialListGeneric,
  getMaterialListSpecific,
  getMaterialType,
  getMaterialSizes,
  getSpecialSizes,
} from '../../services/materialService';
import { getGradeList } from '../../services/gradeService';
import { getActiveConsigneeListForDistributor } from '../../services/consigneeService';
import { getReleaseRateForPO } from '../../services/releaseRates';
import { getSettings } from '../../services/settings';
import {
  getAvailableQuantity,
  getOtherStateParity,
  getExPlantRates,
  getDispatchOrderDetailsForEditing,
} from '../../services/dispatchPlan';
import { getCurrentUser } from '../../services/userService';
import { getPurchaseOrderDetails } from '../../services/PurchaseOrder';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import './create-dispatch-plans.css';
import { STATUS, TYPE_DEPENDENCY } from '../../constants';
import { getAllDistricts, getAllStates } from '../../services/masterDataService';

const token = localStorage.getItem('token');
const user_type = localStorage.getItem('user_type');
const user_id = localStorage.getItem('id');
// const org_id = localStorage.getItem('org_id');

const SERVERBASEURL = process.env.REACT_APP_API;

class CreateDispatchPlans extends React.Component {
  state = {
    materialfields: [
      {
        key_id: 0,
        id: 0,
        materialid: 0,
        typeId: null,
        dependentTypeId: null,
        gradeId: null,
        dispatchMaterials: [
          // {
          //   key_id: 0,
          //   id: 0,
          //   materialid: 0,
          //   // typeId: null,
          //   // dependentTypeId: null,
          //   // gradeId: 0,
          //   sizeId: 0,
          //   quantity: 0,
          //   rate: 0,
          //   discount: 0,
          //   total_amount: 0,
          //   amount_after_gst: 0,
          //   dispatch_to: '',
          //   quantityError: false,
          // },
        ],
        consignee_id: null,
        // consignee_name: '',
        ex_plant: null,
        other_state_parity: null,
      },
    ],
    distributors: [],
    materialList: [],
    materialTypes: [],
    materialGrades: [],
    materialSizes: [],
    specialSizes: [],
    districtDiscounts: [],
    specificMaterialsList: [],
    TotalQuantity: 0,
    TotalRate: 0,
    baseRate: 0,
    distributorId: '',
    deliveryLocation: '',
    AllDiscounts: [],
    TotalDiscount: 0,
    TotalGst: 0,
    GstPercent: 0,
    GrandTotal: 0,
    Basic: 0,
    credit_limit_quantity: 0,
    credit_limit_value: 0,
    AllowSubmit: true,
    purchaseOrderId: null,
    dispatchOrderId: null,
    // MAX_QUANTITY:0,
    // REMAINING_QUANTITY:0,
    MAXIMUM_QUANTITY: {},
    quantityError: false,
    // org_id: user_type == 'admin' ? 0 : org_id,
    notify: {
      isOpen: false,
      message: '',
      type: '',
    },
    validReleaseRate: true,
    quantityValidation: true,
    currentUser: {},
    consigneeList: [],
    poDetails: {},
    remarks: '',
    STATES: [],
    DISTRICTS: [],
    EX_PLANTS_RATE: [],
    OTHER_STATE_PARITY_RATES: [],
    isSubmitting: false,
    extraSizeSelected: null,
  };

  async componentDidMount() {
    let id = this.props.match.params.id;
    let dispatchOrderId = this.props.match.params.dispatchOrderId;
    this.setState({ purchaseOrderId: id });
    if (dispatchOrderId) await this.fetchDispatchOrderDetailsForEdit();
    var specificMaterialsList = [];

    const materialLists = await getPOMaterialListGeneric(id);
    const mLists = materialLists.data.data;
    let Materials = [];
    let materialfields = [];
    for (let [key, value] of mLists.entries()) {
      Materials[value.id] = value;
      // specificMaterialsList[value.id] = [];
      materialfields.push({
        key_id: 0,
        id: 0,
        materialid: value.id,
        typeId: null,
        dependentTypeId: null,
        gradeId: null,
        dispatchMaterials: [],
        consignee_id: null,
        // consignee_name: '',
        ex_plant: null,
        other_state_parity: null,
      });
    }
    if (!this.state.dispatchOrderId) {
      this.setState({ materialList: Materials, materialfields });
    } else {
      this.setState({ materialList: Materials });
    }
    this.fetchAllStates();
    this.fetchAllDistricts();
    this.fetchTypes();
    this.fetchGrades();
    this.fetchSizes();
    this.fetchExPlantRates();
    this.fetchOtherStateParityRates();
    // this.fetchDistrictDiscounts();
    let specific = await getMaterialListSpecific(1);
    // console.log(JSON.stringify(specific.data.data))
    let specificMaterials = specific.data.data;
    for (let [key, val] of specificMaterials.entries()) {
      specificMaterialsList[val.id] = val;
    }
    this.setState({ specificMaterialsList });

    let mainMaterials = {};
    let availableQuantity = await getAvailableQuantity(this.props.match.params.id);
    for (let index = 0; index < availableQuantity.length; index++) {
      mainMaterials[parseInt(availableQuantity[index].material_id)] = {
        availableQuantity: parseFloat(
          // availableQuantity[index].available_quantity ||
          //   availableQuantity[index].quantity
          // changed the below expression to accomodate 0 value coming from backend, currently it is not considered.
          availableQuantity[index].available_quantity !== null
            ? availableQuantity[index].available_quantity
            : availableQuantity[index].quantity,
        ),
        remainingQuantity: parseFloat(
          availableQuantity[index].available_quantity !== null
            ? availableQuantity[index].available_quantity
            : availableQuantity[index].quantity,
        ),
        quantity: parseFloat(availableQuantity[index].quantity),
        quantityError: false,
      };
    }
    await this.setState({ MAXIMUM_QUANTITY: mainMaterials });

    // this.setState({ materialList: Materials });
    let user = await getCurrentUser();
    this.setState({ currentUser: user.data.body });
    this.getRodaysReleaseRate();
    await this.fetchPurchaseOrderDetails();
    await this.getSettings();
    let consignees = await getActiveConsigneeListForDistributor(this.state.poDetails.org_id);
    this.setState({ consigneeList: consignees.data.data });
  }

  fetchDispatchOrderDetailsForEdit = async () => {
    let id = this.props.match.params.id;
    let dispatchOrderId = this.props.match.params.dispatchOrderId;
    this.setState({ purchaseOrderId: id, dispatchOrderId });

    let initialDispatchOrderDetails = await getDispatchOrderDetailsForEditing(dispatchOrderId);
    // console.log('initial' + JSON.stringify(initialDispatchOrderDetails));
    // this.setState({initialDispatchOrderDetails});
    if (initialDispatchOrderDetails.length > 0) {
      if (initialDispatchOrderDetails[0].status !== STATUS.REJECTED && initialDispatchOrderDetails[0].status !== STATUS.PENDING) {
        this.setState({
          notify: {
            isOpen: true,
            message: 'Only Rejected/Pending Dispatch Plans can be edited !',
            type: 'error',
          },
        });
        this.props.history.push('/all-dispatch-plans');
      }
      this.setState({
        TotalQuantity: initialDispatchOrderDetails[0].total_quantity,
        TotalRate: initialDispatchOrderDetails[0].total_rate,
        TotalDiscount: initialDispatchOrderDetails[0].discount,
        Basic: ((initialDispatchOrderDetails[0].total_rate * 100) / (100 + this.state.GstPercent)).toFixed(2),
        TotalGst: initialDispatchOrderDetails[0].gst,
        GrandTotal: initialDispatchOrderDetails[0].total_amount,
        remarks: initialDispatchOrderDetails[0].remarks,
      });
    }
    var materialFieldsFetched = [];
    for (let i = 0; i < initialDispatchOrderDetails.length; i++) {
      let filteredDispatchList = initialDispatchOrderDetails.filter(
        (d) => d.main_material_id == initialDispatchOrderDetails[i].main_material_id,
      );
      let filteredMaterialList = await materialFieldsFetched.filter(
        (m) => m.materialid === initialDispatchOrderDetails[i].main_material_id,
      );
      var material = {};
      if (filteredMaterialList.length === 0) {
        material = {
          key_id: materialFieldsFetched.length,
          id: 0,
          materialid: initialDispatchOrderDetails[i].main_material_id,
          gradeId: initialDispatchOrderDetails[i].grade_id,
          typeId: initialDispatchOrderDetails[i].type_id,
          dependentTypeId: initialDispatchOrderDetails[i].dependent_type_id,
          ex_plant: initialDispatchOrderDetails[i].ex_plant === 1 ? true : false,
          other_state_parity: initialDispatchOrderDetails[i].other_state_parity === 1 ? true : false,
          consignee_id: initialDispatchOrderDetails[i].consignee_id,
          consignee_name: initialDispatchOrderDetails[i].consignee_name,
          care_off: initialDispatchOrderDetails[i].care_off,
          house_no: initialDispatchOrderDetails[i].house_no,
          street_2: initialDispatchOrderDetails[i].street_2,
          street_3: initialDispatchOrderDetails[i].street_3,
          street_4: initialDispatchOrderDetails[i].street_4,
          street_5: initialDispatchOrderDetails[i].street_5,
          city: initialDispatchOrderDetails[i].city,
          consignee_state_code: initialDispatchOrderDetails[i].consignee_state_code,
          consignee_state: initialDispatchOrderDetails[i].consignee_state,
          consignee_district_code: initialDispatchOrderDetails[i].consignee_district_code,
          district: initialDispatchOrderDetails[i].district,
          postal_code: initialDispatchOrderDetails[i].postal_code,
          dispatchMaterials: [],
        };
        // console.log("******"+JSON.stringify(filteredDispatchList))
        for (let j = 0; j < filteredDispatchList.length; j++) {
          material.dispatchMaterials.push({
            key_id: j,
            id: j,
            materialid: filteredDispatchList[j].material_id,
            sizeId: filteredDispatchList[j].size_id,
            quantity: filteredDispatchList[j].quantity,
            special_length: filteredDispatchList[j].special_length,
            rate: filteredDispatchList[j].rate,
            discount: filteredDispatchList[j].dispatchMaterialDiscount,
            // discount: 0,
            amount_after_gst: filteredDispatchList[j].amount_after_gst,
            total_amount: filteredDispatchList[j].amount,
            quantityError: false,
          });
        }
        materialFieldsFetched.push(material);
      }
    }
    materialFieldsFetched.length > 0 && this.setState({ materialfields: materialFieldsFetched });
  };

  fetchPurchaseOrderDetails = async () => {
    let data = await getPurchaseOrderDetails(this.state.purchaseOrderId);
    let po = data.data.data[0];
    this.setState({ poDetails: po });
    if (this.state.currentUser.user_type === 'distributor' && po.org_id !== this.state.currentUser.org_id) {
      this.setState({
        notify: {
          isOpen: true,
          message: 'You are not authorized to view this page! Please contact admin for help !',
          type: 'error',
          timeOut: 4000,
        },
      });
      setInterval(() => {
        this.props.history.push('/order');
      }, 3500);
    }
    // this.setState({ MAX_QUANTITY:po[0].total_quantity, REMAINING_QUANTITY:po[0].total_quantity });
  };
  getRodaysReleaseRate = async () => {
    let data = await getReleaseRateForPO(this.state.purchaseOrderId);
    let ReleaseRates = data.data.data;
    let baseRate = 0;
    if (ReleaseRates.length > 0) {
      baseRate = ReleaseRates[0].new_release_rate;
    } else {
      this.setState({ validReleaseRate: false });
      console.log('No Release Rate Data Fetched !');
    }
    this.setState({ baseRate });
  };

  getSettings = async () => {
    let data = await getSettings();
    let Settings = data.data.data;
    let GstPercent = 0;
    if (Settings.length > 0) {
      GstPercent = Settings[0].gst;
    }
    this.setState({ GstPercent });
  };

  fetchAllStates = async () => {
    const stateLists = await getAllStates();
    // let mLists = stateLists.data;
    // console.log('\n\n\n\n' + JSON.stringify(stateLists));
    this.setState({ STATES: stateLists });
  };

  fetchAllDistricts = async () => {
    const stateLists = await getAllDistricts();
    this.setState({ DISTRICTS: stateLists });
  };
  fetchExPlantRates = async () => {
    const stateLists = await getExPlantRates();
    let mLists = stateLists.data.data;
    this.setState({ EX_PLANTS_RATE: mLists });
  };
  fetchOtherStateParityRates = async () => {
    const stateLists = await getOtherStateParity();
    let mLists = stateLists.data.data;
    this.setState({ OTHER_STATE_PARITY_RATES: mLists });
  };

  fetchTypes = async () => {
    const materialLists = await getMaterialType();
    let mLists = materialLists.data.data;
    this.setState({ materialTypes: mLists });
  };

  fetchGrades = async () => {
    const materialLists = await getGradeList();
    let mLists = materialLists.data.data;
    this.setState({ materialGrades: mLists });
  };

  fetchSizes = async () => {
    const materialSizeLists = await getMaterialSizes();
    let mLists = materialSizeLists.data.data;
    this.setState({ materialSizes: mLists });
    if (!this.state.dispatchOrderId) {
      this.state.materialfields.map((parent, index) => {
        mLists.map((size) => this.addDispatchRow(index, size));
      });
    } else {
      this.state.materialfields.map((parent, index) => {
        mLists.map((size) => {
          if (parent.dispatchMaterials.filter((dm) => dm.sizeId === size.id).length === 0) {
            return this.addDispatchRow(index, size);
          }
        });
      });
    }
    const specialSizeLists = await getSpecialSizes();
    let sLists = specialSizeLists.data.data;
    this.setState({ specialSizes: sLists });
  };

  removeDispatchFormRow = async (index, parentMaterial) => {
    let materialfields = this.state.materialfields;
    let dispatchMaterials = this.state.materialfields[parentMaterial].dispatchMaterials.filter((val, i) => {
      return val.key_id != index;
    });
    materialfields[parentMaterial].dispatchMaterials = dispatchMaterials;

    await this.setState((state) => {
      return {
        ...state,
        materialfields: materialfields,
      };
    });
    this.updateTotalQuantity(parentMaterial, index);
    this.updateTotalRate();
  };

  addDispatchRow = (index, size) => {
    // console.log("-----" + index);
    let materialfields = this.state.materialfields;
    let thisIndex = materialfields[index].dispatchMaterials.length;
    materialfields[index].dispatchMaterials.push({
      key_id: thisIndex,
      id: 0,
      materialid: 0,
      sizeId: size.id,
      quantity: 0,
      rate: 0,
      discount: 0,
      total_amount: 0,
      // consignee_name: '',
      dispatch_to: '',
      quantityError: false,
    });
    this.setState((state) => {
      return {
        ...state,
        materialfields: materialfields,
      };
    });
  };

  addDispatchRowAtSerialPosition = async (index, size) => {
    // console.log("-----" + index);
    let materialfields = this.state.materialfields;
    let thisIndex = materialfields[index].dispatchMaterials.length;

    let dispatchMaterials = materialfields[index].dispatchMaterials;
    const sizeIndex = dispatchMaterials.findIndex((dm) => dm.sizeId === size.id);
    if (sizeIndex >= 0) {
      let newDispatchMaterials = [];
      for (let i = 0; i < sizeIndex; i++) {
        await newDispatchMaterials.push(dispatchMaterials[i]);
      }
      newDispatchMaterials.push({
        key_id: sizeIndex,
        id: 0,
        materialid: 0,
        sizeId: size.id,
        quantity: 0,
        rate: 0,
        discount: 0,
        total_amount: 0,
        dispatch_to: '',
        quantityError: false,
      });
      for (let i = sizeIndex; i < dispatchMaterials.length; i++) {
        await newDispatchMaterials.push({
          ...dispatchMaterials[i],
          key_id: i + 1,
        });
      }
      materialfields[index].dispatchMaterials = newDispatchMaterials;
    } else {
      materialfields[index].dispatchMaterials.push({
        key_id: thisIndex,
        id: 0,
        materialid: 0,
        sizeId: size.id,
        quantity: 0,
        rate: 0,
        discount: 0,
        total_amount: 0,
        // consignee_name: '',
        dispatch_to: '',
        quantityError: false,
      });
    }
    this.setState((state) => {
      return {
        ...state,
        materialfields: materialfields,
      };
    });
  };

  materialFormRow = (field, idx) => {
    const index = field.key_id;
    return (
      <Paper key={index} elivation={0} style={{ margin: '20px', padding: '10px' }}>
        <div style={{ padding: 12, marginTop: '10px' }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input name={'materialid_' + index} style={{ display: 'none' }} type="hidden" value={field.materialid} />

              <Select
                name={'material_id_' + index}
                label="Material"
                value={field.materialid}
                onChange={(e) => {
                  // handleMainMaterialChange(e, index)
                  let materialfields = this.state.materialfields;
                  materialfields[field.key_id].materialid = e.target.value;
                  this.setState({ materialfields });
                }}
                options={this.state.materialList}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined">
                <InputLabel id="demo-mutiple-name-label">Type</InputLabel>
                <MaterialUISelect
                  name={'type_' + index}
                  label="Type"
                  value={field.typeId}
                  onChange={(e) => {
                    // // this.handleMaterialChange(e, index)
                    let materialfields = this.state.materialfields;
                    materialfields[index].typeId = e.target.value;
                    this.setState({ materialfields });
                    this.state.materialfields[index].dispatchMaterials.map((dm, i) => {
                      this.handleMaterialChange(e, i, index);
                    });
                    // this.handleMaterialChange(e, index, parentMaterial);
                    // this.updateTotalRate();
                  }}
                  // options={this.state.specificMaterialsList[this.state.materialfields[parentMaterial].materialid] || []}
                  // options={this.state.materialTypes}
                  required
                >
                  {this.state.materialTypes.length > 0 &&
                    this.state.materialTypes
                      .filter((type) => type.dependency === TYPE_DEPENDENCY.INDEPENDENT)
                      .map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {`${type.type_name}`}
                        </MenuItem>
                      ))}
                </MaterialUISelect>
              </FormControl>
            </Grid>
            {this.state.materialfields[index].typeId !== null && this.findTypesDependent(index).length > 0 && (
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined">
                  <InputLabel id="demo-mutiple-name-label">Dependent Type</InputLabel>
                  <MaterialUISelect
                    name={'dependent_type_' + index}
                    label="Dependent Type"
                    value={field.dependentTypeId}
                    onChange={(e) => {
                      // // this.handleMaterialChange(e, index)
                      let materialfields = this.state.materialfields;
                      materialfields[index].dependentTypeId = e.target.value;
                      this.setState({ materialfields });
                      this.state.materialfields[index].dispatchMaterials.map((dm, i) => {
                        this.handleMaterialChange(e, i, index);
                      });
                      // this.handleMaterialChange(e, index, parentMaterial);
                      // this.updateTotalRate();
                    }}
                  >
                    {this.findTypesDependent(index).length > 0 &&
                      this.findTypesDependent(index).map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {`${type.type_name}`}
                        </MenuItem>
                      ))}
                  </MaterialUISelect>
                </FormControl>
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined">
                <InputLabel id="demo-mutiple-name-label">Grade</InputLabel>
                <MaterialUISelect
                  name={'grade_' + index}
                  label="Grades"
                  value={field.gradeId}
                  onChange={(e) => {
                    // // this.handleMaterialChange(e, index)
                    let materialfields = this.state.materialfields;
                    materialfields[index].gradeId = e.target.value;
                    this.setState({ materialfields });
                    this.state.materialfields[index].dispatchMaterials.map((dm, i) => {
                      this.handleMaterialChange(e, i, index);
                    });
                    // this.handleMaterialChange(e, index, parentMaterial);
                    // this.updateTotalRate();
                  }}
                  required
                >
                  {this.state.materialGrades.length > 0 &&
                    this.state.materialGrades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.id}>
                        {`${grade.grade_name}`}
                      </MenuItem>
                    ))}
                </MaterialUISelect>
              </FormControl>
            </Grid>
            {this.renderConsigneeFields(field, index, 0)}
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="ex_plant"
                    checked={this.state.materialfields[index].ex_plant}
                    name={'ex_plant' + index}
                    onChange={(e) => {
                      let materialfields = this.state.materialfields;
                      materialfields[index].ex_plant = e.target.checked;
                      materialfields[index].dispatchMaterials.map((dMaterial, i) => {
                        // this.calculateRate(i, parentMaterial),
                        this.handleMaterialChange(null, i, index);
                      });
                      // this.handleMaterialChange(null, index, parentMaterial);
                      this.setState({ materialfields });
                    }}
                  />
                }
                label="Ex - Plant"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={this.state.materialfields[index].consignee_state_code === 12}
                    defaultChecked={field.other_state_parity}
                    value="other_state_parity"
                    name={'other_state_parity_' + index}
                    onChange={(e) => {
                      let materialfields = this.state.materialfields;
                      materialfields[index].other_state_parity = e.target.checked;
                      materialfields[index].dispatchMaterials.map((dMaterial, i) => {
                        // this.calculateRate(i, index),
                        console.log(i + '$$$$$');
                        this.handleMaterialChange(null, i, index);
                      });
                      // this.handleMaterialChange(null, index, index);
                      this.setState({ materialfields });
                    }}
                  />
                }
                label="Other State Parity"
              />
            </Grid>
            {/* {this.state.materialfields[index].dispatchMaterials &&
              this.state.materialfields[index].dispatchMaterials.map((f) => this.dispatchFormRow(f, index))} */}
            {/* <Paper> */}
            {this.state.materialfields[index].dispatchMaterials.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="border-1">Material Size</TableCell>
                    <TableCell className="border-1" numeric>
                      Quantity
                    </TableCell>
                    {this.state.materialfields[index].dependentTypeId !== null && (
                      <TableCell className="border-1" numeric>
                        Special Length
                      </TableCell>
                    )}
                    <TableCell className="border-1" numeric>
                      Rate
                    </TableCell>
                    <TableCell className="border-1" numeric>
                      Total Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.materialfields[index].dispatchMaterials &&
                    this.state.materialfields[index].dispatchMaterials.map((f) => this.newDispatchFormRow(f, index))}
                  {/* {
                    this.state.materialSizes.length > 0 && this.state.materialSizes.map((size, i) => 
                      this.newDispatchFormRow(size, index, i)
                  )
                  } */}
                </TableBody>
              </Table>
            )}
            {/* </Paper> */}
            {/* {this.dispatchFormRow(0, index)} */}
            {/* <Grid item xs={4}>
              <Button
                color="primary"
                onClick={() => this.addDispatchRow(index)}
                style={{
                  width: '300px',
                  marginLeft: '10px',
                  color: '#856404',
                  borderColor: '#ffeeba',
                }}
              >
                <AddIcon fontSize="small" />
                Add Dispatch Material
              </Button>
            </Grid> */}
            {/* {this.renderConsigneeFields(field, index, 0)} */}
          </Grid>
          {this.dispatchFormRow(0, index)}
        </div>
      </Paper>
    );
  };

  newDispatchFormRow = (field, parentMaterial) => {
    const index = field.key_id;
    return (
      <TableRow key={field.key_id}>
        <TableCell className="border-1" component="th" scope="size">
          {
            this.state.materialSizes?.find(
              (size) => size.id === this.state.materialfields[parentMaterial].dispatchMaterials[index].sizeId,
            )?.size_name
          }
        </TableCell>
        <TableCell className="border-1">
          <Input
            name={'quantity_' + index}
            //value={eval("values.quantity_" + index)}
            label="Quantity(TON)"
            type="number"
            value={field.quantity}
            // required
            onBlur={(e) => {
              let materialfields = this.state.materialfields;
              let genericMaterial = this.state.materialfields[parentMaterial].materialid;
              let availableQuantity = this.state.MAXIMUM_QUANTITY[genericMaterial].remainingQuantity;
              let MAXIMUM_QUANTITY = this.state.MAXIMUM_QUANTITY;
              if (e.target.value > availableQuantity) {
                MAXIMUM_QUANTITY[genericMaterial].quantityError = true;
                this.setState({ MAXIMUM_QUANTITY });
              } else {
                if (materialfields[parentMaterial].dispatchMaterials[index].quantityError === false) {
                  MAXIMUM_QUANTITY[genericMaterial].remainingQuantity -= parseInt(e.target.value || 0);
                }
                // else{
                // MAXIMUM_QUANTITY[genericMaterial].remainingQuantity-=parseInt(e.target.value);
                // }
                MAXIMUM_QUANTITY[genericMaterial].quantityError = false;
                this.setState({ MAXIMUM_QUANTITY });
              }
            }}
            onChange={(e) => {
              let materialfields = this.state.materialfields;
              let quantity = e.target.value;
              let materialSelected = materialfields[parentMaterial].dispatchMaterials[index].materialid;
              // let materialSelected = materialfields[parentMaterial].dispatchMaterials[field.key_id].materialid;

              materialfields[parentMaterial].dispatchMaterials[index].rate = this.calculateRate(index, parentMaterial);

              //All Rates are now inclusive of GST.
              materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
                materialfields[parentMaterial].dispatchMaterials[index].rate;
              // materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
              //   materialfields[parentMaterial].dispatchMaterials[index].rate +
              //   materialfields[parentMaterial].dispatchMaterials[index].rate * (this.state.GstPercent / 100);
              materialfields[parentMaterial].dispatchMaterials[index].total_amount = 0;
              materialfields[parentMaterial].dispatchMaterials[index].discount =
                this.state.materialList[materialfields[parentMaterial].materialid].discount * parseFloat(e.target.value);
              try {
                if (
                  parseInt(e.target.value) !== NaN &&
                  e.target.value !== '' &&
                  this.state.materialfields[parentMaterial].dispatchMaterials[index].rate !== '' &&
                  e.target.value > 0 &&
                  this.state.materialfields[parentMaterial].dispatchMaterials[index].rate > 0 &&
                  parseFloat(quantity) !== NaN &&
                  quantity > 0
                ) {
                  materialfields[parentMaterial].dispatchMaterials[index].total_amount =
                    materialfields[parentMaterial].dispatchMaterials[index].rate * quantity;
                }
              } catch (error) {
                console.log(error);
              }

              materialfields[parentMaterial].dispatchMaterials[index].quantity = e.target.value;
              // materialfields[parentMaterial].dispatchMaterials[field.key_id].quantity = e.target.value;
              this.setState({ materialfields });
              // this.updateTotalRate(index, parentMaterial);
              this.updateTotalRate();
              this.updateTotalQuantity(parentMaterial, index);
            }}
            // error={this.state.materialfields[parentMaterial].dispatchMaterials[index].quantityError}
            error={this.state.MAXIMUM_QUANTITY[this.state.materialfields[parentMaterial].materialid]?.quantityError}
            helperText={
              this.state.MAXIMUM_QUANTITY[this.state.materialfields[parentMaterial].materialid]
                ? `Max. Quantity for this material can be ${this.state.MAXIMUM_QUANTITY[this.state.materialfields[parentMaterial].materialid]?.availableQuantity
                }`
                : ''
            }
          />
        </TableCell>
        {this.state.materialfields[parentMaterial].dependentTypeId !== null && (
          <TableCell className="border-1" numeric>
            <Input
              name={'special_length_' + index}
              //value={eval("values.quantity_" + index)}
              label="Special Length (in m)"
              type="number"
              onChange={(e) => {
                let materialfields = this.state.materialfields;
                materialfields[parentMaterial].dispatchMaterials[index].special_length = e.target.value;
                this.setState({ materialfields });
              }}
              value={field.special_length}
            />
          </TableCell>
        )}
        <TableCell className="border-1" numeric>
          <Input
            name={'rate_' + index}
            // readOnly={true}
            label="Rate(per ton)"
            // value={field.rate}
            value={this.state.materialfields[parentMaterial].dispatchMaterials[index]?.rate}
            required
            type="number"
          />
        </TableCell>
        <TableCell className="border-1" numeric>
          <Input
            name={'total_amoun_' + index}
            readOnly={true}
            label="Total Amount"
            // value={field.total_amount}
            value={this.state.materialfields[parentMaterial].dispatchMaterials[index]?.total_amount}
            type="number"
          />
        </TableCell>
      </TableRow>
    );
  };

  dispatchFormRow = (parentMaterialIndex) => {
    // const index = field.key_id;
    // console.log("Parent Material :- " + parentMaterialIndex);
    return (
      <Paper key={1} elivation={0} style={{ margin: '20px', padding: '10px', paddingLeft: '0px' }}>
        <div style={{ padding: 12, paddingLeft: '0px', marginTop: '10px' }}>
          <Grid container spacing={2}>
            <Grid item md={5} xs={12}>
              <FormControl variant="outlined">
                <InputLabel id="demo-mutiple-name-label">Add More Sizes</InputLabel>
                <MaterialUISelect
                  name={'extra_size_selected'}
                  label="Sizes"
                  // value={field.id}
                  onChange={async (e) => {
                    // // this.handleMaterialChange(e, index)
                    // let materialfields = this.state.materialfields;
                    // materialfields[parentMaterialIndex].dispatchMaterials[field.key_id].sizeId = e.target.value;
                    // this.setState({ materialfields });
                    // this.handleMaterialChange(e, index, parentMaterialIndex);
                    // this.updateTotalRate();
                    let size = await this.state.materialSizes?.find((size) => size.id === e.target.value);
                    this.setState({ extraSizeSelected: size });
                    // this.addDispatchRow(parentMaterialIndex, size);
                  }}
                >
                  {this.state.materialSizes.length > 0 &&
                    this.state.materialSizes.map((size) => (
                      <MenuItem key={size.id} value={size.id}>
                        {`${size.size_name}`}
                      </MenuItem>
                    ))}
                </MaterialUISelect>
              </FormControl>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={3}>
              {/* {this.state.materialfields.length < 1 ? (
                ''
              ) : ( */}
              <Button
                color="secondary"
                size="small"
                onClick={() => this.addDispatchRowAtSerialPosition(parentMaterialIndex, this.state.extraSizeSelected)}
                style={{
                  width: '270px',
                  marginLeft: '1px',
                  marginTop: '15px',
                }}
              >
                Add Size to List
              </Button>
              {/* )} */}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  };

  renderConsigneeFields = (field, parentMaterial) => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <Select
            name={'consignee' + parentMaterial}
            label="Consignee"
            value={field.consignee_id}
            onChange={(e) => {
              let materialfields = this.state.materialfields;
              materialfields[parentMaterial].consignee_id = e.target.value;
              materialfields[parentMaterial].consignee_name = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).name;

              materialfields[parentMaterial].care_off = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).care_off;
              materialfields[parentMaterial].house_no = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).house_no;
              materialfields[parentMaterial].street_2 = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).street_2;
              materialfields[parentMaterial].street_3 = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).street_3;
              materialfields[parentMaterial].street_4 = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).street_4;
              materialfields[parentMaterial].street_5 = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).street_5;
              materialfields[parentMaterial].city = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).city;
              materialfields[parentMaterial].consignee_state_code = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).state_code;
              // materialfields[parentMaterial].consignee_state = this.state.consigneeList.find(
              //   (val) => val.id === e.target.value,
              // ).consignee_state;
              materialfields[parentMaterial].consignee_state = this.state.STATES.find(
                (val) => val.id === parseInt(materialfields[parentMaterial].consignee_state_code),
              ).title;
              materialfields[parentMaterial].consignee_district_code = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).district_code;
              materialfields[parentMaterial].district = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).district;
              materialfields[parentMaterial].postal_code = this.state.consigneeList.find(
                (val) => val.id === e.target.value,
              ).postal_code;

              materialfields[parentMaterial].dispatchMaterials.map((dMaterial, index) =>
                this.handleMaterialChange(null, index, parentMaterial));

              this.setState({ materialfields });
            }}
            // options={this.state.specificMaterialsList.filter((sm)=>sm.parent_generic === this.state.materialfields[parentMaterial].materialid)}
            options={this.state.consigneeList.map(({ id, name: title }) => ({
              title,
              id,
            }))}
            required
          />
        </Grid>
        {this.state.materialfields[parentMaterial].consignee_id && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                name={'consignee_name_' + parentMaterial}
                value={field.consignee_name}
                label="Consignee Name"
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'gst_number' + parentMaterial}
                // value={field.dispatch_to}
                value={this.state.consigneeList.find((val) => val.id === field.consignee_id)?.gst}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="GST Number"
                disabled
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'care_off_' + parentMaterial}
                value={field.care_off}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="Address : C/O"
                required
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].care_off = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'house_no_' + parentMaterial}
                value={field.house_no}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="House No"
                required
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].house_no = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'street_2_' + parentMaterial}
                value={field.street_2}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="Street 2"
                required
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].street_2 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'street_3_' + parentMaterial}
                value={field.street_3}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="Street 3"
                required
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].street_3 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'street_4_' + parentMaterial}
                value={field.street_4}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="Street 4"
                // required
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].street_4 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'street_5_' + parentMaterial}
                value={field.street_5}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="Street 5"
                // required
                inputProps={{ maxLength: 14 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].street_5 = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'city' + parentMaterial}
                value={field.city}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="City"
                required
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].city = e.target.value;
                  this.setState({ materialfields });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                name={'consignee_state' + parentMaterial}
                label="State"
                value={parseInt(field.consignee_state_code)}
                onChange={(e) => {
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].consignee_state_code = e.target.value;

                  materialfields[parentMaterial].consignee_state = this.state.STATES.find(
                    (val) => val.id === e.target.value,
                  ).title;
                  this.setState({ materialfields });
                  materialfields[parentMaterial].dispatchMaterials.map((dMaterial, index) => {
                    // this.calculateRate(index, parentMaterial),
                    this.handleMaterialChange(null, index, parentMaterial);
                  });
                }}
                // options={this.state.specificMaterialsList.filter((sm)=>sm.parent_generic === this.state.materialfields[parentMaterial].materialid)}
                options={this.state.STATES}
                required
              />
            </Grid>
            {this.state.materialfields[parentMaterial].consignee_state_code && (
              <Grid item xs={12} md={6}>
                <Select
                  name={'district_' + parentMaterial}
                  label="District"
                  // value={this.state.DISTRICTS.find(district => district.id === field.consignee_district_code)?.id}
                  value={parseInt(field.consignee_district_code)}
                  // disabled={this.state.materialfields[parentMaterial].consignee_state_code ? true : false }
                  onChange={(e) => {
                    let materialfields = this.state.materialfields;
                    materialfields[parentMaterial].consignee_district_code = e.target.value;

                    materialfields[parentMaterial].district = this.state.DISTRICTS.find(
                      (val) => val.id === e.target.value,
                    ).title;
                    this.setState({ materialfields });
                    materialfields[parentMaterial].dispatchMaterials.map((dMaterial, index) => {
                      // this.calculateRate(index, parentMaterial),
                      this.handleMaterialChange(null, index, parentMaterial);
                    });
                  }}
                  // options={this.state.specificMaterialsList.filter((sm)=>sm.parent_generic === this.state.materialfields[parentMaterial].materialid)}
                  // options={this.state.DISTRICTS.filter(
                  //   (district) => district.state_id === this.state.materialfields[parentMaterial].consignee_state_code
                  // ) || this.state.DISTRICTS}
                  options={this.state.DISTRICTS.filter(district => district.state_id === parseInt(this.state.materialfields[parentMaterial].consignee_state_code))}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Input
                name={'postal_code' + parentMaterial}
                value={field.postal_code}
                // default={this.state.consigneeList[this.state.materialfields[parentMaterial].dispatchMaterials[parentMaterial].consignee_id].address}
                label="Pin Code"
                type="number"
                required
                onChange={(e) => {
                  // handleDispatchTo(e, parentMaterial)
                  let materialfields = this.state.materialfields;
                  materialfields[parentMaterial].postal_code = e.target.value;
                  this.setState({ materialfields });

                  materialfields[parentMaterial].dispatchMaterials.map((dMaterial, index) => {
                    // this.calculateRate(index, parentMaterial),
                    this.handleMaterialChange(null, index, parentMaterial);
                  });
                }}
              />
            </Grid>
          </>
        )}
      </>
    );
  };
  updateTotalQuantity = (parentMaterial, index) => {
    let TotalQuantity = 0;
    for (let [k, val] of this.state.materialfields.entries()) {
      for (let [key, value] of this.state.materialfields[k].dispatchMaterials.entries()) {
        TotalQuantity += parseFloat(
          this.state.materialfields[k].dispatchMaterials[key].quantity
            ? this.state.materialfields[k].dispatchMaterials[key].quantity
            : 0,
        );
      }
    }
    this.setState({ TotalQuantity });

    // let materialfields = this.state.materialfields;
    // if (this.state.materialfields[parentMaterial].dispatchMaterials[index].quantity > this.state.REMAINING_QUANTITY){
    //   materialfields[parentMaterial].dispatchMaterials[index].quantityError = true;
    //   this.setState({materialfields});
    // }else{
    //   materialfields[parentMaterial].dispatchMaterials[index].quantityError = false;
    //   this.setState({materialfields});
    // }
    // this.setState({REMAINING_QUANTITY: this.state.MAX_QUANTITY - TotalQuantity});
  };

  updateTotalRate = () => {
    let AllowSubmit = this.state.AllowSubmit;
    AllowSubmit = true;
    // let dispatchMaterialFields = this.state.materialfields[parentMaterial]
    //   .dispatchMaterials;
    // let TotalRate = this.state.TotalRate;
    let TotalRate = 0;
    for (let [k, val] of this.state.materialfields.entries()) {
      let dispatchMaterialFields = this.state.materialfields[k].dispatchMaterials;
      for (let [key, value] of dispatchMaterialFields.entries()) {
        TotalRate += parseInt(value.total_amount) !== NaN ? parseInt(value.total_amount) : 0;
      }
    }
    let AllDiscounts = this.state.AllDiscounts;

    let TotalDiscount = 0;
    for (let [k, val] of this.state.materialfields.entries()) {
      let dispatchMaterialFields = this.state.materialfields[k].dispatchMaterials;
      for (let [key, value] of dispatchMaterialFields.entries()) {
        TotalDiscount += parseFloat(value.discount) !== NaN ? parseFloat(value.discount) : 0;
      }
    }
    /**
     * Earlier discount was deducted from the total amount, but due to phase 2 requirement,
     * discount is being deducted from per ton rate in calculateRate(), so adding the total discount 
     * again in total amount, so as to accomodate legacy calculation process.
     */

    TotalRate += TotalDiscount;
    let GrandTotal = TotalRate - TotalDiscount;
    let Basic = ((TotalRate * 100) / (100 + this.state.GstPercent)).toFixed(2);
    let TotalGst = ((this.state.GstPercent * Basic) / 100).toFixed(2);
    // let TotalGst = (this.state.GstPercent * GrandTotal) / 100;
    // GrandTotal = GrandTotal + TotalGst;

    this.setState((state) => {
      return {
        ...state,
        AllDiscounts: AllDiscounts,
        TotalDiscount: TotalDiscount,
        GrandTotal: GrandTotal,
        Basic: Basic,
        TotalRate: TotalRate,
        TotalGst: TotalGst,
        AllowSubmit: AllowSubmit,
      };
    });
  };

  calculateRate = (index, parentMaterial) => {
    let materialfields = this.state.materialfields;

    let rate = this.state.baseRate;

    let typeSelected = materialfields[parentMaterial].typeId;
    let type = this.state.materialTypes.filter((type) => type.id === typeSelected)[0];
    let typeRate = this.state.materialTypes.filter((type) => type.id === typeSelected)[0]
      ? this.state.materialTypes.filter((type) => type.id === typeSelected)[0].rate
      : 0;

    rate += typeRate;

    let dependentTypeSelected = materialfields[parentMaterial].dependentTypeId;
    // let dependentType = this.state.materialTypes.filter(type => type.id === dependentTypeSelected)[0];
    let dependentTypeRate = this.state.materialTypes.filter((type) => type.id === dependentTypeSelected)[0]
      ? this.state.materialTypes.filter((type) => type.id === dependentTypeSelected)[0].rate
      : 0;

    rate += dependentTypeRate;

    if (type && type.apply_grade_rates) {
      let gradeSelected = materialfields[parentMaterial].gradeId;
      let gradeRate = this.state.materialGrades.filter((grade) => grade.id === gradeSelected)[0]
        ? this.state.materialGrades.filter((grade) => grade.id === gradeSelected)[0].rate
        : 0;
      rate += gradeRate;
    }
    if (type && type.apply_size_rates) {
      let sizeSelected = materialfields[parentMaterial].dispatchMaterials[index].sizeId;
      var sizeRate = 0;
      if (this.state.materialfields[parentMaterial].consignee_state_code === 12) {
        // console.log('gujarat' + this.state.materialfields[parentMaterial].consignee_district_code);
        if (
          this.state.DISTRICTS.find(
            (district) => district.id === this.state.materialfields[parentMaterial].consignee_district_code,
          )?.in_south_gujarat
        ) {
          // console.log('south gujarat');
          sizeRate = this.state.materialSizes.filter((size) => size.id === sizeSelected)[0]
            ? this.state.materialSizes.filter((size) => size.id === sizeSelected)[0].south_gujarat_rate
            : 0;
        } else {
          // console.log('rest of gujarat');
          sizeRate = this.state.materialSizes.filter((size) => size.id === sizeSelected)[0]
            ? this.state.materialSizes.filter((size) => size.id === sizeSelected)[0].rate
            : 0;
        }
      } else {
        // console.log('not gujarat');
        sizeRate = this.state.materialSizes.filter((size) => size.id === sizeSelected)[0]
          ? this.state.materialSizes.filter((size) => size.id === sizeSelected)[0].other_state_parity
          : 0;
      }

      rate += sizeRate;
    }

    if (type && type.has_individual_sizes) {
      let sizeSelected = materialfields[parentMaterial].dispatchMaterials[index].sizeId;
      //TODO: Type name epoxy hardcoded for now, to be changed later
      let specialSizeRate = this.state.specialSizes.filter(
        (size) => size.size_id === sizeSelected && size.type_name === 'epoxy',
      )[0]
        ? this.state.specialSizes.filter((size) => size.size_id === sizeSelected && size.type_name === 'epoxy')[0].rate
        : 0;
      rate += specialSizeRate;
    }

    if (
      this.state.materialfields[parentMaterial].consignee_state_code !== 12 &&
      this.state.materialfields[parentMaterial].other_state_parity === true
    ) {
      rate += this.state.OTHER_STATE_PARITY_RATES[0].rate;
    }

    if (this.state.materialfields[parentMaterial].ex_plant === true) {
      // console.log('EX-PLANT\n\n');
      var ex = 0;
      if (this.state.materialfields[parentMaterial].consignee_state_code === 12) {
        ex = this.state.EX_PLANTS_RATE.find((item) => item.state_name === 'Gujarat').discount;
        // console.log('EX-PLant Gujarat' + ex);
      } else {
        ex = this.state.EX_PLANTS_RATE.find((item) => item.state_name === 'Others').discount;
        // console.log('EX-PLant Others' + ex);
      }
      rate += ex;
    }

    rate -= this.state.materialList[materialfields[parentMaterial].materialid].discount;

    let districtSelected = materialfields[parentMaterial].consignee_district_code;
    let districtBasedRate = this.state.DISTRICTS.find((dd) => dd.id === districtSelected)?.rate || 0;
    // ? this.state.districtDiscounts.filter((dd) => dd.pin_code == pinCodeSelected)[0].discount
    // : 0;
    rate += districtBasedRate;

    // let rate =
    //   this.state.baseRate +
    //   parseInt(gradeRate) +
    //   parseInt(typeRate) +
    //   parseInt(sizeRate) - this.state.materialList[
    //       materialfields[parentMaterial].materialid
    //     ].discount;
    return rate;
  };
  findTypesDependent = (index) => {
    var dependentTypes = [];
    let selectedIndependentType = this.state.materialfields[index].typeId;
    this.state.materialTypes
      .filter((type) => type.dependency === TYPE_DEPENDENCY.DEPENDENT)
      .map((type) => {
        // let dependents = JSON.parse("[" + type.dependent_on + "]")
        let dependents = type.dependent_on.split(',').map((val) => parseInt(val));
        if (dependents.includes(selectedIndependentType)) {
          dependentTypes.push(type);
          // return type;
          // return true;
        }
        return null;
      });
    return dependentTypes;
  };

  handleMaterialChange = (e, index, parentMaterial) => {
    let materialfields = this.state.materialfields;

    materialfields[parentMaterial].dispatchMaterials[index].rate = this.calculateRate(index, parentMaterial);

    materialfields[parentMaterial].dispatchMaterials[index].total_amount = 0;

    // All the rates are inclusive of GST.
    materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
      materialfields[parentMaterial].dispatchMaterials[index].rate;
    // materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
    //   materialfields[parentMaterial].dispatchMaterials[index].rate +
    //   (materialfields[parentMaterial].dispatchMaterials[index].rate * this.state.GstPercent) / 100;
    let quantity = materialfields[parentMaterial].dispatchMaterials[index].quantity;
    try {
      if (
        // parseInt(e.target.value) !== NaN &&
        // e.target.value !== '' &&
        this.state.materialfields[parentMaterial].dispatchMaterials[index].rate !== '' &&
        // e.target.value > 0 &&
        this.state.materialfields[parentMaterial].dispatchMaterials[index].rate > 0 &&
        parseFloat(quantity) !== NaN &&
        quantity > 0
      ) {
        materialfields[parentMaterial].dispatchMaterials[index].total_amount =
          materialfields[parentMaterial].dispatchMaterials[index].rate * quantity;
        materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
          materialfields[parentMaterial].dispatchMaterials[index].rate;
        // materialfields[parentMaterial].dispatchMaterials[index].amount_after_gst =
        //   materialfields[parentMaterial].dispatchMaterials[index].rate +
        //   (materialfields[parentMaterial].dispatchMaterials[index].rate * this.state.GstPercent) / 100;
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ materialfields });
    this.updateTotalRate();
  };

  prettifyDispatchMaterials = async () => {
    let dispatchMaterialFields = [];
    for (let [key, value] of this.state.materialfields.entries()) {
      // let dispatch = {};
      let main_material_id = this.state.materialfields[key].materialid;
      // dispatch['main_material_id'] = main_material_id;
      for (let [k, val] of this.state.materialfields[key].dispatchMaterials.entries()) {
        if (this.state.MAXIMUM_QUANTITY[main_material_id].quantityError) {
          console.log('Quantity Error !');
          this.setState({ quantityValidation: false });
        }
        if (val.quantity > 0) {
          // await dispatchMaterialFields.push({ main_material_id, ...val });
          dispatchMaterialFields.push({
            main_material_id,
            consignee_id: this.state.materialfields[key].consignee_id,
            consignee_name: this.state.materialfields[key].consignee_name,
            care_off: this.state.materialfields[key].care_off,
            house_no: this.state.materialfields[key].house_no,
            street_2: this.state.materialfields[key].street_2,
            street_3: this.state.materialfields[key].street_3,
            street_4: this.state.materialfields[key].street_4,
            street_5: this.state.materialfields[key].street_5,
            city: this.state.materialfields[key].city,
            district: this.state.materialfields[key].district,
            consignee_district_code: this.state.materialfields[key].consignee_district_code,
            consignee_state_code: this.state.materialfields[key].consignee_state_code,
            consignee_state: this.state.materialfields[key].consignee_state,
            postal_code: this.state.materialfields[key].postal_code,
            typeId: this.state.materialfields[key].typeId,
            dependentTypeId: this.state.materialfields[key].dependentTypeId,
            gradeId: this.state.materialfields[key].gradeId,
            ...val,
          });
        }
      }
    }
    return dispatchMaterialFields;
  };
  handleSearch = (e) => { };
  handleSubmit = async (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    let data = {};
    data.purchase_order_id = this.state.purchaseOrderId !== undefined ? parseInt(this.state.purchaseOrderId) : 2;
    data.customer_id = parseInt(this.state.distributorId);
    data.delivery_location = this.state.deliveryLocation;
    // data.material = dispatchMaterialFields;
    data.material = await this.prettifyDispatchMaterials();
    data.total_materials = this.state.materialfields.length;
    data.total_quantity = this.state.TotalQuantity;
    data.total_rate = this.state.TotalRate;
    data.discount = this.state.TotalDiscount;
    data.gst = this.state.TotalGst;
    data.created_by = this.state.currentUser?.id;
    data.creator_type = this.state.currentUser?.user_type;
    data.total_amount = this.state.GrandTotal;
    data.remarks = this.state.remarks;
    data.ex_plant = this.state.materialfields[0].ex_plant ? 1 : 0;
    data.other_state_parity = this.state.materialfields[0].other_state_parity ? 1 : 0;
    console.log(JSON.stringify(data));
    if (this.state.validReleaseRate && this.state.quantityValidation) {
      var api;
      if (this.state.dispatchOrderId) {
        api = {
          method: 'put',
          url: SERVERBASEURL + '/dispatch-order/' + this.state.dispatchOrderId,
          data: data,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
      } else {
        api = {
          method: 'post',
          url: SERVERBASEURL + '/dispatch-order/' + this.state.purchaseOrderId,
          data: data,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
      }

      axios(api)
        .then((res) => {
          if (res.data.status !== undefined && res.data.status === 1) {
            this.setState({
              notify: {
                isOpen: true,
                message: res.data.message,
                type: 'success',
                // type: 'Dispatch Plan Created Successfully ! Redirecting...',
              },
              isSubmitting: false,
            });
            // if (this.state.purchaseOrderId === undefined) {
            // console.log("\n\nReset Form \n");
            // setInterval(() => {
            this.resetFormState();
            //   this.props.history.push('/all-dispatch-plans');
            // }, 1800);

            // }
          }
        })
        .catch((err) => {
          this.setState({
            notify: {
              isOpen: true,
              message: `${err?.response?.data?.message || 'Dispatch Order Creation Unsuccessful !'}`,
              type: 'error',
            },
            isSubmitting: false,
          });
          console.log('Error in performing operation', JSON.stringify(err?.response?.data?.message));
        });
    } else {
      this.setState({
        notify: {
          isOpen: true,
          message:
            (!this.state.quantityValidation && 'The quantity selected is not valid !Please contact admin for help !') ||
            `Your Purchase Order Does Not have a valid Release Rate ! Please Contact Admin !`,
          type: 'error',
        },
        isSubmitting: false,
      });
    }
  };

  resetFormState = () => {
    this.setState({
      materialfields: [
        {
          key_id: 0,
          id: 0,
          materialid: 0,
          dispatchMaterials: [
            // {
            //   key_id: 0,
            //   id: 0,
            //   materialid: 0,
            //   quantity: 0,
            //   rate: 0,
            //   discount: 0,
            //   total_amount: 0,
            //   consignee_name: '',
            //   dispatch_to: '',
            //   quantityError: false,
            // },
          ],
        },
      ],
      distributors: [],
      materialList: [],
      TotalQuantity: 0,
      TotalRate: 0,
      baseRate: 0,
      distributorId: '',
      deliveryLocation: '',
      AllDiscounts: [],
      TotalDiscount: 0,
      TotalGst: 0,
      GstPercent: 0,
      GrandTotal: 0,
      credit_limit_quantity: 0,
      credit_limit_value: 0,
      AllowSubmit: true,
      id: 2,
    });
  };

  render() {
    return (
      <main className="pageContent">
        <div className="toolbar" />

        <div style={{ marginBottom: '20px', marginTop: '60px' }}>
          <Grid container justify="space-between">
            <Grid>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  href="/getting-started/installation/"
                //  onClick={handleClick}
                >
                  Purchase Order Details
                </Link>
                <Link
                  color="inherit"
                  href="/getting-started/installation/"
                // onClick={handleClick}
                >
                  Dispatch Plan
                </Link>
                <Link
                  color="textPrimary"
                  href="/components/breadcrumbs/"
                  // onClick={this.handleClick}
                  aria-current="page"
                >
                  Create Dispatch Plan
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid></Grid>
          </Grid>
        </div>

        <div className="root">
          <Form onSubmit={this.handleSubmit}>
            <Paper elevation={0} className="paperbg" style={{ marginBottom: '20px' }}>
              <div style={{ padding: 12 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Material</p>
                      <p className="viewItem">{this.state.materialfields.length}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Total Quantity</p>
                      <p className="viewItem">{this.state.TotalQuantity}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <div>
                      <p>Basic Rate(without GST)</p>
                      {/* <p className="viewItem">{this.state.TotalRate}</p> */}
                      <p className="viewItem">{this.state.Basic}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>GST</p>
                      <p className="viewItem">{this.state.TotalGst}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <div>
                      <p>Total Rate (with GST)</p>
                      <p className="viewItem">{this.state.TotalRate}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <div>
                      <p>Discount</p>
                      <p className="viewItem">{this.state.TotalDiscount}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <div>
                      <p>Final Amount (with GST)</p>
                      <p className="viewItem">{this.state.GrandTotal}</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Paper elevation={0} className="paperbg" style={{ marginBottom: '40px', paddingTop: '10px' }}>
              {this.state.materialfields &&
                this.state.materialfields.map((field, index) => this.materialFormRow(field, index))}
              <div style={{ margin: '15px', textAlign: 'center' }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={this.state.isSubmitting}
                  style={{ width: '200px', marginBottom: '25px' }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Paper>
          </Form>

          <div style={{ marginBottom: '25px' }}>
            <Grid container spacing={2} xs={12} md={12}>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                >
                  <Input
                    label="Add Remarks"
                    className="remarkInput"
                    onChange={(e) => {
                      this.setState({ remarks: e.target.value });
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Notifications notify={this.state.notify} setNotify={(n) => this.setState({ notify: n })} />
      </main>
    );
  }
}

export default withRouter(CreateDispatchPlans);
