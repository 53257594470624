import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getAllOrganizations() {
  const customersList = await axios.get(SERVERBASEURL + "/organizations", config);
  return customersList;
}

export async function getAllCustomers() {
  const customersList = await axios.get(SERVERBASEURL + "/users", config);
  return customersList;
}

export async function insertCustomer(data) {
  const savedStatus = await axios.post(
    SERVERBASEURL + "/user",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateCustomer(data) {
  console.log(data);
  const savedStatus = await axios.post(
    SERVERBASEURL + `/user/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteCustomer(id) {
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/user/${id}`,
    config
  );
  return deleteStatus;
}

//NEW
export async function getDistributorById(id) {
  const distributor = await axios.get(
    SERVERBASEURL + `/distributor/${id}`,
    config
  );
  return distributor;
}
export async function getAllDistributors() {
  const distributors = await axios.get(
    SERVERBASEURL + `/alldistributors`,
    config
  );
  return distributors;
}
