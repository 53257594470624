import { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import Select from '../../components/controls/Select';
import { getAllStates } from '../../services/masterDataService';
import { ORG_TYPE_STATUS } from '../../constants';

const initialFormValues = {
  title: '',
  description: '',
  status: ORG_TYPE_STATUS.ACTIVE,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function CustomerTypeForm({ addOrEdit, recordForEdit }) {
  const classes = useStyles();
  const [ALL_STATES, setAllStates] = useState([]);
  const [inSouthGujarat, setInSouthGujarat] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  // eslint-disable-next-line no-unused-vars
  const { values, errors, setErrors, setValues, resetForm, handleInputChange } = useForm(
    initialFormValues,
    true,
    validate,
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit({ ...values, in_south_gujarat: inSouthGujarat }, resetForm);
    }
  };

  const fetchAllStates = async () => {
    const stateLists = await getAllStates();
    // let mLists = stateLists.data;
    // console.log('\n\n\n\n' + JSON.stringify(stateLists));
    setAllStates(stateLists);
  };

  useEffect(() => {
    fetchAllStates();
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
      setInSouthGujarat(recordForEdit.in_south_gujarat === 1 ? true : false);
    }
  }, [recordForEdit, setValues]);
  const orgTypesStatus = [
    { id: ORG_TYPE_STATUS.ACTIVE, title: ORG_TYPE_STATUS.ACTIVE },
    { id: ORG_TYPE_STATUS.INACTIVE, title: ORG_TYPE_STATUS.INACTIVE },
  ];
  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input required name="title" value={values.title} label="Customer Type Title" onChange={handleInputChange} />
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <Input required name="rate" value={values.rate} label="Price" type="number" onChange={handleInputChange} />
          </Grid> */}
          <Grid item md={12} xs={12}>
            <Input
              name="description"
              value={values.description}
              label="Description/Comments"
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12} md={12}>
            <FormControlLabel
              name={'in_south_gujarat'}
              control={
                <Switch
                  // value="in_south_gujarat"
                  checked={inSouthGujarat}
                  name={'in_south_gujarat'}
                  onChange={(e) => {
                    setInSouthGujarat(e.target.checked);
                  }}
                />
              }
              label="Included in South Gujarat"
            />
          </Grid> */}
          <Grid item xs={12}>
            <Select
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={orgTypesStatus}
            />
          </Grid>
          <Grid item>
            <Button text="Submit" type="submit" />
            <Button text="Reset" type="button" color="default" onClick={resetForm} />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
