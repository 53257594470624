import axios from 'axios';
import env from 'react-dotenv';
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
};

export async function getAllStates() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const stateList = await axios.get(SERVERBASEURL + '/master/all-states', config);
  return stateList.data.data;
}

export async function getAllDistricts() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const stateList = await axios.get(SERVERBASEURL + '/master/all-districts', config);
  return stateList.data.data;
}

export async function getDistrictListForDisplay() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const materialList = await axios.get(SERVERBASEURL + '/master/districts', config);
  return materialList;
}

export async function insertDistrict(data) {
  const savedStatus = await axios.post(SERVERBASEURL + '/master/district', JSON.stringify(data), config);
  return savedStatus;
}

export async function updateDistrict(data) {
  console.log(data);
  const savedStatus = await axios.put(SERVERBASEURL + `/master/district/${data.id}`, data, config);
  return savedStatus;
}

export async function disableDistrict(id) {
  const deleteStatus = await axios.delete(SERVERBASEURL + `/master/district/${id}`, config);
  return deleteStatus;
}
