import  React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import Select from '../../components/controls/Select';
import { Select as MaterialUISelect, MenuItem, InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import { number } from 'prop-types';
import { getDistributorList } from '../../services/distributorService';
import { getCurrentUser } from '../../services/userService';
import { getPurchaseOrderListForDistributor } from '../../services/PurchaseOrder';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';

const initialFormValues = {
  name: '',
  // consignee_code: null,
  gst: '',
  // tin: "",
  phone: '',
  name: '',
  // email: "",
  address: '',
  city: '',
  postal_code: '',
  status: 1,
  formId: 0,
  distributors: [],
  // user_type: "distributor",
  // district: '',
  // password: "",
  // confirm_password: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
class SelectDistributorAndPO extends React.Component {
  state = {
    currentUser:null,
    selectedDistributor:'',
    selectedPO:"",
    allDistributors:[],
    allPOs:[],
    distributor_hide_class : ''
  }
  //   const [state, setState] = useState({
  //     distributorId: '',
  //     orderBookingId: ''
  //   });
  // const distributor_hide_class = currentUser && currentUser.user_type == 'admin' ? 'show' : 'none';
  // const [distributor_hide_class,setDistributor_hide_class] = useState(currentUser && currentUser.user_type == 'admin' ? 'show' : 'none');

  getCurrentUserFunc = async () => {
    // setLoading(true)
    let user = await getCurrentUser();
    console.log('******* user', user.data.body);
    this.setState({currentUser:user.data.body});
    // setLoading(false)
    return user;
  };

  async componentDidMount() {
    let fetchedUser = await this.getCurrentUserFunc();
    this.setState({distributor_hide_class: this.state.currentUser && this.state.currentUser.user_type == 'admin' ? 'show' : 'none'});
    console.log(fetchedUser)
    if (fetchedUser && fetchedUser.user_type != "admin"){
        // console.log('fetching'+fetchedUser.org_id+this.state.currentUser.org_id)
        // await fetchPOList(fetchedUser.org_id);
        this.setState({
          distributor_hide_class:'show',
          selectedDistributor: fetchedUser.org_id
        });
        // setDistributor_hide_class('show')
        // setSelectedDistributor(fetchedUser.org_id)
        this.fetchPOList(this.state.currentUser.org_id);
    }
    console.log(this.state.distributor_hide_class+"99999"+this.state.currentUser.org_id)
    const allDistributors = await getDistributorList();
   this.setState({allDistributors : allDistributors.data.data});
    // setAllDistributors(allDistributors.data.data);
  };

  // eslint-disable-next-line no-unused-vars
  // { values, errors, setErrors, setValues, resetForm, handleInputChange } = useForm(initialFormValues, true);

   handleFormSubmit = (e) => {
    e.preventDefault();
  };

   handleDistributorsSelect = async (event) => {
    // setSelectedDistributor(event.target.value);
    this.setState({selectedDistributor: event.target.value});
    // console.log('Final Selected : ' + this.state.selectedDistributor);
    // this.fetchPOList(this.state.selectedDistributor);
    this.fetchPOList(event.target.value);
  };

   fetchPOList = async selectedDistributor => {
    //  console.log(selectedDistributor)
    let poList = await getPurchaseOrderListForDistributor(selectedDistributor);
    // console.log(poList.data.data.data);
    // setAllPOs(poList.data.data.data);
    this.setState({allPOs:poList.data.data.data})
  }

  // useEffect(async () => {
  //   const allDistributors = await getDistributorList();
  //   setAllDistributors(allDistributors.data.data);
  // }, []);
  // console.log("---> va", distributor_hide_class+currentUser?.user_type);
  render(){
  return (
    <div className='root'>
    {/* <div className={classes.root}> */}
      <Form onSubmit={this.handleFormSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} style={{ display: `${this.state.distributor_hide_class}` }}>
            <FormControl
              variant="outlined"
              // {...(error && { error: true })}
            >
              <InputLabel id="demo-mutiple-name-label">Distributors</InputLabel>
              {/* <Select */}
              <MaterialUISelect
                name="distributor_id"
                label="Distributor"
                disabled={this.state.currentUser && this.state.currentUser.user_type === 'admin' ? false : true}
                // options={allDistributors}
                value={this.state.selectedDistributor}
                required
                onChange={(e) => {
                  this.handleDistributorsSelect(e);
                }}
              >
                {this.state.allDistributors.length > 0 &&
                  this.state.allDistributors.map((distributor) => (
                    <MenuItem key={distributor.id} value={distributor.id}>
                      {`${distributor.org_name} -- ${distributor.sap_distributor_code}`}
                    </MenuItem>
                  ))}
                {/* </Select> */}
              </MaterialUISelect>
            </FormControl>
          </Grid>
          {/* {currentUser && currentUser.user_type != 'admin' ? <Grid item xs={12} md={6}></Grid> : ''} */}
          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              // {...(error && { error: true })}
            >
              <InputLabel id="demo-mutiple-name-label">Purchase Orders</InputLabel>

              <MaterialUISelect
                name="order_booking"
                label="OrderBooking"
                disabled={this.state.allPOs.length > 0 ? false : true}
                // options={state.bookingOrders}
                value={this.state.selectedPO}
                required
                onChange={(e) => this.setState({selectedPO:e.target.value})}
              >
                {this.state.allPOs.length > 0 &&
                  this.state.allPOs.map((po) => (
                    <MenuItem key={po.id} value={po.id}>
                      {po.ref_no}
                    </MenuItem>
                  ))}
              </MaterialUISelect>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {/* <Link to={`/create-dispatch-plan/${selectedPO}`} disabled={selectedPO?false:true} > */}
              <Button color="primary" variant="contained" 
              text="Create Dispatch Plan"
               disabled={!this.state.selectedPO} 
            onClick={e=>{
                this.props.history.push(`/create-dispatch-plan/${this.state.selectedPO}`)
            }}
            />
            {/* </Link> */}
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
}

export default withRouter(SelectDistributorAndPO);