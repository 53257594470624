import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState } from 'react'
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPasswordScreen1";
import ForgetPasswordScreen2 from "./pages/ForgetPasswordScreen2";
import ForgetPasswordScreenUser from "./pages/ForgetPasswordScreenUser";
import NoMatch from "./pages/NoMatch";
import Customers from "./pages/Customers/Customers";
import SalesPersons from "./pages/sales-persons/SalesPersons";
import ConsigneeList from "./pages/consignee";
import theme from "./components/ui/Theme";
import Discount from "./pages/Discount/Discount";
import Materials from "./pages/Materials/Material";
import Grades from "./pages/Grades/Grades";
import Size from "./pages/Sizes/Sizes";
import Types from "./pages/Types/Types";
import Settings from "./pages/Settings/Settings";
import OrderAction from "./pages/purchaseOrder/OrderAction";
import OrderList from "./pages/purchaseOrder";
import OrderBooking from "./pages/OrderBooking/OrderBooking";
import OrderView from "./pages/purchaseOrder/OrderView";
import DispatchPlan from './pages/Plans/DispatchPlan';
import DispatchPlanList from './pages/Plans/';
import DispatchPlanDetails from './pages/Plans/DispatchPlanDetails';
import CreateDispatchPlans from './pages/Plans/CreateDispatchPlans';
import EditDispatchPlan from './pages/Plans/EditDispatchPlan';
import LedgerList from './pages/Ledger/LedgerList';
import Districts from './pages/districts';
import { getCurrentUser } from './services/userService'
import DistributorTypesList from "./pages/customerTypes";
const App = () => {
  const history = useHistory();

  const [userType, setUserType] = useState(localStorage.getItem('user_type'))
  const [currentUser, serCurrentUser] = useState()
  const getCurrentUserFunc = async () => {
    try {
      const user = await getCurrentUser()
      console.log('user', user.statusCode)
      if (user && user.data) {
        serCurrentUser(user.data.body)
      }
    } catch (err) {
      history.push('/')
    }

  }
  useEffect(() => {
    getCurrentUserFunc()
  }, [])
  return (
    <ThemeProvider theme={theme}>

      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forget-password">
          <ForgetPassword />
        </Route>
        <Route exact path="/forget-password/:token">
          <ForgetPasswordScreen2 />
        </Route>
        <Header>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/forget-password-user">
            <ForgetPasswordScreenUser />
          </Route>
          <Route exact path="/order_booking">
            <OrderBooking />
          </Route>
          <Route exact path="/order/action">
            <OrderAction />
          </Route>
          <Route exact path="/order/action/:id">
            <OrderAction />
          </Route>
          <Route exact path="/order/view/:id">
            <OrderView />
          </Route>
          <Route path="/all-dispatch-plans">
            <DispatchPlanList />
          </Route>
          <Route path="/dispatch-plans/:id">
            <DispatchPlan />
          </Route>
          <Route path="/dispatch-plan-details/:id">
            <DispatchPlanDetails />
          </Route>
          <Route path="/create-dispatch-plan/:id">
            <CreateDispatchPlans />
          </Route>
          <Route path="/edit-dispatch-plan/:id/:dispatchOrderId">
            {/* <EditDispatchPlan /> */}
            <CreateDispatchPlans />
          </Route>
          <Route exact path="/ledger">
            <LedgerList />
          </Route>
          <Route path="/consignees">
            <ConsigneeList />
          </Route>
          {/* <Route exact path="/order">
            </Route> */}

          <Route exact path="/order">
            <OrderList />
          </Route>
          {userType === "admin" ? <>

            <Route path="/distributors">
              <Customers />
            </Route>
            <Route path="/sales-persons">
              <SalesPersons />
            </Route>
            <Route path="/discount">
              <Discount />
            </Route>
            <Route path="/materials">
              <Materials />
            </Route>
            <Route path="/grades">
              <Grades />
            </Route>
            <Route path="/districts">
              <Districts />
            </Route>
            <Route path="/master-org-types">
              <DistributorTypesList />
            </Route>
            <Route path="/size">
              <Size />
            </Route>
            <Route path="/types">
              <Types />
            </Route>

            {/* <Route path="/dispatch-plans/:id">
              <DispatchPlan />
            </Route>
            <Route path="/dispatch-plan-details/:id">
              <DispatchPlanDetails />
            </Route>
            <Route path="/create-dispatch-plan/:id">
              <CreateDispatchPlans />
            </Route> */}
            {/* <Route path="/settings">
              <Settings />
            </Route> */}
          </> :
            <Route path="/discount">
              <Discount />
            </Route>
          }
        </Header>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </ThemeProvider >
  );
};

export default App;
