import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { getDistributorStats } from '../services/dashboardService';
import ExportPageAsPDF from '../helpers/ExportPageAsPDF';
// import { visuallyHidden } from '@mui/utils';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'org_name',
    numeric: false,
    disablePadding: true,
    label: 'Distributor',
  },
  {
    id: 'sap_code',
    numeric: true,
    disablePadding: false,
    label: 'SAP Code',
  },
  {
    id: 'todaysBookingQuantity',
    numeric: true,
    disablePadding: false,
    label: 'OB Today',
  },
  {
    id: 'totalPOQuantityInProgress',
    numeric: true,
    disablePadding: false,
    label: 'PO Today',
  },
  {
    id: 'totalDO',
    numeric: true,
    disablePadding: false,
    label: 'DP Today',
  },
  // {
  //   id: 'tillDateDispatch',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Till Date Dispatch',
  // },
  // {
  //   id: 'closingBalance',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Closing Balance',
  // },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span">
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => (theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{ marginLeft: '0px', paddingLeft: '0px' }}
    >
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : ( */}
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        Today's Stats :
      </Typography>
      {/* // )} */}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export class DashboardStats extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'todaysBookingQuantity',
    selected: [],
    page: 0,
    dense: false,
    rowsPerPage: 5,
    distributorStats: [],
    allOrgs: [],
    doStats: [],
    obStats: [],
    tillDateDispatch: [],
    closingBalance: [],
    totalPO: 0,
    totalOB: 0,
    totalDP: 0,
    totalDispatched: 0,
    eligibleOrgs: [],
  };

  constructor(props) {
    super(props);
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - this.state.allOrgs.length) : 0;
  }

  componentDidMount() {
    this.fetchDashboardStats();
  }

  fetchDashboardStats = async () => {
    const stats = await getDistributorStats();
    this.setState({
      allOrgs: stats.data.data.allOrgs,
      distributorStats: stats.data.data.mainData,
      doStats: stats.data.data.doStats,
      obStats: stats.data.data.todaysOrderBooking,
      tillDateDispatch: stats.data.data.tillDateDispatch,
      closingBalance: stats.data.data.closingBalance,
    });
    this.generateStats();
    this.calculateTotalStats();
  };

  generateStats = async () => {
    let eligibleOrgs = [];
    // this.state.distributorStats.map(stat => totalPO += stat.totalPOQuantityInProgress);
    await this.state.allOrgs.map(row => {
      let poQuantity = this.state.distributorStats.find((stat) => stat.org_id === row.id)?.totalPOQuantityInProgress;
      let doQuantity = this.state.doStats.find((stat) => stat.org_id === row.id)?.totalDO;
      let boQuantity = this.state.obStats.find((stat) => stat.org_id === row.id)?.todaysBookingQuantity;
      if (poQuantity > 0 || doQuantity > 0 || boQuantity > 0) {
        eligibleOrgs.push({ ...row, poQuantity, doQuantity, boQuantity })
      }
    });

    this.setState({
      eligibleOrgs
    });
  };
  calculateTotalStats = () => {
    var totalPO = 0;
    var totalOB = 0;
    var totalDP = 0;
    var totalDispatched = 0;
    this.state.distributorStats.map(stat => totalPO += stat.totalPOQuantityInProgress);
    this.state.doStats.map(stat => totalDP += stat.totalDO);
    this.state.obStats.map(stat => totalOB += stat.todaysBookingQuantity);
    this.state.tillDateDispatch.map(stat => totalDispatched += stat.tillDateDispatchQuantity);

    this.setState({
      totalPO,
      totalOB,
      totalDP,
      totalDispatched,
    });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc' });
    this.setState({ orderBy: property });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    // setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    // setRowsPerPage();
    // setPage(0);
  };

  handleChangeDense = (event) => {
    this.setState({ dense: event.target.checked });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  render() {
    return (
      <Box sx={{ width: '100%' }} style={{ marginTop: '20px' }}>
        <Paper sx={{ width: '100%', mb: 2 }} style={{ paddingLeft: '2.5%', paddingRight: '2.5%', overflowX: 'scroll' }}>
          <EnhancedTableToolbar />
          <TableContainer >
            <Table id="dashboardStats">
              {/* <Table sx={{ minWidth: 400 }} aria-labelledby="tableTitle" size={this.state.dense ? 'small' : 'medium'}> */}
              <EnhancedTableHead
                numSelected={this.state.selected.length}
                order={this.state.order}
                orderBy={this.state.orderBy}
                // onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.state.allOrgs.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(this.state.eligibleOrgs || [], getComparator(this.state.order, this.state.orderBy))
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
                  )
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let poQuantity = row?.poQuantity;// this.state.distributorStats.find((stat) => stat.org_id === row.id)?.totalPOQuantityInProgress;
                    let doQuantity = row?.doQuantity;// this.state.doStats.find((stat) => stat.org_id === row.id)?.totalDO;
                    let boQuantity = row?.boQuantity;// this.state.obStats.find((stat) => stat.org_id === row.id)?.todaysBookingQuantity;
                    if ((poQuantity > 0) ||
                      (doQuantity > 0) ||
                      (boQuantity > 0)
                    ) {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.org_name}
                          </TableCell>
                          <TableCell align="right">{row.sap_distributor_code}</TableCell>
                          <TableCell align="right">
                            {boQuantity || 0}
                          </TableCell>
                          <TableCell align="right">
                            {/* {row.totalPOQuantityInProgress} */}
                            {poQuantity || 0}
                          </TableCell>
                          <TableCell align="right">
                            {doQuantity || 0}
                          </TableCell>
                          {/* <TableCell align="center">
                          {this.state.tillDateDispatch.find((stat) => stat.org_id === row.id)?.tillDateDispatchQuantity ||
                            0}
                        </TableCell> */}
                          {/* <TableCell align="center">
                          {this.state.closingBalance.find((stat) => stat.org_id === row.id)?.closingBalance || 'N/A'}
                        </TableCell> */}
                          {/* <TableCell align="right">{row.protein}</TableCell> */}
                        </TableRow>
                      );
                    }
                  })}

                <TableRow style={{ borderTop: '1px solid black' }}>
                  <TableCell
                    component="th"
                    id="id"
                    align="center"
                    scope="row"
                    colSpan={2}
                    style={{ fontWeight: 'bold', fontSize: '1.2em', color: 'red' }}
                  >
                    Today's Total :
                  </TableCell>
                  <TableCell
                    component="th"
                    align="right"
                    id="id"
                    scope="row"
                    style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                  >
                    {this.state.totalOB}
                  </TableCell>
                  <TableCell
                    component="th"
                    align="right"
                    id="id"
                    scope="row"
                    style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                  >
                    {this.state.totalPO}
                  </TableCell>
                  <TableCell
                    component="th"
                    align="right"
                    id="id"
                    scope="row"
                    style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                  >
                    {parseFloat(this.state.totalDP).toFixed(3)}
                  </TableCell>
                  {/* <TableCell
                    component="th"
                    align="center"
                    id="id"
                    scope="row"
                    style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                  >
                    {this.state.totalDispatched}
                  </TableCell> */}
                </TableRow>
                {this.emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (this.state.dense ? 33 : 53) * this.emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: this.state.eligibleOrgs.length }]}
            component="div"
            count={this.state.eligibleOrgs.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <div style={{ display: "flex", justifyContent: 'space-around' }}>
          <ExportPageAsPDF
            pdfTitle="TODAY'S STATS"
            pdfFileName='todaysStats'
            htmlElementId='dashboardStats'
            buttonText='Export Stats to a PDF'
          />
        </div>
      </Box>
    );
  }
}
