import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  Select as MaterialUISelect,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import Select from '../../components/controls/Select';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { getLedgerList, getLedgerExcel } from '../../services/ledger';
import { getDistributorList, getSpecificDistributor } from '../../services/distributorService';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getCurrentUser } from '../../services/userService';
import { formatDate } from '../../helpers/helperFunctions';
import { USER_TYPES } from '../../constants';
import { getDistributorsForSalesPersons } from '../../services/salesPersonService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

function createData(row) {
  return {
    DOC_DT: row['DOC_DT'],
    POS_DT: row['POS_DT'],
    DOC_NO: row['DOC_NO'],
    ASS_DATA: row['ASS_DATA'],
    BILL_NO: row['BILL_NO'],
    DESCR: row['DESCR'],
    CR_AMT: row['CR_AMT'],
    DR_AMT: row['DR_AMT'],
    CURR: row['CURR'],
    CR_DT_TXT: row['CR_DT_TXT'],
    Text: row['Text'],
    KUNNR: row['KUNNR'],
    CLS_BAL: row['CLS_BAL'],
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const cssStyles = (theme) => ({
  pageContent: {
    // margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  container: {
    display: 'block',
    maxWidth: '85vw',
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '2px',
    margin: '5px',
    fontSize: '12px',
  },
  textDark: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#323232',
    opacity: '0.99',
  },
});
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'Doc.Date',
    numeric: false,
    disablePadding: false,
    label: 'Doc.Date',
  },
  // {
  //   id: "Posting Date",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Posting Date",
  // },
  { id: 'Doc.No.', numeric: true, disablePadding: false, label: 'Doc.No.' },
  { id: 'KUNNR', numeric: false, disablePadding: false, label: 'Distributor' },
  { id: 'Ass._Data', numeric: true, disablePadding: false, label: 'Ass._Data' },
  {
    id: 'Billing Doc',
    numeric: false,
    disablePadding: false,
    label: 'Billing Doc',
  },
  {
    id: 'Description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'Credit Amount',
    numeric: true,
    disablePadding: false,
    label: 'Credit Amount',
  },
  {
    id: 'Debit Amount',
    numeric: true,
    disablePadding: false,
    label: 'Debit Amount',
  },

  {
    id: 'Currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
  },
  {
    id: 'Credit/Debit',
    numeric: false,
    disablePadding: false,
    label: 'Credit/Debit',
  },
  {
    id: 'CLS_BAL',
    numeric: true,
    disablePadding: false,
    label: 'Closing Balance',
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,

  textCenter: {
    textAlign: 'center',
  },
  container: {
    display: 'block',
    maxWidth: '85vw',
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '10px',
    margin: '10px',
  },
}));

const LedgerList = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    rows: [],
    stableData: [],
  });

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [user, setUser] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [distributorId, setDistributorId] = useState();
  const [distributorSelected, setDistributorSelected] = useState(-1);
  const [downloading, setDownloading] = useState(false);

  let today = new Date();
  const date = `${today.getFullYear()}-${
    today.getMonth() < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
  }-${new Date().getDate()}`;
  const [start_date, setStartDate] = useState(date);
  const [end_date, setEndDate] = useState(date);

  const filterColumn = 'DOC_DT';

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCurrentUserFunc = async () => {
    setLoading(true);
    const user = await getCurrentUser();
    console.log('user', user);
    if (user && user.data) {
      setCurrentUser(user.data.body);
      if (user.data.body.user_type !== 'admin') {
        setDistributorId(user.data.body.org_id);
      }
    }
    setUserLoading(!userLoading);
    setLoading(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.rows.length - page * rowsPerPage);

  const funcGetDispatchOrderList = async (filterStatus = -1) => {
    setLoading(true);
    try {
      const DispatchOrderList = await getLedgerList();

      const poList = DispatchOrderList.data.data.data;
      setUser(DispatchOrderList.data.user);
      let rows = [];
      for (let [key, value] of poList.entries()) {
        setDistributorSelected(filterStatus);
        if (filterStatus !== -1 && value.KUNNR != filterStatus) {
          console.log('filterStatus -----> ' + filterStatus);
          continue;
        }
        rows.push(createData(value));
      }

      setState((state) => {
        return {
          ...state,
          rows: rows,
          stableData: rows,
        };
      });
    } catch (err) {
      history.push('/');
    }

    setLoading(false);
  };
  // console.log('stableData', state.stableData)
  useEffect(async () => {
    console.log('#####');
    await getCurrentUserFunc();
    funcGetDispatchOrderList();
  }, []);

  useEffect(() => {
    distributorsList();
  }, [userLoading]);

  const distributorsList = async () => {
    var distLists;
    var dLists;
    if (currentUser?.user_type === 'admin') {
      distLists = await getDistributorList();
      dLists = distLists.data.data;
    } else if (currentUser?.user_type === USER_TYPES.SALES_PERSON) {
      distLists = await getDistributorsForSalesPersons(currentUser.id);
      dLists = distLists;
    } else {
      distLists = await getSpecificDistributor();
      dLists = distLists.data.data;
    }
    console.log(dLists, 'dLists');
    let distributors = [];
    let distributorVal = {};
    for (let [key, value] of dLists.entries()) {
      distributors[value.sap_distributor_code] = value;
      distributorVal = value;
      console.log(distributorVal);
    }
    console.log('dis', distributors, distributorId);
    setDistributors(distributors);
    setDistributorId(currentUser?.user_type === 'admin' ? distributorId : distributorVal.id);
  };

  const handleDistributorChange = (org_id) => {
    let distributorId;
    if (org_id) {
      distributorId = org_id;
    }
    setDistributorId(distributorId);
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const downloadExcel = async () => {
    setDownloading(true);
    let contractData = await getLedgerExcel();
    console.log('contractdata', contractData);
    console.log(Buffer.from(contractData.data));
    const base64string = contractData.data;
    const contenttype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    var file = b64toBlob(base64string, contenttype);
    var a = document.createElement('a'),
      file;
    a.href = window.URL.createObjectURL(file);
    a.target = '_blank';
    a.download = 'Ledeger-' + new Date().toDateString() + '.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownloading(false);
  };

  const handleChangeDate = (e) => {
    let startChange = e.target.name === 'start_date' ? true : false;
    let endChange = e.target.name === 'end_date' ? true : false;
    var newFilterDate = e.target.value;

    if (e.target.name === 'end_date') {
      setEndDate(e.target.value);
    } else if (e.target.name === 'start_date') {
      setStartDate(e.target.value);
    }

    var resultProductData = state.stableData.filter(function (a) {
      var startsplit = startChange ? newFilterDate.split('-') : start_date.split('-');
      var endsplit = endChange ? newFilterDate.split('-') : end_date.split('-');
      const oneless = (input) => (parseInt(input) - 1).toString();
      var from = new Date(startsplit[0], oneless(startsplit[1]), startsplit[2]); // -1 because months are from 0 to 11
      var to = new Date(endsplit[0], oneless(endsplit[1]), endsplit[2]);
      var check = new Date(a[filterColumn]);

      return check >= from && check <= to;
    });
    console.log('result', resultProductData);
    setState((previousState) => ({
      ...previousState,
      rows: resultProductData,
    }));
  };

  const renderDistributorOptions = () => {
    var options = [];
    options.push(
      <MenuItem key={-1} value={-1}>
        All Distributors
      </MenuItem>,
    );
    for (const key in distributors) {
      if (distributors.hasOwnProperty(key)) {
        const element = distributors[key];
        options.push(
          <MenuItem key={element} value={element.sap_distributor_code}>
            {element.title}
          </MenuItem>,
        );
      }
    }
    return options;
  };

  return (
    <main className={classes.pageContent}>
      <div className={classes.toolbar} />
      <div style={{ marginBottom: '25px', marginTop: '10px' }}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6} spacing={1}>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>
              Ledger List
            </Typography>
          </Grid>
          {currentUser && (
            <>
              {' '}
              {currentUser.status === 1 && (
                <Grid>
                  <Button color="primary" variant="contained" onClick={() => downloadExcel()}>
                    {downloading ? <CircularProgress disableShrink color="white" /> : 'Download'}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
      <div className={classes.root}>
        <Paper className={classes.container}>
          <Grid container justify="space-between">
            <Grid style={{ textAlign: 'left', marginBottom: '8px' }} item md={6} xs={6}>
              {currentUser?.user_type !== USER_TYPES.DISTRIBUTOR && (
                <FormControl variant="outlined" style={{ marginTop: '5px', minWidth: '245px' }}>
                  <InputLabel id="demo-mutiple-name-label">Filter Distributor</InputLabel>
                  <MaterialUISelect
                    labelId="demo-mutiple-name-label"
                    name="Filters"
                    label="Filters"
                    value={distributorSelected}
                    defaultValue={-1}
                    MenuProps={MenuProps}
                    disabled={currentUser?.user_type === USER_TYPES.DISTRIBUTOR ? true : false}
                    onChange={(e) => {
                      funcGetDispatchOrderList(e.target.value);
                    }}
                  >
                    {renderDistributorOptions()}
                  </MaterialUISelect>
                </FormControl>
              )}
            </Grid>
            <Grid style={{ textAlign: 'right' }} item md={6} xs={6}>
              <TextField
                style={{ marginRight: '1em' }}
                name="start_date"
                label="Start Date"
                type="date"
                required
                value={moment(start_date).format('yyyy-MM-DD')}
                onChange={handleChangeDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                name="end_date"
                label="End Date"
                type="date"
                required
                style={{ marginRight: '20px', marginBottom: '12px' }}
                value={moment(end_date).format('yyyy-MM-DD')}
                onChange={handleChangeDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <hr />
          {loading === false ? (
            <>
              {' '}
              <TableContainer style={{ textAlign: 'center' }}>
                <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={state.rows.length}
                  />
                  <TableBody>
                    {stableSort(state.rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        // console.log('row', row)
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                            <TableCell align="right">{formatDate(row['DOC_DT'])}</TableCell>
                            {/* <TableCell align="right">{row["POS_DT"]}</TableCell> */}
                            <TableCell align="right">{row['DOC_NO']}</TableCell>
                            <TableCell align="right">{row['KUNNR']}</TableCell>
                            <TableCell align="right">{row['ASS_DATA']}</TableCell>
                            <TableCell align="right">{row['BILL_NO']}</TableCell>
                            <TableCell align="left">{row['DESCR']}</TableCell>
                            <TableCell align="right">{row['CR_AMT']}</TableCell>
                            <TableCell align="right">{row['DR_AMT']}</TableCell>
                            <TableCell align="right">{row['CURR']}</TableCell>
                            <TableCell align="right">{row['CR_DT_TXT']}</TableCell>
                            <TableCell align="left">{row['CLS_BAL']}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={state.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <CircularProgress />
            </>
          )}
        </Paper>
      </div>
    </main>
  );
};

export default withStyles(cssStyles)(LedgerList);
