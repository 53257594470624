import { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import { useForm, Form } from "../../components/useForm";
import Select from "../../components/controls/Select";

const initialFormValues = {
  name: "",
  type_id: "",
  grade_id: "",
  size_id: "",
  status: "1",
  formId: 0,
  material_code: '',
  parent_generic: null,
  hierarchy: null
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function DiscountForm({
  materials,
  grades,
  sizes,
  types,
  addOrEdit,
  recordForEdit,
  parentGenericData
}) {
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("material_code" in fieldValues) {
      temp.material_code = "";
      console.log(fieldValues.material_code, "=12", fieldValues.material_code.length === 10, values.material_code, fieldValues.material_code.toString().length);
      if (
        fieldValues.material_code === "" ||
        fieldValues.material_code === undefined
      ) {
        temp.material_code = "Material Code is required";
      } else if (
        fieldValues.material_code.toString().length !== 10
      ) {
        temp.material_code =
          "Material Code should be 10 digit";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // eslint-disable-next-line no-unused-vars
  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };
  console.log(values)

  const materialStatus = [
    { id: 1, title: "Active" },
    { id: 0, title: "Inactive" },
  ];
  const materialHierarchy = [
    { id: 'generic', title: "Generic" },
    { id: 'specific', title: "Specific" },
  ];

  useEffect(() => {
    if (recordForEdit !== null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);
  const onChangeHierarchy = (e) => {
    console.log(e.target.value)
    setValues({ ...values, hierarchy: e.target.value, parent_generic: null })
    // setValues({ ...values,' })
    // if (e.target.value === "generic") {
    //   setValues({ ...values, hierarchy: e.target.value })

    //   setValues({ ...values, parent_generic: null })
    // }
  }
  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Select
              required
              name="hierarchy"
              label="Hierarchy"
              value={values.hierarchy}
              onChange={onChangeHierarchy}
              options={materialHierarchy}
            />
          </Grid>
          {values.hierarchy === 'specific' && <>
            <Grid item md={12} xs={12}>
              <Select
                required
                name="parent_generic"
                label="Parent Generic"
                value={values.parent_generic}
                onChange={handleInputChange}
                options={parentGenericData}
              />
            </Grid>
          </>}
          {values.hierarchy !== null && <> <Grid item md={6} xs={12}>
            <Input
              required
              name="name"
              value={values.name}
              label="Name"
              onChange={handleInputChange}
            />
          </Grid>
            <Grid item md={6} xs={12}>
              <Select
                required
                name="grade_id"
                label="Grade"
                value={values.grade_id}
                onChange={handleInputChange}
                options={grades}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                required
                name="size_id"
                label="Size"
                value={values.size_id}
                onChange={handleInputChange}
                options={sizes}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                required
                name="type_id"
                label="Type"
                value={values.type_id}
                onChange={handleInputChange}
                options={types}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                name="status"
                label="Status"
                value={values.status}
                onChange={handleInputChange}
                options={materialStatus}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                required
                name="material_code"
                type="number"
                value={values.material_code}
                label="Material Code"
                error={errors.material_code}
                onChange={handleInputChange}
              />
            </Grid></>}
          <Grid item>
            <Button text="Submit" type="submit" />
            <Button
              text="Reset"
              type="button"
              color="default"
              onClick={resetForm}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
