import { Dialog as MuiDialog, Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ActionButton from "./controls/ActionButton";

const useStyle = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    // top: theme.spacing(5),
  },
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    paddingRight: "0",
  },
}));

export function Dialog({
  heading,
  handleClose,
  open,
  children,
  width,
  ...other
}) {
  const classes = useStyle();

  return (
    <MuiDialog
      maxWidth={width}
      fullWidth={true}
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography component="div" variant="h6" style={{ flexGrow: 1 }}>
            {heading}
          </Typography>
          <ActionButton onClick={handleClose} color="secondary">
            <CancelPresentationIcon />
          </ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </MuiDialog>
  );
}
