import { useEffect, useState } from "react";
import {
    TableBody,
    TableRow,
    TableCell, TableHead,
    Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import ActionButton from "../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { getCurrentUser } from "../../services/userService";
import { USER_TYPES } from "../../constants";

const DiscountTable = ({ discounts, openInPopUp = null, filterFn = {
    fn: (items) => {
        return items;
    },
}, tableTitle }) => {
    const [userOrgId, setUserOrgId] = useState(localStorage.getItem('user_org_id'));
    const [userType, setUserType] = useState();
    const [currentUser, setCurrentUser] = useState();

    const headCells = userType === 'admin' ? [
        { id: "organizationName", label: "Organization Name", disableSorting: true },
        { id: "discountName", label: "Discount Name", disableSorting: true },
        { id: "type", label: "Type Name", disableSorting: true },
        { id: "size", label: "Size Name", disableSorting: true },
        { id: "grade", label: "Grade Name", disableSorting: true },
        { id: "discountType", label: "Discount Type", disableSorting: true },
        { id: "parameter", label: "Parameter", disableSorting: true },
        { id: "rate", label: "Rate", disableSorting: true },
        { id: "endDate", label: "Valid Upto", disableSorting: true },
        { id: "status", label: "Status", disableSorting: true },
        { id: "action", label: "Action", disableSorting: true },
    ] : [
        // { id: "organizationName", label: "Organization Name", disableSorting: true },
        { id: "discountName", label: "Discount Name", disableSorting: true },
        { id: "type", label: "Type Name", disableSorting: true },
        { id: "size", label: "Size Name", disableSorting: true },
        { id: "grade", label: "Grade Name", disableSorting: true },
        { id: "discountType", label: "Discount Type", disableSorting: true },
        { id: "parameter", label: "Parameter", disableSorting: true },
        { id: "rate", label: "Rate", disableSorting: true },
        { id: "status", label: "Status", disableSorting: true },
    ];

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const getCurrentUserFunc = async () => {
        const user = await getCurrentUser();
        if (user && user.data) {
            setCurrentUser(user.data.body);
            setUserType(user.data.body.user_type);
            setUserOrgId(user.data.body.org_id);
        }
    }

    useEffect(() => {
        getCurrentUserFunc();
    }, [])

    const {
        TableContainer,
        TableHeader,
        TablePaginations,
        recordsAfterPagingAndSorting,
    } = useTable(discounts, headCells, filterFn, [5, 10, 25, { label: 'All', value: discounts.length }]);

    return (<>
        <TableContainer>
            {
                tableTitle &&
                <TableHead>
                    <Typography component="h2"
                        variant="h6"
                        color="primary" style={{ color: '#1A1E6E', margin: '8px' }} >
                        {tableTitle}
                    </Typography>
                </TableHead>
            }
            <TableHeader />
            <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                    <TableRow key={row.id}>

                        {currentUser?.user_type === USER_TYPES.ADMIN && <TableCell>{row.org_name}</TableCell>}
                        <TableCell>{row.discount_name}</TableCell>
                        <TableCell>{row.type_name === null && row.type_id === 0 ? "ALL" : row.type_name}</TableCell>
                        <TableCell>{row.size_name === null && row.size_id === 0 ? "ALL" : row.size_name}</TableCell>
                        <TableCell>{row.grade_name === null && row.grade_id === 0 ? "ALL" : row.grade_name}</TableCell>

                        <TableCell>{row.discount_type}</TableCell>
                        <TableCell>{row.discount_parameter}</TableCell>
                        <TableCell>{row.discount_rate}</TableCell>
                        {currentUser?.user_type === USER_TYPES.ADMIN && <TableCell>{`${new Date(row.end_date).getDate()} ${MONTHS[new Date(row.end_date).getMonth()]}, ${new Date(row.end_date).getFullYear()}`}</TableCell>}
                        <TableCell>{row.status == 1 ? 'Active' : 'In-Active'}</TableCell>
                        {currentUser?.user_type === USER_TYPES.ADMIN &&
                            <TableCell>
                                <ActionButton color="primary">
                                    <EditOutlinedIcon
                                        onClick={() => {
                                            openInPopUp(row);
                                        }}
                                        fontSize="small"
                                    />
                                </ActionButton>
                                {/* <ActionButton
                      color="secondary"
                      onClick={() => onDelete(row.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </ActionButton> */}
                            </TableCell>
                        }
                    </TableRow>
                ))}
            </TableBody>
        </TableContainer>
        <TablePaginations />
    </>)
}

export default DiscountTable;