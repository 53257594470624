import { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import { useForm, Form } from "../../components/useForm";
import Select from "../../components/controls/Select";

const initialFormValues = {
  org_id: null,
  credit_limit_quantity: null,
  credit_limit_value: null
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export default function CustomerForm({ addOrEdit, recordForEdit, type }) {
  const classes = useStyles();
  console.log('--csadc', recordForEdit)

  const validate = (fieldValues = values) => {
    console.log('---', fieldValues)
    let temp = { ...errors };

    // if ("credit_limit_quantity" in fieldValues && "credit_limit_value" in fieldValues) {
    temp.name = "";


    // }


    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // eslint-disable-next-line no-unused-vars
  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const organizationStatus = [
    { id: 1, title: "Active" },
    { id: 0, title: "Inactive" },
  ];

  const userTypes = [
    { id: "distributor", title: "Distributor" },
    { id: "admin", title: "Admin" },
  ];

  useEffect(() => {
    if (recordForEdit !== null)
      if (type === 'edit') {
        delete recordForEdit["password"]
      }
    setValues({
      ...recordForEdit,
    });
  }, [recordForEdit, setValues]);
  const onChange = (e) => {
    if (e.target.value === '') {
      setValues({ ...values, [e.target.name]: null })
    }
    else {
      setValues({ ...values, [e.target.name]: e.target.value })
    }
  }
  console.log('---> va', values)
  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Input
              name="org_name"
              value={recordForEdit.org_name}
              label="Distributor Name"
              disabled={true}
            // onChange={handleInputChange}
            // error={errors.org_name}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              name="credit_limit_quantity"
              value={values.credit_limit_quantity}
              label="Credit Limit for Quantity (MT)"
              // disabled={true}
              onChange={onChange}
              error={errors.credit_limit_quantity}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Input
              name="credit_limit_value"
              value={values.credit_limit_value}
              label="Credit Limit for Value (Rs)"
              // disabled={true}
              onChange={onChange}
              error={errors.credit_limit_value}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button text="Submit" type="submit" />
            <Button
              text="Reset"
              type="button"
              color="default"
              onClick={resetForm}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
