import { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import Select from '../../components/controls/Select';
import { Select as MaterialUISelect, MenuItem, InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import { number } from 'prop-types';
import { getDistributorList } from '../../services/distributorService';
import { getDistributorsForConsignee } from '../../services/consigneeService';
import { getAllDistricts, getAllStates } from '../../services/masterDataService';
import { getCurrentUser } from '../../services/userService';
import { USER_TYPES } from '../../constants';

const initialFormValues = {
  name: '',
  // consignee_code: null,
  gst: '',
  // tin: "",
  phone: '',
  name: '',
  // email: "",
  // address: "",
  care_off: '',
  street_2: '',
  street_3: '',
  street_4: '',
  street_5: '',
  house_no: '',
  city: '',
  postal_code: '',
  status: 1,
  formId: 0,
  distributors: [],
  // user_type: "distributor",
  // district: '',
  // password: "",
  // confirm_password: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export default function ConsigneeForm({ addOrEdit, recordForEdit, type }) {
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedState, setSelectedState] = useState();
  const [allDistributors, setAllDistributors] = useState([]);
  const [ALL_STATES, setALL_STATES] = useState([]);
  const [ALL_DISTRICTS, setALL_DISTRICTS] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const classes = useStyles();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues) {
      temp.name = '';
      if (fieldValues.name === '' || fieldValues.name === undefined) {
        temp.name = 'Consignee name is required';
      } else if (fieldValues.name && fieldValues.name.length > 30) {
        temp.name = 'Consignee name should be maximum 30 characters';
      }
    }
    // if ("name" in fieldValues) {
    //   temp.name = "";
    //   if (fieldValues.name === "" || fieldValues.name === undefined) {
    //     temp.name = "Contact person name is required";
    //   } else if (fieldValues.name && fieldValues.name.length > 150) {
    //     temp.name = "Contact person name should be maximum 150 characters";
    //   } else if (/[0-9]/.test(fieldValues.name)) {
    //     temp.name = "Contact person name only contain should be only alphabet";
    //   }
    // }
    if ('consignee_code' in fieldValues) {
      console.log('!Number.isInteger(fieldValues.consignee_code)', !Number.isInteger(fieldValues.consignee_code));
      temp.consignee_code = '';
      if (fieldValues.consignee_code === '' || fieldValues.consignee_code === undefined) {
        temp.consignee_code = 'Consignee code is required';
      } else if (fieldValues.consignee_code.length < 7) {
        temp.consignee_code = 'Consignee Code should be of minimum 7 length';
      }
      // else if (!Number.isInteger(parseInt(fieldValues.consignee_code))) {
      //   temp.consignee_code = "Distributor Code Should be number"
      // }
    }
    if ('gst' in fieldValues) {
      temp.gst = '';
      if (fieldValues.gst === '' || fieldValues.gst === undefined) {
        temp.gst = 'GST number is required';
      } else if (fieldValues.gst.length !== 15) {
        temp.gst = 'GST number should be of 15 length';
      }
    }
    if ('street_2' in fieldValues) {
      temp.street_2 = '';
      // if (fieldValues.street_2 === "" || fieldValues.street_2 === undefined) {
      //   temp.street_2 = "Street 2 is required";
      // } else
      if (fieldValues.street_2 && fieldValues.street_2.length > 14) {
        temp.street_2 = 'street_2 should be maximum 14 characters';
      }
    }
    if ('care_off' in fieldValues) {
      temp.care_off = '';
      if (fieldValues.care_off === '' || fieldValues.care_off === undefined) {
        temp.care_off = 'Street 2 is required';
      } else if (fieldValues.care_off && fieldValues.care_off.length > 14) {
        temp.care_off = 'care_off should be maximum 14 characters';
      }
    }
    if ('house_no' in fieldValues) {
      temp.house_no = '';
      if (fieldValues.house_no === '' || fieldValues.house_no === undefined) {
        temp.house_no = 'Street 2 is required';
      } else if (fieldValues.house_no && fieldValues.house_no.length > 14) {
        temp.house_no = 'house_no should be maximum 14 characters';
      }
    }
    if ('street_3' in fieldValues) {
      temp.street_3 = '';
      // if (fieldValues.street_3 === "" || fieldValues.street_3 === undefined) {
      //   temp.street_3 = "Street 2 is required";
      // } else
      if (fieldValues.street_3 && fieldValues.street_3.length > 14) {
        temp.street_3 = 'street_3 should be maximum 14 characters';
      }
    }
    if ('street_4' in fieldValues) {
      temp.street_4 = '';
      if (fieldValues.street_4 && fieldValues.street_4.length > 14) {
        temp.street_4 = 'street_4 should be maximum 14 characters';
      }
    }
    if ('street_5' in fieldValues) {
      temp.street_5 = '';
      if (fieldValues.street_5 && fieldValues.street_5.length > 14) {
        temp.street_5 = 'street_5 should be maximum 14 characters';
      }
    }
    if ('district' in fieldValues) {
      temp.district = '';
      if (fieldValues.district === '' || fieldValues.district === undefined) {
        temp.district = 'District is required';
      }
      //  else if (fieldValues.district && fieldValues.city.length > 80) {
      //   temp.city = "City should be maximum 150 characters";
      // }
      else if (/[0-9]/.test(fieldValues.district)) {
        temp.district = 'District Name only contain should be only alphabet';
      }
    }
    if ('city' in fieldValues) {
      temp.city = '';
      if (fieldValues.city === '' || fieldValues.city === undefined) {
        temp.city = 'City is required';
      } else if (fieldValues.city && fieldValues.city.length > 30) {
        temp.city = 'City should be maximum 30 characters';
      } else if (/[0-9]/.test(fieldValues.city)) {
        temp.city = 'City Name only contain should be only alphabet';
      }
    }
    if ('postal_code' in fieldValues) {
      temp.postal_code = '';
      console.log(fieldValues.postal_code.toString().length);
      console.log('filk12', fieldValues.postal_code);

      if (fieldValues.postal_code === '' || fieldValues.postal_code === undefined) {
        temp.postal_code = 'Postal code is required';
      }
      else if (fieldValues.postal_code.toString().length !== 6) {
        temp.postal_code = 'Postal Code number should be 6 length';
      }
    }
    if ('email' in fieldValues) {
      temp.email = '';
      if (fieldValues.email === '' || fieldValues.email === undefined) {
        temp.email = 'Please enter email id';
      } else if (fieldValues.email && !/$^|.+@.+..+/.test(fieldValues.email)) {
        temp.email = 'Email is not valid';
      }
    }
    if ('phone' in fieldValues) {
      console.log('---dsds', !/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(fieldValues.phone));

      console.log(fieldValues.phone.length !== 10, '---');
      temp.phone = '';
      const letters = /^[A-Za-z]+$/;
      console.log(fieldValues.phone.match(letters));
      if (fieldValues.phone == '' || fieldValues.phone === undefined) {
        temp.phone = 'Mobile number is required';
      } else if (
        fieldValues.phone.length !== 10 ||
        !/^\d+$/.test(fieldValues.phone) ||
        fieldValues.phone.match(letters)
      ) {
        temp.phone = 'Please enter valid mobile number';
      }
    }
    console.log(fieldValues.password === '');
    if (type === 'edit') {
      if (fieldValues.password !== '') {
        console.log('--');
        if ('password' in fieldValues) {
          temp.password = '';
          if (fieldValues.password === '' || fieldValues.password === undefined) {
            temp.password = 'Password is required';
          } else if (
            fieldValues.password &&
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(fieldValues.password)
          ) {
            temp.password =
              'Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet';
          } else if (fieldValues.password && fieldValues.password.length > 100) {
            temp.password = 'Password should be max 100 characters long';
          }
        }
      }
      if (fieldValues.password === '') {
        temp.password = '';
      }
    }
    if (type === 'add') {
      if ('password' in fieldValues) {
        temp.password = '';
        if (fieldValues.password === '' || fieldValues.password === undefined) {
          temp.password = 'Password is required';
        } else if (
          fieldValues.password &&
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(fieldValues.password)
        ) {
          temp.password =
            'Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet';
        } else if (fieldValues.password && fieldValues.password.length > 100) {
          temp.password = 'Password should be max 100 characters long';
        }
      }
    }
    if ('confirm_password' in fieldValues) {
      temp.confirm_password = '';
      console.log(fieldValues.confirm_password, '=', values.password);
      if (fieldValues.confirm_password === '' || fieldValues.confirm_password === undefined) {
        temp.confirm_password = 'Confirm Password is required';
      } else if (fieldValues.confirm_password && fieldValues.confirm_password != values.password) {
        temp.confirm_password = 'The two passwords that you entered do not match!';
      }
    }
    if (type === 'edit' && fieldValues.password === '') {
      temp.confirm_password = '';
    }
    // if ("organizationId" in fieldValues)
    //   temp.organizationId = Number(fieldValues.organizationId)
    //     ? ""
    //     : "Minimum 1 numbers required";

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  // eslint-disable-next-line no-unused-vars
  const { values, errors, setErrors, setValues, resetForm, handleInputChange } = useForm(
    initialFormValues,
    true,
    validate,
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (selectedDistrict) {
        addOrEdit(
          {
            ...values,
            distributors: selectedDistributors,
            district: ALL_DISTRICTS.find((d) => d.id === selectedDistrict).title,
          },
          resetForm,
        );
      } else {
        addOrEdit({ ...values, distributors: selectedDistributors }, resetForm);
      }
    }
  };

  const handleDistributorsSelect = (event) => {
    setSelectedDistributors(event.target.value);
    console.log('Final Selected : ' + selectedDistributors);
  };

  const fetchAllStates = async () => {
    const stateLists = await getAllStates();
    setALL_STATES(stateLists);
  };

  const fetchAllDistricts = async () => {
    const stateLists = await getAllDistricts();
    setALL_DISTRICTS(stateLists);
  };

  const organizationStatus = [
    { id: 1, title: 'Active' },
    { id: 0, title: 'Inactive' },
  ];

  const userTypes = [
    { id: 'distributor', title: 'Distributor' },
    { id: 'admin', title: 'Admin' },
  ];

  const getCurrentUserFunc = async () => {
    const user = await getCurrentUser();
    if (user && user.data) {
      setCurrentUser(user.data.body)
    }
  }

  useEffect(() => {
    getCurrentUserFunc();
    fetchAllStates();
    fetchAllDistricts();
  }, []);

  const fetchDistributors = async () => {
    const allDistributors = await getDistributorList();
    setAllDistributors(allDistributors.data.data);
    if (recordForEdit !== null)
      if (type === 'edit') {
        let initialSelectedDistributors = await getDistributorsForConsignee(recordForEdit.id);
        let initialInArray = [];
        for (let i = 0; i < initialSelectedDistributors.length; i++) {
          initialInArray.push(initialSelectedDistributors[i].org_id);
        }
        setSelectedDistributors(initialInArray);
        // delete recordForEdit["password"];
      }
    setValues({
      ...recordForEdit,
    });
  }

  useEffect(() => {
    fetchDistributors();
  }, [recordForEdit, setValues]);

  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Input
              name="name"
              value={values.name}
              label="Consignee Name"
              onChange={handleInputChange}
              error={errors.name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="consignee_code"
              value={parseInt(values.consignee_code)}
              label="Consignee Code"
              type="number"
              onChange={handleInputChange}
              error={errors.consignee_code}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input name="gst" value={values.gst} label="GST No" onChange={handleInputChange} error={errors.gst} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="care_off"
              value={values.care_off}
              label="C/O"
              onChange={handleInputChange}
              error={errors.care_off}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="house_no"
              value={values.house_no}
              label="House No."
              onChange={handleInputChange}
              error={errors.house_no}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="street_2"
              value={values.street_2}
              label="Street 2"
              onChange={handleInputChange}
              error={errors.street_2}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="street_3"
              value={values.street_3}
              label="Street 3"
              onChange={handleInputChange}
              error={errors.street_3}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="street_4"
              value={values.street_4}
              label="Street 4"
              onChange={handleInputChange}
              error={errors.street_4}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="street_5"
              value={values.street_5}
              label="Street 5"
              onChange={handleInputChange}
              error={errors.street_5}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="city"
              value={values.city}
              label="City"
              onChange={handleInputChange}
              error={errors.city}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              // defaultValue="Active"
              name="state_code"
              label="State"
              // defaultValue={values.state_code}
              value={parseInt(values.state_code)}
              onChange={(e) => {
                handleInputChange(e);
                setSelectedState(ALL_STATES.find((d) => d.id === e.target.value));
              }}
              options={ALL_STATES}
              required
            />
          </Grid>
          {
            (selectedState || values.state_code) &&
            <Grid item md={6} xs={12}>
              <Select
                // defaultValue="Active"
                name="district_code"
                label="District"
                value={parseInt(values.district_code)}
                onChange={(e) => {
                  handleInputChange(e);
                  setSelectedDistrict(e.target.value);
                }}
                options={selectedState ? ALL_DISTRICTS.filter((dis) => dis.state_id === selectedState.id) : ALL_DISTRICTS.filter((dis) => dis.state_id === values.state_code)}
                // options={selectedState ? ALL_DISTRICTS.filter((dis) => dis.state_id === selectedState.id) : ALL_DISTRICTS}
                error={errors.district}
              />
            </Grid>
          }
          <Grid item md={6} xs={12}>
            <Input
              name="postal_code"
              value={values.postal_code}
              label="Postal Code"
              onChange={handleInputChange}
              error={errors.postal_code}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              defaultValue="Active"
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={organizationStatus}
            />
          </Grid>
          {currentUser?.user_type !== USER_TYPES.DISTRIBUTOR &&
            <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
              // {...(error && { error: true })}
              >
                <InputLabel id="demo-mutiple-name-label">Distributors</InputLabel>
                <MaterialUISelect
                  labelId="demo-mutiple-name-label"
                  name="distributors"
                  multiple
                  // label="Distributors"
                  value={selectedDistributors}
                  onChange={handleDistributorsSelect}
                  MenuProps={MenuProps}
                >
                  {/* <MenuItem key={0} value={0}>ALL Distributors</MenuItem> */}
                  {allDistributors.length > 0 &&
                    allDistributors.map((distributor) => (
                      <MenuItem key={distributor.id} value={distributor.id}>
                        {`${distributor.org_name} -- ${distributor.sap_distributor_code}`}
                      </MenuItem>
                    ))}
                </MaterialUISelect>
                {/* {error && <FormHelperText>{error}</FormHelperText>} */}
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button text="Submit" type="submit" />
            <Button text="Reset" type="button" color="default" onClick={resetForm} />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
