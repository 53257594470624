import React from 'react';
import axios from 'axios';
import { Grid, InputAdornment, } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import { Select as MaterialUISelect, MenuItem, InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import { getCurrentUser } from '../../services/userService';
import { getPurchaseOrderDetailsForView, } from '../../services/PurchaseOrder';
import { withRouter } from "react-router";

const token = localStorage.getItem('token');
const SERVERBASEURL = process.env.REACT_APP_API;

class EditSalesOrderDetails extends React.Component {
    state = {
        currentUser: null,
        selectedPO: "",
        salesOrderFile: null,
        salesOrderId: null,
    }

    getCurrentUserFunc = async () => {
        let user = await getCurrentUser();
        this.setState({ currentUser: user.data.body });
        return user;
    };

    async componentDidMount() {
        let fetchedUser = await this.getCurrentUserFunc();
        // this.setState({ distributor_hide_class: this.state.currentUser && this.state.currentUser.user_type == 'admin' ? 'show' : 'none' });
        console.log(fetchedUser)
        // this.fetchPODetails();
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('sap_sales_order_id', this.state.salesOrderId);
        formData.append('salesOrderFile', this.state.salesOrderFile);
        var api = {
            method: 'post',
            url: SERVERBASEURL + "/dispatch-order/edit-sales-order/" + this.props.dpId,
            data: formData,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        axios(api)
            .then((res) => {
                if (res.data.status !== undefined && res.data.status === 1) {
                    this.props.setNotify({
                        isOpen: true,
                        message: res.data?.message,
                        type: 'success',
                    }
                    );
                }
                this.props.updateDPList();
                this.props.handleClose();
            })
            .catch((err) => {
                this.props.setNotify({
                    isOpen: true,
                    message: `${err?.response?.data?.message || 'Sales Order Details Update Unsuccessful !'}`,
                    type: 'error',
                }
                );
                console.log('Error in performing operation', JSON.stringify(err?.response?.data?.message));
                this.props.handleClose();
            });
    };

    fetchPODetails = async () => {
        let response = await getPurchaseOrderDetailsForView(this.props.poId);
        this.setState({ poDetails: response.data.data[0] })
    }

    render() {
        return (
            <div className='root'>
                <Form onSubmit={this.handleFormSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"salesOrderId"}
                                    type='text'
                                    label='Sales Order Id'
                                    value={this.state.salesOrderId}
                                    required
                                    onChange={e => this.setState({ salesOrderId: e.target.value })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"salesOrder"}
                                    type='file'
                                    label='Sales Order File'
                                    onChange={e => {
                                        this.setState({ salesOrderFile: e.target.files[0] })
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}

                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button color="primary" variant="contained"
                                text="Save Sales Order Details"
                                onClick={this.handleFormSubmit}
                            />
                        </Grid>
                    </Grid>
                </Form>

            </div>
        );
    }
}

export default withRouter(EditSalesOrderDetails);