import { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  makeStyles,
  Grid,
  TableBody,
  Paper,
  Toolbar,
  TableRow,
  TableCell,
  InputAdornment
} from "@material-ui/core";
import { Dialog } from "../../components/Dialog";
import useTable from "../../components/useTable";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import AddIcon from "@material-ui/icons/Add";
import { Search } from "@material-ui/icons";
import ConsigneeForm from "./ConsigneeForm";

import {
  deleteCustomer,
  getDistributorById
} from "../../services/customerService";
import ActionButton from "./../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Notifications from "./../../components/Notifications";
import {
  getConsigneeList,
  insertConsignee,
  updateConsignee
} from "../../services/consigneeService";

const useStyles = makeStyles(theme => ({
  pageContent: {
    // margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar,

  searchInput: {
    width: "75%"
  },
  newButton: {
    position: "absolute",
    right: "10px"
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px"
  }
}));

export default function ConsigneeList() {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openPopUpCredit, setOpenPopUpCredit] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEditCredit, setRecordForEditCredit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: ""
  });
  const [typeModal, setTypeModal] = useState("add");
  const [filterFn, setFilterFn] = useState({
    fn: items => {
      return items;
    }
  });
  const classes = useStyles();

  const getDistributorInfo = async id => {
    const distributorInfo = await getDistributorById(id);
  };

  const fetchConsignees = async () => {
    setLoading(true);
    let consigneeList = await getConsigneeList();
    setLoading(false);
    return setCustomers(consigneeList.data.data);
  };

  useEffect(() => {
    fetchConsignees();
  }, []);

  const headCells = [
    { id: "ConsigneeName", label: "Consignee Name", disableSorting: true },
    { id: "consigneeCode", label: "Consignee Code", disableSorting: true },
    { id: "care_off", label: "C/O", disableSorting: true },
    { id: "house_no", label: "House No", disableSorting: true },
    { id: "street_2", label: "Street 2", disableSorting: true },
    { id: "street_3", label: "Street 3", disableSorting: true },
    { id: "city", label: "City", disableSorting: true },
    { id: "postalCode", label: "Pin Code", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true }
  ];

  const handlePopUpOpen = () => {
    setRecordForEdit(null);
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
      fn: items => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter(x =>
            x.name.toLowerCase().includes(target.value.toLowerCase())
          );
        }
      }
    });
  };

  const addOrEdit = async (customer, resetForm) => {
    try {
      let res;
      // if (customer.formId === 0) {
      if (!customer.id) {
        // const response = await insertConsignee(customer);
        res = await insertConsignee(customer);
      }
      else {
        res = await updateConsignee(customer);
      }
      resetForm();
      setOpenPopUp(false);
      if (res && res.data && res.data.data !== null) {
        setNotify({
          isOpen: true,
          message: res.data.message || "Submitted successfully",
          type: "success"
        });
      } else {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: "error"
        });
      }
      fetchConsignees();
    } catch (err) {
      // console.log(err.response.data)
      setNotify({
        isOpen: true,
        message: err.response.data.message,
        type: "error"
      });
    }
  };

  const openInPopUp = row => {
    setTypeModal("edit");
    setRecordForEdit(row);
    setOpenPopUp(true);
  };

  const onDelete = id => {
    if (window.confirm("Are you sure to delete this record")) {
      // const updatedData = customers.filter((row) => row.organizationId !== id);
      deleteCustomer(id);
      setNotify({
        isOpen: true,
        message: "Deleted successfully",
        type: "error"
      });
      setTimeout(fetchConsignees, 1000);
    }
  };

  const {
    TableContainer,
    TableHeader,
    TablePaginations,
    recordsAfterPagingAndSorting
  } = useTable(customers, headCells, filterFn, [5, 10, 25, { label: 'All', value: customers.length }]);

  return (
    <>
      <PageHeader
        title="Consignees"
        subTitle="List of all the consignees"
        icon={<PeopleOutlineIcon />}
      />
      <main className={classes.pageContent}>
        <div className={classes.toolbar} />

        <Paper style={{ paddingTop: "20px" }}>
          <Toolbar>
            <Input
              label="Search by Consignee Name"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />
            <Button
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={handlePopUpOpen}
            />
          </Toolbar>
          {!loading ? (
            <>
              {" "}
              <div className={classes.container}>
                <TableContainer>
                  <TableHeader />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map(row => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.consignee_code}</TableCell>
                        <TableCell>{row.care_off}</TableCell>
                        <TableCell>{row.house_no}</TableCell>
                        <TableCell>{row.street_2}</TableCell>
                        <TableCell>{row.street_3}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.postal_code}</TableCell>
                        {/* <TableCell>
                          {row.district === null ? "NULL" : row.district}
                        </TableCell> */}
                        {/* <TableCell>{row.credit_limit_quantity === null ? "N/A" : row.credit_limit_quantity}</TableCell>
                    <TableCell>{row.credit_limit_value === null ? "N/A" : row.credit_limit_value}</TableCell> */}
                        <TableCell>
                          {row.status === 1 ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell>
                          <ActionButton
                            onClick={() => {
                              openInPopUp(row);
                            }}
                            color="primary"
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </ActionButton>
                          {/* <ActionButton
                            onClick={() => {
                              openInPopUpCredit(row);
                            }}
                            color="primary"
                          >
                            <AttachMoneyIcon fontSize="small" />
                          </ActionButton> */}
                          {/* <ActionButton
                        color="secondary"
                        onClick={() => onDelete(row.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </ActionButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TableContainer>
              </div>
              <TablePaginations />{" "}
            </>
          ) : (
            <>
              <CircularProgress />
            </>
          )}
        </Paper>
        <Grid item xs={12}>
          <Dialog
            heading="Add Consignee"
            handleClose={handleClose}
            open={openPopUp}
            width="md"
          >
            <ConsigneeForm
              type={typeModal}
              recordForEdit={recordForEdit}
              addOrEdit={addOrEdit}
            />
          </Dialog>
        </Grid>
        <Notifications notify={notify} setNotify={setNotify} />
      </main>
    </>
  );
}
