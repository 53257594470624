import { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import Select from '../../components/controls/Select';
import { Select as MaterialUISelect, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { STATES } from '../../constants/states';
import { getDistributorList } from '../../services/distributorService';
import { getDistributorsForSalesPersons } from '../../services/salesPersonService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialFormValues = {
  sap_distributor_code: null,
  phone: '',
  name: '',
  email: '',
  state: null,
  status: 1,
  formId: 0,
  user_type: 'sales_person',
  password: '',
  confirm_password: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export default function SalesPersonForm({ addOrEdit, recordForEdit, type }) {
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [allDistributors, setAllDistributors] = useState([]);

  const classes = useStyles();
  console.log('--csadc', recordForEdit);

  const validate = (fieldValues = values) => {
    console.log('---', fieldValues);
    let temp = { ...errors };
    if ('name' in fieldValues) {
      temp.name = '';
      if (fieldValues.name === '' || fieldValues.name === undefined) {
        temp.name = 'Contact person name is required';
      } else if (fieldValues.org_name && fieldValues.org_name.length > 150) {
        temp.name = 'Contact person name should be maximum 150 characters';
      } else if (/[0-9]/.test(fieldValues.name)) {
        temp.name = 'Contact person name only contain should be only alphabet';
      }
    }
    // if ("sap_distributor_code" in fieldValues) {
    //   console.log('!Number.isInteger(fieldValues.sap_distributor_code)', !Number.isInteger(fieldValues.sap_distributor_code))
    //   temp.sap_distributor_code = "";
    //   console.log(fieldValues.sap_distributor_code);
    //   if (
    //     fieldValues.sap_distributor_code === "" ||
    //     fieldValues.sap_distributor_code === undefined
    //   ) {
    //     temp.sap_distributor_code = "Distributor code is required";
    //   }
    //   else if (fieldValues.sap_distributor_code.length < 7) {
    //     temp.sap_distributor_code = "Distributor Code number should be of 7 length"
    //   }
    // else if (!Number.isInteger(parseInt(fieldValues.sap_distributor_code))) {
    //   temp.sap_distributor_code = "Distributor Code Should be number"
    // }
    // }
    if ('state' in fieldValues) {
      temp.state = '';
      if (fieldValues.state === '' || fieldValues.state === undefined) {
        temp.state = "Distributor's state is required";
      }
    }
    if ('email' in fieldValues) {
      temp.email = '';
      if (fieldValues.email === '' || fieldValues.email === undefined) {
        temp.email = 'Please enter email id';
      } else if (fieldValues.email && !/$^|.+@.+..+/.test(fieldValues.email)) {
        temp.email = 'Email is not valid';
      }
    }
    if ('phone' in fieldValues) {
      console.log('---dsds', !/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(fieldValues.phone));

      console.log(fieldValues.phone.length !== 10, '---');
      temp.phone = '';
      const letters = /^[A-Za-z]+$/;
      console.log(fieldValues.phone.match(letters));
      if (fieldValues.phone == '' || fieldValues.phone === undefined) {
        temp.phone = 'Mobile number is required';
      } else if (
        fieldValues.phone.length !== 10 ||
        !/^\d+$/.test(fieldValues.phone) ||
        fieldValues.phone.match(letters)
      ) {
        temp.phone = 'Please enter valid mobile number';
      }
    }
    console.log(fieldValues.password === '');
    if (type === 'edit') {
      if (fieldValues.password !== '') {
        console.log('--');
        if ('password' in fieldValues) {
          temp.password = '';
          if (fieldValues.password === '' || fieldValues.password === undefined) {
            temp.password = 'Password is required';
          } else if (
            fieldValues.password &&
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(fieldValues.password)
          ) {
            temp.password =
              'Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet';
          } else if (fieldValues.password && fieldValues.password.length > 100) {
            temp.password = 'Password should be max 100 characters long';
          }
        }
      }
      if (fieldValues.password === '') {
        temp.password = '';
      }
    }
    if (type === 'add') {
      if ('password' in fieldValues) {
        temp.password = '';
        if (fieldValues.password === '' || fieldValues.password === undefined) {
          temp.password = 'Password is required';
        } else if (
          fieldValues.password &&
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(fieldValues.password)
        ) {
          temp.password =
            'Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet';
        } else if (fieldValues.password && fieldValues.password.length > 100) {
          temp.password = 'Password should be max 100 characters long';
        }
      }
    }
    if ('confirm_password' in fieldValues) {
      temp.confirm_password = '';
      console.log(fieldValues.confirm_password, '=', values.password);
      if (fieldValues.confirm_password === '' || fieldValues.confirm_password === undefined) {
        temp.confirm_password = 'Confirm Password is required';
      } else if (fieldValues.confirm_password && fieldValues.confirm_password != values.password) {
        temp.confirm_password = 'The two passwords that you entered do not match!';
      }
    }
    if (type === 'edit' && fieldValues.password === '') {
      temp.confirm_password = '';
    }
    // if ("organizationId" in fieldValues)
    //   temp.organizationId = Number(fieldValues.organizationId)
    //     ? ""
    //     : "Minimum 1 numbers required";

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  // eslint-disable-next-line no-unused-vars
  const { values, errors, setErrors, setValues, resetForm, handleInputChange } = useForm(
    initialFormValues,
    true,
    validate,
  );

  const handleDistributorsSelect = (event) => {
    setSelectedDistributors(event.target.value);
    console.log('Final Selected : ' + selectedDistributors);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('###' + JSON.stringify(values));
      // addOrEdit(values, resetForm);
      addOrEdit({ ...values, distributors: selectedDistributors }, resetForm);
    }
  };

  const organizationStatus = [
    { id: 1, title: 'Active' },
    { id: 0, title: 'Inactive' },
  ];

  // const userTypes = [
  //   { id: "distributor", title: "Distributor" },
  //   { id: "admin", title: "Admin" },
  // ];

  useEffect(() => {
    if (recordForEdit !== null)
      if (type === 'edit') {
        delete recordForEdit['password'];
      }
    setValues({
      ...recordForEdit,
    });
  }, [recordForEdit, setValues]);

  useEffect(() => {
    let fetchDistributors = async () => {
      const allDistributors = await getDistributorList();
      // console.log('All distributors : '+JSON.stringify(allDistributors.data.data))
      setAllDistributors(allDistributors.data.data);
      if (recordForEdit !== null)
        if (type === 'edit') {
          let initialSelectedDistributors = await getDistributorsForSalesPersons(recordForEdit.id);
          // console.log(initialSelectedDistributors)
          let initialInArray = [];
          for (let i = 0; i < initialSelectedDistributors.length; i++) {
            // console.log(initialSelectedDistributors[i].org_id)
            initialInArray.push(initialSelectedDistributors[i].org_id);
          }
          // console.log(initialInArray)
          setSelectedDistributors(initialInArray);
          // delete recordForEdit["password"];
        }
      setValues({
        ...recordForEdit,
      });
    };
    fetchDistributors();
  }, [recordForEdit, setValues]);
  console.log('---> va', values);
  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Input
              name="name"
              value={values.name}
              label="Sales Person's Name"
              onChange={handleInputChange}
              error={errors.name}
            />
          </Grid>

          {/* <Grid item md={6} xs={12}>
            <FormControl variant="outlined">
              <InputLabel id="stateDropdown">State</InputLabel>
              <MaterialUISelect
                // defaultValue='Active'
                labelId="stateDropdown"
                name="state"
                value={values.state}
                label="State"
                onChange={handleInputChange}
                error={errors.state}
                // options={STATES.states}
                MenuProps={MenuProps}
              >
                {STATES.length > 0 &&
                  STATES.map((state) => (
                    <MenuItem key={state.id} value={state.code}>
                      {state.name}
                    </MenuItem>
                  ))}
              </MaterialUISelect>
            </FormControl>
          </Grid> */}
          <Grid item md={6} xs={12}>
            <Input
              name="email"
              autoComplete={false}
              value={values.email}
              label="Email"
              onChange={handleInputChange}
              error={errors.email}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input name="phone" value={values.phone} label="Mobile" onChange={handleInputChange} error={errors.phone} />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControl
              variant="outlined"
              // {...(error && { error: true })}
            >
              <InputLabel id="demo-mutiple-name-label">Distributors</InputLabel>
              <MaterialUISelect
                labelId="demo-mutiple-name-label"
                name="distributors"
                multiple
                // label="Distributors"
                value={selectedDistributors}
                onChange={handleDistributorsSelect}
                MenuProps={MenuProps}
              >
                {/* <MenuItem key={0} value={0}>ALL Distributors</MenuItem> */}
                {allDistributors.length > 0 &&
                  allDistributors.map((distributor) => (
                    <MenuItem key={distributor.id} value={distributor.id}>
                      {`${distributor.org_name} -- ${distributor.sap_distributor_code}`}
                    </MenuItem>
                  ))}
              </MaterialUISelect>
              {/* {error && <FormHelperText>{error}</FormHelperText>} */}
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              name="password"
              // value={values.password}
              label="Password"
              type="password"
              onChange={handleInputChange}
              error={errors.password}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name="confirm_password"
              value={values.confirm_password}
              label="Confirm Password"
              type="password"
              onChange={handleInputChange}
              error={errors.confirm_password}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              defaultValue="Active"
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={organizationStatus}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button text="Submit" type="submit" />
            <Button text="Reset" type="button" color="default" onClick={resetForm} />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
