import React from 'react';
import _ from 'lodash';
import { Grid, } from '@material-ui/core';
import Button from '../../components/controls/Button';
import { useForm, Form } from '../../components/useForm';
import { FormControl } from '@material-ui/core';
import { getCurrentUser } from '../../services/userService';
import { withRouter } from "react-router";
import Select from '../../components/controls/Select';
import { STATUS } from '../../constants';
import { STATUS_TEXT } from '../../constants';
import { getDispatchPlanReport } from '../../services/dispatchPlan';
import { b64toBlob } from '../../helpers/helperFunctions';

class DownloadReport extends React.Component {
    state = {
        currentUser: null,
        selectedStatus: "",
        status: [],
        STATUS_OPTIONS: [],
    }

    getCurrentUserFunc = async () => {
        let user = await getCurrentUser();
        this.setState({ currentUser: user.data.body });
        return user;
    };

    async componentDidMount() {
        let STATUS_OPTIONS = [];
        for (const key in STATUS) {
            if (Object.hasOwnProperty.call(STATUS, key)) {
                let id = STATUS[key];
                let title = STATUS_TEXT[key]
                STATUS_OPTIONS.push({ id, title })
            }
        }
        this.setState({ STATUS_OPTIONS });
    };

    handleFormSubmit = async (e) => {
        let contractData = await getDispatchPlanReport(this.state.selectedStatus);
        // console.log('contractdata', contractData);
        // console.log(Buffer.from(contractData.data));
        const base64string = contractData.data;
        const contenttype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        var file = b64toBlob(base64string, contenttype);
        var a = document.createElement('a'),
            file;
        a.href = window.URL.createObjectURL(file);
        a.target = '_blank';
        let statusSelected = await STATUS_TEXT[_.findKey(STATUS, status => status === this.state.selectedStatus)]
        // console.log("🚀 ~ file: DownloadReport.js ~ line 62 ~ DownloadReport ~ handleFormSubmit= ~ statusSelected", statusSelected);
        a.download = statusSelected + '-DP-Report-' + new Date().toDateString() + '.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    render() {
        return (
            <div className='root'>
                <Form onSubmit={this.handleFormSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                            >
                                <Select
                                    name="status"
                                    label="Select DP Status"
                                    options={this.state.STATUS_OPTIONS}
                                    value={this.state.selectedStatus}
                                    required
                                    onChange={(e) => this.setState({ selectedStatus: e.target.value })}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button color="primary" variant="contained"
                                text="Download Report"
                                onClick={this.handleFormSubmit}
                            />
                        </Grid>
                    </Grid>
                </Form>

            </div>
        );
    }
}

export default withRouter(DownloadReport);