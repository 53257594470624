import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import ActionButton from '../../components/controls/ActionButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useParams, useHistory } from 'react-router-dom';

import { getDispatchPlansForPO, getSalesOrderFile } from '../../services/dispatchPlan';
import { getPurchaseOrderDetailsForView } from '../../services/PurchaseOrder';
import { useStyles } from './styles';
import PODetails from './PODetails';
import { STATUS, STATUS_TEXT, USER_TYPES } from '../../constants';
import { formatDate } from '../../helpers/helperFunctions';
import { getCurrentUser } from '../../services/userService';

const DispatchPlan = () => {
  const history = useHistory();
  let { id } = useParams();
  const [planStatus, setPlanStatus] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [ plans, setDispatchedPlans] = useState(dispatchedPlans)
  const [plans, setDispatchedPlans] = useState([]);
  const [poDetails, setPoDetails] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [dispatchedPlansQuantity, setDispatchedPlansQuantity] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    event.preventDefault();
  };

  const fetchDispatchPlans = async (status) => {
    let dispatchedPlans = await getDispatchPlansForPO(id);
    let total = 0;
    await dispatchedPlans.map(dp => {
      if (dp.status !== STATUS.REJECTED) {
        total += dp.total_quantity;
      }
    })
    setDispatchedPlansQuantity(total);
    if (status) {
      dispatchedPlans = dispatchedPlans.filter((plan) => plan.status === status);
      setPlanStatus(status);
    }
    setDispatchedPlans(dispatchedPlans);
  };

  const fetchDispatchPlansByDate = async (date) => {
    let dispatchedPlans = await getDispatchPlansForPO(id);
    if (date) {
      dispatchedPlans = dispatchedPlans.filter((plan) => {
        // console.log(plan.created);
        let created = new Date(plan.created);
        let d = `${created.getFullYear()}-${created.getMonth() < 10 ? '0' + (created.getMonth() + 1) : created.getMonth() + 1
          }-${created.getDate()}`;
        return d == date;
      });
    }
    setDispatchedPlans(dispatchedPlans);
  };

  useEffect(() => {
    fetchDispatchPlans();
  }, [])

  useEffect(async () => {
    let poDetails = await getPurchaseOrderDetailsForView(id);
    setPoDetails({ ...poDetails.data.data[0], dispatchedPlansQuantity });
  }, [dispatchedPlansQuantity]);

  const fetchUser = async () => {
    let user = await getCurrentUser();
    // console.log(JSON.stringify(user.data.body))
    setCurrentUser(user.data.body);
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const returnStatus = (status) => {
    switch (status) {
      case STATUS.PENDING:
        return (
          <button style={{ textTransform: 'uppercase' }} className={classes.pending}>
            {STATUS_TEXT.PENDING}
          </button>
        );
      case STATUS.APPROVED:
        return (
          <button style={{ textTransform: 'uppercase' }} className={classes.approved}>
            {STATUS_TEXT.APPROVED}
          </button>
        );
      case STATUS.REJECTED:
        return (
          <button style={{ textTransform: 'uppercase' }} className={classes.rejected}>
            {STATUS_TEXT.REJECTED}
          </button>
        );
      case STATUS.COMPLETED:
        return (
          <button style={{ textTransform: 'uppercase' }} className={classes.completed}>
            {STATUS_TEXT.COMPLETED}
          </button>
        );
      case STATUS.IN_PROGRESS:
        return (
          <button style={{ textTransform: 'uppercase' }} className={classes.inProgress}>
            {STATUS_TEXT.IN_PROGRESS}
          </button>
        );
      default:
        return null;
    }
  };

  const viewUrl = (id) => {
    history.push('/dispatch-plan-details/' + id);
  };

  let today = new Date();
  const todaysDate = `${today.getFullYear()}-${today.getMonth() < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
    }-${new Date().getDate()}`;

  return (
    <main className={classes.pageContent}>
      <div className={classes.toolbar} />
      {/* <Typography variant="h6" style={{ marginBottom: "10px" }}>
        Purchase Order Details
      </Typography> */}
      <h3 style={{ marginBottom: '20px' }}>Purchase Order Details :</h3>
      <PODetails poDetails={poDetails} />

      <div style={{ marginBottom: '20px' }}>
        <Grid container justify="space-between">
          <Grid>
            <Breadcrumbs aria-label="breadcrumb">
              <h3>
                <Link color="textPrimary" href="/" onClick={handleClick}>
                  All Dispatch Plans
                </Link>
              </h3>
              {/* <Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
                Core
              </Link>
              <Link
                color="textPrimary"
                href="/components/breadcrumbs/"
                onClick={handleClick}
                aria-current="page"
              >
                Breadcrumb
              </Link> */}
            </Breadcrumbs>
          </Grid>
          {currentUser?.user_type !== USER_TYPES.SALES_PERSON && (
            <Grid>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={() => {
                  history.push(`/create-dispatch-plan/${id}`);
                }}
              >
                Create Dispatch Plan
              </Button>
            </Grid>
          )}
        </Grid>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Grid container justify="space-between">
          <Grid>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <SearchIcon
                style={{
                  position: 'absolute',
                  right: 10,
                  top: 13,
                  width: 50,
                  height: 20,
                }}
              />
              <TextField
                id="outlined-basic"
                size="small"
                style={{ backgroundColor: '#fff' }}
                type="date"
                defaultValue={todaysDate}
                placeholder="Search anything"
                onChange={(e) => fetchDispatchPlansByDate(e.target.value)}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid>
            <ButtonGroup aria-label="outlined button group">
              <Button
                style={
                  planStatus == STATUS.PENDING
                    ? { color: '#fff', backgroundColor: '#333996' }
                    : { backgroundColor: '#fff' }
                }
                onClick={() => {
                  fetchDispatchPlans(STATUS.PENDING);
                }}
              >
                {STATUS_TEXT.PENDING}
              </Button>
              <Button
                style={
                  planStatus == STATUS.APPROVED
                    ? { color: '#fff', backgroundColor: '#333996' }
                    : { backgroundColor: '#fff' }
                }
                onClick={() => {
                  fetchDispatchPlans(STATUS.APPROVED);
                }}
              >
                {STATUS_TEXT.APPROVED}
              </Button>
              <Button
                style={
                  planStatus == STATUS.REJECTED
                    ? { color: '#fff', backgroundColor: '#333996' }
                    : { backgroundColor: '#fff' }
                }
                onClick={() => {
                  fetchDispatchPlans(STATUS.REJECTED);
                }}
              >
                {STATUS_TEXT.REJECTED}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </div>
      {/* comment popup */}
      <Dialog
        // fullScreen={fullScreen}
        // maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.textCenter}>{'Add Comments'}</DialogTitle>
        <Divider light></Divider>
        <DialogContent className={classes.textCenter}>
          <div style={{ minHeight: '300px', minWidth: '300px', marginTop: '20px' }}>
            <Grid constainer justify="center" spacing={2}>
              <Grid item xs={12}>
                <TextField placeholder="Type here..." variant="outlined"></TextField>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '150px' }}>
                <Button color="primary" variant="contained" style={{ width: '200px' }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      <div style={{ marginBottom: '25px' }}>
        <Grid container spacing={2}>
          {plans.length === 0 ? (
            <Grid item xs={12} md={12}>
              <div style={{ marginBottom: '10px', textAlign: 'center' }}>{'No data available'}</div>
            </Grid>
          ) : (
            plans.map((plan) => {
              return (
                <Grid item xs={12} md={12}>
                  <Paper style={{ padding: '14px' }}>
                    <div style={{ marginBottom: '10px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <div>
                            <div className={classes.textLight} style={{ marginBottom: '5px' }}>
                              {formatDate(plan.created)}
                            </div>
                            <div className={classes.textDark}>{plan.dealer_name}</div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <div>
                            <div className={classes.textLight} style={{ marginBottom: '5px' }}>
                              Dispatch Ref. No.
                            </div>
                            <div className={classes.textDark} style={{ textTransform: 'uppercase' }}>
                              {plan.ref_no}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <div>
                            <div className={classes.textLight} style={{ marginBottom: '5px' }}>
                              Sales Order
                            </div>
                            <div className={classes.textDark} style={{ textTransform: 'uppercase' }}>
                              {plan.sap_sales_order_id || '   --'}&nbsp;&nbsp;
                              {plan.sales_order_filename && (
                                <Link
                                  onClick={async () => {
                                    let salesOrderData = await getSalesOrderFile(plan.sales_order_filename);
                                    // console.log(salesOrderData)
                                    const blob = new Blob([salesOrderData], { type: 'application/pdf' });
                                    let a = document.createElement('a');
                                    a.href = window.URL.createObjectURL(blob);
                                    a.download = `${plan.sales_order_filename || 'SalesOrder'}`;
                                    a.click();
                                    // console.info("I'm a button.");
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <AttachFileIcon fontSize="small" />
                                </Link>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <div>
                            <div className={classes.textLight} style={{ marginBottom: '5px' }}>
                              Materials
                            </div>
                            <div className={classes.textDark}>
                              <Link href={'/dispatch-plan-details/' + plan.id}>{plan.total_materials}</Link>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <div>
                            <div className={classes.textLight} style={{ marginBottom: '5px' }}>
                              Quantity
                            </div>
                            <div className={classes.textDark}>{plan.total_quantity}</div>
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={2}>
                          <div>
                            <div className={classes.textLight} style={{ marginBottom: '5px' }}>
                              Total Rate
                            </div>
                            <div className={classes.textDark}>&#8377;&nbsp;{plan.total_rate}</div>
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <div>
                            <div className={classes.textDark}>{returnStatus(plan.status)}</div>
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <ActionButton color="primary">
                            <Tooltip title="View Dispatch Plan Details">
                              <VisibilityIcon onClick={(e) => viewUrl(plan.id)} fontSize="small" />
                            </Tooltip>
                          </ActionButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              );
            })
          )}
        </Grid>
      </div>
    </main>
  );
};

export default DispatchPlan;
