import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getCreditLimitByOrgId(body) {
  const d = await axios.get(
    SERVERBASEURL + `/credit_limits/get`,
    config
  );
  return d;
}

export async function insertCreditLimit(data) {
  const d = await axios.post(
    SERVERBASEURL + "/credit_limit",
    data,
    config
  );
  return d;
}

export async function updateCreditLimit(data) {
  const d = await axios.put(
    SERVERBASEURL + `/credit_limit/${data.credit_id}`,
    data,
    config
  );
  return d;
}
