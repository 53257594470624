import { useState } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
    Grid,
    makeStyles,
    Paper,
    Typography,
    Button,
    Avatar,
    Link,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Input from "../components/controls/Input";
import LogoBlack from "../assets/electrotherm-logo-black.png";
import Notifications from "../components/Notifications";

import { Headers } from "../constants/index";

const SERVERBASEURL = process.env.REACT_APP_API;

const initialValues = {
    email: "",
};
const useStyles = makeStyles((theme) => ({
    paperStyle: {
        padding: 20,
        margin: "50px auto",
        height: "70vh",
        width: 350,
    },
    avatarStyle: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: "30px",
        marginTop: "30px",
    },
    btnStyle: {
        margin: "30px 0",
    },
    fields: {
        margin: theme.spacing(1),
    },
}));

const Login = () => {
    const [values, setValues] = useState(initialValues);
    const [login, setLogin] = useState(false);
    const classes = useStyles();
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [errors, setErrors] = useState({
        password: '',
        confirm_password: ''
    })
    const history = useHistory();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = (fieldValues = values) => {
        console.log('---', fieldValues)
        let temp = { ...errors };

        if ("password" in fieldValues) {
            temp.password = "";
            if (fieldValues.password === "" || fieldValues.password === undefined) {
                temp.password = "Password is required";
            } else if (
                fieldValues.password &&
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(
                    fieldValues.password
                )
            ) {
                temp.password =
                    "Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet";
            } else if (fieldValues.password && fieldValues.password.length > 100) {
                temp.password = "Password should be max 100 characters long";

            }
        }

        if ("confirm_password" in fieldValues) {
            temp.confirm_password = "";
            console.log(fieldValues.confirm_password, "=", values.password);
            if (
                fieldValues.confirm_password === "" ||
                fieldValues.confirm_password === undefined
            ) {
                temp.confirm_password = "Confirm Password is required";
            } else if (
                fieldValues.confirm_password &&
                fieldValues.confirm_password != values.password
            ) {
                temp.confirm_password =
                    "The two passwords that you entered do not match!";
            }
        }

        // if ("organizationId" in fieldValues)
        //   temp.organizationId = Number(fieldValues.organizationId)
        //     ? ""
        //     : "Minimum 1 numbers required";

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const { token } = useParams()
    const handleSubmit = () => {
        if (validate()) {
            axios.post(`${process.env.REACT_APP_API}/forgetPassword`, { "newPass": values.password, "resetLink": token }).then(res => {
                if (res.data.statusCode === 200) {
                    setNotify({
                        isOpen: true,
                        message: res.data.message,
                        type: "success"
                    })
                    history.push('/')
                }
                else if (res.data.statusCode === 400) {
                    setNotify({
                        isOpen: true,
                        message: res.data.message,
                        type: "error"
                    })
                }
            }).catch(err => {
                // what now?
                console.log(err, 'error');
                setNotify({
                    isOpen: true,
                    message: "Something went wrong",
                    type: "error"
                })
            })
        }
    }
    return (
        <Grid>
            <Paper elevation={10} className={classes.paperStyle}>
                <Grid align="center">
                    <img
                        style={{
                            width: "150px",
                            marginLeft: "1.5em",
                            marginTop: "5px",
                            marginRight: "1em",
                            marginBottom: "15px",
                        }}
                        className="align-center"
                        src='/logo.png'
                    />
                    {/* <Avatar className={classes.avatarStyle}>
            <LockOutlinedIcon />
          </Avatar> */}
                    <Typography variant="h5">Forget Password</Typography>
                </Grid>
                {/* <form
                    // noValidate
                    autoComplete="off"
                    className={classes.form}
                // onSubmit={handleFormSubmit}
                > */}
                <Input
                    className={classes.fields}
                    fullWidth
                    required
                    label=" New Password"
                    name="password"
                    type="password"
                    error={errors.password}
                    value={values.password}
                    onChange={handleInputChange}
                    placeholder="Enter Password"
                ></Input>
                <Input
                    className={classes.fields}
                    fullWidth
                    required
                    label="Confirm New Password"
                    name="confirm_password"
                    type="password"
                    error={errors.confirm_password}
                    value={values.confirm_password}
                    onChange={handleInputChange}
                    placeholder="Enter Confirm Password"
                ></Input>
                <Button
                    className={classes.btnStyle}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                >
                    Reset Password
          </Button>
                {/* </form> */}
                <p>
                    Remember password? <Link href="/">Login</Link>
                </p>
            </Paper>
            {/* {token && <Redirect to="/dashboard" />} */}
            <Notifications notify={notify} setNotify={setNotify} />
        </Grid>
    );
};

export default Login;
