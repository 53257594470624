import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getAllDiscounts() {
  let d = await axios.get(
    SERVERBASEURL + "/discounts",
    config
  );
  return d;
}

export async function getAllDiscountsForOrganization(orgId) {
  let d = await axios.get(
    SERVERBASEURL + `/discounts/organization/${orgId}`,
    config
  );
  return d;
}

export async function getAllDiscountsForProcessing() {
  const d = await axios.get(
    SERVERBASEURL + "/discount/getAllDiscounts",
    config
  );
  return d;
}

export async function insertDiscount(data) {
  const d = await axios.post(
    SERVERBASEURL + "/discount",
    JSON.stringify(data),
    config
  );
  return d;
}

export async function updateDiscount(data) {
  const d = await axios.put(
    SERVERBASEURL + `/discount/${data.id}`,
    data,
    config
  );
  return d;
}

export async function deleteDiscount(id) {
  const d = await axios.delete(
    SERVERBASEURL + `/discount/${id}`,
    config
  );
  return d;
}

export async function disableDiscount(id) {
  const d = await axios.get(
    SERVERBASEURL + `/discount/disable/${id}`,
    config
  );
  return d;
}

export async function getDiscountForEachMaterialInPurchaseOrder(materialQtyRateMap, org_id, orderBookingId) {
  const _configs = {
    ...config,
    params: materialQtyRateMap
  }
  const d = await axios.get(
    SERVERBASEURL + `/discount_for_each_material/${org_id}/${orderBookingId}`,
    _configs
  );
  return d;
}

