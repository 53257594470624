import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getOrderBookings(org_id) {
  const orderBookingList = await axios.get(
    SERVERBASEURL+"/order_booking_for_distributor/" + org_id,
    config
  );
  console.log(orderBookingList.data.data);
  return orderBookingList.data!=null?orderBookingList.data.data:[];
}

export async function getTodaysBookingQuantity() {
  const dispatchQuantity = await axios.get(
    SERVERBASEURL+`/todays-booking-quantity`,
    config
  );
  // console.log("getTodaysBookingQuantity"+JSON.stringify(dispatchQuantity));
  return dispatchQuantity.data.data;
}