import {
  Card as MuiCard,
  CardActionArea,
  CardContent,
} from "@material-ui/core";

const Card = ({ children, classNames }) => {
  return (
    <MuiCard className={classNames}>
      <CardActionArea>
        <CardContent>{children}</CardContent>
      </CardActionArea>
    </MuiCard>
  );
};

export default Card;
