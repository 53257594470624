import { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
  TableSortLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    maxWidth: "350",
    "& thead th": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
}));

export default function useTable(records, headCells, filterFn, rowsPerPageOptions = [5, 10, 25]) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const classes = useStyles(0);
  const pages = rowsPerPageOptions || [5, 10, 25];
  console.log('onTable', order, orderBy, rowsPerPage, page)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TableContainer = ({ children }) => (
    <Table className={classes.table}>{children}</Table>
  );
  console.log('headcells', headCells)

  const TableHeader = () => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((head) => (
            <TableCell
              key={head.id}
              sortDirection={orderBy === head.id ? order : false}
            >
              {head.disableSorting ? (
                head.label
              ) : (
                <TableSortLabel
                  active={orderBy === head.id}
                  direction={orderBy === head.id ? order : "asc"}
                  onClick={(e) => {
                    handleSortRequest(head.id);
                  }}
                >
                  {head.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const TablePaginations = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  const getComparoter = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  };

  const stableSort = (recordsArray, comparater) => {
    const stabilizeThis = recordsArray.map((el, index) => [el, index]);
    stabilizeThis.sort((a, b) => {
      const order = comparater(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizeThis.map((el) => el[0]);
  };

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparoter(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TableContainer,
    TableHeader,
    TablePaginations,
    recordsAfterPagingAndSorting,
  };
}
