import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getGradeList() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const materialList = await axios.get(
    SERVERBASEURL + "/material-grade-rates",
    config
  );
  return materialList;
}

export async function insertGrade(data) {
  const savedStatus = await axios.post(
    SERVERBASEURL + "/material-grade-rate",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateGrade(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/material-grade-rate/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function deleteGrade(id) {
  const deleteStatus = await axios.delete(
    SERVERBASEURL + `/material-grade-rate/${id}`,
    config
  );
  return deleteStatus;
}

