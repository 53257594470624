import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  Tooltip,
  IconButton,
  InputAdornment,
  Chip,
  Select as MaterialUISelect,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { Dialog } from "../../components/Dialog";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import ActionButton from "../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { getPurchaseOrderList, setPurchaseOrderStatus, getContractFile } from "../../services/PurchaseOrder";
import Input from "../../components/controls/Input";
import { Search } from "@material-ui/icons";

import { lighten, makeStyles } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from 'moment'
import { getCurrentUser } from '../../services/userService'
import { Redirect } from "react-router-dom";
import { STATUS, STATUS_TEXT, USER_TYPES } from "../../constants";
import { formatDate } from "../../helpers/helperFunctions";
import Select from "../../components/controls/Select";
import EditContractDetails from "./EditContractDetails";
import Notifications from "../../components/Notifications";

function createData(id, date, distributor, ref_no, contract_id, contract_filename, dispatch_orders, status, created_by, created_by_uid, org_name) {
  return { id, date, distributor, ref_no, contract_id, contract_filename, dispatch_orders, status, created_by, created_by_uid, org_name };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const cssStyles = ((theme) => ({
  pageContent: {
    // margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,

  searchInput: {
    width: "58%",
    marginLeft: "18px",
    marginRight: "12px"
  },
  exportButton: {
    position: "relative",
    fontSize: "12px"
  },
  bookOrderButton: {
    position: "relative",
    fontSize: "12px",
    whiteSpace: "nowrap"
  },
  acceptButton: {
    position: "relative",
    fontSize: "12px",
    whiteSpace: "nowrap",
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: "#139018",
    },
  },
  rejectButton: {
    position: "relative",
    fontSize: "12px",
    whiteSpace: "nowrap"
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "2px",
    margin: "5px",
    fontSize: "12px",
  },
}));
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "organizations_name",
    numeric: true,
    disablePadding: false,
    label: "Organizations Name",
  },
  { id: "ref_no", numeric: true, disablePadding: false, label: "Ref No" },
  {
    id: "contract_id",
    numeric: true,
    disablePadding: false,
    label: "Contract ID",
  },
  {
    id: "dispatch_order",
    numeric: true,
    disablePadding: false,
    label: "Dispatch Orders",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,

  textCenter: {
    textAlign: "center",
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
  },
}));


const OrderList = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    rows: [],
    stableData: []
  });


  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });


  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openPopUpReject, setopenPopUpReject] = React.useState(false)
  const [filterSelected, setFilterSelected] = useState(-1);
  const [openContractPopUp, setOpenContractPopUp] = useState(false);
  const [poForContract, setPoForContract] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [rowData, setRowData] = React.useState([])

  const [rejectReason, setrejectReason] = React.useState('')
  const [orderId, setOrderId] = useState()
  const [user, setUser] = useState()
  const [currentUser, serCurrentUser] = useState()
  const [loading, setLoading] = useState(false)
  const [errors, seterrors] = useState({
    rejectReason: ''
  })
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let pid = params.get('pid');
    var count = state.rows.findIndex(row => row.id === parseInt(pid));
    setPage(parseInt(count / rowsPerPage))
  }, [state])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleContractClose = () => {
    setOpenContractPopUp(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCurrentUserFunc = async () => {
    setLoading(true)
    const user = await getCurrentUser()
    if (user && user.data) {
      serCurrentUser(user.data.body)
    }
    setLoading(false)
  }
  const handleSearch = (e) => {
    let target = e.target;
    let res
    if (target.value === "") {
      res = state.stableData
    } else {
      res = state.stableData.filter((val) => {
        return val.org_name.toLowerCase()
          .includes(target.value.toLowerCase()) ||
          val.ref_no.includes(target.value)
      })
    }
    setState({ ...state, rows: res })
  };


  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, state.rows.length - page * rowsPerPage);
  const funcGetPurchaseOrderList = async (filterStatus = -1) => {

    setLoading(true)
    try {
      const PurchaseOrderList = await getPurchaseOrderList();

      const poList = PurchaseOrderList.data.data.data;
      setUser(PurchaseOrderList.data.user)
      let rows = [];
      let status = "";
      for (let [key, value] of poList.entries()) {
        // let dispatchCount = awai(value.id);
        // var dispatchOrdersCount = dispatchCount[0].total_dispatch_count;
        // console.log("------"+JSON.stringify(dispatchCount[0].total_dispatch_count));
        setFilterSelected(filterStatus);
        if (filterStatus != -1 && value.status != filterStatus) {
          continue;
        }
        if (value.status === STATUS.PENDING) {
          status = STATUS_TEXT.PENDING;
        } else if (value.status === STATUS.REJECTED) {
          status = STATUS_TEXT.REJECTED;
        } else if (value.status === STATUS.APPROVED) {
          status = STATUS_TEXT.APPROVED;
        } else if (value.status === STATUS.IN_PROGRESS) {
          status = STATUS_TEXT.IN_PROGRESS;
        } else if (value.status === STATUS.COMPLETED) {
          status = STATUS_TEXT.COMPLETED;
        }
        rows.push(createData(value.id, value.created, value.name, value.ref_no, value.sap_contract_id, value.contract_filename, value.total_dispatch_count, status, value.created_by, value.created_by_uid, value.org_name));
      }

      setState((state) => {
        return {
          ...state,
          rows: rows,
          stableData: rows
        };
      });
    } catch (err) {
      history.push('/')
    }

    setLoading(false)
  };
  useEffect(() => {

    getCurrentUserFunc()
    funcGetPurchaseOrderList();

  }, []);
  const updateStatusOfOrder = async (orderid, status, reason_for_rejection, row) => {
    const action_by_uid = localStorage.getItem('user_type')
    const requestData = {
      id: orderid,
      action_by_uid: action_by_uid,
      reason_for_rejection: reason_for_rejection,
      status: status,
      created_by_uid: row.created_by_uid
    }

    try {
      const res = await setPurchaseOrderStatus(requestData)
      funcGetPurchaseOrderList()
    }
    catch (err) {
      console.log(err)
    }

  }
  const openPopupReject = (orderId, row) => {
    setopenPopUpReject(true)
    setOrderId(orderId)
    setRowData(row)
  }
  const orderStatusRenderCode = (value, orderId, createdByUserType, row) => {
    const { classes } = props;
    const userType = currentUser?.user_type
    if (value === "Pending" && (userType !== createdByUserType) && currentUser?.status === 1 && currentUser.user_type !== USER_TYPES.SALES_PERSON) {
      return (<div style={{ display: "inline-grid" }}>
        <Button
          text="Accept"
          variant="contained"
          // className={classes.acceptButton}
          style={{
            position: "relative",
            height: '30px',
            fontSize: "12px",
            whiteSpace: "nowrap",
            backgroundColor: '#4caf50',
            '&:hover': {
              backgroundColor: "#139018",
            },
          }}
          color="secondary"
          onClick={(e) => { updateStatusOfOrder(orderId, 3, '', row); }}
        >Approve</Button>
        <Button
          text="Reject"
          variant="contained"
          // className={classes.rejectButton}
          style={{
            position: "relative",
            height: '30px',
            fontSize: "12px",
            whiteSpace: "nowrap"
          }}
          color="secondary"
          onClick={(e) => { openPopupReject(orderId, row) }}
        >Reject</Button>
      </div>
      )
    }
    else {
      return (<Chip label={value} />);
    }
  }
  const editUrl = (id) => {
    history.push("/order/action/" + id);
  };

  const viewUrl = (id) => {
    history.push("/order/view/" + id);
  };
  const closePopup = () => {
    setrejectReason('')
    setopenPopUpReject(false)
  }

  const handleContractPopUpOpen = id => {
    // setRecordForEdit(null);
    setPoForContract(id)
    setOpenContractPopUp(true);
  };

  const renderFilterOptions = () => {
    var options = [];
    options.push(<MenuItem key={-1} value={-1}>
      All POs
    </MenuItem>);
    for (const key in STATUS) {
      if (STATUS.hasOwnProperty(key)) {
        const element = STATUS[key];
        options.push(<MenuItem key={element} value={element}>
          {STATUS_TEXT[key]}
        </MenuItem>);
      }
    }
    return options;
  };

  return (
    <main className={classes.pageContent}>
      <div className={classes.toolbar} />
      <div style={{ marginBottom: "25px", marginTop: '10px' }}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item container xs={12} sm={6} md={6} lg={6} spacing={1}>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Purchase Orders
            </Typography>
          </Grid>
          {currentUser && <> {currentUser.status === 1 && currentUser.user_type !== USER_TYPES.SALES_PERSON &&
            <Grid>
              {/* //  item container xs={12} sm={5} md={5} lg={4} spacing={0}> */}
              {/* <Grid item xs={5}>
              <Button variant="contained">Export</Button>
            </Grid> */}
              {/* <Grid item xs={7}> */}
              <Link to="/order/action">
                <Button color="primary" variant="contained">
                  Create Order
                </Button>
              </Link>
              {/* </Grid> */}
            </Grid>
          }</>}
        </Grid>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Grid container justify="space-between" spacing={0}>
          <Grid >
            <div style={{ position: "relative", display: "inline-block" }}>
              <Input
                label="Search by Organization name or reference number"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </div>
          </Grid>
          <Grid>
            <FormControl variant="outlined"
              style={{ marginTop: '5px' }}
            >
              <InputLabel id="demo-mutiple-name-label">Filters</InputLabel>
              <MaterialUISelect
                labelId="demo-mutiple-name-label"
                name="Filters"
                label="Filters"
                value={filterSelected}
                MenuProps={MenuProps}
                onChange={(e) => { funcGetPurchaseOrderList(e.target.value) }}
              >
                {renderFilterOptions()}
              </MaterialUISelect>
            </FormControl>
            {/* <ButtonGroup aria-label="outlined button group">
              <Button 
                style={filterSelected== -1 ? {color: '#fff', backgroundColor: '#333996'}: { backgroundColor: '#fff' }} 
                onClick={()=>{ funcGetPurchaseOrderList(-1) }}
              >
                ALL
              </Button>
              <Button 
                style={filterSelected==STATUS.PENDING? {color: '#fff', backgroundColor: '#333996'}: { backgroundColor: '#fff' }} 
                onClick={()=>{ funcGetPurchaseOrderList(STATUS.PENDING) }}
              >
                {STATUS_TEXT.PENDING}
              </Button>
              <Button 
                style={filterSelected==STATUS.APPROVED? {color: '#fff', backgroundColor: '#333996'}: { backgroundColor: '#fff' }} 
                onClick={()=>{ funcGetPurchaseOrderList(STATUS.APPROVED) }}
              >
                {STATUS_TEXT.APPROVED}
              </Button>
              <Button 
                style={filterSelected==STATUS.IN_PROGRESS? {color: '#fff', backgroundColor: '#333996'}: { backgroundColor: '#fff' }} 
                onClick={()=>{ funcGetPurchaseOrderList(STATUS.IN_PROGRESS) }}
              >
                {STATUS_TEXT.IN_PROGRESS}
              </Button>
              <Button 
                style={filterSelected==STATUS.REJECTED? {color: '#fff', backgroundColor: '#333996'}: { backgroundColor: '#fff' }} 
                onClick={()=>{ funcGetPurchaseOrderList(STATUS.REJECTED) }}
              >
                {STATUS_TEXT.REJECTED}
              </Button>
            </ButtonGroup> */}
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}>
        <Paper className={classes.container}>
          {loading === false ? <> <TableContainer style={{ textAlign: "center" }}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={state.rows.length}
              />
              <TableBody>
                {stableSort(state.rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      /*
                      onClick={()=>{
                         history.push('/create-dispatch-plan/'+row.id)
                      }}
                      */
                      >
                        <TableCell component="th" id={labelId} scope="row">
                          {/* {moment(row.date).format('DD:MM:yyyy')} */}
                          {formatDate(row.date)}
                        </TableCell>
                        <TableCell align="right">{row.org_name}</TableCell>
                        <TableCell align="right">{row.ref_no.toUpperCase()}</TableCell>
                        <TableCell align="right">
                          {row.contract_id || "--"}&nbsp;&nbsp;
                          {row.contract_filename && currentUser?.user_type === "admin" &&
                            <Link
                              onClick={async () => {
                                let contractData = await getContractFile(row.contract_filename);
                                const blob = new Blob([contractData], { type: 'application/pdf' })
                                let a = document.createElement('a');
                                a.href = window.URL.createObjectURL(blob);
                                a.download = `${row.contract_filename || 'Contract'}`
                                a.click();
                              }}
                              style={{ 'cursor': 'pointer' }}
                            >
                              <AttachFileIcon fontSize="small" />
                            </Link>
                          }
                        </TableCell>
                        <TableCell align="right">
                          {row.dispatch_orders !== null ?
                            (row.status === STATUS_TEXT.APPROVED || row.status === STATUS_TEXT.IN_PROGRESS || row.status === STATUS_TEXT.COMPLETED)
                              ? <Link to={`/dispatch-plans/${row.id}`} >
                                {row.dispatch_orders}
                              </Link>
                              : row.dispatch_orders
                            : 0}
                        </TableCell>
                        <TableCell align="right">{orderStatusRenderCode(row.status, row.id, row.created_by, row)}</TableCell>
                        <TableCell>
                          {(row.status !== STATUS_TEXT.APPROVED && row.status !== STATUS_TEXT.IN_PROGRESS && row.status !== STATUS_TEXT.COMPLETED) && row.created_by_uid === user.id && <ActionButton color="primary">
                            <EditOutlinedIcon
                              onClick={(e) => editUrl(row.id)}
                              fontSize="small"
                            />
                          </ActionButton>}
                          <ActionButton color="primary">
                            <VisibilityIcon
                              onClick={(e) => viewUrl(row.id)}
                              fontSize="small"
                            />
                          </ActionButton>
                          {(row.status === STATUS_TEXT.APPROVED) && (currentUser.user_type === USER_TYPES.ADMIN || currentUser.user_type === USER_TYPES.SALES_PERSON) && <ActionButton color="primary">
                            {/* <ActionButton color="primary"> */}
                            <SettingsIcon
                              onClick={e => handleContractPopUpOpen(row.id)}
                              fontSize="small"
                            />
                          </ActionButton>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: state.rows.length }]}
              component="div"
              count={state.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /></> : <>
            <CircularProgress />
          </>}
          <Dialog
            heading="Reason For Rejection"
            handleClose={(e) => { setopenPopUpReject(false) }}
            open={openPopUpReject}
            width="sm"
          >
            {errors.rejectReason === null || errors.rejectReason !== '' && <Chip color='secondary' label={errors.rejectReason} />}
            {/* {this.state.orderStatusError != null && this.state.orderStatusError.length > 0 && <Chip label={this.state.orderStatusError} />} */}
            <TextField
              required
              multiline
              rows={4}
              name="rejectionReason"
              value={rejectReason}
              label="Reason For Rejection"
              style={{ width: "100%", fontSize: "12px" }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {

                setrejectReason(e.target.value)
                // const { name, value } = e.target;
                // var kv = {};
                // kv[name] = value;
                // kv["orderStatusError"] = null;
                // this.setState({ ...kv });
              }}
            />
            <Grid item>
              <Button text="Submit"
                type="submit"
                color="primary"
                style={{ marginTop: '1em' }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault()
                  if (rejectReason === '' && !rejectReason) {
                    seterrors({ ...errors, rejectReason: 'Rejection Reason is required' })
                  } else {
                    updateStatusOfOrder(orderId, 2, rejectReason, rowData)
                    seterrors({
                      rejectReason: ''
                    })
                    closePopup()
                    // setopenPopUpReject(false)
                  }
                  // e.preventDefault();
                  // console.log("this.state.rejectionReason ==> [" + this.state.rejectionReason + "]");
                  // if (this.state.rejectionReason == null || this.state.rejectionReason.trim().length < 1) {
                  //   console.log("Inside!!");
                  //   this.setState({ orderStatusError: "Please give a reason for rejecting this order booking" });
                  // } else {
                  //   this.updateStatusOfOrderBooking(this.state.rejectDialogData.order_booking_id, "rejected");
                  // }
                }} >Submit</Button>
            </Grid>

          </Dialog>
          <Grid item xs={12}>
            <Dialog
              heading="Edit Contract Details"
              handleClose={handleContractClose}
              open={openContractPopUp}
              width="md"
            >
              <EditContractDetails
                poId={poForContract}
                handleClose={handleContractClose}
                setNotify={setNotify}
                updatePOList={funcGetPurchaseOrderList}
              />
            </Dialog>
          </Grid>
        </Paper>
      </div>
      <Notifications notify={notify} setNotify={setNotify} />
      {currentUser === null && <Redirect to="/" />}
    </main>
  );
};

export default withStyles(cssStyles)(OrderList);
