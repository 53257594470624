import { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import { useForm, Form } from "../../components/useForm";
import Select from "../../components/controls/Select";

const initialFormValues = {
  size_name: "",
  rate: "",
  status: 1
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function DiscountForm({
  addOrEdit,
  recordForEdit,
}) {
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // eslint-disable-next-line no-unused-vars
  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit !== null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);
  const sizeStatus = [
    { id: 1, title: "Active" },
    { id: 0, title: "Inactive" },
  ];

  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Input
              required
              name="size_name"
              value={values.size_name}
              label="Name"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              required
              name="rate"
              value={values.rate}
              label="Price"
              type='number'
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              required
              name="south_gujarat_rate"
              value={values.south_gujarat_rate}
              label="South Gujarat Rate"
              type='number'
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              required
              name="other_state_parity"
              value={values.other_state_parity}
              label="Other State Parity"
              type='number'
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              required
              name="epoxy_special_rate"
              value={values.epoxy_special_rate}
              label="Epoxy Special Rate"
              type='number'
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={sizeStatus}
            />
          </Grid>
          <Grid item>
            <Button text="Submit" type="submit" />
            <Button
              text="Reset"
              type="button"
              color="default"
              onClick={resetForm}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
