import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import { useForm, Form } from "../../components/useForm";
import Select from "../../components/controls/Select";
import { Select as MaterialUISelect, MenuItem, InputLabel, FormHelperText, FormControl} from '@material-ui/core';

import {getMaterialType} from "../../services/materialService";

const initialFormValues = {
  type_name: "",
  rate: "",
  dependency: "",
  dependent_on: [],
  custom_length: "",
  apply_grade_rates: 1,
  apply_size_rates: 1,
  has_individual_sizes: "",
  status: 1
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function TypeForm({
  materials,
  grades,
  sizes,
  types,
  addOrEdit,
  recordForEdit,
}) {
  const [typeOptions, setTypeOptions] = useState([]);
  const [dependent_on, setDependentOn] = useState([]);
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // eslint-disable-next-line no-unused-vars
  const {
    values,
    errors,
    setErrors,
    setValues,
    resetForm,
    handleInputChange,
  } = useForm(initialFormValues, true, validate);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit({...values, dependent_on}, resetForm);
    }
  };

  const fetchTypes = async () => {
    const materialLists = await getMaterialType();
    const mLists = materialLists.data.data;
    console.log("###")
    console.log(mLists);
    setTypeOptions(mLists);
  };

  useEffect(() => {
    fetchTypes();
    if (recordForEdit !== null){
      // console.log("use"+recordForEdit.dependent_on)
      if (recordForEdit.dependent_on){
        setDependentOn(JSON.parse("["+recordForEdit['dependent_on']+"]"));
      }
      // console.log("dependent"+dependent_on)
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit, setValues]);
  const typeStatus = [
    { id: 1, title: "Active" },
    { id: 0, title: "Inactive" },
  ];
  const typeDependency = [
    { id: 1, title: "Dependent" },
    { id: 0, title: "Independent" },
  ];
  const customLength = [
    { id: 1, title: "Yes" },
    { id: 0, title: "No" },
  ];
  const applySizeRates = [
    { id: 1, title: "Applicable" },
    { id: 0, title: "Not Applicable" },
  ];
  const applyGradeRates = [
    { id: 1, title: "Applicable" },
    { id: 0, title: "Not Applicable" },
  ];
  const individualSizes = [
    { id: 1, title: "Yes" },
    { id: 0, title: "No" },
  ];
  return (
    <div className={classes.root}>
      <Form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Input
              required

              name="type_name"
              value={values.type_name}
              label="Name"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              required
              name="rate"
              value={values.rate}
              label="Price"
              type='number'
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              name="dependency"
              label="Dependency"
              value={values.dependency}
              onChange={handleInputChange}
              options={typeDependency}
            />
          </Grid>
          {values.dependency === 1 &&
          <Grid item md={6} xs={12}>
            <FormControl variant="outlined" 
            // {...(error && { error: true })}
            >
              <InputLabel id="demo-mutiple-name-label">Dependent On</InputLabel>
              <MaterialUISelect
                labelId="demo-mutiple-name-label"
                name="dependent_on"
                multiple
                // label="Distributors"
                // value={JSON.parse("[" + values.dependent_on + "]")}
                value ={dependent_on}
                // value={JSON.parse("[" + values.dependent_on + "]")}
                // value={values.dependent_on}
                // onChange={handleInputChange}
                onChange={e=>{
                  // console.log("onnnn"+dependent_on)
                  // console.log("onchange"+e.target.value)
                  setDependentOn(e.target.value);
                }}
                MenuProps={MenuProps}
              >
                {/* <MenuItem key={0} value={0}>ALL Distributors</MenuItem>
                <MenuItem key={1} value={1}> Distributors</MenuItem> */}
                {typeOptions.length > 0 && typeOptions.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {`${type.type_name}`}
                  </MenuItem>
                ))}
              </MaterialUISelect>
              {/* {error && <FormHelperText>{error}</FormHelperText>} */}
            </FormControl>
          </Grid>
          }
          {/* <Grid item md={6} xs={12}>
            <Select
              required
              name="dependent_on"
              value={values.dependent_on}
              label="Dependent Upon"
              // type='number'
              onChange={handleInputChange}
              options={typeDependency}
            />
          </Grid> */}
          <Grid item md={6} xs={12}>
            <Select
              required
              name="custom_length"
              value={values.custom_length}
              label="Custom Length"
              // type='number'
              onChange={handleInputChange}
              options={customLength}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              required
              name="apply_grade_rates"
              value={values.apply_grade_rates}
              label="Grade Rates"
              // type='number'
              onChange={handleInputChange}
              options={applyGradeRates}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              required
              name="apply_size_rates"
              value={values.apply_size_rates}
              label="Size Rates"
              // type='number'
              onChange={handleInputChange}
              options={applySizeRates}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              required
              name="has_individual_sizes"
              value={values.has_individual_sizes}
              label="Has Individual Sizes"
              // type='number'
              onChange={handleInputChange}
              options={individualSizes}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              options={typeStatus}
            />
          </Grid>
          <Grid item>
            <Button text="Submit" type="submit" />
            <Button
              text="Reset"
              type="button"
              color="default"
              onClick={resetForm}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
