import React from 'react';
import axios from 'axios';
import { Grid, InputAdornment, } from '@material-ui/core';
import Input from '../../components/controls/Input';
import Button from '../../components/controls/Button';
import { Form } from '../../components/useForm';
import { FormControl } from '@material-ui/core';
import { getCurrentUser } from '../../services/userService';
import { getPurchaseOrderDetailsForView, } from '../../services/PurchaseOrder';
import { withRouter } from "react-router";

const SERVERBASEURL = process.env.REACT_APP_API;

class UploadInvoiceData extends React.Component {
    state = {
        currentUser: null,
        selectedPO: "",
        invoiceDataFile: null,
        salesOrderId: null,
    }

    getCurrentUserFunc = async () => {
        let user = await getCurrentUser();
        this.setState({ currentUser: user.data.body });
        return user;
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('invoiceDataFile', this.state.invoiceDataFile);
        var api = {
            method: 'post',
            url: SERVERBASEURL + "/invoice-data-file/",
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        axios(api)
            .then((res) => {
                if (res.data !== undefined && (res.status === 200 || res.status === 201)) {
                    this.props.setNotify({
                        isOpen: true,
                        message: res.data?.message || "Invoice details successfully uploaded !",
                        type: 'success',
                    }
                    );
                }
                this.props.updateDPList();
                this.props.handleClose();
            })
            .catch((err) => {
                this.props.setNotify({
                    isOpen: true,
                    message: `${err?.response?.data?.message || 'Invoice Details Update Unsuccessful !'}`,
                    type: 'error',
                }
                );
                console.log('Error in performing operation', JSON.stringify(err?.response?.data?.message));
                this.props.handleClose();
            });
    };

    fetchPODetails = async () => {
        let response = await getPurchaseOrderDetailsForView(this.props.poId);
        this.setState({ poDetails: response.data.data[0] })
    }

    render() {
        return (
            <div className='root'>
                <Form onSubmit={this.handleFormSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                variant="outlined"
                            >
                                <Input
                                    name={"invoiceDataFile"}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                    type='file'
                                    label='Invoice Data Excel File'
                                    onChange={e => {
                                        this.setState({ invoiceDataFile: e.target.files[0] })
                                    }}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button color="primary" variant="contained"
                                text="Upload Invoice Order Data"
                                onClick={this.handleFormSubmit}
                            />
                        </Grid>
                    </Grid>
                </Form>

            </div>
        );
    }
}

export default withRouter(UploadInvoiceData);