import axios from "axios";
const SERVERBASEURL = process.env.REACT_APP_API;

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};
console.log('config', config)
export async function insertReleaseRate(data) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const savedStatus = await axios.post(
    SERVERBASEURL + "/release-rate",
    data,
    config
  );
  return savedStatus;
}

export async function getHistoryReleaseRate() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const getData = await axios.get(SERVERBASEURL + "/release-rates", config);
  return getData;
}

/**
 * This will only be used for admins from now on since the distributors will have to be sent 
 * release rate based on their state.
 *  */
export async function getReleaseRate(token) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const getData = await axios.get(
    SERVERBASEURL + "/today-release-rates/",
    config
  );
  return getData;
}
export async function approveReleaseRate(id, data) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const approveStatus = await axios.post(
    SERVERBASEURL + `/aprrove-release-rate/${id}`,
    data,
    config
  );
  return approveStatus;
}
export async function updateReleaseRate(id, data) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const approveStatus = await axios.post(
    SERVERBASEURL + `/update-release-rate/${id}`,
    data,
    config
  );
  return approveStatus;
}

export async function getDistributorStats() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const getData = await axios.get(SERVERBASEURL + "/all-distributor-stats", config);
  return getData;
}