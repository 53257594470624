import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getSettings() {
  const d = await axios.get(
    SERVERBASEURL + "/setting",
    config
  );
  return d;
}
