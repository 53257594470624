import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
    Grid,
    makeStyles,
    Paper,
    Typography,
    Button,
    Avatar,
    Link,
    CircularProgress
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Input from "../components/controls/Input";
import LogoBlack from "../assets/electrotherm-logo-black.png";
import Notifications from "../components/Notifications";
import { getCurrentUser, resetPassword } from '../services/userService'
import { Headers } from "../constants/index";

const SERVERBASEURL = process.env.REACT_APP_API;

const initialValues = {
    password: '',
    confirm_password: ''
};
const useStyles = makeStyles((theme) => ({
    paperStyle: {
        padding: 20,
        margin: "50px ",
        height: "70vh",
        width: 550,
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    avatarStyle: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: "30px",
        marginTop: "30px",
    },
    btnStyle: {
        margin: "30px 0",
    },
    fields: {
        margin: theme.spacing(1),
    },
}));

const Login = () => {
    const [values, setValues] = useState(initialValues);
    const [login, setLogin] = useState(false);
    const [currentUser, setCurrentUser] = useState([])
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [errors, setErrors] = useState({
        password: '',
        confirm_password: ''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = (fieldValues = values) => {
        console.log('---', fieldValues)
        let temp = { ...errors };

        // if ("password" in fieldValues) {
        temp.password = "";
        if (fieldValues.password === "" || fieldValues.password === undefined) {
            temp.password = "Password is required";
        } else if (
            fieldValues.password &&
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})/.test(
                fieldValues.password
            )
        ) {
            temp.password =
                "Password should be minimum 8 character long having at least 1 lowercase alphabet, 1 uppercase alphabet";
        } else if (fieldValues.password && fieldValues.password.length > 100) {
            temp.password = "Password should be max 100 characters long";

        }
        // }

        // if ("confirm_password" in fieldValues) {
        temp.confirm_password = "";
        console.log(fieldValues.confirm_password, "=", values.password);
        if (
            fieldValues.confirm_password === "" ||
            fieldValues.confirm_password === undefined
        ) {
            temp.confirm_password = "Confirm Password is required";
        } else if (
            fieldValues.confirm_password &&
            fieldValues.confirm_password != values.password
        ) {
            temp.confirm_password =
                "The two passwords that you entered do not match!";
        }
        // }

        // if ("organizationId" in fieldValues)
        //   temp.organizationId = Number(fieldValues.organizationId)
        //     ? ""
        //     : "Minimum 1 numbers required";

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const handleSubmit = async () => {

        if (validate()) {
            setLoading(true)
            const res = await resetPassword({ id: currentUser.id, email: currentUser.email, password: values.password })
            console.log(res, "response", res.data.data)
            if (res.data.statusCode === 200) {
                setNotify({
                    isOpen: true,
                    message: res.data.body.message,
                    type: "success"
                })
                setValues(initialValues)
                setLoading(false)
                // setShowMessage(true)
                // history.pushState('/')
            }
            else if (res.data.statusCode === 400) {
                setNotify({
                    isOpen: true,
                    message: res.data.body.message,
                    type: "error"
                })
                setLoading(false)
            }
        }
    }
    // const getCurrentUser = async () => {
    //     // const res = await getCurrentUser()
    //     // console.log("Response", res)
    // }
    useEffect(() => {
        let creditLimitByOrgId = async () => {
            let data = await getCurrentUser()
            console.log('data', data)
            setCurrentUser(data.data.body)
        };
        creditLimitByOrgId();
    }, []);
    const token = localStorage.getItem("token");
    return (
        <Grid>
            <Paper className={classes.paperStyle}>
                {!loading ? <> <Grid align="center">
                    {/* <img
                        style={{
                            width: "150px",
                            marginLeft: "1.5em",
                            marginTop: "5px",
                            marginRight: "1em",
                            marginBottom: "15px",
                        }}
                        className="align-center"
                        src={LogoBlack}
                    /> */}
                    {/* <Avatar className={classes.avatarStyle}>
            <LockOutlinedIcon />
          </Avatar> */}
                    <Typography variant="h5" style={{ marginBottom: '1em' }}>Forget Password</Typography>
                </Grid>

                    <Input
                        className={classes.fields}
                        fullWidth
                        required
                        label=" New Password"
                        name="password"
                        error={errors.password}
                        type="password"
                        value={values.password}
                        onChange={handleInputChange}
                        placeholder="Enter Password"
                    ></Input>
                    <Input
                        className={classes.fields}
                        fullWidth
                        required
                        label="Confirm New Password"
                        error={errors.confirm_password}
                        name="confirm_password"
                        type="password"
                        value={values.confirm_password}
                        onChange={handleInputChange}
                        placeholder="Enter Confirm Password"
                    ></Input>
                    <Button
                        className={classes.btnStyle}
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Reset Password
          </Button></> : <CircularProgress />}
                {/* <p>
                    Remember password? <Link href="/">Login</Link>
                </p> */}
            </Paper>
            {!currentUser && <Redirect to="/" />}
            <Notifications notify={notify} setNotify={setNotify} />
        </Grid>
    );
};

export default Login;
