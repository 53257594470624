import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  InputAdornment,
  Chip,
  Select as MaterialUISelect,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Dialog } from "../../components/Dialog";

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import ActionButton from "../../components/controls/ActionButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from "prop-types";
import clsx from "clsx";
import { getDispatchOrderList, getDispatchPlanReport, getSalesOrderFile } from "../../services/dispatchPlan";
import Input from "../../components/controls/Input";
import { Search } from "@material-ui/icons";

import { lighten, makeStyles } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { getCurrentUser } from '../../services/userService'
import { STATUS, STATUS_TEXT, USER_TYPES } from "../../constants";
import { formatDate } from "../../helpers/helperFunctions";
import SelectDistributorAndPO from "./SelectDistributorAndPO";
import EditSalesOrderDetails from "./EditSalesOrderDetails";
import Notifications from "../../components/Notifications";
import UploadInvoiceData from "./UploadInvoiceData";
import { useStyles as useDPStyles } from "./styles";
import UploadReportFile from "./UploadReportFile";
import DownloadReport from "./DownloadReport";
function createData(id, date, distributor, ref_no, po_ref_no, sap_sales_order_id, sales_order_filename, total_materials, total_quantity, status, created_by, org_name) {
  return { id, date, distributor, ref_no, po_ref_no, sap_sales_order_id, sales_order_filename, total_materials, total_quantity, status, created_by, org_name };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const cssStyles = ((theme) => ({
  pageContent: {
    // margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,

  searchInput: {
    width: "58%",
    marginLeft: "18px",
    marginRight: "12px"
  },
  exportButton: {
    position: "relative",
    fontSize: "12px"
  },
  bookOrderButton: {
    position: "relative",
    fontSize: "12px",
    whiteSpace: "nowrap"
  },
  acceptButton: {
    position: "relative",
    fontSize: "12px",
    whiteSpace: "nowrap",
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: "#139018",
    },
  },
  rejectButton: {
    position: "relative",
    fontSize: "12px",
    whiteSpace: "nowrap"
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "2px",
    margin: "5px",
    fontSize: "12px",
  },
  textDark: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#323232",
    opacity: "0.99",
  },
  inProgress: {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
  },
  rejected: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
  },
  pending: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
  },
  completed: {
    color: '#fff',
    backgroundColor: '#007bff',
    borderColor: '#ffeeba',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
  },
  approved: {
    color: '#fff',
    backgroundColor: '#17a2b8',
    borderColor: '#ffeeba',
    border: 0,
    padding: '6px 9px',
    fontWeight: 'bold',
  }
}));
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "organizations_name",
    numeric: true,
    disablePadding: false,
    label: "Organizations Name",
  },
  { id: "ref_no", numeric: true, disablePadding: false, label: "DP Ref No" },
  { id: "po_ref_no", numeric: true, disablePadding: false, label: "PO Ref No" },
  {
    id: "sap_sales_order_id",
    numeric: true,
    disablePadding: false,
    label: "Sales Order ID",
  },
  {
    id: "total_materials",
    numeric: true,
    disablePadding: false,
    label: "Total Materials",
  },
  {
    id: "total_quantity",
    numeric: true,
    disablePadding: false,
    label: "Total Quantity",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,

  textCenter: {
    textAlign: "center",
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
  },
}));


const DispatchPlanList = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    rows: [],
    stableData: []
  });


  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });


  const classes = useStyles();
  const dpClasses = useDPStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [openPopUpReject, setopenPopUpReject] = React.useState(false)
  const [filterSelected, setFilterSelected] = useState(-1);

  const [openPopUp, setOpenPopUp] = useState(false);
  const [openInvoicePopUp, setOpenInvoicePopUp] = useState(false);
  const [openSalesOrderPopUp, setOpenSalesOrderPopUp] = useState(false);
  const [openReportUploadPopUp, setOpenReportUploadPopUp] = useState(false);
  const [downloadReportPopUp, setDownloadReportPopUp] = useState(false);
  const [dpForSalesOrder, setDpForSalesOrder] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [rejectReason, setrejectReason] = React.useState('')
  const [orderId, setOrderId] = useState()
  const [user, setUser] = useState()
  const [currentUser, serCurrentUser] = useState()
  const [loading, setLoading] = useState(false)
  const [errors, seterrors] = useState({
    rejectReason: ''
  })

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePopUpOpen = () => {
    // setRecordForEdit(null);
    setOpenPopUp(true);
  };

  const handleReportUploadPopUpOpen = () => {
    setOpenReportUploadPopUp(true);
  };

  const handleReportUploadPopUpClose = () => {
    setOpenReportUploadPopUp(false);
  };

  const handleInvoicePopUpOpen = () => {
    setOpenInvoicePopUp(true);
  };

  const handleInvoicePopUpClose = () => {
    setOpenInvoicePopUp(false);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getCurrentUserFunc = async () => {
    setLoading(true)
    const user = await getCurrentUser()
    if (user && user.data) {
      serCurrentUser(user.data.body)
    }
    setLoading(false)
  }
  const handleSearch = (e) => {
    let target = e.target;
    let res
    if (target.value === "") {
      res = state.stableData
    } else {
      res = state.stableData.filter((val) => {
        return val.org_name.toLowerCase()
          .includes(target.value.toLowerCase()) ||
          val.po_ref_no.includes(target.value) ||
          val.ref_no.includes(target.value)
      })
    }
    setState({ ...state, rows: res })
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, state.rows.length - page * rowsPerPage);

  const funcGetDispatchOrderList = async (filterStatus = -1) => {

    setLoading(true)
    try {
      const DispatchOrderList = await getDispatchOrderList();

      const poList = DispatchOrderList.data.data;
      setUser(DispatchOrderList.data.user)
      let rows = [];
      for (let [key, value] of poList.entries()) {
        setFilterSelected(filterStatus);
        if (filterStatus != -1 && value.status != filterStatus) {
          continue;
        }
        rows.push(createData(value.id, value.created, value.name, value.ref_no, value.po_ref_no,
          value.sap_sales_order_id, value.sales_order_filename, value.total_materials, value.total_quantity,
          value.status, value.created_by, value.org_name));
      }

      setState((state) => {
        return {
          ...state,
          rows: rows,
          stableData: rows
        };
      });
    } catch (err) {
      history.push('/')
    }

    setLoading(false)
  };
  useEffect(async () => {
    await getCurrentUserFunc()
    funcGetDispatchOrderList();

  }, []);

  const viewUrl = (id) => {
    history.push("/dispatch-plan-details/" + id);
  };

  const renderFilterOptions = () => {
    var options = [];
    options.push(<MenuItem key={-1} value={-1}>
      All Dispatch Plans
    </MenuItem>);
    for (const key in STATUS) {
      if (STATUS.hasOwnProperty(key)) {
        const element = STATUS[key];
        options.push(<MenuItem key={element} value={element}>
          {STATUS_TEXT[key]}
        </MenuItem>);
      }
    }
    return options;
  };

  const returnStatus = status => {
    switch (status) {
      case STATUS.PENDING:
        return <Chip style={{ textTransform: 'uppercase' }} className={dpClasses.pending} label={STATUS_TEXT.PENDING} />;
      // return <button style={{textTransform:'uppercase'}} className={classes.pending}>{STATUS_TEXT.PENDING}</button>;
      case STATUS.APPROVED:
        return <Chip style={{ textTransform: 'uppercase' }} className={dpClasses.approved} label={STATUS_TEXT.APPROVED} />;
      case STATUS.REJECTED:
        return <Chip style={{ textTransform: 'uppercase' }} className={dpClasses.rejected} label={STATUS_TEXT.REJECTED} />;
      case STATUS.COMPLETED:
        return <Chip style={{ textTransform: 'uppercase' }} className={dpClasses.completed} label={STATUS_TEXT.COMPLETED} />;
      case STATUS.IN_PROGRESS:
        return <Chip style={{ textTransform: 'uppercase' }} className={dpClasses.inProgress} label={STATUS_TEXT.IN_PROGRESS} />;
      default:
        return null;
    }
  }

  const handleSalesOrderClose = () => {
    setOpenSalesOrderPopUp(false);
  };

  const handleSalesOrderPopUpOpen = id => {
    // setRecordForEdit(null);
    setDpForSalesOrder(id)
    setOpenSalesOrderPopUp(true);
  };

  const handleDownloadReportPopUpOpen = id => {
    setDownloadReportPopUp(true);
  };

  const handleDownloadReportPopUpClose = id => {
    setDownloadReportPopUp(false);
  };

  return (
    <main className={classes.pageContent}>
      <div className={classes.toolbar} />
      <div style={{ marginBottom: "25px", marginTop: '10px' }}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item container xs={12} sm={6} md={3} spacing={1}>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              All Dispatch Plans
            </Typography>
          </Grid>
          {currentUser && <> {currentUser.status === 1 &&
            <Grid>
              {
                (currentUser?.user_type === USER_TYPES.ADMIN || currentUser?.user_type === USER_TYPES.SALES_PERSON) &&
                <Button color="primary" variant="contained" style={{ marginRight: '5px' }}
                  // onClick={async () => {
                  //   let contractData = await getDispatchPlanReport();
                  //   // console.log('contractdata', contractData);
                  //   // console.log(Buffer.from(contractData.data));
                  //   const base64string = contractData.data;
                  //   const contenttype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                  //   var file = b64toBlob(base64string, contenttype);
                  //   var a = document.createElement('a'),
                  //     file;
                  //   a.href = window.URL.createObjectURL(file);
                  //   a.target = '_blank';
                  //   a.download = 'DP-Report-' + new Date().toDateString() + '.xlsx';
                  //   document.body.appendChild(a);
                  //   a.click();
                  //   document.body.removeChild(a);
                  // }}
                  onClick={handleDownloadReportPopUpOpen}
                >
                  Download Report
                </Button>
              }
              {currentUser.user_type !== USER_TYPES.DISTRIBUTOR &&
                <Button color="primary" variant="contained" style={{ marginRight: '5px' }}
                  onClick={handleReportUploadPopUpOpen}
                >
                  Upload Report
                </Button>
              }
              {
                (currentUser?.user_type === USER_TYPES.ADMIN || currentUser.user_type === USER_TYPES.SALES_PERSON) &&
                <Button color="primary" variant="contained" style={{ marginRight: '5px' }}
                  onClick={handleInvoicePopUpOpen}
                >
                  Upload Invoice Data
                </Button>
              }
              {currentUser.user_type !== USER_TYPES.SALES_PERSON &&
                <Button color="primary" variant="contained"
                  onClick={handlePopUpOpen}
                >
                  New Dispatch Plan
                </Button>
              }
            </Grid>
          }</>}
        </Grid>
      </div>
      <Paper style={{ paddingTop: "15px", paddingBottom: '15px' }}>
        <Toolbar>
          {/* <div style={{ marginBottom: "20px" }}> */}
          <Grid container justify="space-between" spacing={0}>
            <Grid item xs={12} sm={12} md={9}>
              <Input
                label="Search by Organization name or reference no."
                className={classes.searchInput}
                style={{ width: '75%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid >
              <FormControl variant="outlined"
                style={{ marginTop: '5px' }}
              >
                <InputLabel id="demo-mutiple-name-label">Filters</InputLabel>
                <MaterialUISelect
                  labelId="demo-mutiple-name-label"
                  name="Filters"
                  label="Filters"
                  value={filterSelected}
                  MenuProps={MenuProps}
                  onChange={(e) => { funcGetDispatchOrderList(e.target.value) }}
                >
                  {renderFilterOptions()}
                </MaterialUISelect>
              </FormControl>
            </Grid>
          </Grid>
          {/* </div> */}
        </Toolbar>
      </Paper>


      <div className={classes.root}>
        <Paper className={classes.container}>
          {loading === false ? <> <TableContainer style={{ textAlign: "center" }}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={state.rows.length}
              />
              <TableBody>
                {stableSort(state.rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      /*
                      onClick={()=>{
                         history.push('/create-dispatch-plan/'+row.id)
                      }}
                      */
                      >
                        {/* {console.log(row.status !== 3, 'trueflass', row)} */}
                        <TableCell component="th" id={labelId} scope="row">
                          {/* {moment(row.date).format('DD:MM:yyyy')} */}
                          {formatDate(row.date)}
                        </TableCell>
                        <TableCell align="right">{row.org_name}</TableCell>
                        <TableCell align="right">{row.ref_no && row.ref_no.toUpperCase()}</TableCell>
                        <TableCell align="right">{row.po_ref_no.toUpperCase()}</TableCell>
                        <TableCell align="right">
                          {row.sap_sales_order_id || "   --"}&nbsp;&nbsp;
                          {row.sales_order_filename && <Link
                            onClick={async () => {
                              let salesOrderData = await getSalesOrderFile(row.sales_order_filename);
                              // console.log(salesOrderData)
                              const blob = new Blob([salesOrderData], { type: 'application/pdf' })
                              let a = document.createElement('a');
                              a.href = window.URL.createObjectURL(blob);
                              a.download = `${row.sales_order_filename || 'SalesOrder'}`
                              a.click();
                            }}
                            style={{ 'cursor': 'pointer' }}
                          >
                            <AttachFileIcon fontSize="small" />
                          </Link>
                          }
                        </TableCell>
                        <TableCell align="right">
                          {row.total_materials}
                        </TableCell>
                        <TableCell align="right">
                          {row.total_quantity}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            <div className={classes.textDark}>
                              {returnStatus(row.status)}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <ActionButton color="primary">
                            <VisibilityIcon
                              onClick={(e) => viewUrl(row.id)}
                              fontSize="small"
                            />
                          </ActionButton>
                          {(row.status === STATUS.APPROVED) && (currentUser.user_type === USER_TYPES.ADMIN || currentUser.user_type === USER_TYPES.SALES_PERSON) && <ActionButton color="primary">
                            <SettingsIcon
                              onClick={e => handleSalesOrderPopUpOpen(row.id)}
                              fontSize="small"
                            />
                          </ActionButton>}
                        </TableCell>

                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: state.rows.length }]}
              component="div"
              count={state.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /></> : <>
            <CircularProgress />
          </>}
        </Paper>
        <Grid item xs={12}>
          <Dialog
            heading="Create New Dispatch Plan"
            handleClose={handleClose}
            open={openPopUp}
            width="md"
          >
            <SelectDistributorAndPO
            // type={typeModal}
            // recordForEdit={recordForEdit}
            />
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            heading="Edit Sales Order Details"
            handleClose={handleSalesOrderClose}
            open={openSalesOrderPopUp}
            width="md"
          >
            <EditSalesOrderDetails
              dpId={dpForSalesOrder}
              handleClose={handleSalesOrderClose}
              setNotify={setNotify}
              updateDPList={funcGetDispatchOrderList}
            />
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            heading="Upload Invoice Data in Excel File"
            handleClose={handleInvoicePopUpClose}
            open={openInvoicePopUp}
            width="md"
          >
            <UploadInvoiceData
              dpId={dpForSalesOrder}
              handleClose={handleInvoicePopUpClose}
              setNotify={setNotify}
              updateDPList={funcGetDispatchOrderList}
            />
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            heading="Upload DP Report in Excel File"
            handleClose={handleReportUploadPopUpClose}
            open={openReportUploadPopUp}
            width="md"
          >
            <UploadReportFile
              dpId={dpForSalesOrder}
              handleClose={handleReportUploadPopUpClose}
              setNotify={setNotify}
              updateDPList={funcGetDispatchOrderList}
            />
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            heading="Download All DP Report"
            handleClose={handleDownloadReportPopUpClose}
            open={downloadReportPopUp}
            width="sm"
          >
            <DownloadReport
              dpId={dpForSalesOrder}
              handleClose={handleDownloadReportPopUpClose}
              setNotify={setNotify}
              updateDPList={funcGetDispatchOrderList}
            />
          </Dialog>
        </Grid>
      </div>
      <Notifications notify={notify} setNotify={setNotify} />
    </main>
  );
};

export default withStyles(cssStyles)(DispatchPlanList);
