import axios from "axios";

const SERVERBASEURL = process.env.REACT_APP_API;
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json"
  }
};

export async function insertConsignee(data) {
  const savedStatus = await axios.post(
    SERVERBASEURL + "/consignee",
    JSON.stringify(data),
    config
  );
  return savedStatus;
}

export async function updateConsignee(data) {
  console.log(data);
  const savedStatus = await axios.put(
    SERVERBASEURL + `/consignee/${data.id}`,
    data,
    config
  );
  return savedStatus;
}

export async function getConsigneeList() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const consigneeList = await axios.get(SERVERBASEURL + "/consignee", config);
  return consigneeList;
}

export async function getActiveConsigneeList() {
  const consigneeList = await axios.get(SERVERBASEURL + "/active-consignees", config);
  return consigneeList;
}

export async function getActiveConsigneeListForDistributor(org_id) {
  const consigneeList = await axios.get(SERVERBASEURL + "/active-consignees-for-distributor/" + org_id, config);
  return consigneeList;
}

export async function getSpecificConsignee(consigneeId) {
  const consignee = await axios.get(
    SERVERBASEURL + `/consignee/${consigneeId}`,
    config
  );
  return consignee;
}

export async function getDistributorsForConsignee(consigneeId) {
  const consignee = await axios.get(
    SERVERBASEURL + `/consignee/connected-distributors/${consigneeId}`,
    config
  );
  return consignee.data.data;
}
