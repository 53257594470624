import { AppBar as MuiAppBar,Toolbar } from "@material-ui/core";

const AppBar = ({ children, classNames }) => {
  return (
    <MuiAppBar className={classNames} color="primary" position="fixed">
      <Toolbar>{children}</Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
