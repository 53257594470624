import axios from "axios";
import env from "react-dotenv";
const SERVERBASEURL = process.env.REACT_APP_API;

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export async function getAllProposedDiscounts() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  let d = await axios.get(
    SERVERBASEURL + "/proposed-discounts/getAllProposedDiscounts",
    config
  );
  return d;
}

export async function getTodaysProposedDiscount() {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  const d = await axios.get(
    SERVERBASEURL + "/proposed-discounts/getTodaysProposedDiscount",
    config
  );
  return d;
}
export async function getMaximumQuantity(Quantity, id) {
  config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  const d = await axios.post(
    SERVERBASEURL + `/highestDiscount`,
    {
      "quantity": Quantity,
      "id": id
    },
    config,

  );
  return d;
}
