import { useState, useEffect } from "react";
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  makeStyles,
  Grid,
  TableBody,
  Paper,
  Toolbar,
  TableRow,
  TableCell,
  InputAdornment,
} from "@material-ui/core";
import { Dialog } from "../../components/Dialog";
import useTable from "../../components/useTable";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import AddIcon from "@material-ui/icons/Add";
import { Search } from "@material-ui/icons";
import CustomerForm from "./CustomerForm";

import CustomerFromCredit from "./CustomerCreditLimit";
import {
  insertCustomer,
  updateCustomer,
  deleteCustomer,
  getAllDistributors,
} from "../../services/customerService";
import ActionButton from "./../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Notifications from "./../../components/Notifications";
import { insertCreditLimit, updateCreditLimit } from '../../services/creditLimit'
import { ORG_TYPES, ORG_TYPE_TEXT } from "../../constants";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    // margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,

  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
  },
}));

export default function Customers() {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [loading, setLoading] = useState(true)
  const [openPopUpCredit, setOpenPopUpCredit] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEditCredit, setRecordForEditCredit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [typeModal, setTypeModal] = useState('add')
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const classes = useStyles();

  const funccustomers = async () => {
    setLoading(true)
    let customersList = await getAllDistributors()
    setLoading(false)
    return setCustomers(customersList.data.data.reverse());

  };

  useEffect(() => {
    funccustomers()

  }, []);

  const headCells = [
    { id: "DistributorName", label: "Distributor Name", disableSorting: true },
    { id: "contactPerson", label: "Contact Person", disableSorting: true },
    { id: "mobileNumber", label: "Mobile Number", disableSorting: true },
    { id: "email", label: "Email Id", disableSorting: true },
    { id: "city", label: "City", disableSorting: true },

    { id: "district", label: "District", disableSorting: true },
    // { id: "credit_limit_quantity", label: "Credit Limit Quantity (MT)", disableSorting: true },
    // { id: "credit_limit_value", label: "Credit Limit Value (Rs)", disableSorting: true },
    { id: "distributorType", label: "Distributor Type", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const handlePopUpOpen = () => {
    setRecordForEdit(null);
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.org_name
              .toLowerCase()
              .includes(target.value.toLowerCase())
          );
        }
      },
    });
  };

  const addOrEdit = async (customer, resetForm) => {
    let res
    if (customer.formId === 0) {
      const response = await insertCustomer(customer);
    } else {
      res = await updateCustomer(customer);
    }
    resetForm();
    setOpenPopUp(false);
    if (res.data.data !== null) {
      setNotify({
        isOpen: true,
        message: "Submitted successfully",
        type: "success",
      });
    }
    else {
      setNotify({
        isOpen: true,
        message: res.data.message,
        type: "error",
      });
    }
    funccustomers()
  };
  const addOrEditCredit = async (customer, resetForm) => {
    let res
    if (customer.credit_limit_value === "" || customer.credit_limit_value === null && customer.credit_id === null) {
      if (customer.credit_limit_quantity === "" || customer.credit_limit_quantity === null) {
        setNotify({
          isOpen: true,
          message: "Please input limit for Quantity or Value at least ",
          type: "error",
        });
      }
      else {
        if (customer.credit_id === null) {
          const request = {
            org_id: customer.org_id,
            credit_limit_quantity: customer.credit_limit_quantity,
            credit_limit_value: customer.credit_limit_value
          }
          res = await insertCreditLimit(request);
        }
        else {
          res = await updateCreditLimit(customer);
        }
        // resetForm();
        // setRecordForEditCredit(null);
        setOpenPopUpCredit(false);
        if (res.data.data !== null) {
          setNotify({
            isOpen: true,
            message: "Submitted successfully",
            type: "success",
          });

        }
        else {
          setNotify({
            isOpen: true,
            message: res.data.message,
            type: "error",
          });
        }
        funccustomers()
      }
    }
    else {
      if (customer.credit_id === null) {
        const request = {
          org_id: customer.org_id,
          credit_limit_quantity: customer.credit_limit_quantity,
          credit_limit_value: customer.credit_limit_value
        }
        res = await insertCreditLimit(request);
      }
      else {
        res = await updateCreditLimit(customer);
      }
      // resetForm();
      // setRecordForEditCredit(null);
      setOpenPopUpCredit(false);
      if (res.data.data !== null) {
        setNotify({
          isOpen: true,
          message: "Submitted successfully",
          type: "success",
        });

      }
      else {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: "error",
        });
      }
      funccustomers()
    }
  };

  const openInPopUp = (row) => {
    setTypeModal("edit")
    setRecordForEdit(row);
    setOpenPopUp(true);
  };
  const openInPopUpCredit = (row) => {
    // setTypeModal("edit")
    const OrgRow = {
      org_id: row.id,
      credit_id: row.credit_id,
      org_name: row.org_name,
      credit_limit_quantity: row.credit_limit_quantity,
      credit_limit_value: row.credit_limit_value
    }
    setRecordForEditCredit(OrgRow);
    setOpenPopUpCredit(true);
  };
  const onDelete = (id) => {
    if (window.confirm("Are you sure to delete this record")) {
      // const updatedData = customers.filter((row) => row.organizationId !== id);
      deleteCustomer(id);
      setNotify({
        isOpen: true,
        message: "Deleted successfully",
        type: "error",
      });
      setTimeout(funccustomers, 1000);
    }
  };

  const {
    TableContainer,
    TableHeader,
    TablePaginations,
    recordsAfterPagingAndSorting,
  } = useTable(customers, headCells, filterFn, [5, 10, 25, { label: 'All', value: customers.length }]);

  return (
    <>
      {/* <PageHeader
        title="Distributors"
        subTitle="List of all the distributors"
        icon={<PeopleOutlineIcon />}
      /> */}
      <main className={classes.pageContent}>
        <div className={classes.toolbar} />

        <Paper style={{ paddingTop: "20px" }}>
          <Toolbar>
            <Input
              label="Search by Distributor"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
            <Button
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={handlePopUpOpen}
            />
          </Toolbar>
          {!loading ? <> <div className={classes.container}>
            <TableContainer>
              <TableHeader />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.org_name}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.district === null ? "NULL" : row.district}</TableCell>
                    {/* <TableCell>{row.credit_limit_quantity === null ? "N/A" : row.credit_limit_quantity}</TableCell>
                    <TableCell>{row.credit_limit_value === null ? "N/A" : row.credit_limit_value}</TableCell> */}
                    <TableCell>
                      {row.org_type}
                    </TableCell>
                    <TableCell>{row.status === 1 ? "Active" : "Inactive"}</TableCell>
                    <TableCell>
                      <ActionButton onClick={() => {
                        openInPopUp(row);
                      }} color="primary">
                        <EditOutlinedIcon

                          fontSize="small"
                        />
                      </ActionButton>
                      {/* <ActionButton onClick={() => {
                        openInPopUpCredit(row);
                      }} color="primary">
                        <AttachMoneyIcon

                          fontSize="small"
                        />
                      </ActionButton> */}
                      {/* <ActionButton
                        color="secondary"
                        onClick={() => onDelete(row.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </ActionButton> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </div>
            <TablePaginations /> </> : <>
            <CircularProgress />

          </>}
        </Paper>
        <Grid item xs={12}>
          <Dialog
            heading="Add Distributor"
            handleClose={handleClose}
            open={openPopUp}
            width="md"
          >
            <CustomerForm type={typeModal} recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
          </Dialog>
          <Dialog
            heading="Add Distributor Credit Limit"
            handleClose={() => { setOpenPopUpCredit(false) }}
            open={openPopUpCredit}
            width="md"
          >
            <CustomerFromCredit recordForEdit={recordForEditCredit} addOrEdit={addOrEditCredit} />
          </Dialog>
        </Grid>
        <Notifications notify={notify} setNotify={setNotify} />
      </main>
    </>
  );
}
