import { useState, useEffect } from "react";
import {
  makeStyles,
  TableBody,
  Paper,
  Toolbar,
  TableRow,
  TableCell,
  InputAdornment,
} from "@material-ui/core";
import Notifications from "../../components/Notifications";
import { Dialog } from "../../components/Dialog";
import {
  insertMaterialSize,
  updateMaterialSize,
  deleteMaterialSize,
  getMaterialSizes
} from "../../services/materialService";
import {
  insertGrade,
  // updateMaterialSize,
  // deleteMaterialSize,
  // getMaterialSizes
} from "../../services/gradeService";
import { Search } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ActionButton from "../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import useTable from "../../components/useTable";
import PageHeader from "../../components/PageHeader";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import SizeForm from "./SizeForm";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const Materials = () => {
  const [materials, setMaterials] = useState([]);
  const [grades, setGrades] = useState([{ id: "", title: "" }]);
  const [sizes, setSizes] = useState([{ id: "", title: "" }]);
  const [types, setTypes] = useState([{ id: "", title: "" }]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    { id: "name", label: "Name", disableSorting: true },
    { id: "price", label: "Rate (Rs)", disableSorting: true },
    { id: "South Gujarat Rate", label: "South Gujarat Rate (Rs)", disableSorting: true },
    { id: "Other State Parity", label: "Other State Parity (Rs)", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true },
  ];

  const fetchSize = async () => {
    const materialSizeLists = await getMaterialSizes();
    const mLists = materialSizeLists.data.data;
    setMaterials(mLists);
  };
  useEffect(async () => {
    fetchSize();
  }, []);

  const handlePopUpOpen = () => {
    setRecordForEdit(null);
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const onDelete = async (id) => {
    if (window.confirm("Are you sure to disable this record")) {
      await deleteMaterialSize(id);
      await setNotify({
        isOpen: true,
        message: "Size Deactivated Successfully",
        type: "error",
      });
      fetchSize()
      // window.location.reload();
    }
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.size_name.toLowerCase().includes(target.value.toLowerCase())
          );
        }
      },
    });
  };

  const addOrEdit = async (material, resetForm) => {
    if (!material.id) {
      // material.status = 1;
      const response = await insertMaterialSize(material);
    } else { await updateMaterialSize(material); }
    resetForm();
    setRecordForEdit(null);
    setOpenPopUp(false);
    setNotify({
      isOpen: true,
      message: "Submitted successfully",
      type: "success",
    });
    fetchSize();
    // window.location.reload();
  };

  const openInPopUp = (row) => {
    setRecordForEdit(row);
    setOpenPopUp(true);
  };

  const {
    TableContainer,
    TableHeader,
    TablePaginations,
    recordsAfterPagingAndSorting,
  } = useTable(materials, headCells, filterFn, [5, 10, 25, { label: 'All', value: materials.length }]);

  return (
    <>
      <PageHeader
        title="Size"
        subTitle="Size "
        icon={<FlipToFrontIcon />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Input
            label="Search by Size name"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={handlePopUpOpen}
          />
        </Toolbar>
        <TableContainer>
          <TableHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.size_name}</TableCell>
                <TableCell>{row.rate}</TableCell>
                <TableCell>{row.south_gujarat_rate}</TableCell>
                <TableCell>{row.other_state_parity}</TableCell>
                <TableCell>{row.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>
                  <ActionButton onClick={() => {
                    openInPopUp(row);
                  }} color="primary">
                    <EditOutlinedIcon

                      fontSize="small"
                    />
                  </ActionButton>
                  <ActionButton
                    color="secondary"
                    onClick={() => onDelete(row.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
        <TablePaginations />
      </Paper>
      <Dialog
        heading="Add Size"
        handleClose={handleClose}
        open={openPopUp}
        width="xs"
      >
        <SizeForm
          materials={materials}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Dialog>
      <Notifications notify={notify} setNotify={setNotify} />
    </>
  );
};
export default Materials;
