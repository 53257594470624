import React from 'react';
import PageHeader from "../../components/PageHeader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Notifications from "../../components/Notifications";
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Grid,
    TableBody,
    Paper,
    Toolbar,
    TableRow,
    TableCell,
    InputAdornment,
    TableContainer,
    Table,
    TableHead,
    TablePagination,
    TableFooter,
    Chip,
    TextField
} from "@material-ui/core";
import CommentIcon from '@material-ui/icons/Comment';
import ActionButton from "../../components/controls/ActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { withStyles } from '@material-ui/core/styles';
import Input from "../../components/controls/Input";
import Button from "../../components/controls/Button";
import AddIcon from "@material-ui/icons/Add";
import { ContactlessOutlined, Search } from "@material-ui/icons";
import axios from "axios";
import NewOrderBookingForm from "./NewOrderBookingForm"
import { Dialog } from "../../components/Dialog";
import moment from "moment";
import { getCurrentUser } from '../../services/userService'
import { Redirect } from 'react-router-dom';
const cssStyles = ((theme) => ({
    pageContent: {
        // margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,

    searchInput: {
        width: "58%",
        marginLeft: "18px",
        marginRight: "12px"
    },
    exportButton: {
        position: "relative",
        fontSize: "12px"
    },
    bookOrderButton: {
        position: "relative",
        fontSize: "12px",
        whiteSpace: "nowrap"
    },
    acceptButton: {
        position: "relative",
        fontSize: "12px",
        whiteSpace: "nowrap",
        backgroundColor: '#4caf50',
        '&:hover': {
            backgroundColor: "#139018",
        },
    },
    rejectButton: {
        position: "relative",
        fontSize: "12px",
        whiteSpace: "nowrap"
    },
    container: {
        display: "block",
        maxWidth: "85vw",
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "2px",
        margin: "5px",
        fontSize: "12px",
    },
}));
let today = new Date();
const date = `${today.getFullYear()}-${today.getMonth() < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${new Date().getDate()}`
class OrderBooking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            start_date: date,
            end_date: date,
            stabletableData: [],
            isDisableComment: false,
            user: {},
            tableData: [],
            notify: {
                isOpen: false,
                message: "",
                type: "",
            },
            loading: false,
            showPopup: false,
            rowData: null,
            isBookOrderDialogOpen: false,
            pageNum: 0,
            rowsPerPage: 5,
            userType: localStorage.getItem('user_type'),
            rejectDialogData: null,
            rowData: null,
            rejectionReason: null,
            orderStatusError: null,
            currentUser: [],
            resTable: null,
        };
        this.setFilterFn = {
            fn: (items) => {
                return items;
            },
        }
        this.getTableDataRows = this.getTableDataRows.bind(this);
        this.getCurrentUserFunc = this.getCurrentUserFunc.bind(this);
        this.getTableHeaderRow = this.getTableHeaderRow.bind(this);
        this.shouldAllowOrderBooking = this.shouldAllowOrderBooking.bind(this);
        this.orderStatusRenderCode = this.orderStatusRenderCode.bind(this);
        this.SERVERBASEURL = process.env.REACT_APP_API;
        this.token = localStorage.getItem("token");
        this.openPopupEdit = this.openPopupEdit.bind(this)
        this.openPopupShow = this.openPopupShow.bind(this)
    }
    getCurrentUserFunc = async () => {
        this.setState({ loading: true })
        const user = await getCurrentUser()
        if (user && user.data){
            this.setState({ currentUser: user.data.body })
        }
        this.setState({ loading: false })
    }
    componentDidMount() {
        this.getTableDataRows();
        this.getCurrentUserFunc()
    }
    formatDate(date) {
        moment(date).format("DD:MM:yyyy")
    }
    createData(order_booking_id, orgId, distributorName, quantity, releaseRate, discountRate, totalDiscount, finalRate, created, orderBookingStatus, orderBookingReferenceNumber, createdByUserType, created_by_user_id, reason_for_rejection, created_order, refNo) {
        return { order_booking_id, orgId, distributorName, quantity, releaseRate, discountRate, totalDiscount, finalRate, created, orderBookingStatus, orderBookingReferenceNumber, createdByUserType, created_by_user_id, reason_for_rejection, createdOrder: created_order, refNo };
    }

    getTableDataRows() {
        var self = this;
        self.setState({ loading: true })
        axios({
            method: 'get',
            url: self.SERVERBASEURL + "/order_booking/" + self.getCurrentDate(),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
        })
            .then((res, req) => {
                var tableData = [];

                res.data.data.data.reverse().forEach((item, index) => {

                    tableData.push(
                        self.createData(
                            item.order_booking_id,
                            item.org_id,
                            item.org_name,
                            item.quantity,
                            item.release_rate,
                            item.discount_rate,
                            item.total_discount,
                            item.final_rate,

                            moment(item.created_order).format("DD/MM/yyyy"),
                            item.order_booking_status,
                            item.order_booking_reference_number,
                            item.createdby_usertype,
                            item.created_by_user_id,
                            item.reason_for_rejection,
                            item.created_order,
                            item.order_booking_reference_number,
                        ));
                });

                self.setState({ tableData, stabletableData: tableData, user: res.data.user });

            })
            .catch(err => console.log('Error in performing operation', err));
        self.setState({ loading: false })
    }

    getTableHeaderRow() {
        return [
            { id: 'distributorName', label: 'Distributor Name', minWidth: 170 },
            { id: 'quantity', label: 'Quantity (MT)', minWidth: 70 },
            { id: 'releaseRate', label: 'Release Rate (Rs)', minWidth: 70 },
            { id: 'refNo', label: 'Reference No', minWidth: 70 },
            { id: 'discountRate', label: 'Discount Rate (Rs)', minWidth: 70 },
            { id: 'totalDiscount', label: 'Total Discount (Rs)', minWidth: 70 },
            { id: 'finalRate', label: 'Final Rate (Rs)', minWidth: 70 },
            { id: 'created', label: 'Date', minWidth: 70 },
            { id: 'orderBookingStatus', label: 'Order Booking Status', minWidth: 158, renderCode: this.orderStatusRenderCode },
            { id: 'orderBookingStatus', label: 'Action', minWidth: 70, type: 'Action' },
        ]
    }
    handleChangeDate = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
        let startDatedd
        let endDatedd
        let startDateyy
        let endDateyy
        let startDatemm
        let endDatemm
        if (e.target.name === 'end_date') {
            startDatedd = String(new Date(this.state.start_date).getDate()).padStart(2, '0');
            endDatedd = String(new Date(e.target.value).getDate()).padStart(2, '0');
            startDateyy = String(new Date(this.state.start_date).getFullYear()).padStart(2, '0');
            endDateyy = String(new Date(e.target.value).getFullYear()).padStart(2, '0');
            startDatemm = String(new Date(this.state.start_date).getMonth()).padStart(2, '0');
            endDatemm = String(new Date(e.target.value).getMonth()).padStart(2, '0');
        }
        else if (e.target.name === 'start_date') {
            startDatedd = String(new Date(e.target.value).getDate()).padStart(2, '0');
            endDatedd = String(new Date(this.state.end_date).getDate()).padStart(2, '0');
            startDateyy = String(new Date(e.target.value).getFullYear()).padStart(2, '0');
            endDateyy = String(new Date(this.state.end_date).getFullYear()).padStart(2, '0');
            startDatemm = String(new Date(e.target.value).getMonth()).padStart(2, '0');
            endDatemm = String(new Date(this.state.end_date).getMonth()).padStart(2, '0');
        }
        var resultProductData = this.state.stabletableData.filter(function (a) {
            const date = String(new Date(a.createdOrder).getDate()).padStart(2, '0')
            const dateyy = String(new Date(a.createdOrder).getFullYear()).padStart(2, '0')
            const datemm = String(new Date(a.createdOrder).getMonth()).padStart(2, '0')
            return date >= startDatedd && date <= endDatedd && dateyy >= startDateyy && dateyy <= endDateyy && datemm <= startDatemm && datemm >= endDatemm

        });
        this.setState({ ...this.state, tableData: resultProductData, [e.target.name]: e.target.value })
    }
    orderStatusRenderCode(value, orderBookingId, createdByUserType, row) {
        const { classes } = this.props;
        if (value === "pending" && (this.state.userType !== createdByUserType && this.state.userType !== "sales_person")) {
            return (<div style={{ display: "inline-grid" }}>
                <Button
                    text="Accept"
                    variant="contained"
                    className={classes.acceptButton}
                    color="secondary"
                    onClick={(e) => { this.updateStatusOfOrderBooking(orderBookingId, "accepted", row); }}
                />
                <Button
                    text="Reject"
                    variant="contained"
                    className={classes.rejectButton}
                    color="secondary"
                    onClick={(e) => { this.setBookingStatusToReject(orderBookingId, row); }}
                />
            </div>
            )
        }
        else {
            return (<Chip label={value} />);
        }
    }

    setBookingStatusToReject(orderBookingId, row) {
        this.setState({
            rejectDialogData: {
                order_booking_id: orderBookingId,
            },
            rowData: row,
            showPopup: true,
            isDisableComment: false
        });
    }
    openPopupShow = (rejection_reason) => {
        this.setState({
            ...this.state,
            showPopup: true,
            rejectionReason: rejection_reason,
            isDisableComment: true
        })
    }

    updateStatusOfOrderBooking(id, newStatus, row) {
        var self = this;
        axios({
            method: 'get',
            url: self.SERVERBASEURL + "/order_booking/" + id + "/" + newStatus + "/" + this.state.rejectionReason + "/" + row.created_by_user_id,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${self.token}`,
            },
        })
            .then(res => {
                if (res.data.statusCode === 400) {
                    this.setState({
                        notify: {
                            isOpen: true,
                            message: res.data.message,
                            type: "error",
                        }
                    })
                }
                self.getTableDataRows()

            }).catch(err =>
                console.log('Error in performing operation', err));

        this.setState({ rejectDialogData: null, rejectionReason: null, showPopup: false })
    }

    getCurrentDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return yyyy + '-' + mm + '-' + dd;
    }

    shouldAllowOrderBooking() {
        return true;
    }

    handleDialogOpen() {
        if (this.shouldAllowOrderBooking()) {
            this.setState({ isBookOrderDialogOpen: true });
        }
    }
    openPopupEdit(row) {
        this.setState({ isBookOrderDialogOpen: true, rowData: row })
    }
    handleSearch = (e) => {
        let target = e.target;
        let res
        if (target.value === "") {
            res = this.state.stabletableData
        } else {
            res = this.state.stabletableData.filter((val) => {
                return val.distributorName.toLowerCase()
                    .includes(target.value.toLowerCase()) || 
                 val.orderBookingReferenceNumber
                    .includes(target.value)
            })
        }
        this.setState({ ...this.state, tableData: res })

    };
    render() {

        var paginatedTableData = this.state.rowsPerPage > 0 ? this.state.tableData.slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage) : this.state.tableData;

        const { classes } = this.props;
        return (<>

            <main className={classes.pageContent}>
                <div className={classes.toolbar} />

                <Paper style={{ paddingTop: "20px" }}>

                    <Toolbar>

                        {/* <TextField
                                id="date"
                                type="date"
                                defaultValue={this.getCurrentDate()}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            /> */}

                        <Input
                            label="Search by Organization name or reference no."
                            className={classes.searchInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleSearch}
                        />
                        <Grid style={{ marginLeft: '2em' }} item md={6} xs={12}>
                            <TextField
                                style={{ marginRight: '1em' }}
                                name="start_date"
                                label="Start Date"
                                type="date"
                                required
                                // inputProps={{ min: this.state.end_date }}
                                value={moment(this.state.start_date).format('yyyy-MM-DD')}
                                onChange={this.handleChangeDate}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name="end_date"
                                label="End Date"
                                type="date"
                                required
                                // inputProps={{ min: this.state.end_date }}
                                value={moment(this.state.end_date).format('yyyy-MM-DD')}
                                onChange={this.handleChangeDate}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        {/* <Button
                                text="Export"
                                variant="outlined"
                                startIcon={<AddIcon />}
                                className={classes.exportButton}
                                onClick={(e) => { }}
                            /> */}
                        {this.state.currentUser && this.state.userType !== "sales_person" &&
                            <> {this.state.currentUser.status === 1 &&
                                <Button
                                    text="Book Order"
                                    variant="contained"
                                    className={classes.bookOrderButton}
                                    onClick={(e) => this.handleDialogOpen()}
                                />
                            }</>
                        }
                    </Toolbar>


                    {this.state.loading === false ? <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {this.getTableHeaderRow().map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedTableData.map((row) => (<TableRow hover role="checkbox" tabIndex={-1} key={row.orderBookingReferenceNumber}>
                                    {this.getTableHeaderRow().map((column) => {
                                        const value = row[column.id];
                                        if (column.renderCode && this.state.currentUser && this.state.currentUser.status === 1) {
                                            return (<TableCell key={column.id} align={column.align}>{column.renderCode(value, row.order_booking_id, row.createdByUserType, row)}</TableCell>);
                                        }
                                        // else if (column.type === "Action") {
                                        //     console.log('--value', value)
                                        //     return ()
                                        // }
                                        else if (column.type !== "Action") {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            )
                                        };

                                    })}
                                    {this.state.currentUser && <><TableCell> {<> {

                                        row.orderBookingStatus !== "accepted" && this.state.user.id === row.created_by_user_id &&

                                        <ActionButton color="primary">
                                            <EditOutlinedIcon
                                                onClick={(e) => this.openPopupEdit(row)}
                                                fontSize="small"
                                            />
                                        </ActionButton>

                                    }
                                        {
                                            row.orderBookingStatus === "rejected" &&
                                            // <TableCell>

                                            <ActionButton color="primary">
                                                <CommentIcon
                                                    onClick={(e) => this.openPopupShow(row.reason_for_rejection)}
                                                    fontSize="small"
                                                />
                                            </ActionButton>
                                            // </TableCell>
                                        }</>}</TableCell></>}
                                </TableRow>))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={3}
                                        count={this.state.tableData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.pageNum}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={(e, newPage) => { this.setState({ pageNum: newPage }) }}
                                        onChangeRowsPerPage={(event) => { this.setState({ rowsPerPage: parseInt(event.target.value, 10) }); this.setState({ pageNum: 0 }) }}

                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer> : <>
                            <CircularProgress />
                        </>
                    }
                </Paper>
                <Dialog
                    heading="Add Order Booking"
                    handleClose={(e) => { this.setState({ isBookOrderDialogOpen: false, rowData: null, showPopup: false, isDisableComment: false }) }}
                    open={this.state.isBookOrderDialogOpen}
                    width="md"
                >
                    <NewOrderBookingForm
                        closeDialog={() => {
                            this.setState({ isBookOrderDialogOpen: false, rowData: null });
                            this.getTableDataRows();
                        }} orderDetails={this.state.rowData} />
                </Dialog>
                <Dialog
                    heading="Reason For Rejection"
                    handleClose={(e) => { this.setState({ rejectDialogData: null, rejectionReason: null, showPopup: false }) }}
                    open={this.state.showPopup}
                    width="sm"
                >

                    {this.state.orderStatusError != null && this.state.orderStatusError.length > 0 && <Chip label={this.state.orderStatusError} />}
                    <TextField
                        required
                        multiline
                        rows={4}
                        disabled={this.state.isDisableComment}
                        name="rejectionReason"
                        value={this.state.rejectionReason}
                        label="Reason For Rejection"
                        style={{ width: "100%", fontSize: "12px" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            var kv = {};
                            kv[name] = value;
                            kv["orderStatusError"] = null;
                            this.setState({ ...kv });
                        }}
                    />
                    {!this.state.isDisableComment && <Grid item>
                        <Button text="Submit"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                if (this.state.rejectionReason == null || this.state.rejectionReason.trim().length < 1) {
                                    this.setState({ orderStatusError: "Please give a reason for rejecting this order booking" });
                                } else {
                                    this.updateStatusOfOrderBooking(this.state.rejectDialogData.order_booking_id, "rejected", this.state.rowData);
                                }
                            }} />
                    </Grid>}

                </Dialog>
                <Notifications notify={this.state.notify} isClass={false} setNotify={(notifyReq) => { this.setState({ notify: { isOpen: false, message: '', error: '' } }) }} />
                {<>{this.state.currentUser === null && <Redirect to='/' />}</>}
            </main>
        </>);
    }
}

export default withStyles(cssStyles)(OrderBooking);