import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import { getCurrentUser } from "../../services/userService";

import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  getPurchaseOrderDetailsForView,
  getPurchaseOrderDetails,
  setPurchaseOrderStatus,
  getContractFile,
} from "../../services/PurchaseOrder";
import { useHistory, useParams } from "react-router-dom";
import Notifications from "../../components/Notifications";
import { Redirect } from "react-router-dom";
import { formatDate } from "../../helpers/helperFunctions";
import ExportPDF from "../../helpers/GeneratePdf";
import { STATUS, USER_TYPES } from "../../constants";

// const user_type = localStorage.getItem("user_type");

const useStyles = makeStyles((theme) => ({
  pageContent: {
    // margin: theme.spacing(3),
    padding: theme.spacing(1),
    width: "210mm",
    marginLeft: "auto",
    marginRight: "auto",
  },
  toolbar: theme.mixins.toolbar,

  textCenter: {
    textAlign: "center",
  },
  btn: {
    color: "#4DE019",
    borderColor: "#4DE019",
    "&:hover": {
      background: "#4DE019",
      color: "#fff",
    },
  },
  backButton: {
    position: "fixed",
    top: '80px',
    fontSize: "12px",
    whiteSpace: "nowrap"
  },
  btnReject: {
    color: "#F43333",
    borderColor: "#F43333",
    "&:hover": {
      background: "#F43333",
      color: "#fff",
    },
  },
  table: {
    // minWidth: 650,
    // display: "block",
    // overflowX: "auto",
  },
  container: {
    display: "block",
    maxWidth: "85vw",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
    border: "0.1px solid grey",
  },
  textLight: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#323232",
    opacity: "0.75",
  },
  textDark: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#323232",
    opacity: "0.99",
  },
  comment: {
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#5A1515",
    opacity: "0.75",
  },
  approveBlock: {
    width: "341mm",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "baseline",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function createData(material_name, material_code, quantity, rate, total, consignee, location) {
  return { material_name, material_code, quantity, rate, total, consignee, location };
}

const OrderView = () => {
  const { id } = useParams();
  const [currentUser, serCurrentUser] = useState();
  const history = useHistory();
  const [state, setState] = useState({
    rows: [],
    created: "",
    created_by: "",
    delivery_location: "",
    discount: 0,
    gst: 0,
    id: "",
    email: "",
    name: "",
    org_name: "",
    address: "",
    postalCode: "",
    state: "",
    phone: "",
    city: "",
    reason_for_rejection: "",
    ref_no: "",
    sap_contract_id: "",
    contract_filename: "",
    status: "",
    text_status: "",
    tin: "",
    total_amount: 0,
    total_materials: 0,
    total_quantity: 0,
    total_rate: 0,
    reason_for_rejection_text: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  //   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getCurrentUserFunc = async () => {
    const user = await getCurrentUser()
    serCurrentUser(user.data.body)
  }
  useEffect(() => {
    getCurrentUserFunc()
  }, [])
  useEffect(() => {
    let funcGetPurchaseOrderDetailsForView = async () => {
      let data = await getPurchaseOrderDetailsForView(id);
      let d = data.data.data;
      if (d.length > 0) {
        d = d[0];
        setState((state) => {
          return {
            ...state,
            ...d,
          };
        });
      }
    };
    funcGetPurchaseOrderDetailsForView();
  }, []);

  useEffect(() => {
    let funcGetPurchaseOrderDetails = async () => {
      let data = await getPurchaseOrderDetails(id);
      let d = data.data.data;
      if (d.length > 0) {
        let rows = [];

        for (let [key, value] of d.entries()) {
          rows.push(
            createData(
              value.material_name,
              value.material_code,
              value.quantity,
              value.rate,
              value.amount,
              value.consignee_name,
              value.delivery_location
            )
          );
        }
        setState((state) => {
          return {
            ...state,
            rows: rows,
          };
        });
      }
    };
    funcGetPurchaseOrderDetails();
  }, []);

  const handleSubmitRejectPOMessage = () => {
    let reason_for_rejection_text = state.reason_for_rejection_text;
    if (reason_for_rejection_text == "") {
      alert("Please enter reason for rejection.");
    } else {
      let funcSetPurchaseOrderStatus = async () => {
        let reason_for_rejection_text = state.reason_for_rejection_text;
        let status = 2;
        let data = await setPurchaseOrderStatus({
          reason_for_rejection_text,
          id,
          status,
        });
        let d = data.data;
        if (d.status == 1) {
          setOpen(false);
          setNotify({
            isOpen: true,
            message: d.message,
            type: "success",
          });
          setState((state) => {
            return {
              ...state,
              reason_for_rejection: reason_for_rejection_text,
              reason_for_rejection_text: "",
              text_status: "Rejected",
              status: 2,
            };
          });
        }
      };
      funcSetPurchaseOrderStatus();
    }
  };

  const handleSubmitApprovePO = () => {
    let funcSetPurchaseOrderStatus = async () => {
      let reason_for_rejection_text = "";
      let status = 3;
      let data = await setPurchaseOrderStatus({
        reason_for_rejection_text,
        id,
        status,
      });
      let d = data.data;
      setNotify({
        isOpen: true,
        message: d.message,
        type: "success",
      });
      if (d.status == 1) {
        setState((state) => {
          return {
            ...state,
            reason_for_rejection: "",
            reason_for_rejection_text: "",
            text_status: "Approved",
            status: 3,
          };
        });
      }
    };
    funcSetPurchaseOrderStatus();
  };

  const handleRejectPOMessage = (e) => {
    let reason_for_rejection_text = e.target.value;
    setState((state) => {
      return {
        ...state,
        reason_for_rejection_text: reason_for_rejection_text,
      };
    });
  };

  const goBack = () => {
    history.push('/order?pid=' + id)
  }

  return (
    <>
      <Button
        text="Go Back"
        variant="contained"
        className={classes.backButton}
        onClick={goBack}
      >Go Back</Button>
      <main id={"purchase-order-view"} className={classes.pageContent}>
        <div className={classes.toolbar} />
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Purchase Order Details
        </Typography>

        {currentUser?.user_type !== USER_TYPES.SALES_PERSON && currentUser?.user_type != state.created_by && state.status == 1 ? (
          <div style={{ marginBottom: "20px" }} id={"approve-reject-block"}>
            <Grid container justify="flex-end">
              <Grid>
                <Button
                  className={classes.btnReject}
                  variant="outlined"
                  style={{ marginRight: "5px" }}
                  onClick={handleClickOpen}
                >
                  Reject
                </Button>
                <Button
                  onClick={(e) => handleSubmitApprovePO()}
                  className={classes.btn}
                  variant="outlined"
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          ""
        )}
        {/* comment popup */}
        <Dialog
          // fullScreen={fullScreen}
          // maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle className={classes.textCenter}>
            {"Add Comments"}
          </DialogTitle>
          <Divider light></Divider>
          <DialogContent className={classes.textCenter}>
            <div
              style={{
                minHeight: "200px",
                minWidth: "300px",
                marginTop: "20px",
              }}
            >
              <Grid constainer justify="center" spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    placeholder="Type here..."
                    variant="outlined"
                    onChange={(e) => handleRejectPOMessage(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "50px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ width: "200px" }}
                    onClick={(e) => handleSubmitRejectPOMessage(e)}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        <div style={{ marginBottom: "25px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper style={{ padding: "10px", border: "0.1px solid grey" }}>
                <div style={{ marginBottom: "10px" }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <div>
                        <span className={classes.textLight}>Date : </span>{" "}
                        <span className={classes.textDark}>
                          {formatDate(state.created)}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div id={"status-block"}>
                        <span className={classes.textLight}>{"Status: "}</span>
                        <span style={{ color: "#F08910" }}>
                          {state.text_status}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{ marginBottom: "10px" }}>
                      <div
                        className={classes.textLight}
                        style={{ marginBottom: "5px" }}
                      >
                        Distributor
                      </div>
                      <div className={classes.textDark}>{state.org_name}</div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <div
                        className={classes.textLight}
                        style={{ marginBottom: "5px" }}
                      >
                        Contract ID
                      </div>
                      <div className={classes.textDark}>
                        {state.sap_contract_id || "--"}&nbsp;&nbsp;
                        {state.contract_filename &&
                          currentUser?.user_type === "admin" && (
                            <Link
                              onClick={async () => {
                                let contractData = await getContractFile(
                                  state.contract_filename
                                );
                                const blob = new Blob([contractData], {
                                  type: "application/pdf",
                                });
                                let a = document.createElement("a");
                                a.href = window.URL.createObjectURL(blob);
                                a.download = `${state.contract_filename || "Contract"
                                  }`;
                                a.click();
                                // console.info("I'm a button.");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <AttachFileIcon fontSize="small" />
                            </Link>
                          )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper style={{ padding: "10px", border: "0.1px solid grey" }}> */}
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{ marginBottom: "10px" }}>
                      <span className={classes.textLight}>Ref NO :</span>
                      <span className={classes.textDark}>{state.ref_no}</span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginBottom: "10px" }}>
                      <span className={classes.textLight}>BO Ref NO :</span>
                      <span className={classes.textDark}>{state.order_booking_reference_number}</span>
                    </div>
                  </Grid>
                </Grid>
                <div
                  className={classes.textLight}
                  style={{ marginBottom: "10px", textAlign: "center" }}
                >
                  <div
                    className={classes.textDark}
                    style={{ fontSize: "18px", opacity: 1, fontWeight: 600 }}
                  >
                    {" "}
                    Details of Buyer{" "}
                  </div>
                </div>
                <div
                  className={classes.textLight}
                  style={{ marginBottom: "10px" }}
                >
                  Name :{" "}
                  <span className={classes.textDark}>{state.org_name}</span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span className={classes.textLight}>Address :</span>
                  <span className={classes.textDark}>
                    {state.address +
                      ", " +
                      state.city +
                      ", " +
                      state.postalCode +
                      ". "}
                  </span>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} sm={6} md={6} lg={6}>
                      <div>
                        <span className={classes.textLight}>{" State: "}</span>
                        <span className={classes.textDark}>{state.state}</span>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={6} md={6} lg={6}>
                      <div>
                        <span className={classes.textLight}>{" Phone: "}</span>
                        <span className={classes.textDark}>{state.phone}</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} sm={6} md={6} lg={6}>
                      <div>
                        <span
                          className={classes.textLight}
                          style={{ marginBottom: "5px" }}
                        >
                          {" Contact Person: "}
                        </span>
                        <span className={classes.textDark}>{state.name}</span>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={6} md={6} lg={6}>
                      <div>
                        <span
                          className={classes.textLight}
                          style={{ marginBottom: "5px" }}
                        >
                          {" Email: "}
                        </span>
                        <span className={classes.textDark}>{state.email}</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <span className={classes.textLight}>Consignee Name:</span>
                      <span className={classes.textDark}>{state.org_name}</span>
                    </Grid>
                  </Grid>
                </div>

                <Grid container>
                  <Grid item xs={8} sm={6} md={6} lg={6}>
                    <span className={classes.textLight}>{"Address: "}</span>
                    <span className={classes.textDark}>
                      {state.delivery_location}
                    </span>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6} lg={6}>
                    <span className={classes.textLight}>{"GSTINNo: "}</span>
                    <span className={classes.textDark}>{state.gstNumber}</span>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
        {state.reason_for_rejection != "" ? (
          <div style={{ marginBottom: "25px" }} id={"comment-block"}>
            <div className={classes.textDark} style={{ marginBottom: "10px" }}>
              Comment For Rejection
            </div>
            <Paper style={{ padding: "10px" }}>
              <p className={classes.comment}>{state.reason_for_rejection}</p>
            </Paper>
          </div>
        ) : (
          ""
        )}
        <div style={{ marginBottom: "25px" }}>
          <Paper className={classes.container}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Total Material</TableCell>
                  <TableCell align="center">Total Quantity</TableCell>
                  <TableCell align="center">Total Rate(with GST)</TableCell>
                  <TableCell align="center">Discount</TableCell>
                  <TableCell align="center">GST</TableCell>
                  <TableCell align="center">Final Amount(with GST)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row" align="center">
                    {state.total_materials}
                  </TableCell>
                  <TableCell align="center">{state.total_quantity}</TableCell>
                  <TableCell align="center">{state.total_rate}</TableCell>
                  <TableCell align="center">{state.discount}</TableCell>
                  <TableCell align="center">{state.gst}</TableCell>
                  <TableCell align="center">{state.total_amount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
        <div className={classes.table}>
          <Paper className={classes.container}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Material</TableCell>
                  <TableCell align="center">Material Code</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Rate</TableCell>
                  <TableCell align="center">Total(with GST)</TableCell>
                  <TableCell align="center">Consignee</TableCell>
                  <TableCell align="center">Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.rows.map((row) => (
                  <TableRow key={row.material_name}>
                    <TableCell component="th" scope="row" align="center">
                      {row.material_name}
                    </TableCell>
                    <TableCell align="center">{row.material_code}</TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="center">{row.rate}</TableCell>
                    <TableCell align="center">{row.total}</TableCell>
                    <TableCell align="center">{row.consignee}</TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        {currentUser && (
          <> {currentUser.status === 0 && <Redirect to="/dashboard" />}</>
        )}
        {currentUser === null && <Redirect to="/" />}
        <Notifications notify={notify} setNotify={setNotify} />
      </main>
      <div className={classes.approveBlock}>
        {state.status >= STATUS.APPROVED && <ExportPDF distributorName={state.org_name} orderId={state.ref_no} />}
      </div>
    </>
  );
};

export default OrderView;
